import React from 'react'
import { Link } from 'react-router-dom'
import Breadcrums from '../../components/company_panel/common/breadcrumbs';


const RefundConfiguration = () => {

    const breadcrumbs = [
        {
            title: "Refund ",
            url: "/company/transaction/refund/list/1"
        },
        {
            title: "Configuration",
            url: ""
        },
    ]

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <Breadcrums data={breadcrumbs} />
                        <h4>Refund</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <ul className="d-flex setting w-100 mb-0 p-0">
                                <li className="active"><Link to={`/company/transaction/refund/configuration`} className="btn btn-primary">Refund Configuration</Link></li>
                                <li className="ms-3"><Link to={`/company/transaction/refund/reasons/list`} className="btn">Reasons</Link></li>
                            </ul>
                        </div>
                    </div>
                    <form>
                        <div className="row-sm">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className="row row-sm">
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='name' className='text-left d-flex'>Dine-in <span className="requirestar">*</span></label>
                                            <input name='name' id="name" type='text' placeholder='Enter Order Refund Time' className='form-control' />
                                            <span className='text-danger d-flex text-left'></span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='name' className='text-left d-flex'>Take-out <span className="requirestar">*</span></label>
                                            <input name='name' id="name" type='text' placeholder='Enter Order Refund Time' className='form-control' />
                                            <span className='text-danger d-flex text-left'></span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='name' className='text-left d-flex'>Pick-up <span className="requirestar">*</span></label>
                                            <input name='name' id="name" type='text' placeholder='Enter Order Refund Time' className='form-control' />
                                            <span className='text-danger d-flex text-left'></span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='name' className='text-left d-flex'>Drive-thru <span className="requirestar">*</span></label>
                                            <input name='name' id="name" type='text' placeholder='Enter Order Refund Time' className='form-control' />
                                            <span className='text-danger d-flex text-left'></span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='name' className='text-left d-flex'>Delivery <span className="requirestar">*</span></label>
                                            <input name='name' id="name" type='text' placeholder='Enter Order Refund Time' className='form-control' />
                                            <span className='text-danger d-flex text-left'></span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <h2 className='text-left d-flex mb-2'>Outsource</h2>
                                            <label htmlFor='name' className='text-left d-flex'>ABC Aggregator <span className="requirestar">*</span></label>
                                            <input name='name' id="name" type='text' placeholder='Enter Order Refund Time' className='form-control' />
                                            <span className='text-danger d-flex text-left'></span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='name' className='text-left d-flex'>ABCD Aggregator <span className="requirestar">*</span></label>
                                            <input name='name' id="name" type='text' placeholder='Enter Order Refund Time' className='form-control' />
                                            <span className='text-danger d-flex text-left'></span>
                                        </div>
                                        <div className="mt-2">
                                            <button className="btn btn-primary mr-2" type="button" >
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default RefundConfiguration