import React, { useEffect, useState } from 'react'
import { useNavigate, Link, NavLink } from 'react-router-dom'
import { Formik } from 'formik'
import Swal from 'sweetalert2'
import { ChangePassword } from '../../services/company_panel/LoginCompanyServices';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CompanyChangePassword = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState('false')
    const [newshow, setNewShow] = useState('false')
    const [conformshow, setConformShow] = useState('false')
    const [profileid, setProfileId] = useState()
    const [submitted, setSubmitted] = useState(false)
    const breadcrumbs = [{ title: "My Profile", url: "/company/profile" }, { title: "Edit Profile", url: "" }]


    const handleshow = (event) => {
        if (event === "oldshw") {
            setShow(!show)
        }
        if (event === "newpwd") {
            setNewShow(!newshow)
        }
        if (event === "confirepwd") {
            setConformShow(!conformshow)
        }   
    }

    useEffect(() => {
        const id = JSON.parse(localStorage.getItem("company_user"))
        setProfileId(id.id)
    }, [])


    return (
        <>

            <div className='page-header d-flex align-items-center'>
                <div className='page-header-left'>
                    <h4>My Profile</h4>
                    <Breadcrums data={breadcrumbs} />
                </div>
                <div className='page-header-right ms-auto'>
                    {/* <Link className="btn btn-primary" to={"/company/profile/edit"}>
                            Edit Profile
                        </Link> */}
                </div>
            </div>

            <Formik
                enableReinitialize
                initialValues={{
                    oldpassword: "",
                    newpassword: "",
                    confirmpassword: "",
                }}
                validate={values => {
                    const error = {};
                    if (!values.oldpassword) error.oldpassword = "Old password is required";
                    if (!values.newpassword) {
                        error.newpassword = "New Password is required";
                    } else if (values.newpassword.length < 6) {
                        error.newpassword = "New Password must be at least 6 characters long";
                    }

                    if (!values.confirmpassword) {
                        error.confirmpassword = "Confirm Password is required";
                    } else if (values.confirmpassword.length < 6) {
                        error.confirmpassword = "Confirm Password must be at least 6 characters long";
                    }

                    if (values.newpassword != values.confirmpassword) error.confirmpassword = "Password does not match"
                    return error;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitted(true)
                    let formData = new FormData();
                    formData.append('id', profileid);
                    formData.append('current_password', values.oldpassword);
                    formData.append('new_password', values.newpassword);
                    formData.append('confirm_password', values.confirmpassword);
                    ChangePassword(formData).then(data => {
                        setSubmitting(false);
                        setSubmitted(false)
                        if (data && data.data && data.data.success === 1) {
                            Swal.fire({
                                customClass: 'swal-wide',
                                icon: 'success',
                                title: data.data.message,
                                showConfirmButton: false,
                                timer: 2000,
                                toast: true,
                                position: 'top-right',
                            })
                            resetForm({ values: '' });
                            setTimeout(() => {
                                localStorage.clear();
                                navigate('/company/login');
                            }, 2000);
                        } else {
                            Swal.fire({
                                customClass: 'swal-wide',
                                icon: 'error',
                                title: data.data.error[0].msg,
                                showConfirmButton: false,
                                timer: 2000,
                                toast: true,
                                position: 'top-right',
                            })
                        }
                    }).catch(error => {
                        setSubmitted(false)
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                {/* <ul class="nav ml-2 mt-4 border-bottom">
                                    <li class="nav-item ">
                                        <Link className="dropdown-item " to={"/company/profile/edit"}>
                                            Edit Profile
                                        </Link>
                                    </li>
                                    <li class="nav-item border-bottom border-secondary">
                                        <Link className="dropdown-item " to={"/company/changepassword"}>
                                            Change Password
                                        </Link>
                                    </li>
                                </ul> */}
                                <div className='gap-20 border-bottom'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <ul className="d-flex setting w-100 mb-0 p-0">
                                            <li className=""><Link to={"/company/profile/edit"} className="btn">Edit Profile</Link></li>
                                            <li className="ms-3 active"><Link to={"/company/changepassword"} className="btn btn-primary">Change Password</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="main-content-body tab-pane px-4 border-top-0 active" id="edit">
                                    {/* <div className="profile-tab tab-menu-heading mt-5">
                                        <nav className="nav main-nav-line p-3 mb-3 tabs-menu profile-nav-line bg-gray-100">
                                            <span className="nav-link pl-2" data-toggle="tab" href="#edit">My Profile</span>
                                        </nav>
                                    </div> */}
                                    <div className="my-4">
                                        <div className='row'>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='oldpassword' className='text-left d-flex'>Old Password <span className="requirestar">*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type={!show === true ? "text" : "password"}
                                                        className="form-control"
                                                        id="oldpassword"
                                                        name="oldpassword"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.oldpassword}
                                                        placeholder="Enter old password" />
                                                    <span className='input-group-text' onClick={() => { handleshow("oldshw") }}>{(!show === true ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>)}</span>
                                                </div>
                                                <span className='text-danger d-flex text-left'>{errors.oldpassword && touched.oldpassword && errors.oldpassword}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='newpassword' className='text-left d-flex'>New Password <span className="requirestar">*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type={!newshow === true ? "text" : "password"}
                                                        id="newpassword"
                                                        name='newpassword'
                                                        className="form-control"
                                                        placeholder="Enter new password"
                                                        value={values.newpassword}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    <span className='input-group-text' onClick={() => { handleshow("newpwd") }}>{(!newshow === true ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>)}</span>
                                                </div>
                                                <span className='text-danger d-flex text-left'>{errors.newpassword && touched.newpassword && errors.newpassword}</span>
                                            </div>



                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='confirmpassword' className='text-left d-flex'>Confirm Password <span className="requirestar">*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type={!conformshow === true ? "text" : "password"}
                                                        id="confirmpassword"
                                                        name='confirmpassword'
                                                        className="form-control"
                                                        placeholder="Enter confirm password"
                                                        value={values.confirmpassword}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        autoComplete="off" />
                                                    <span className='input-group-text' onClick={() => { handleshow("confirepwd") }}>{(!conformshow === true ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>)}</span>
                                                </div>
                                                <span className='text-danger d-flex text-left'>{errors.confirmpassword && touched.confirmpassword && errors.confirmpassword}</span>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <button className="btn btn-primary mr-2" type="submit" disabled={submitted ? true : null}>

                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>
    )
}

export default CompanyChangePassword