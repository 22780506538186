
import React, { useState, useEffect, useMemo } from 'react'
import { Field, Formik } from 'formik';
import { CreateInventory } from '../../services/company_panel/inventoryService';
import { GetAllChildCategories, GetAllParentCategories, GetParentCategories } from '../../services/company_panel/categoryService';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, getBrandId, getCompany } from '../../helpers/commonFunctions';
import { Form } from 'react-bootstrap';
import { getAllUoms } from '../../services/company_panel/setting/uomConfigurationService';
import { globalLoader } from '../../helpers/global';
import { getAllMenuItems } from '../../services/company_panel/mealService';
const InventoryAdd = () => {

    const breadcrumbs = [
        {
            title: "Inventory",
            url: "/company/raw_materials/inventory/list/1"
        },
        {
            title: "Create inventory",
            url: ""
        },
    ]

    // const conversionFactors = {
    //     kg: {
    //         g: 1000,
    //         kg: 1,
    //         quintal: 0.01,
    //         ton: 0.001,
    //     },
    //     g: {
    //         g: 1,
    //         kg: 0.001,
    //         quintal: 0.00001,
    //         ton: 0.000001,
    //     },
    //     ton: {
    //         g: 1000000,
    //         kg: 1000,
    //         quintal: 10,
    //         ton: 1,
    //     },
    //     quintal: {
    //         kg: 100,
    //         g: 100000,
    //         quintal: 1,
    //         ton: 0.1
    //     },
    //     ml: {
    //         ml: 1,
    //         l: 0.001
    //     },
    //     l: {
    //         ml: 1000,
    //         l: 1
    //     },
    //     quantity: {
    //         quantity: 1
    //     }
    // };
    // const primaryUnits = [
    //     { key: 'kg', value: 'KG' },
    //     { key: 'ml', value: 'ML' },
    //     { key: 'g', value: 'Grams' },
    //     { key: 'l', value: 'Litre' },
    //     { key: 'quantity', value: 'Quantity' },
    //     { key: 'quintal', value: 'Quintal' },
    //     { key: 'ton', value: 'Ton' },
    // ];
    const [primaryUnit, setPrimaryUnit] = useState('');
    // const [transferUnits, setTransferUnits] = useState([]);

    const onChangePrimaryUnit = (unit, setFieldValue, values) => {
        // if (unit == 'l' || unit == 'ml') {
        //     setTransferUnits([
        //         { key: 'ml', value: 'ML' },
        //         { key: 'l', value: 'Litre' },
        //     ])
        // } else if (unit == 'quantity') {
        //     setTransferUnits([
        //         { key: 'quantity', value: 'Quantity' },
        //     ])
        // } else {
        //     setTransferUnits([
        //         { key: 'kg', value: 'KG' },
        //         { key: 'g', value: 'Grams' },
        //         { key: 'quintal', value: 'Quintal' },
        //         { key: 'ton', value: 'Ton' },
        //     ])
        // }

        setFieldValue('conversion1', 1);
        setFieldValue('price1', values.raw_material_price ? Math.floor(values.raw_material_price) : "");
        setFieldValue('calories1', values.calories_as_per_primary_unit ? Math.floor(values.calories_as_per_primary_unit) : "");
        setFieldValue('uom2', '');
        setFieldValue('conversion2', '');
        setFieldValue('price2', '');
        setFieldValue('calories2', '');
        setFieldValue('uom3', '');
        setFieldValue('conversion3', '');
        setFieldValue('price3', '');
        setFieldValue('calories3', '');
        setFieldValue('uom4', '');
        setFieldValue('conversion4', '');
        setFieldValue('price4', '');
        setFieldValue('calories4', '');
    }
    const onChangeCalories = (setFieldValue, value, values) => {
        setFieldValue('calories1', Math.floor(value));
        let calories;
        if (values.conversion2) {
            calories = Math.floor(value) * values.conversion2;
            setFieldValue('calories2', Math.floor(calories) || Math.ceil(calories));
        }
        if (values.conversion3) {
            calories = Math.floor(value) * values.conversion3;
            setFieldValue('calories3', Math.floor(calories) || Math.ceil(calories));
        }
        if (values.conversion4) {
            calories = Math.floor(value) * values.conversion4;
            setFieldValue('calories4', Math.floor(calories) || Math.ceil(calories));
        }
    }
    const onChangePrice = (setFieldValue, value, values) => {
        setFieldValue('price1', Math.floor(value));
        let price;
        if (values.conversion2) {
            price = Math.floor(value) * values.conversion2
            setFieldValue('price2', Math.floor(price) || Math.ceil(price));
        }
        if (values.conversion3) {
            price = Math.floor(value) * values.conversion3;
            setFieldValue('price3', Math.floor(price) || Math.ceil(price));
        }
        if (values.conversion4) {
            price = Math.floor(value) * values.conversion4;
            setFieldValue('price4', Math.floor(price) || Math.ceil(price));
        }
    }

    const unitConversion = (conversion, values, field, setFieldValue) => {
        const price = conversion * values.raw_material_price;
        const calories = conversion * values.calories_as_per_primary_unit;
        // setFieldValue(`price${field}`, Math.floor(price) || Math.ceil(price));
        // setFieldValue(`calories${field}`, Math.floor(calories) || Math.ceil(calories));
        setFieldValue(`price${field}`, price);
        setFieldValue(`calories${field}`, calories);
    }
    // const onChangeUOM = (unit1, values, field, setFieldValue) => {
    //     const unit2 = values.primary_unit_of_measure;
    //     if (conversionFactors[unit1] && conversionFactors[unit1][unit2]) {
    //         setFieldValue(`conversion${field}`, conversionFactors[unit1][unit2]);
    //         setFieldValue(`price${field}`, conversionFactors[unit1][unit2] * values.raw_material_price);
    //         setFieldValue(`calories${field}`, conversionFactors[unit1][unit2] * values.calories_as_per_primary_unit);
    //     }
    // }

    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const [parentCategories, setParentCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [uomList, setUOMList] = useState([]);
    const [page, setPage] = useState(1);
    const [btnDisable, setBtnDisable] = useState(true);
    const [isOutletChanged, setIsOutletChanged] = useState(false);
    const [isBrandChanged, setIsBrandChanged] = useState(false);


    const generateSKU = (length = 8, setFieldValue) => {

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let sku = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters?.length);
            sku += characters[randomIndex];
        }
        setFieldValue('sku', sku);
    }

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
    });

    useEffect(() => {
        const formData = new FormData()
        formData.append('brand_id', getBrandId());
        GetParentCategories(formData).then(data => {
            setParentCategories(data && data.data && data.data.response ? data.data.response.rows : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })
        getAllUoms({ brand_id: getBrandId() }).then(data => {
            setUOMList(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [isBrandChanged])


    const nextStep = (values) => {
        const tempUnit = uomList.find((uom) => uom?.id == values.primary_unit_of_measure);
        const label = `${tempUnit?.uom}`;
        setPrimaryUnit(label);

        setPage(page + 1);
        var currentStepNum = $('#checkout-progress').data('current-step');
        var nextStepNum = (currentStepNum + 1);
        var currentStep = $('.step.step-' + currentStepNum);
        var nextStep = $('.step.step-' + nextStepNum);
        var progressBar = $('#checkout-progress');
        $('.btn-prev').show();
        $('#section' + currentStepNum).toggle();
        $('#section' + nextStepNum).toggle();
        if (nextStepNum == 2) {
            $(this).toggle();
            $('.btn-submit').toggle();
            $('.btn-next').hide();
        }

        $('.checkout-progress').removeClass('.step-' + currentStepNum).addClass('.step-' + (currentStepNum + 1));

        currentStep.removeClass('active').addClass('valid');
        currentStep.find('span').addClass('opaque');
        currentStep.find('.fa.fa-check').removeClass('opaque');

        nextStep.addClass('active');
        progressBar.removeAttr('class').addClass('step-' + nextStepNum).data('current-step', nextStepNum);
    }

    const prevStep = (selected) => {
        setPage(page - 1);
        var currentStepNum = $('#checkout-progress').data('current-step');
        var prevStepNum = (currentStepNum - 1);
        var currentStep = $('.step.step-' + currentStepNum);
        var prevStep = $('.step.step-' + prevStepNum);
        var progressBar = $('#checkout-progress');
        $('.btn-next').removeClass('disabled');
        $('#section' + currentStepNum).toggle();
        $('#section' + prevStepNum).toggle();
        if (currentStepNum == 2) {
            $('.btn-submit').toggle();
            $('.btn-next').toggle();
        }

        if (currentStepNum == 2) {
            $('.btn-prev').hide();
        }
        if (currentStepNum == 1) {
            $('.btn-submit').hide();
            $('.btn-prev').hide();
            $('.btn-next').toggle();
            return false;
        }
        if (prevStepNum == 1) {
            $(this).addClass('disabled');
        }
        $('.checkout-progress').removeClass('.step-' + currentStepNum).addClass('.step-' + (prevStepNum));

        currentStep.removeClass('active');
        prevStep.find('span').removeClass('opaque');
        prevStep.find('.fa.fa-check').addClass('opaque');

        prevStep.addClass('active').removeClass('valid');
        progressBar.removeAttr('class').addClass('step-' + prevStepNum).data('current-step', prevStepNum);
    };

    const getSubCategories = (categoryId) => {
        console.log('getting sub-categories id: ', categoryId);
        if (!categoryId) {
            return;
        }
        const formData = new FormData()
        formData.append('category_id', categoryId);
        formData.append('brand_id', getBrandId());
        GetAllChildCategories(formData).then(data => {
            console.log(data.data.response.rows);
            setSubCategories(data && data.data && data.data.response ? data.data.response.rows : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })  
    };  

    const [activeStep, setActiveStep] = useState(1);

    // Function to handle step changes
    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    const [menuItemData, setMenuItemsData] = useState([]);

    useEffect(() => {
        getAllMenuItems({ brand_id: getBrandId() }).then(data => {
            if (data && data.data && data.data.response) {
                setMenuItemsData(data.data.response.rows);
            }
        }).catch(error => {
            console.log("error=====>", error);
        });
    }, [isBrandChanged]);

    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                </div>
                <div class="page-header-right ms-auto">

                </div>
            </div>
            <Formik
                initialValues={{
                    category_id: '',
                    sub_category_id: '',
                    item_type: '',
                    production_item: '',
                    item_name: '',
                    sku: '',
                    primary_unit_of_measure: '',
                    calories_as_per_primary_unit: '',
                    raw_material_price: '',
                    can_change_later: '',
                    minimum_stock_limit: '',
                    transferable: '',
                    // best_time_before_use: '',
                    conversion1: '',
                    price1: '',
                    calories1: '',
                    uom2: '',
                    conversion2: '',
                    price2: '',
                    calories2: '',
                    uom3: '',
                    conversion3: '',
                    price3: '',
                    calories3: '',
                    uom4: '',
                    conversion4: '',
                    price4: '',
                    calories4: '',
                }}
                validate={values => {
                    const error = {};
                    if (!values.category_id) error.category_id = "Category is required";
                    if (!values.sub_category_id) error.sub_category_id = "Sub category is required";
                    if (!values.item_type) error.item_type = "Item type is required";
                    // if (!values.production_item) error.production_item = "Production item is required";
                    // if (!values.item_name.trim()) error.item_name = "Item name is required";
                    if (!values.item_name.trim()) {
                        error.item_name = "Item name is required";
                    } else if (values.item_name.trim().length > 100) {
                        error.item_name = "Item name cannot exceed 100 characters";
                    }

                    if (!values.sku.trim()) {
                        error.sku = "SKU is required"
                    } else if (values.sku.trim().length > 100) {
                        error.sku = "SKU cannot exceed 100 characters";
                    }
                    if (!values.primary_unit_of_measure) error.primary_unit_of_measure = "Primary unit of measure is required";

                    // if (!values.calories_as_per_primary_unit) {
                    //     error.calories_as_per_primary_unit = "Calories as per primary unit is required";
                    // } else if (parseFloat(values.calories_as_per_primary_unit) < 0) {
                    //     error.calories_as_per_primary_unit = "Calories as per primary unit cannot be negative";
                    // }
                    if (!values.calories_as_per_primary_unit) {
                        error.calories_as_per_primary_unit = "Calories as per primary unit is required";
                    } else if (!Number.isInteger(parseFloat(values.calories_as_per_primary_unit))) {
                        error.calories_as_per_primary_unit = "Calories as per primary unit must be a whole number";
                    } else if (parseFloat(values.calories_as_per_primary_unit) < 0) {
                        error.calories_as_per_primary_unit = "Calories as per primary unit cannot be negative";
                    }


                    // Validate raw_material_price field
                    if (!values.raw_material_price) {
                        error.raw_material_price = "Price of raw material is required";
                    } else if (!Number.isInteger(parseFloat(values.raw_material_price))) {
                        error.raw_material_price = "Price of raw material must be a whole number";
                    } else if (parseFloat(values.raw_material_price) < 0) {
                        error.raw_material_price = "Price of raw material cannot be negative";
                    }

                    // Validate minimum_stock_limit field
                    if (!values.minimum_stock_limit) {
                        error.minimum_stock_limit = "Minimum stock limit is required";
                    } else if (!Number.isInteger(parseFloat(values.minimum_stock_limit))) {
                        error.minimum_stock_limit = "Minimum stock limit must be a whole number";
                    } else if (parseFloat(values.minimum_stock_limit) < 0) {
                        error.minimum_stock_limit = "Minimum stock limit cannot be negative";
                    }

                    if (!values.uom2) error.uom2 = "Transfer UOM is required";
                    if (!values.uom3) error.uom3 = "Usage UOM is required";
                    if (!values.uom4) error.uom4 = "Count UOM is required";
                    if (!values.conversion1) error.conversion1 = "Primary unit conversion is required";

                    if (!values.conversion2) {
                        error.conversion2 = "Transfer unit conversion is required";
                    }
                    // else if (!Number.isInteger(parseFloat(values.conversion2))) {
                    //     error.conversion2 = "Transfer unit conversion must be a whole number";
                    // } 
                    else if (parseFloat(values.conversion2) < 0) {
                        error.conversion2 = "Transfer unit conversion cannot be negative";
                    }

                    if (!values.conversion3) {
                        error.conversion3 = "Usage unit conversion is required";
                    }
                    // else if (!Number.isInteger(parseFloat(values.conversion3))) {
                    //     error.conversion3 = "Usage unit conversion must be a whole number";
                    // } 
                    else if (parseFloat(values.conversion3) < 0) {
                        error.conversion3 = "Usage unit conversion cannot be negative";
                    }

                    if (!values.conversion4) {
                        error.conversion4 = "Count unit conversion is required";
                    }
                    // else if (!Number.isInteger(parseFloat(values.conversion4))) {
                    //     error.conversion4 = "Count unit conversion must be a whole number";
                    // }
                    else if (parseFloat(values.conversion4) < 0) {
                        error.conversion4 = "Count unit conversion cannot be negative";
                    }


                    // if (!values.best_time_before_use) error.best_time_before_use = "best time before use is required";

                    if (page == 1) {
                        if (error.category_id || error.sub_category_id || error.item_type || error.item_name || error.primary_unit_of_measure
                            || error.sku || error.calories_as_per_primary_unit || error.raw_material_price || error.minimum_stock_limit || error.best_time_before_use) {
                            setBtnDisable(true);
                        } else {
                            setBtnDisable(false);
                        }

                    }
                    return error;
                }}

                onSubmit={(values, { setSubmitting }) => {
                    setSubmitted(true);
                    globalLoader(true);
                    let formData = new FormData();
                    formData.append('category_id', values.category_id);
                    formData.append('sub_category_id', values.sub_category_id);
                    formData.append('item_type', values.item_type);
                    formData.append('production_item_id', values?.item_type === 'production' ? values?.production_item : '');
                    formData.append('item_name', values.item_name.trim());
                    formData.append('sku', values.sku.trim());

                    formData.append('primary_unit_of_measure', values.primary_unit_of_measure);
                    formData.append('raw_material_price', Math.floor(values.raw_material_price));
                    formData.append('calories_as_per_primary_unit', Math.floor(values.calories_as_per_primary_unit));

                    formData.append('can_change_later', values.can_change_later ? true : false);
                    formData.append('minimum_stock_limit', Math.floor(values.minimum_stock_limit));
                    formData.append('transferable', values.transferable);
                    // formData.append('best_time_before_use', values.best_time_before_use);
                    formData.append('company_id', getCompany().company_id);
                    formData.append('brand_id', getBrandId());

                    formData.append('conversion1', values.conversion1);
                    formData.append('price1', values.price1);
                    formData.append('calories1', values.calories1);
                    formData.append('uom2', values.uom2);
                    formData.append('conversion2', values.conversion2);
                    formData.append('price2', values.price2);
                    formData.append('calories2', values.calories2);
                    formData.append('uom3', values.uom3);
                    formData.append('conversion3', values.conversion3);
                    formData.append('price3', values.price3);
                    formData.append('calories3', values.calories3);
                    formData.append('uom4', values.uom4);
                    formData.append('conversion4', values.conversion4);
                    formData.append('price4', values.price4);
                    formData.append('calories4', values.calories4);

                    CreateInventory(formData).then(response => {
                        globalLoader(false);
                        setSubmitting(false);
                        setSubmitted(false);
                        if (response.data.status === 1 || response.data.status === "1") {
                            Swal.fire({
                                customClass: 'swal-wide',
                                icon: 'success',
                                title: response.data.message,
                                showConfirmButton: false,
                                timer: 1500,
                                toast: true,
                                position: 'top-right',
                            });
                            setTimeout(() => {
                                navigate(`/company/raw_materials/inventory/list/${1}`)
                            }, 1000);
                        } else {
                            Swal.fire({
                                customClass: 'swal-wide',
                                icon: 'error',
                                title: response.data.message,
                                showConfirmButton: false,
                                timer: 5000,
                                toast: true,
                                position: 'top-right',
                            })
                            setSubmitted(false)
                        }
                    }).catch(error => {
                        globalLoader(false);
                        console.log("error ====> ", error);
                    })
                }}

            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>

                    <div className='steps' data-current-step={activeStep}>
                        <ul>
                            <li className={`step step-1 ${activeStep >= 1 ? 'active' : ''}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(1)}>
                                <span>1</span><p>Inventory Info</p>
                            </li>
                            <li className={`step step-2 ${activeStep >= 2 ? 'active' : 'tag_disabled'}`} onClick={() => handleStepChange(2)}>
                                <span>2</span><p>Unit of Converison</p>
                            </li>
                        </ul>
                    </div>

                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="row row-sm">

                                <div className="step-1" id="checkout-progress" data-current-step="1" style={{ display: 'none', padding: '0' }}>
                                    <div className="progress-bar">
                                        <div className="step step-1 active"><span> 1</span>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="step-label">Inventory Info</div>
                                        </div>
                                        <div className="step step-2"><span> 2</span>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="step-label">Unit of Converison</div>
                                        </div>
                                    </div>
                                </div>

                                <section id="section1" className="section1 " style={{ display: 'block' }}>

                                    <div className="row">
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='category_id' className='text-left d-flex'>Category<span className="requirestar">*</span></label>
                                            <select id="category_id" className='form-control' name='category_id' onChange={(event) => { getSubCategories(event.target.value); handleChange(event); }} onBlur={handleBlur} value={values.category_id}>
                                                <option value="">Please Select</option>
                                                {parentCategories?.length ? parentCategories.map((row) => (
                                                    <option value={row.id}>{capitalizeAllFirstLetters(row.name)}</option>
                                                )) : ''}
                                            </select>
                                            <span className='text-danger d-flex text-left'>{errors.category_id && touched.category_id && errors.category_id}</span>
                                        </div>

                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='sub_category_id' className='text-left d-flex'>Sub category<span className="requirestar">*</span></label>
                                            <select id="sub_category_id" className='form-control' name='sub_category_id' onChange={handleChange} onBlur={handleBlur} value={values.sub_category_id}>
                                                <option value="">Please Select</option>
                                                {subCategories?.length ? subCategories.map((row) => (
                                                    <option value={row.id}>{capitalizeAllFirstLetters(row.name)}</option>
                                                )) : ''}
                                            </select>
                                            <span className='text-danger d-flex text-left'>{errors.sub_category_id && touched.sub_category_id && errors.sub_category_id}</span>
                                        </div>
                                    </div>
                                    {/* 
                                    <div className="row">
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='item_type' className='text-left d-flex'>Item type<span className="requirestar">*</span></label>
                                            <select id="item_type" className='form-control' name='item_type' onChange={handleChange} onBlur={handleBlur} value={values.item_type}>
                                                <option value="">Please Select</option>
                                                <option value="raw-material">Raw material</option>
                                                <option value="semi-finished-goods">Semi finished goods</option>
                                                <option value="production">Production</option>
                                            </select>
                                            <span className='text-danger d-flex text-left'>{errors.item_type && touched.item_type && errors.item_type}</span>
                                        </div>

                                        {values?.item_type === 'production' ? <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='production_item' className='text-left d-flex'>Production item<span className="requirestar">*</span></label>
                                            <select id="production_item" className='form-control' name='production_item' onChange={handleChange} onBlur={handleBlur} value={values.production_item}>
                                                <option value="">Please Select</option>
                                                <option value="item1">Item1</option>
                                                <option value="item2">Item2</option>
                                              
                                            </select>
                                            <span className='text-danger d-flex text-left'>{errors.production_item && touched.production_item && errors.production_item}</span>
                                        </div> : ''}
                                    </div> */}
                                    <div className="row">
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='item_type' className='text-left d-flex'>
                                                Item type<span className="requirestar">*</span>
                                            </label>
                                            <select
                                                id="item_type"
                                                className='form-control'
                                                name='item_type'    
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.item_type}
                                            >
                                                <option value="">Please Select</option>
                                                <option value="raw-material">Raw material</option>
                                                <option value="semi-finished-goods">Semi finished goods</option>
                                                {menuItemData.length > 0 && (<option value="production">Production</option>)}
                                            </select>
                                            <span className='text-danger d-flex text-left'>
                                                {errors.item_type && touched.item_type && errors.item_type}
                                            </span>
                                        </div>

                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='production_item' className='text-left d-flex'>
                                                Production item<span className="requirestar"></span>
                                            </label>
                                            <select
                                                id="production_item"
                                                className='form-control'
                                                name='production_item'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.item_type === 'production' ? values.production_item : ""}
                                                disabled={values.item_type !== 'production'}
                                            >
                                                <option value="">Please Select</option>
                                                {menuItemData?.length ? menuItemData.map((row) => (
                                                    <option value={row.id}>{capitalizeAllFirstLetters(row.item_name)}</option>
                                                )) : ''}
                                            </select>
                                            <span className='text-danger d-flex text-left'>
                                                {errors.production_item && touched.production_item && errors.production_item}
                                            </span>
                                        </div>

                                    </div>


                                    <div className="row">
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='item_name' className='text-left d-flex'>Item name<span className="requirestar">*</span></label>
                                            <input type="text" name="item_name" className="form-control" id="item_name" placeholder='Enter item name' onChange={handleChange} onBlur={handleBlur} value={values.item_name} autoComplete='off' />
                                            {/* <select id="item_name" className='form-control' name='item_name' onChange={handleChange}  onBlur={handleBlur} value={values.item_name}>
                                                    <option value="">Please Select</option>
                                                    <option value="item1">Item1</option>
                                                    <option value="item2">Item2</option>
                                                    </select>   */}
                                            <span className='text-danger d-flex text-left'>{errors.item_name && touched.item_name && errors.item_name}</span>
                                        </div>

                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='sku' className='text-left d-flex'>SKU<span className="requirestar">*</span></label>
                                            {/* <input name='sku' id="sku" type='text' onChange={handleChange} onBlur={handleBlur} value={values.sku}  className='form-control' /> */}
                                            <div className="set-plus-icon-input">
                                                <input type="text" name="sku" className="form-control" id="sku" placeholder='Enter SKU' onChange={handleChange} onBlur={handleBlur} value={values.sku} autoComplete='off' />
                                                <div className="icon-box">
                                                    <a onClick={(e) => generateSKU(8, setFieldValue)}><i className="fa fa-rotate-right"></i></a>
                                                </div>
                                            </div>
                                            <span className='text-danger d-flex text-left'>{errors.sku && touched.sku && errors.sku}</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='primary_unit_of_measure' className='text-left d-flex'>Primary unit of measure<span className="requirestar">*</span></label>
                                            <select id="primary_unit_of_measure" className='form-control' name='primary_unit_of_measure' onChange={(event) => { handleChange(event); onChangePrimaryUnit(event.target.value, setFieldValue, values) }} onBlur={handleBlur} value={values.primary_unit_of_measure}>
                                                <option value="">Please Select</option>
                                                {uomList?.length ? uomList.map((uom) => (
                                                    <option value={uom?.id}>{capitalizeFirstLetter(uom?.uom)} {capitalizeFirstLetter(uom?.uom_code)}</option>
                                                )) : ''}
                                            </select>
                                            <span className='text-danger d-flex text-left'>{errors.primary_unit_of_measure && touched.primary_unit_of_measure && errors.primary_unit_of_measure}</span>
                                        </div>

                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='calories_as_per_primary_unit' className='text-left d-flex'>Calories as per primary unit<span className="requirestar">*</span></label>
                                            <input name='calories_as_per_primary_unit' placeholder='Enter Calories as per primary unit' id="calories_as_per_primary_unit" type='number' onChange={(event) => { handleChange(event); onChangeCalories(setFieldValue, event.target.value, values) }} onBlur={handleBlur} value={values.calories_as_per_primary_unit} className='form-control' />
                                            {/* <select id="calories_as_per_primary_unit" className='form-control' name='calories_as_per_primary_unit' onChange={handleChange}  onBlur={handleBlur} value={values.calories_as_per_primary_unit}>
                                                    <option value="">Please Select</option>
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    </select>   */}
                                            <span className='text-danger d-flex text-left'>{errors.calories_as_per_primary_unit && touched.calories_as_per_primary_unit && errors.calories_as_per_primary_unit}</span>
                                        </div>
                                    </div>



                                    <div className="row">
                                        {/* <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='single_piece_calories' className='text-left d-flex'>Calories of a single piece<span className="requirestar">*</span></label>
                                                <input name='single_piece_calories' id="single_piece_calories" type='number' onChange={handleChange} onBlur={handleBlur} value={values.single_piece_calories}  className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.single_piece_calories && touched.single_piece_calories && errors.single_piece_calories}</span>
                                            </div> */}

                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label htmlFor='raw_material_price' className='text-left d-flex'>Price of raw material<span className="requirestar">*</span></label>
                                                <div className='form-check text-start'>
                                                    <input
                                                        type="checkbox"
                                                        id="can_change_later"
                                                        name="can_change_later"

                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.can_change_later}
                                                        className="form-check-input cp"

                                                    />
                                                    <label htmlFor="can_change_later" className="form-check-label cp">Can change later</label>
                                                </div>
                                            </div>
                                            <input name='raw_material_price' placeholder='Enter Price of raw material' id="raw_material_price" type='number' onChange={(event) => { handleChange(event); onChangePrice(setFieldValue, event.target.value, values) }} onBlur={handleBlur} value={values.raw_material_price} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.raw_material_price && touched.raw_material_price && errors.raw_material_price}</span>
                                        </div>

                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='minimum_stock_limit' className='text-left d-flex'>Minimum stock limit<span className="requirestar">*</span></label>
                                            <input name='minimum_stock_limit' placeholder='Enter Minimum stock' id="minimum_stock_limit" type='number' onChange={handleChange} onBlur={handleBlur} value={values.minimum_stock_limit} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.minimum_stock_limit && touched.minimum_stock_limit && errors.minimum_stock_limit}</span>
                                        </div>
                                    </div>


                                    <div className="row">


                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            {/* <label htmlFor='transferable' className='text-left d-flex'>Transferable</label>
                                            <select id="transferable" className='form-control' name='transferable' onChange={handleChange} onBlur={handleBlur} value={values.transferable}>
                                                <option value="">Please Select</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                                {/* {subCategories.length ? subCategories.map((row) => ( 
                                                        <option value={row.id}>{row.name}</option>
                                                    )):''} 
                                            </select> */}
                                            <div className='col-lg-6 text-start form-group mb-3 mr-3'>
                                                <label className='text-left d-flex'>Transferable</label>   </div>
                                            <div className="d-flex align-items-center">
                                                <div className="form-check w-25 text-start d-flex align-items-center me-3">
                                                    <input
                                                        type="radio"
                                                        id="transferableYes"
                                                        name="transferable"
                                                        value="true"
                                                        checked={values.transferable === "true"}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="form-check-input cp"
                                                    />
                                                    <label className="form-check-label cp ms-2" htmlFor="transferableYes">Yes</label>
                                                </div>
                                                <div className="form-check w-25 text-start d-flex align-items-center">
                                                    <input
                                                        id="transferableNo"
                                                        type="radio"
                                                        name="transferable"
                                                        value="false"
                                                        checked={values.transferable === "false"}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="form-check-input cp"
                                                    />
                                                    <label className="form-check-label cp ms-2" htmlFor="transferableNo">No</label>
                                                </div>
                                            </div>
                                        </div>



                                        {/* <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='best_time_before_use' className='text-left d-flex'>Best time before use:</label>
                                            <Form.Control
                                                style={{ width: '754px', marginBottom: '20px' }}
                                                type="date"
                                                name="best_time_before_use"
                                                value={values.best_time_before_use}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <span className='text-danger d-flex text-left'>{errors.best_time_before_use && touched.best_time_before_use && errors.best_time_before_use}</span>
                                        </div> */}

                                    </div>
                                    {/* <div className='col-lg-6 form-group'>
                                        <input type="checkbox" id="can_change_later" name="can_change_later" onChange={handleChange} onBlur={handleBlur} value={values.can_change_later} />
                                        <label for="can_change_later"> can change later</label>
                                    </div> */}


                                </section>

                                <section id="section2" className="section2 " style={{ display: 'none', padding: '0' }}>
                                    {/* <div className="d-flex">
                                        <label className='col-3 text-center' style={{ fontWeight: '800' }}>UOM</label>
                                        <label className='col-3 text-center' style={{ fontWeight: '800' }}>Conversion</label>
                                        <label className='col-3 text-center' style={{ fontWeight: '800' }}>Price</label>
                                        <label className='col-3 text-center' style={{ fontWeight: '800' }}>Calories</label>
                                    </div>

                                    <div className='d-flex mt-4'>
                                        <h5 className='col-1'>Primary Unit of Measurement</h5>

                                        <input type='text' onBlur={handleBlur}
                                            value={primaryUnit}
                                            className='form-control ms-2  ' disabled={true}
                                        />
                                        <div className='w-100 form-group'>
                                            <input id="conversion1" className='form-control mr-3' name='conversion1' type='number' onBlur={handleBlur} onChange={(event) => { handleChange(event); unitConversion(event.target.value, values, 1, setFieldValue); }} value={values.conversion1} />
                                            <span className='text-danger d-flex text-left'>{errors.conversion1 && touched.conversion1 && errors.conversion1}</span>
                                        </div>
                                        <input id="price1" name='price1' type='number' onBlur={handleBlur} value={values.price1} className='form-control ms-2' disabled={true} />
                                        <input id="calories1" name='calories1' type='number' onBlur={handleBlur} value={values.calories1} className='form-control ms-2' disabled={true} />
                                    </div>

                                    <div className='d-flex mt-4'>
                                        <h5 className='col-1'>Transfer Unit of Measure</h5>
                                        <div className='w-100 form-group'>
                                            <select id="uom2" className='form-control' name='uom2' onChange={(event) => { handleChange(event); onChangeUOM(event.target.value, values, 2, setFieldValue) }} onBlur={handleBlur} value={values.uom2}>
                                                <option value="">Please Select</option>
                                                {transferUnits.length ? transferUnits.map((row) => (
                                                    <option value={row.key}>{row.value}</option>
                                                )) : ''}
                                            </select>
                                            <span className='text-danger d-flex text-left'>{errors.uom2 && touched.uom2 && errors.uom2}</span>
                                        </div>
                                        <div className='w-100'>
                                            <input name='conversion2' id="conversion2" type='number' onChange={(event) => { handleChange(event); unitConversion(event.target.value, values, 2, setFieldValue); }} onBlur={handleBlur} value={values.conversion2} className='form-control ms-2' />
                                            <span className='text-danger d-flex text-left'>{errors.conversion2 && touched.conversion2 && errors.conversion2}</span>
                                        </div>
                                        <input name='price2' id="price2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.price2} className='form-control ms-3' disabled={true} />
                                        <input name='calories2' id="calories2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.calories2} className='form-control ms-2' disabled={true} />
                                    </div>

                                    <div className='d-flex mt-4'>
                                        <h5 className='col-1'>Usage Unit of Measure</h5>
                                        <div className='w-100 form-group'>
                                            <select id="uom3" className='form-control' name='uom3' onChange={(event) => { handleChange(event); onChangeUOM(event.target.value, values, 3, setFieldValue) }} onBlur={handleBlur} value={values.uom3}>
                                                <option value="">Please Select</option>
                                                {transferUnits.length ? transferUnits.map((row) => (
                                                    <option value={row.key}>{row.value}</option>
                                                )) : ''}
                                            </select>
                                            <span className='text-danger d-flex text-left'>{errors.uom3 && touched.uom3 && errors.uom3}</span>
                                        </div>
                                        <div className='w-100'>
                                            <input name='conversion3' id="conversion3" type='number' onChange={(event) => { handleChange(event); unitConversion(event.target.value, values, 3, setFieldValue); }} onBlur={handleBlur} value={values.conversion3} className='form-control ms-2' />
                                            <span className='text-danger d-flex text-left'>{errors.conversion3 && touched.conversion3 && errors.conversion3}</span>
                                        </div>
                                        <input name='price3' id="price3" type='text' onChange={handleChange} onBlur={handleBlur} value={values.price3} className='form-control ms-3' disabled={true} />
                                        <input name='calories3' id="calories3" type='text' onChange={handleChange} onBlur={handleBlur} value={values.calories3} className='form-control ms-2' disabled={true} />
                                    </div>

                                    <div className='d-flex mt-4'>
                                        <h5 className='col-1'>Count Unit of Measurement</h5>
                                        <div className='w-100 form-group'>
                                            <select id="uom4" className='form-control' name='uom4' onChange={(event) => { handleChange(event); onChangeUOM(event.target.value, values, 4, setFieldValue) }} onBlur={handleBlur} value={values.uom4} >
                                                <option value="">Please Select</option>
                                                {transferUnits.length ? transferUnits.map((row) => (
                                                    <option value={row.key}>{row.value}</option>
                                                )) : ''}
                                            </select>
                                            <span className='text-danger d-flex text-left'>{errors.uom4 && touched.uom4 && errors.uom4}</span>
                                        </div>
                                        <div className='w-100'>
                                            <input name='conversion4' id="conversion4" type='number' onChange={(event) => { handleChange(event); unitConversion(event.target.value, values, 4, setFieldValue); }} onBlur={handleBlur} value={values.conversion4} className='form-control ms-2' />
                                            <span className='text-danger d-flex text-left'>{errors.conversion4 && touched.conversion4 && errors.conversion4}</span>
                                        </div>
                                        <input name='price4' id="price4" type='text' onChange={handleChange} onBlur={handleBlur} value={values.price4} className='form-control ms-3' disabled={true} />
                                        <input name='calories4' id="calories4" type='text' onChange={handleChange} onBlur={handleBlur} value={values.calories4} className='form-control ms-2' disabled={true} />
                                    </div> */}


                                    <table className="table table-custom p-0">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>UOM</th>
                                                <th>Conversion</th>
                                                <th>Price</th>
                                                <th>Calories</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Primary Unit of Measurement</td>
                                                <td className=' form-group'>
                                                    <input type='text' onBlur={handleBlur}
                                                        value={primaryUnit}
                                                        className='form-control form-group' disabled={true}
                                                    />
                                                </td>
                                                <td className=' form-group'>
                                                    <input id="conversion1" className='form-control' name='conversion1' type='number' onBlur={handleBlur} onChange={(event) => { handleChange(event); }} value={values.conversion1} disabled />
                                                    {/* <span className='text-danger'>{errors.conversion1 && touched.conversion1 && errors.conversion1}</span> */}
                                                </td>
                                                <td>
                                                    <input id="price1" name='price1' type='number' onBlur={handleBlur} value={values.price1} className='form-control' disabled={true} />
                                                </td>
                                                <td>
                                                    <input id="calories1" name='calories1' type='number' onBlur={handleBlur} value={values.calories1} className='form-control' disabled={true} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Transfer Unit of Measure</td>
                                                <td className=' form-group'>
                                                    {/* <select id="uom2" className='form-control form-group' name='uom2' onChange={(event) => { handleChange(event); onChangeUOM(event.target.value, values, 2, setFieldValue) }} onBlur={handleBlur} value={values.uom2}>
                                                        <option value="">Please Select</option>
                                                        {uomList?.length ? uomList.map((uom) => (
                                                            <option value={uom?.id}>{uom?.uom} {uom?.uom_code}</option>
                                                        )) : ''}
                                                    </select> */}
                                                    <select id="uom2" className='form-control form-group' name='uom2' onChange={(event) => { handleChange(event); }} onBlur={handleBlur} value={values.uom2}>
                                                        <option value="">Please Select</option>
                                                        {uomList?.length ? uomList.map((uom) => (
                                                            <option value={uom?.id}>{uom?.uom}</option>
                                                        )) : ''}
                                                    </select>
                                                    <span className='text-danger'>{errors.uom2 && touched.uom2 && errors.uom2}</span>
                                                </td>
                                                <td >
                                                    <input name='conversion2' id="conversion2" type='number' onChange={(event) => { handleChange(event); unitConversion(event.target.value, values, 2, setFieldValue); }} onBlur={handleBlur} value={values.conversion2} className='form-control' />
                                                    <span className='text-danger'>{errors.conversion2 && touched.conversion2 && errors.conversion2}</span>
                                                </td>
                                                <td>
                                                    <input name='price2' id="price2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.price2} className='form-control' disabled={true} />
                                                </td>
                                                <td>
                                                    <input name='calories2' id="calories2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.calories2} className='form-control' disabled={true} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Usage Unit of Measure</td>
                                                <td className=' form-group'>
                                                    <select id="uom3" className='form-control' name='uom3' onChange={(event) => { handleChange(event); }} onBlur={handleBlur} value={values.uom3}>
                                                        <option value="">Please Select</option>
                                                        {uomList?.length ? uomList.map((uom) => (
                                                            <option value={uom?.id}>{uom?.uom}</option>
                                                        )) : ''}
                                                    </select>
                                                    <span className='text-danger'>{errors.uom3 && touched.uom3 && errors.uom3}</span>
                                                </td>
                                                <td>
                                                    <input name='conversion3' id="conversion3" type='number' onChange={(event) => { handleChange(event); unitConversion(event.target.value, values, 3, setFieldValue); }} onBlur={handleBlur} value={values.conversion3} className='form-control' />
                                                    <span className='text-danger'>{errors.conversion3 && touched.conversion3 && errors.conversion3}</span>
                                                </td>
                                                <td>
                                                    <input name='price3' id="price3" type='text' onChange={handleChange} onBlur={handleBlur} value={values.price3} className='form-control' disabled={true} />
                                                </td>
                                                <td>
                                                    <input name='calories3' id="calories3" type='text' onChange={handleChange} onBlur={handleBlur} value={values.calories3} className='form-control' disabled={true} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td >Count Unit of Measurement</td>
                                                <td className=' form-group'>
                                                    <select id="uom4" className='form-control' name='uom4' onChange={(event) => { handleChange(event); }} onBlur={handleBlur} value={values.uom4}>
                                                        <option value="">Please Select</option>
                                                        {uomList?.length ? uomList.map((uom) => (
                                                            <option value={uom?.id}>{uom?.uom} </option>
                                                        )) : ''}
                                                    </select>
                                                    <span className='text-danger'>{errors.uom4 && touched.uom4 && errors.uom4}</span>
                                                </td>
                                                <td>
                                                    <input name='conversion4' id="conversion4" type='number' onChange={(event) => { handleChange(event); unitConversion(event.target.value, values, 4, setFieldValue); }} onBlur={handleBlur} value={values.conversion4} className='form-control' />
                                                    <span className='text-danger'>{errors.conversion4 && touched.conversion4 && errors.conversion4}</span>
                                                </td>
                                                <td>
                                                    <input name='price4' id="price4" type='text' onChange={handleChange} onBlur={handleBlur} value={values.price4} className='form-control' disabled={true} />
                                                </td>
                                                <td>
                                                    <input name='calories4' id="calories4" type='text' onChange={handleChange} onBlur={handleBlur} value={values.calories4} className='form-control' disabled={true} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </section>

                                {/* <div className="mt-2">
                            <button className="btn btn-primary mr-2" type="submit" disabled={submitted ? true : null}>
                                Submit
                            </button>
                        </div> */}
                                <div className="button-container mt-4 p-0">
                                    <button className="btn btn-outline-primary btn-prev" type="button" onClick={prevStep} style={{ display: 'none' }}>Back</button>
                                    <button className="btn btn-next btn-primary ms-2" type="button" onClick={() => { nextStep(values) }} disabled={btnDisable}> Next</button>
                                    <button className="btn btn-primary ms-2 btn-submit" type="submit" style={{ display: 'none' }} disabled={submitted ? true : null}>Submit</button>
                                </div>

                            </div>
                        </div>
                    </div>

                </form >
            )}
            </Formik >
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>
    );
}

export default InventoryAdd