import React, { useState, useEffect, useMemo } from 'react'
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { GetChangeStatus, GetTipList, DeleteTip, CreateTip, GetTipDetail, GetEditTip } from '../../../services/company_panel/setting/tipConfigurationService';
import Swal from 'sweetalert2'
import CustomPagination from '../../../components/company_panel/common/custompagination'
import Sorting from '../../../components/company_panel/common/sorting';
import Loader from '../../../components/company_panel/common/loader'
import $ from "jquery";
import { capitalizeFirstLetter, getBrandId, getCompany } from '../../../helpers/commonFunctions';
import ConfigurationTab from '../configurationTab';
import Select from 'react-select';
import * as yup from 'yup';
import notFound from '../../../assets/company/img/no-record-found.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LOADER_TIME } from '../../../utils/Constants';



const TipList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const [showDefault, setShowDefault] = useState({})
    const [actionType, setActionType] = useState('add');
    const [tipType, setTipType] = useState();
    const [isBrandChanged, setIsBrandChanged] = useState(false);


    const tipTypeData = [
        { value: 'Flat Amount', label: 'Flat Amount' },
        { value: 'Percentage', label: 'Percentage' },
    ];

    const handleChangeTipType = (selected) => {

        formik.setFieldValue("tipType", selected);
        formik.setFieldValue("prices", '');
    };

    const handlePrice = (existingValues) => {

        const checkType = $('.check_type').val();

        if (checkType) {
            const price = $('#price').val();
            if (price) {
                if (Number.isInteger(Number(price)) && price) {
                    if (checkType === 'Percentage') {
                        if (price <= 100 && price > 0) {
                            let values = [...existingValues];
                            values.push(price);
                            formik.setFieldValue("prices", values);
                            $('.prices-error').html('');
                        } else {

                            $('.prices-error').html('The values must be between 1 and 100.');
                        }
                    } else {
                        if (price >= 0) {
                            let values = [...existingValues];
                            values.push(price);
                            formik.setFieldValue("prices", values);
                            $('.prices-error').html('');
                        } else {
                            $('.prices-error').html('The values must be between 1 and 100.');

                        }
                    }
                } else {
                    $('.prices-error').html('Please enter integer value.');
                }
            } else {
                $('.prices-error').html('Values is required.');
            }
            $('#price').val('');
        }


    };

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    const handleRemovePrice = (existingValues, value) => {

        let values = [...existingValues];
        const index = values.indexOf(value);
        if (index > -1) {
            values.splice(index, 1);
            formik.setFieldValue("prices", values);
        }

    };

    const deleteValues = (id, values, index) => {
        values.splice(index, 1);
        let formData = new FormData();
        formData.append('values', values);
        formData.append('id', id);
        GetEditTip(formData).then(response => {
            if (response.data.status === 1 || response.data.status === "1") {
                Swal.fire({
                    customClass: 'swal-wide',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    position: 'top-right',
                });
                setTimeout(() => {
                    $('#addCardModal').hide();
                    $('.modal-backdrop').remove();
                    setStatusUpdate(!statsUpdate)
                    formik.resetForm();
                    setShowDefault({});
                }, 1000);
                setStatusUpdate(!statsUpdate);
            } else {
                Swal.fire({
                    customClass: 'swal-wide',
                    icon: 'error',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000,
                    toast: true,
                    position: 'top-right',
                })
                setSubmitted(false)
            }
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }


    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('brand_id', getBrandId());
            GetTipList(formData).then(data => {

                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
            }).catch(error => {
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch,isBrandChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        setSearch({})
        prepareSearch()
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                DeleteTip(row).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Record deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }

    // Change Status functionality

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                GetChangeStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    // edit functionality

    const editFunction = (row) => {

        GetTipDetail(row).then(data => {
            console.log(data.data.response.id);
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
        setActionType('edit');
        $('#addCardModal').show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end


    const closeModel = (id) => {
        $('#addCardModal').hide();
        $('.modal-backdrop').remove();
        $('.prices-error').html('');
        setShowDefault({});
        setStatusUpdate(!statsUpdate)
        formik.setErrors({});
        formik.resetForm();
    }

    const openModel = (id, type) => {
        $('.prices-error').html('');
        formik.setErrors({});
        formik.resetForm();
        setActionType(type);
        $('#' + id).show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
    }

    const convertToPercentages = (str) => {
        return str.split(',')  // Split the string by commas into an array
            .map(num => `${num}%`)  // Append '%' to each number
            .join(',');  // Join the array back into a comma-separated string
    };


    const validationSchema = yup.object().shape({
        tipType: yup.object().required('Type is required'),
        prices: yup.array().required('Values is required').test('nonEmpty', 'Values must not be empty', value => value && value.length > 0)
            .test('maxLength', 'Values cannot be more than 5 items', value => {
                // const valuesArray = value ? value?.split(',').filter(val => val.trim() !== '') : [];
                return value?.length <= 5;
            }),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            tipType: showDefault && showDefault.type ? { value: showDefault.type, label: showDefault.type } : "",
            prices: showDefault && showDefault.values ? showDefault.values.split(',') : [],
            status: showDefault && showDefault.status == 1 ? 'on' : "",
        },
        validationSchema: validationSchema,
        onSubmit: values => {

            let formData = new FormData();
            formData.append('type', values.tipType.value);
            formData.append('values', values.prices);
            formData.append('status', values.status == 'on' ? 1 : 0);
            formData.append('company_id', getCompany().company_id);
            formData.append('brand_id', getBrandId());
            formData.append('id', showDefault && showDefault.id ? showDefault.id : "");

            if (actionType === 'edit') {
                GetEditTip(formData).then(response => {

                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            $('#addCardModal').hide();
                            $('.modal-backdrop').remove();
                            setStatusUpdate(!statsUpdate)
                            formik.resetForm();
                            setShowDefault({});
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        setSubmitted(false)
                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })

            } else {
                CreateTip(formData).then(response => {

                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            $('#addCardModal').hide();
                            $('.modal-backdrop').remove();
                            setStatusUpdate(!statsUpdate)
                            formik.resetForm();
                            setShowDefault({});
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        setSubmitted(false)
                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })

            }

        },
    });




    return (
        <>
            {/* <Breadcrums data={breadcrumbs} /> */}
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Settings</h4>
                    </div>
                    <div className='page-header-right ms-auto'>

                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationTab tab='tip' />
                            </div>
                        </div>
                    </div>
                    {/* <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            <div className='page-header-left'>

                            </div>
                            <div className='page-header-right ms-auto'>
                                <button className="btn btn-primary" type="button" onClick={(e) => openModel('addCardModal', 'add')}>Add Tip</button>
                            </div>
                        </div>
                    </div> */}
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="25%">
                                            <div className='sorting_column'>
                                                <span>Type</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="type" />
                                            </div>
                                        </th>
                                        <th width="25%">
                                            <div className='sorting_column'>
                                                <span>Values</span>
                                            </div>
                                        </th>
                                        <th className='text-center action_head text-end' width="25%">Status</th>
                                        {/* <th className='action_head text-end' width="25%">Actions</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{capitalizeFirstLetter(row?.type)}</td>
                                                    {/* <td>{row.type === 'Percentage' ? convertToPercentages(row.values) : row.values}</td> */}
                                                    <td className='d-flex'>
                                                        {
                                                            row.values?.length ? row.values?.split(',').map((value, index) => (
                                                                <div className='show-data-single' style={{ width: '72px' }}>
                                                                    <div className='set-value'>{value}{row.values && row.type === 'Percentage' ? '%' : ''}</div>
                                                                    <div className='remove-value' onClick={() => { deleteValues(row?.id, row?.values?.split(','), index) }}>+</div>
                                                                </div>

                                                            )) : ''
                                                        }
                                                        {row.values.split(',').length < 5 &&
                                                            <button type="button" className='btn-icon' onClick={(e) => editFunction(row.id)}>
                                                                <i className="ti ti-plus" style={{ color: '#2F9BFF' }}></i>
                                                            </button>}
                                                    </td>
                                                    <td>
                                                        <div className="form-check form-switch w-25 mx-auto">
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" checked={row.status == 1 ? 'checked' : ''} onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }} />
                                                            <label className="form-check-label" for="status-change"></label>
                                                        </div>
                                                    </td>
                                                    {/* <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><div className='dropdown-item' onClick={() => { editFunction(row.id) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                            </ul>
                                                        </div>
                                                    </td> */}
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={4} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No record found</p>
                                                </td>
                                            </tr>
                                            }</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "outlet-management", path: "/company/setting/tip-configuration/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Modal*/}

                <div className="modal fade" id="addCardModal" aria-labelledby="addCardModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="modal-title" id="addCardModalLabel">{actionType === 'edit' ? 'Edit' : 'Add'} Tip</h2>
                                <button type="button" className="btn-close" onClick={(e) => closeModel('addCardModal')} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label for="name" className="col-form-label">Type:</label>
                                        <input type='hidden' value={formik.values && formik.values.tipType ? formik.values.tipType.value : ''} className="check_type" />
                                        <Select
                                            options={tipTypeData}
                                            value={formik.values?.tipType}
                                            onChange={(selected) => { handleChangeTipType(selected) }}
                                            disabled={true}
                                        />
                                        <span className='text-danger d-flex text-left'>{formik.errors.tipType && formik.touched.tipType && formik.errors.tipType}</span>
                                    </div>
                                    <div className="mb-3">
                                        <label for="price" className="col-form-label">Values :</label>
                                        <div className="set-plus-icon-input">
                                            <input type="text" className="form-control" name="price" id="price" placeholder='Enter Number' autoComplete='off' disabled={formik.values && formik.values.tipType ? false : true} />
                                            <div className="icon-box">
                                                <a onClick={() => { handlePrice(formik.values?.prices) }}><i className="fa fa-plus" style={{ color: '#2F9BFF' }}></i></a>
                                            </div>
                                        </div>
                                        <div className='show-data-all'>
                                            {formik.values?.prices?.length ? formik.values?.prices?.map((value) => (
                                                <div className='show-data-single' >
                                                    <div className='set-value'>{value}{formik.values && formik.values.tipType.value === 'Percentage' ? '%' : ''}</div>
                                                    <div className='remove-value' onClick={() => { handleRemovePrice(formik.values?.prices, value) }}>+</div>
                                                </div>

                                            )) : ''}
                                        </div>
                                        <span className='text-danger d-flex text-left prices-error'>{formik.errors.prices && formik.touched.prices && formik.errors.prices}</span>
                                    </div>
                                    <div className="form-check form-switch w-25">
                                        <input className="form-check-input" type="checkbox" id="status" name="status" onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.status == 'on' ? 'checked' : ''} autoComplete='off' />
                                        <label className="form-check-label" for="status">Status</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-primary" style={{ width: '48%' }} data-bs-dismiss="modal" onClick={() => { closeModel('addCardModal') }}>Cancel</button>
                                    <button type="submit" className="btn btn-primary" style={{ width: '48%' }}>{actionType === 'edit' ? 'Update' : 'Add'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </div>
        </>
    );
}

export default TipList