import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { GetChangeStatus, GetRoleList, DeleteRole, GetAllRoleList } from '../../../services/company_panel/platform_user/platformUserService';
import Swal from 'sweetalert2'
import { Button, Card, Table, Container, Row, Col, Modal } from 'react-bootstrap';
import { formateDate, getCompany, getOutletId } from '../../../helpers/commonFunctions';
import StatusFilter from '../../../components/common/statusFilter'
import { showFilterlist } from '../../../utils/Constants'
import CustomRangepicker from '../../../components/common/rangepicker'
import $ from "jquery"
import CustomPagination from '../../../components/company_panel/common/custompagination'
import notFound from '../../../assets/company/img/no-record-found.png'
import Loader from '../../../components/company_panel/common/loader'
import { GetTransferStockOutRequestsList } from '../../../services/company_panel/transferStockService';

const TransferOutList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false)

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState();
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
    });

    useEffect(() => {
        if (search) {
            setTimeout(()=>{
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('from_outlet_id', getOutletId());
            GetTransferStockOutRequestsList(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])

                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        },1000)
        }
    }, [location, statsUpdate, sorting, search, globalSearch, isBrandChanged, isOutletChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
    }

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteRole(row).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Record deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }

    // edit functionality

    const editFunction = (row) => {
        navigate(`/company/platform-User/role/edit/${row}`)
    }



    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end


    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Transfer stock</h4>
                    </div>
                    <div className='page-header-right ms-auto'>

                        <button className="btn btn-primary ms-2" onClick={() => navigate(`/company/store_manager/transfer_stock/transfer_out/add`)}>Create Transfer</button>

                    </div>

                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <ul className="d-flex setting list-unstyled w-100 mb-0 p-0">
                                <li className="">
                                    <Link to={`/company/store_manager/transfer_stock/transfer_in/list/${1}`} className="btn btn-link" color='black'>Transfer In</Link>
                                </li>
                                <li className="ms-3 active">
                                    <Link to={`/company/store_manager/transfer_stock/transfer_out/list/${1}`} className="btn btn-primary " color='black'>Transfer out</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                        </div>
                    </div> */}

                    <div className="table-responsive">
                        <table className="table table-custom">
                            <thead>
                                <tr>
                                    <th width="30%">Outlet</th>
                                    <th width="25%">
                                        <div className='sorting_column'>
                                            <span>Date</span>
                                        </div>
                                    </th>
                                    <th width="25%">
                                        <div className='sorting_column'>
                                            <span>Status</span>
                                        </div>
                                    </th>
                                    {/* <th width="25%">
                                        <div className='sorting_column'>
                                            <span></span>
                                        </div>
                                    </th> */}
                                    {/* <th width="10%" className='action_head'></th> */}
                                    <th width="15%" className='action_head'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loader ? (
                                    <tr>
                                        <td colSpan={11}><Loader /></td>
                                    </tr>
                                ) : (
                                    <>
                                        {list.length ? list.map((row, i) => (
                                            <tr key={i}>
                                                <td>{row.outlet?.outlet_name}</td>
                                                <td>{row.created_at ? formateDate(row.created_at) : "N/A"}</td>
                                                <td >{row.rejected == true ?
                                                    'Rejected' :
                                                    (row.recieved ?
                                                        'Recieved' :
                                                        (row.accepted ?
                                                            'Accepted' :
                                                            'Created'
                                                        )
                                                    )}
                                                </td>
                                                <td>
                                                    <Button variant="primary" className="me-3" style={{ width: '200px' }} onClick={() => { setShowModal(true); setModalData(row) }}>View</Button>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan={11} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No Record.</p>
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className='tabler-footer'>
                        <div className='d-flex align-items-center'>
                            <div id="example1_info" role="status" className="table-result" aria-live="polite">
                                <b>Total Records : {dataLength || "0"}</b>
                            </div>
                            {dataLength && dataLength > 0 ? (
                                <CustomPagination
                                    datalength={dataLength}
                                    itemperpage={itemPerPage}
                                    setPage={setPage}
                                    currentPage={page}
                                    pageRoute={[{ name: "outlet-management", path: "/company/store_manager/transfer_stock/transfer_out/list" }]}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '1.5em' }}>{modalData?.outlet?.outlet_name}</Modal.Title>

                </Modal.Header>
                <Modal.Body style={{ padding: '0' }}>
                    <table className="table table-custom ">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Inventory Stock</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                modalData?.transfer_stock_request_inventories.map((request) => {
                                    return <tr>
                                        <td>{request?.inventory.item_name}</td>
                                        <td>{request?.quantity}</td>
                                    </tr>
                                })
                            }

                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-outline-primary' onClick={() => setShowModal(false)}>
                        Close
                    </button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TransferOutList