import React, { useEffect, useState } from 'react'
import { Formik, Field } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { GetEditCompany, GetCompanyDetail } from '../../services/companyService'
import Swal from "sweetalert2"
import Layout from '../../layout'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toHtml from 'html-react-parser';
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader'
import $ from 'jquery';
import { countries } from 'countries-list';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactCountryFlag from 'react-country-flag'
import { SelectPicker } from 'rsuite'
import { MAX_FILE_SIZE } from '../../utils/Constants'


const EditCompany = () => {
    const navigate = useNavigate()
    const [previewImage, setPreviewImage] = useState("");
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const [year, setYear] = useState([])
    const [selectedCountryCode, setSelectedCountryCode] = useState([]);
    const breadcrumbs = [
        {
            title: "Dashboard",
            url: "/admin/dashboard"
        },
        {
            title: "Companies",
            url: "/admin/company/list/1"
        },
        {
            title: "Edit",
            url: ""
        },
    ]

    useEffect(() => {
        GetCompanyDetail(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : []);
            setPreviewImage(data?.data?.response?.logo)
        }).catch(error => {
            console.log("error=====>", error);
        });

        const currentYear = new Date().getFullYear();
        const yearArray = [];
        for (let i = 1900; i <= currentYear; i++) {
            yearArray.push(i);
        }
        setYear(yearArray);

        // const options = Object.keys(countries).map(countryCode => ({
        //     countryCode: countryCode,
        //     value: countries[countryCode].phone,
        // }));
        // setSelectedCountryCode(options);
        const options = Object.keys(countries).map(countryCode => ({
            label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReactCountryFlag
                        countryCode={countryCode}
                        style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }}
                        aria-label={countryCode}
                    />
                    {` + ${countries[countryCode].phone}`}
                </div>
            ),
            value: countries[countryCode].phone
        }));
        setSelectedCountryCode(options);
    }, []);

    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: showDefault && showDefault.name ? showDefault.name : "",
                        logo: showDefault && showDefault.logo ? showDefault.logo : "",
                        country_code: showDefault && showDefault.country_code ? (Array.isArray(showDefault.country_code) ? showDefault.country_code : [Number(showDefault.country_code)]) : [],
                        short_description: showDefault && showDefault.short_description ? showDefault.short_description : "",
                        established_year: showDefault && showDefault.established_year ? showDefault.established_year : "",
                        official_address: showDefault && showDefault.official_address ? showDefault.official_address : "",
                        official_email: showDefault && showDefault.official_email ? showDefault.official_email : "",
                        long_description: showDefault && showDefault.long_description ? showDefault.long_description : "",
                        official_phone_number: showDefault && showDefault.official_phone_number ? showDefault.official_phone_number : "",
                        licence_number: showDefault && showDefault.licence_number ? showDefault.licence_number : "",
                        vat_number: showDefault && showDefault.vat_number ? showDefault.vat_number : "",
                        subscription_access: showDefault && showDefault.subscription_access ? showDefault.subscription_access : ""
                    }}

                    validate={values => {
                        const error = {};
                        if (values?.logo?.size > MAX_FILE_SIZE) {
                                error.logo = 'Image size exceeds the limit of 5MB';
                                $("#errortext").text("Image size exceeds the limit of 5MB");
                            } else {
                                $("#errortext").text("");
                            }
                        if (!values.name.trim()) {
                            error.name = "Name is required";
                        } else if (values.name.length < 3) {
                            error.name = "Name must be at least 3 characters";
                        } else if (values.name.length > 20) {
                            error.name = "Name cannot exceed 20 characters";
                        }
                        if (!values.country_code) error.country_code = "Country code is required";
                        if (!values.official_email) {
                            error.official_email = "Email is required"
                        } else if (!/^[a-zA-Z0-9._'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values.official_email)) {
                            error.official_email = 'Invalid email address';
                        }
                        if (!values.vat_number.trim()) {
                            error.vat_number = "Vat number is required"
                        } else if (!/^\d{15}$/.test(values.vat_number)) {
                            error.vat_number = 'Please enter a valid 15-digit number';
                        }

                        if (!values.official_phone_number.trim()) {
                            error.official_phone_number = "Phone number is required"
                        } else if (!/^\d{10}$/.test(values.official_phone_number)) {
                            error.official_phone_number = 'Please enter a valid 10-digit number';
                        }
                        if (!values.official_address.trim()) {
                            error.official_address = "Official address is required";
                        } else if (values.official_address.length > 250) {
                            error.official_address = "Official address cannot exceed 250 characters";
                        }
                        if (!values.short_description.trim()) {
                            error.short_description = "Short description is required";
                        } else if (values.short_description.length < 20) {
                            error.short_description = "Short description must be at least 20 characters";
                        } else if (values.short_description.length > 500) {
                            error.short_description = "Short description cannot exceed 500 characters";
                        }
                        if (!values.long_description.trim()) {
                            error.long_description = "Long description is required";
                        } else if (values.long_description.length < 20) {
                            error.long_description = "Long description must be at least 20 characters";
                        } else if (values.long_description.length > 500) {
                            error.long_description = "Long description cannot exceed 500 characters";
                        }
                        return error;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitted(true)
                        let formData = new FormData();
                        formData.append("id", params.id)
                        formData.append('logo', values.logo);
                        formData.append('name', values.name.trim());
                        formData.append('short_description', values.short_description.trim());
                        formData.append('established_year', values.established_year);
                        formData.append('official_address', values.official_address.trim());
                        formData.append('official_email', values.official_email);
                        formData.append('long_description', values.long_description.trim());
                        formData.append('official_phone_number', values.official_phone_number);
                        formData.append('licence_number', values.licence_number.trim());
                        formData.append('vat_number', values.vat_number.trim());
                        formData.append('subscription_access', values.subscription_access);
                        formData.append('country_code', values.country_code);
                        GetEditCompany(formData).then(response => {
                            setSubmitting(false);
                            setSubmitted(false);
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate(`/admin/company/list/${params.pgno}`)
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                })
                                setSubmitted(false)
                            }
                        }).catch(error => {
                            console.log("error ====> ", error);
                        })
                    }}
                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        {console.log("form values", values)}
                        <div className="row row-sm">
                            <div className="col-lg-12 col-md-12 animation_fade">
                                <div className="card custom-card">

                                    <div className="card-body">
                                        <div>
                                            <h6 className="main-content-label mb-3">Edit Company</h6>
                                        </div>
                                        {showDefault && Object.keys(showDefault).length > 0 ?
                                            <div className="row row-sm">
                                                <div className='col-lg-6 text-center form-group'>
                                                    {/* <td>{showDefault && showDefault.logo ? <img src={showDefault.logo} style={{ height: "50px" }} /> : "N/A"}</td> */}
                                                    <label htmlFor='logo' className='text-left d-flex'>Logo<span className="requirestar">*</span> </label>
                                                    <input className='form-control imgInput' id="logo" name="logo" accept="image/*" type="file"
                                                        onChange={(event) => {
                                                            setFieldValue("logo", event.currentTarget.files[0] || "");
                                                            { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                                                        }}
                                                    />
                                                    <div className='d-flex justify-content-start mt-3'>
                                                        <div className={previewImage ? "" : "d-none"}>
                                                            <img src={previewImage} style={{ height: "100px" }} />
                                                        </div>
                                                    </div>
                                                    <span className='text-danger d-flex text-left' id='errortext'>
                                                        {errors.logo && touched.logo && errors.logo}
                                                    </span>
                                                </div>

                                                <div className='col-lg-12 text-center form-group'>
                                                    <label htmlFor='name' className='text-left d-flex'>Name<span className="requirestar">*</span></label>
                                                    <input name='name' id="name" placeholder='Enter name' type='text' onChange={handleChange} onBlur={handleBlur} value={values.name} className='form-control' />
                                                    <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                                                </div>
                                                <div className='col-lg-12 text-center form-group required'>
                                                    <label htmlFor='short_description' className='text-left d-flex'>Short description  <span className="requirestar">*</span> </label>
                                                    <textarea name='short_description' placeholder='Enter short description' id="short_description" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description} className='form-control' />
                                                    <span className='text-danger d-flex text-left'>{errors.short_description && touched.short_description && errors.short_description}</span>
                                                </div>
                                                <div className='col-lg-12 text-center form-group'>
                                                    <label htmlFor='subject' className='text-left d-flex'>Long description <span className="requirestar">*</span></label>
                                                    {
                                                        Boolean(showDefault) ?
                                                            <>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    // config={ editorConfiguration }
                                                                    data={values.long_description}
                                                                    config={{
                                                                        placeholder: 'Enter long description',
                                                                    }}
                                                                    onReady={editor => {
                                                                        console.log('Editor is ready to use!', editor);
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setFieldValue("long_description", editor.getData());
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        console.log('Blur.', editor);
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        console.log('Focus.', editor);
                                                                    }}
                                                                />
                                                            </>
                                                            : ""
                                                    }
                                                    <span className='text-danger d-flex text-left'>{errors.long_description && touched.long_description && errors.long_description}</span>
                                                </div>
                                                <div className='col-lg-12 text-center form-group'>
                                                    <label htmlFor='established_year' className='text-left d-flex'>Established year <span className="requirestar">*</span></label>
                                                    <div className='select-down-arrow'>
                                                        <select id="established_year" className='form-control' name='established_year' onChange={handleChange} onBlur={handleBlur}>
                                                            <option value="">Select year</option>
                                                            {year.length ? year.map((row, i) => (
                                                                <option value={row} selected={values.established_year == row ? 'selected' : ''}>{row}</option>
                                                            )) : ''}
                                                        </select>
                                                    </div>
                                                    <span className='text-danger d-flex text-left'>{errors.established_year && touched.established_year && errors.established_year}</span>
                                                </div>
                                                <div className='col-lg-12 text-center form-group'>
                                                    <label htmlFor='official_address' className='text-left d-flex'>Official address <span className="requirestar">*</span></label>
                                                    <input name='official_address' placeholder='Enter official address' id="official_address" type='text' onChange={handleChange} onBlur={handleBlur} value={values.official_address} className='form-control' />
                                                    <span className='text-danger d-flex text-left'>{errors.official_address && touched.official_address && errors.official_address}</span>
                                                </div>

                                                <div className='col-lg-12 text-center form-group'>
                                                    <label htmlFor='official_email' className='text-left d-flex'>Official email <span className="requirestar">*</span></label>
                                                    <input name='official_email' id="official_email" placeholder='Enter official email' type='email' onChange={handleChange} onBlur={handleBlur} value={values.official_email} className='form-control' />
                                                    <span className='text-danger d-flex text-left'>{errors.official_email && touched.official_email && errors.official_email}</span>
                                                </div>
                                                <div className='col-lg-12 text-center form-group'>
                                                    <label htmlFor='official_phone_number' className='text-left d-flex'>
                                                        Official phone number <span className="requirestar">*</span>
                                                    </label>
                                                    {/* <div className='select-down-arrow'> */}
                                                    {/* <select
                                                        id="country_code"
                                                        className='form-control'
                                                        name='country_code'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.country_code}
                                                    >
                                                        <option value="">Select country code :</option>
                                                        {selectedCountryCode.map((row) => (
                                                            <option key={row.value} value={row.value}>
                                                                <ReactCountryFlag
                                                                    countryCode={row.countryCode}
                                                                    style={{ width: '1.5em', height: '1.5em' }}
                                                                    aria-label={row.countryCode}
                                                                />
                                                                +{row.value}
                                                            </option>
                                                        ))}
                                                    </select> */}
                                                    <div className='d-flex phonenumber-input'>
                                                        <SelectPicker style={{ border: 'none', paddingLeft: '0', paddingRight: '0' }}
                                                            data={selectedCountryCode}
                                                            name='country_code'
                                                            className='form-control'
                                                            id='country_code'
                                                            defaultValue={values.country_code}
                                                            onChange={(value) => { setFieldValue('country_code', value) }}
                                                            onBlur={handleBlur}
                                                            value={values.country_code}
                                                            placeholder='Select code'
                                                        />
                                                        {/* </div> */}
                                                        <input
                                                            name='official_phone_number'
                                                            id="official_phone_number"
                                                            type='text'
                                                            placeholder='Enter phone number'
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.official_phone_number}
                                                            className='form-control form-control-number'
                                                        />
                                                    </div>
                                                    <span className='text-danger d-flex text-left'>
                                                        {errors.country_code && touched.country_code && errors.country_code}
                                                    </span>
                                                    <span className='text-danger d-flex text-left'>{errors.official_phone_number && touched.official_phone_number && errors.official_phone_number}</span>

                                                </div>
                                                <div className='col-lg-12 text-center form-group'>
                                                    <label htmlFor='licence_number' className='text-left d-flex'>Licence number </label>
                                                    <input name='licence_number' placeholder='Enter licence number' id="licence_number" type='text' onChange={handleChange} onBlur={handleBlur} value={values.licence_number} className='form-control' />

                                                </div>
                                                <div className='col-lg-12 text-center form-group'>
                                                    <label htmlFor='vat_number' className='text-left d-flex'>VAT Number <span className="requirestar">*</span></label>
                                                    <input name='vat_number' placeholder='Enter VAT number' id="vat_number" type='text' onChange={handleChange} onBlur={handleBlur} value={values.vat_number} className='form-control' />
                                                    <span className='text-danger d-flex text-left'>{errors.vat_number && touched.vat_number && errors.vat_number}</span>
                                                </div>
                                                <div className='col-lg-12 text-center form-group'>
                                                    <label htmlFor='subscription_access' className='text-left d-flex'>Subscription access <span className="requirestar">*</span></label>
                                                    <div className='select-down-arrow'>
                                                        <select id="subscription_access" className='form-control' name='subscription_access' onChange={handleChange} onBlur={handleBlur}>
                                                            <option value="">Select subscription access</option>
                                                            <option value={1} selected={values.subscription_access == 1 ? 'selected' : ''}>Yes</option>
                                                            <option value={0} selected={values.subscription_access == 0 ? 'selected' : ''}>No</option>
                                                        </select>
                                                    </div>
                                                    <span className='text-danger d-flex text-left'>{errors.subscription_access && touched.subscription_access && errors.subscription_access}</span>
                                                </div>
                                                <div className="">
                                                    <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                                                        <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                        Save
                                                    </button>
                                                    <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                        <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div> : <Loader />}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                )}
                </Formik>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </Layout>
        </>

    )
}

export default EditCompany