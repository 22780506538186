import React, { useEffect, useState } from 'react'
import { Field, useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom'
import { GetOutletUserDetail, EditUserLoginPin, viewOutletUserDetail } from '../../../services/company_panel/platform_user/platformUserService';
import toHtml from 'html-react-parser'
import { capitalizeAllFirstLetters, capitalizeFirstLetter, formateDate, removeModalBackdrops, removeSpecialCharacter, TrimText } from '../../../helpers/commonFunctions';
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import Loader from '../../../components/company_panel/common/loader'
import Swal from 'sweetalert2'
import $ from "jquery";
import * as yup from 'yup';
import OTPInput from 'react-otp-input';

const OutletUserView = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Platform User", url: "/company/business/platform-User/outlet-user/list/1" }, { title: "Outlet Users", url: "/company/business/platform-User/outlet-user/list/1" }, {
        title: "User Detail",
        url: ""
    },]
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [roleData, setRoleData] = useState([]);

    useEffect(() => {
        return () => {
          removeModalBackdrops();
        }
      }, []);

    useEffect(() => {
        viewOutletUserDetail(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            let tempRoleData;
            if (data.data.response.rolename == 'Store manager') {
                tempRoleData = data.data.response.areanamelist;
            } else {
                tempRoleData = data.data.response.outletnamelist;
            }
            let tempRoles = tempRoleData.map((role) => {
                return capitalizeFirstLetter(role);
            })
            setRoleData(tempRoles);
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [statsUpdate])

    const closeModel = (id) => {
        $('#addCardModal').hide();
        $('.modal-backdrop').remove();
        setShowDefault({});
        setStatusUpdate(!statsUpdate)
        formik.resetForm();
    }

    const openModel = (id, type) => {

        $('#' + id).show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
    }

    const editFunction = () => {
        navigate(`/company/business/platform-User/outlet-user/1/edit/${params.id}`)
    }


    // const convertTimeFormat = (timeString) => {

    //     if(timeString){

    //         let [hour,minute,second] = timeString.trim().split(':');
    //         let [seconds, time] = second.trim().split(' ');

    //         let newTimeString = `${hour} : ${minute} ${time}`;
    //         return newTimeString;
    //     }
    // }
    const convertTimeFormat = (timeString) => {
        if (typeof timeString === 'string') {
            let [hour, minute, second] = timeString.trim().split(':');
            if (second) {
                let [seconds, time] = second.trim().split(' ');
                time = time || '';
                let newTimeString = `${hour.trim()} : ${minute.trim()} ${time.trim()}`;
                return newTimeString;
            }
        }
        return null;
    }

    var otp_inputs = document.querySelectorAll(".otp__digit")
    var mykey = "0123456789".split("")
    otp_inputs.forEach((_) => {
        _.addEventListener("keyup", handle_next_input)
    })
    function handle_next_input(event) {
        let current = event.target
        let index = parseInt(current.classList[1].split("__")[2])
        current.value = event.key

        if (event.keyCode == 8 && index > 1) {
            current.previousElementSibling.focus()
        }
        if (index < 4 && mykey.indexOf("" + event.key + "") != -1) {
            var next = current.nextElementSibling;
            next.focus()
        }
        var _finalKey = ""
        for (let { value } of otp_inputs) {
            _finalKey += value
        }
        if (_finalKey.length == 4) {

            formik.setFieldValue("user_login_pin", _finalKey);
        } else {

            // formik.setFieldValue("user_login_pin",_finalKey);
        }
    }


    const validationSchema = yup.object().shape({
        user_login_pin: yup
            .string()
            .min(4, 'PIN must be at least 4 digits')
            .max(4, 'PIN must be exactly 4 digits')
            .required('This field is required'),

    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            user_login_pin: "",
        },
        validationSchema: validationSchema,
        onSubmit: values => {

            let formData = new FormData();
            formData.append("id", params.id)
            formData.append('user_login_pin', values.user_login_pin);

            EditUserLoginPin(formData).then(response => {
                if (response?.data?.status === 1 || response?.data?.status === "1") {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true,
                        position: 'top-right',
                    });
                    $('#addCardModal').hide();
                    $('.modal-backdrop').remove();
                    setTimeout(() => {
                        navigate(`/company/business/platform-User/outlet-user/list/${1}`)
                    }, 1000);
                } else {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'error',
                        title: 'Login pin already exists',
                        showConfirmButton: false,
                        timer: 5000,
                        toast: true,
                        position: 'top-right',
                    })
                }
            }).catch(error => {
                console.log("error ====> ", error);
            })

        }

    });

    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Platform User</h4>
                </div>
                <div class="page-header-right ms-auto">
                    <button className='btn btn-primary' onClick={() => { editFunction() }}>Edit User</button>

                </div>
            </div>
            <div className="card custom-card">
                {showDefault && Object.keys(showDefault).length > 0 ?
                    <div className="card-body">
                        <div className='brand-banner'>
                            <div className='avatar avatar-xl mx-auto'>
                                {showDefault && showDefault.profile_image ? <img src={showDefault.profile_image} /> : "N/A"}
                            </div>
                            <h4 >{showDefault && showDefault.first_name ? capitalizeAllFirstLetters(showDefault.first_name) : "N/A"}
                                {showDefault && showDefault.last_name ? ' ' + capitalizeAllFirstLetters(showDefault.last_name) : ""} </h4>

                            {/* <h4> {showDefault && showDefault.first_name ? ' ' + showDefault.first_name : "N/A"}
                                {showDefault && showDefault.last_name_ar ? showDefault.last_name_ar : "N/A"}</h4> */}

                            <p>{showDefault && showDefault.created_at ? formateDate(showDefault.created_at) : "N/A"}</p>
                        </div>

                        <div className='row'>
                            <div className='col-4'>
                                <div className='profile-info mt-5'>
                                    <div className='profile-info-in'>
                                        <h5>Email</h5>
                                        <p>{showDefault && showDefault.email ? showDefault.email : "N/A"}</p>
                                    </div>
                                    {/* <div className='profile-info-in'>
                                        <h5>Brand Name</h5>
                                        <p>{showDefault && showDefault.brandname ? capitalizeAllFirstLetters(showDefault.brandname) : "N/A"}</p>
                                    </div> */}
                                    <div className='profile-info-in'>
                                        <h5>Employment Type </h5>
                                        <p>{showDefault && showDefault.employment_type ? removeSpecialCharacter(showDefault.employment_type) : "N/A"}</p>
                                    </div>
                                    <div className='profile-info-in'>
                                        <h5>Shift </h5>
                                        <p>{showDefault && showDefault.shift ? showDefault.shift : "N/A"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='profile-info mt-5'>
                                    <div className='profile-info-in'>
                                        <h5>Phone Number </h5>
                                        <p>{showDefault && showDefault.mobile ? showDefault.mobile : "N/A"}</p>
                                    </div>

                                    <div className='profile-info-in'>
                                        <h5>{showDefault.rolename == 'Store manager' ? 'Areas' : 'Outlets'}</h5>
                                        <p>{showDefault && showDefault.rolename ? roleData.join(', ') : "N/A"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='profile-info mt-5'>
                                    <div className='profile-info-in'>
                                        <h5>Role</h5>
                                        <p>{showDefault && showDefault.rolename ? showDefault.rolename : "N/A"}</p>
                                    </div>
                                    <div className='profile-info-in'>
                                        <h5>Outlet Location</h5>
                                        <p>{showDefault && showDefault.outletlocation ? showDefault.outletlocation : "N/A"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='profile-info mt-5'>
                            <div className='profile-info-in'>
                                <h5>Store opening and closing time </h5>
                                {/* <div className='row'>
                                    <div className='col-4 mt-1'>
                                        Monday : {showDefault.monday_opening_time ? convertTimeFormat(showDefault.monday_opening_time) : 'Closed'} - {showDefault.monday_closing_time ? convertTimeFormat(showDefault.monday_closing_time) : 'Closed'}
                                    </div>
                                    <div className='col-4 mt-1'>
                                        Tuesday : {showDefault.tuesday_opening_time ? convertTimeFormat(showDefault.tuesday_opening_time) : 'Closed'} - {showDefault.tuesday_closing_time ? convertTimeFormat(showDefault.tuesday_closing_time) : 'Closed'}
                                    </div>
                                    <div className='col-4 mt-1'>
                                        Wednesday : {showDefault.wednesday_opening_time ? convertTimeFormat(showDefault.wednesday_opening_time) : 'Closed'} - {showDefault.wednesday_closing_time ? convertTimeFormat(showDefault.wednesday_closing_time) : 'Closed'}
                                    </div>
                                    <div className='col-4 mt-1'>
                                        Thursday : {showDefault.thursday_opening_time ? convertTimeFormat(showDefault.thursday_opening_time) : 'Closed'} - {showDefault.thursday_closing_time ? convertTimeFormat(showDefault.thursday_closing_time) : 'Closed'}
                                    </div>
                                    <div className='col-4 mt-1'>
                                        Friday : {showDefault.friday_opening_time ? convertTimeFormat(showDefault.friday_opening_time) : 'Closed'} - {showDefault.friday_closing_time ? convertTimeFormat(showDefault.friday_closing_time) : 'Closed'}
                                    </div>
                                    <div className='col-4 mt-1'>
                                        Saturday : {showDefault.saturday_opening_time ? convertTimeFormat(showDefault.saturday_opening_time) : 'Closed'} - {showDefault.saturday_closing_time ? convertTimeFormat(showDefault.saturday_closing_time) : 'Closed'}
                                    </div>
                                    <div className='col-4 mt-1'>
                                        Sunday : {showDefault.sunday_opening_time ? convertTimeFormat(showDefault.sunday_opening_time) : 'Closed'} - {showDefault.sunday_closing_time ? convertTimeFormat(showDefault.sunday_closing_time) : 'Closed'}
                                    </div>
                                </div> */}
                                <div className='row'>
                                    {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day, index) => (
                                        <div className='col-4 mt-1' key={index}>
                                            {`${day.charAt(0).toUpperCase() + day.slice(1)} : `}
                                            {showDefault[`${day}_opening_time`] || showDefault[`${day}_closing_time`] ? (
                                                <>
                                                    {showDefault[`${day}_opening_time`] ? (showDefault[`${day}_opening_time`]) : ''} -
                                                    {showDefault[`${day}_closing_time`] ? (showDefault[`${day}_closing_time`]) : ''}
                                                </>
                                            ) : (
                                                <span style={{ color: 'red' }}>Closed</span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <button type="button" class="btn btn-primary mr-2" onClick={(e) => openModel('addCardModal', 'add')}>Reset PIN</button>
                        </div>
                    </div> : <Loader />}
            </div>



            {/*  Modal*/}
            <div className="modal fade" id="addCardModal" aria-labelledby="addCardModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="addCardModalLabel"> Reset PIN</h3>
                            <button type="button" className="btn-close" onClick={(e) => closeModel('addCardModal')} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <div className='row mt-2'>
                                        <div className='col-1'></div>
                                        <div className='col-10'>
                                            <div className="pin-input-fields">
                                                <OTPInput
                                                    inputStyle={{
                                                        width: "80px",
                                                        height: "1.5em",
                                                        padding: "22px",
                                                        fontSize: "20px",
                                                        borderRadius: 4,
                                                        margin: '10px',
                                                        border: "1px solid rgba(232, 232, 232, 1)"
                                                    }}
                                                    renderInput={(props) => <input {...props} />}

                                                    numInputs={4}
                                                    separator={<span>-</span>}
                                                    value={formik.values.user_login_pin}
                                                    onChange={(value) => formik.setFieldValue('user_login_pin', value)}
                                                />
                                                {/* <input
                                                    type="number"
                                                    className="otp__digit otp__field__1"
                                                    maxLength={1}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('user_login_pin',
                                                            `${e.target.value}${formik.values.user_login_pin.slice(1)}`);
                                                    }}
                                                />
                                                <input
                                                    type="number"
                                                    className="otp__digit otp__field__2"
                                                    maxLength={1}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('user_login_pin',
                                                            `${formik.values.user_login_pin[0] || ''}${e.target.value}${formik.values.user_login_pin.slice(2)}`);
                                                    }}
                                                />
                                                <input
                                                    type="number"
                                                    className="otp__digit otp__field__3"
                                                    maxLength={1}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('user_login_pin',
                                                            `${formik.values.user_login_pin.slice(0, 2)}${e.target.value}${formik.values.user_login_pin.slice(3)}`);
                                                    }}
                                                />
                                                <input
                                                    type="number"
                                                    className="otp__digit otp__field__4"
                                                    maxLength={1}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('user_login_pin',
                                                            `${formik.values.user_login_pin.slice(0, 3)}${e.target.value}`);
                                                    }}
                                                /> */}
                                            </div>
                                        </div>
                                        <div className='col-1'></div>

                                        <span className='text-danger d-flex text-left' id='errortext'>
                                            {formik.errors.user_login_pin && formik.touched.user_login_pin && formik.errors.user_login_pin}
                                        </span>
                                        <div className='col-lg-4'></div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary" style={{ width: '48%' }} data-bs-dismiss="modal" onClick={(e) => closeModel('addCardModal')}>Cancel</button>
                                <button type="submit" className="btn btn-primary" style={{ width: '48%' }}>Save</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}

export default OutletUserView