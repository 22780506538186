import React, { useCallback, useRef, useState } from 'react';
import { GoogleMap, useLoadScript, Autocomplete, Marker } from '@react-google-maps/api';
import { Modal, Button } from 'react-bootstrap';

const libraries = ['places'];
const mapContainerStyle = {
    width: '100%',
    height: '400px'
};

const GoogleMapModal = ({ show, handleClose, setShowMap, onSelectLocation,location,setLocation }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'YOUR_GOOGLE_MAPS_API_KEY',
        libraries
    });

    const [marker, setMarker] = useState(null);
    const mapRef = useRef();

    const onMapClick = useCallback((event) => {
        setMarker({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    }, []);

    const handleSelectLocation = () => {
      
        onSelectLocation(marker);
        handleClose();
       
    };
    

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading Maps...</div>;
    return (
        <Modal show={show} onHide={() => setShowMap(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Select Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Autocomplete>
                    <input
                        type="text"
                        placeholder="Search location"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `100%`,
                            height: `40px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`
                        }}
                        onChange={(e) => setLocation(e.target.value)}
                    />
                </Autocomplete>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={8}
                    center={{ lat: 44.4268, lng: 26.1025 }}
                    onClick={onMapClick}
                    ref={mapRef}
                >
                    {marker && <Marker position={{ lat: marker.lat, lng: marker.lng }} />}
                </GoogleMap>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowMap(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSelectLocation}>
                    Select Location
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default GoogleMapModal;
