import React, { useEffect, useState } from 'react'
import {  useParams } from 'react-router-dom'
import Loader from '../../components/company_panel/common/loader';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { CouponDetails } from '../../services/company_panel/coupons.service';
import { SpecialCharacter, capitalizeAllFirstLetters, formateDate} from '../../helpers/commonFunctions';
import { CustomerDetails } from '../../services/company_panel/customer.services';

const CustomerView = () => {
  const params = useParams()
  const [showDefault, setShowDefault] = useState({})
  const breadcrumbs = [{ title: "Customers", url: "/company/crm/customers/list/1" }, {
    title: "Customer Detail",
    url: ""
  },]

  useEffect(() => {
    CustomerDetails({ id: params.id }).then(data => {
      setShowDefault(data && data.data && data.data.response ? data.data.response : [])
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [])

  return (
    <>
      <div class="page-header d-flex align-items-center">
        <div class="page-header-left">
          <Breadcrums data={breadcrumbs} />
          <h4>Customers</h4>
        </div>
      </div>
      <div className="card custom-card">
        {showDefault && Object.keys(showDefault).length > 0 ?
          <div className="card-body p-0">
            <div className="col-lg-12 form-group">
              <table id="simple-table" className="table table-custom  ">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td className='text-end'>{showDefault && showDefault.customer_name ? capitalizeAllFirstLetters(showDefault.customer_name) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td >Phone Number</td>
                    <td className='text-end'>{showDefault && showDefault.customer_number ? (showDefault.customer_number) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td className='text-end'>{showDefault && showDefault.customer_address ? (capitalizeAllFirstLetters(showDefault.customer_address)) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>CreatedDate</td>
                    <td className='text-end'>{showDefault && showDefault.created_at ? formateDate(showDefault.created_at) : "N/A"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> : <Loader />}
      </div>
    </>
  )
}

export default CustomerView