import axiosInstance from "../../../utils/axiosCompany";

export const CreateOutlet = async (values) => {
    return await axiosInstance().post("company/platform-roles/add-user", values);
}

export const GetModulesAndPermissions = async () => {
    return await axiosInstance().get("company/platform-roles/get-modules-and-permissions");
}

export const CreateRole = async (values) => {
    return await axiosInstance().post("company/platform-roles/add-role", values);
}

export const ChangeStatusOutletUser = async (values) => {
    return await axiosInstance().post("company/platform-roles/change-user-status", values);
}

export const GetOutletUserDetail = async (id) => {
    return await axiosInstance().get(`company/platform-roles/get-single-user?id=${id}`);
};

export const viewOutletUserDetail = async (id) => {
    return await axiosInstance().get(`company/platform-roles/view-single-user?id=${id}`);
};

export const GetRoles = async (company_id) => {
    return await axiosInstance().get(`company/platform-roles/get-roles?company_id=${company_id}`);
};

export const GetOutletUserList = async (values) => {
    return await axiosInstance().post("company/platform-roles/get-all-users", values);
}

export const GetAllRoleList = async (values) => {
    return await axiosInstance().post("company/platform-roles/get-all-roles", values);
}

export const DeleteOutletUser = async (id) => {
    return await axiosInstance().delete(`company/platform-roles/delete-user/${id}`)
}

export const EditOutletUser  = async (values) => {
    return await axiosInstance().post("company/platform-roles/update-user", values);
}

export const EditUserLoginPin  = async (values) => {
    return await axiosInstance().post("company/platform-roles/reset-user-login-pin", values);
}

export const GetRoleList = async (values) => {
    return await axiosInstance().post("company/platform-roles/get-all-roles", values);
}

export const DeleteRole = async (id) => {
    return await axiosInstance().delete(`company/platform-roles/delete-role/${id}`)
}

export const GetRoleDetail = async (id) => {
    return await axiosInstance().get(`company/platform-roles/get-single-role?id=${id}`);
};


export const EditRole  = async (values) => {
    return await axiosInstance().post("company/platform-roles/update-role", values);
}






export const GetOutletList = async (values) => {
    return await axiosInstance().post("company/list-outlet-management", values);
}

export const GetEditOutlet  = async (values) => {
    return await axiosInstance().post("company/edit-outlet-management", values);
}

export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("company/change-status-outlet-management", values)
}

export const GetDeleteOutlet = async (values) => {
    return await axiosInstance().post("company/delete-outlet-management", values)
}

export const GetOutletDetail = async (id) => {
    return await axiosInstance().get(`company/get-edit-outlet-management?id=${id}`);
};

export const GetBrandList = async (company_id) => {
    return await axiosInstance().get(`company/brand-list?company_id=${company_id}`);
};

export const DeleteHardwareDevice = async (values) => {
    return await axiosInstance().post("company/delete-hardware-device", values)
}


export const AllUsers  = async (values) => {
    return await axiosInstance().post("company/platform-roles/get-all-active-users", values);
}