import React,{useEffect,useState} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/company/img/logo.png'
import { checkPermission } from '../../../helpers/commonFunctions';
import $ from 'jquery';
import { GetWasteConfigurationDetails } from '../../../services/company_panel/setting/wasteConfigurationService';

const SideBar = () => {
    const loc = useLocation();
    const [showDefault, setShowDefault] = useState({})
    // useEffect(()=>{
    //     $('.menu-dorpdown.active').addClass('collapsed');
    //     $('.menu-dorpdown.active').removeClass('active');
    //     $('.multi-collapse.collapse.show').removeClass('show');
    //     setTimeout(() => {
    //         const elem = document.querySelector('.company_sidebar_link.active');
    //         if(elem){
    //             const id = elem.getAttribute('data-parent-id');
    //             const id2 = elem.getAttribute('data-parent-id2');
    //             if(id){
    //                 const parentElem = document.getElementById(id);
    //                 const parentElem2 = document.getElementById(id2);
    //                 parentElem?.classList.add('active');
    //                 parentElem2?.classList.add('show');
    //                 parentElem?.classList.remove('collapsed');

    //             }
    //         }else{
    //             $('.menu-dorpdown.active').addClass('collapsed');
    //             $('.menu-dorpdown.active').removeClass('active');
    //             $('.multi-collapse.collapse.show').removeClass('show');
    //         }
    //     }, 0);
    // },[loc]);

    function isActive(url="/"){
        return window?.location?.pathname?.includes(url);
    }

    useEffect(() => {
        GetWasteConfigurationDetails().then(data => {
            setShowDefault(data && data?.data && data?.data?.response ? data?.data?.response[0] : {})
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, []);

    return (
        <aside className="sidebar">
            <div className="logo-head">
                <Link to="/company/dashboard">
                    <img src={Logo} />
                </Link>
            </div>
            <div className="nav-menu">
                <div className={"nav-item" + (loc.pathname === "/company/dashboard" ? " active" : "")}>
                    <Link className="nav-link" to="/company/dashboard">
                        <i className="ti ti-layout-dashboard sidemenu-icon"></i>
                        <span className="nav-link-title">Dashboard</span>
                    </Link>
                </div>
                <div className={"nav-item menu-dorpdown"+(isActive('/company/business')?" active":" collapsed")} data-bs-toggle="collapse" href="#menu1" role="button" aria-expanded="false" aria-controls="menu1" id='business'>
                    <a className="nav-link">
                    <i className="ti ti-building-skyscraper sidemenu-icon"></i>
                        <span className="nav-link-title">Business</span>
                    </a>
                </div>
                <div class={"collapse multi-collapse"+(isActive('/company/business')?" show":"")} id="menu1">
                    {checkPermission('brands', 'view') ?
                        <div className={`nav-item ms-4`} >
                            <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/business/brand')?" active":"")} data-parent-id="business" data-parent-id2="menu1" to={`/company/business/brand/list/${1}`}>
                            <i className="ti ti-crown sidemenu-icon"></i>
                                <span className="nav-link-title">Brands</span>
                            </NavLink>
                        </div>
                        : ''}
                    {checkPermission('outlets', 'view') ?
                        <div className={`nav-item ms-4`}>
                            <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/business/outlet')?" active":"")} to={`/company/business/outlet/list/${1}`} data-parent-id="business" data-parent-id2="menu1" >
                                <i className="ti ti-building-store sidemenu-icon"></i>
                                <span className="nav-link-title">Outlets</span>
                            </NavLink>
                        </div>
                        : ''}
                    {checkPermission('subscription', 'view') ?
                       <div className={`nav-item ms-4`}>
                            <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/business/subscription')?" active":"")} to="/company/business/subscription" data-parent-id="business" data-parent-id2="menu1" >
                                <i className="ti ti-layout-distribute-horizontal sidemenu-icon"></i>
                                <span className="nav-link-title">Subscription</span>
                            </NavLink>
                        </div>
                        : ''}
                    {checkPermission('platform User', 'view') ?
                        <div className={`nav-item ms-4`}>
                            <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/business/platform-User')?" active":"")}  to={`/company/business/platform-user/outlet-user/list/${1}`} data-parent-id="business" data-parent-id2="menu1" >
                                <i className="ti ti-user-circle sidemenu-icon"></i>
                                <span className="nav-link-title">Platform User</span>
                            </NavLink>
                        </div>
                        : ''}
                </div>
                <div className={"nav-item menu-dorpdown"+(isActive('/company/raw_materials')?" active":" collapsed")} data-bs-toggle="collapse" href="#menu2" role="button" aria-expanded="false" id='row-material' aria-controls="menu2">
                    <a className="nav-link">
                    <i className="ti ti-package sidemenu-icon"></i>
                        <span className="nav-link-title">Raw Materials</span>
                    </a>
                </div>
                <div class={"collapse multi-collapse"+(isActive('/company/raw_materials')?" show":"")} id="menu2">
                    <div className={`nav-item ms-4`}>
                        <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/raw_materials/category')?" active":"")} to={`/company/raw_materials/category/list/${1}`} data-parent-id="row-material" data-parent-id2="menu2">
                            <i className="ti ti-checklist sidemenu-icon"></i>
                            <span className="nav-link-title">Category</span>
                        </NavLink>
                    </div>
                    <div className={`nav-item ms-4`}>
                        <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/raw_materials/sub_category')?" active":"")} to={`/company/raw_materials/sub_category/list/${1}`} data-parent-id="row-material" data-parent-id2="menu2">
                            <i className="ti ti-checklist sidemenu-icon"></i>
                            <span className="nav-link-title">Sub Category</span>
                        </NavLink>
                    </div>
                    <div className={`nav-item ms-4`}>
                        <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/raw_materials/inventory')?" active":"")} to={`/company/raw_materials/inventory/list/${1}`} data-parent-id="row-material" data-parent-id2="menu2">
                            <i className="ti ti-checklist sidemenu-icon"></i>
                            <span className="nav-link-title">Inventory</span>
                        </NavLink>
                    </div>
                    {checkPermission('suppliers', 'view') ?
                    <div className={`nav-item ms-4`}>
                        <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/raw_materials/supplier_manage')?" active":"")} to={`/company/raw_materials/supplier_manage/list/${1}`} data-parent-id="row-material" data-parent-id2="menu2">
                            <i className="ti ti-truck-delivery sidemenu-icon"></i>
                            <span className="nav-link-title">Suppliers</span>
                        </NavLink>
                    </div>
                    : ''}
                </div>

                <div className={"nav-item menu-dorpdown"+(isActive('/company/menu')?" active":" collapsed")} data-bs-toggle="collapse" href="#menu3" role="button" aria-expanded="false" id='menu' aria-controls="menu3">
                    <a className="nav-link">
                    <i className="ti ti-tools-kitchen sidemenu-icon"></i>
                        <span className="nav-link-title">Menu</span>
                    </a>
                </div>

                <div class={"collapse multi-collapse"+(isActive('/company/menu')?" show":"")} id="menu3">
                    <div className={`nav-item ms-4`}>
                    <NavLink end className={"nav-link company_sidebar_link"+(isActive('/company/menu/routing-station')?" active":"")} to={`/company/menu/routing-station/groups/list/${1}`} data-parent-id="menu" data-parent-id2="menu3">
                        <i className="ti ti-router sidemenu-icon"></i>
                        <span className="nav-link-title">Routing Station</span>
                    </NavLink>
                </div>
                    <div className={`nav-item ms-4`}>
                    <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/menu/menu')?" active":"")} to={`/company/menu/menu/categories/list/${1}`} data-parent-id="menu" data-parent-id2="menu3">
                        <i className="ti ti-tools-kitchen sidemenu-icon"></i>
                        <span className="nav-link-title">Menu</span>
                    </NavLink>
                </div>
                </div>

                <div className={"nav-item menu-dorpdown"+(isActive('/company/store_manager')?" active":" collapsed")} data-bs-toggle="collapse" href="#menu4" role="button" aria-expanded="false" id='store-manager' aria-controls="menu4">
                    <a className="nav-link">
                    <i className="ti ti-user sidemenu-icon"></i> 
                        <span className="nav-link-title">Store Manager</span>
                    </a>
                </div>

                <div class={"collapse multi-collapse"+(isActive('/company/store_manager')?" show":"")} id="menu4">
                {showDefault?.status === 1 ?
                <div className={`nav-item ms-4`}>
                    <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/store_manager/waste_management')?" active":"")} to="/company/store_manager/waste_management/list/1" data-parent-id="store-manager" data-parent-id2="menu4">
                        <i className="ti ti-recycle sidemenu-icon"></i>
                        <span className="nav-link-title">Waste management</span>
                    </NavLink>
                </div>
               :null} 
                <div className={`nav-item ms-4`}>
                    <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/store_manager/transfer_stock')?" active":"")} to={`/company/store_manager/transfer_stock/transfer_in/list/${1}`} data-parent-id="store-manager" data-parent-id2="menu4">
                        <i className="ti ti-transform sidemenu-icon"></i>
                        <span className="nav-link-title">Transfer stock</span>
                    </NavLink>
                </div>
                {checkPermission('purchase order', 'view') ?
                    <div className={`nav-item ms-4`}>
                        <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/store_manager/purchase_order')?" active":"")} to={`/company/store_manager/purchase_order/list/${1}`} data-parent-id="store-manager" data-parent-id2="menu4">
                            <i className="ti ti-notes sidemenu-icon"></i>
                            <span className="nav-link-title">Purchase order</span>
                        </NavLink>
                    </div>
                    : ''}
                </div>

                <div className={"nav-item menu-dorpdown"+(isActive('/company/crm')?" active":" collapsed")} data-bs-toggle="collapse" href="#menu5" role="button" aria-expanded="false"  id='crm' aria-controls="menu5">
                    <a className="nav-link">
                    <i className="ti ti-tag sidemenu-icon"></i>
                        <span className="nav-link-title">CRM</span>
                    </a>
                </div>

                <div class={"collapse multi-collapse"+(isActive('/company/crm')?" show":"")} id="menu5">
                        <div className="nav-item ms-4">
                    <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/crm/customers')?" active":"")} to={`/company/crm/customers/list/${1}`} data-parent-id="crm" data-parent-id2="menu5">
                        <i className="ti ti-users sidemenu-icon"></i>
                        <span className="nav-link-title">Customers</span>
                    </NavLink>
                </div>
                {checkPermission('coupons', 'view') ?
                    <div className={`nav-item ms-4`}>
                        <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/crm/coupons')?" active":"")} to={`/company/crm/coupons/coupon/list/${1}`} data-parent-id="crm" data-parent-id2="menu5">
                            <i className="ti ti-tag sidemenu-icon"></i>
                            <span className="nav-link-title">Coupons</span>
                        </NavLink>
                    </div>
                    : ''}
                <div className="nav-item ms-4">
                    <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/crm/loyalty-points')?" active":"")} to={`/company/crm/loyalty-points/list/${1}`} data-parent-id="crm" data-parent-id2="menu5">
                        <i className="ti ti-coins sidemenu-icon"></i>
                        <span className="nav-link-title">Loyalty Points</span>
                    </NavLink>
                </div>
                </div>

                <div className={"nav-item menu-dorpdown"+(isActive('/company/transaction')?" active":" collapsed")} data-bs-toggle="collapse" href="#menu6" role="button" aria-expanded="false" id='transaction' aria-controls="menu6">
                    <a className="nav-link">
                    <i className="ti ti-receipt sidemenu-icon"></i>
                        <span className="nav-link-title">Transaction</span>
                    </a>
                </div>

                <div class={"collapse multi-collapse"+(isActive('/company/transaction')?" show":"")} id="menu6">
                <div className="nav-item ms-4">
                    <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/transaction/order')?" active":"")} to={`/company/transaction/order/list/${1}`} data-parent-id="transaction" data-parent-id2="menu6">
                        <i className="ti ti-package sidemenu-icon"></i>
                        <span className="nav-link-title">Orders</span>
                    </NavLink>
                </div>
                <div className="nav-item ms-4">
                    <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/transaction/refund')?" active":"")} to={`/company/transaction/refund/list/${1}`} data-parent-id="transaction" data-parent-id2="menu6">
                        <i className="ti ti-receipt-refund sidemenu-icon"></i>
                        <span className="nav-link-title">Refund</span>
                    </NavLink>
                </div>
                <div className="nav-item ms-4">
                    <NavLink className={"nav-link company_sidebar_link"+(isActive('/company/transaction/transaction')?" active":"")} to={`/company/transaction/transaction/list/${1}`} data-parent-id="transaction" data-parent-id2="menu6" >
                        <i className="ti ti-transition-right sidemenu-icon"></i>
                        <span className="nav-link-title">Transaction</span>
                    </NavLink>
                </div>
                </div>
                <div className={`nav-item ${loc.pathname.includes("/company/report") ? " active" : ""}`}>
                    <Link className="nav-link" to={`/company/report/list/${1}`}>
                    <i className="ti ti-chart-histogram sidemenu-icon"></i>
                        <span className="nav-link-title">Reports</span> 
                    </Link>
                </div>

                <div className={`nav-item ${loc.pathname.includes("/company/setting") ? " active" : ""}`}>
                    <Link className="nav-link" to={`/company/setting/tax-configuration/list/${1}`}>
                        <i className="ti ti-settings sidemenu-icon"></i>
                        <span className="nav-link-title">Settings</span>
                    </Link>
                </div>
                {/* <div className={`nav-item ${loc.pathname.includes("/company/socket") ? " active" : ""}`}>
                    <Link className="nav-link" to="/company/socket">
                        <i className="ti ti-transition-right sidemenu-icon"></i>
                        <span className="nav-link-title">Socket</span>
                    </Link>
                </div> */}
            </div>
        </aside>
    )
}

export default SideBar;

 {/* {checkPermission('brands', 'view') ?
                    <div className={`nav-item ${loc.pathname.includes("/company/brand") ? " active" : ""}`}>
                        <Link className="nav-link" to={`/company/brand/list/${1}`}>
                            <i className="ti ti-building-skyscraper sidemenu-icon"></i>
                            <span className="nav-link-title">Brands</span>
                        </Link>
                    </div>
                    : ''} */}
                {/* {checkPermission('outlets', 'view') ?
                    <div className={`nav-item ${loc.pathname.includes("/company/outlet") ? " active" : ""}`}>
                        <Link className="nav-link" to={`/company/outlet/list/${1}`}>
                            <i className="ti ti-building-store sidemenu-icon"></i>
                            <span className="nav-link-title">Outlets</span>
                        </Link>
                    </div>
                    : ''} */}
                {/* {checkPermission('platform User', 'view') ?
                    <div className={`nav-item ${loc.pathname.includes("/company/platform-User") ? " active" : ""}`}>
                        <Link className="nav-link" to={`/company/platform-User/outlet-user/list/${1}`}>
                            <i className="ti ti-user-circle sidemenu-icon"></i>
                            <span className="nav-link-title">Platform User</span>
                        </Link>
                    </div>
                    : ''} */}

                {/* {checkPermission('suppliers', 'view') ?
                    <div className={`nav-item ${loc.pathname.includes("/company/supplier") ? " active" : ""}`}>
                        <Link className="nav-link" to={`/company/supplier_manage/list/${1}`}>
                            <i className="ti ti-truck-delivery sidemenu-icon"></i>
                            <span className="nav-link-title">Suppliers</span>
                        </Link>
                    </div>
                    : ''} */}
                {/* <div className="nav-item">
                    <Link className="nav-link" to="/company/dashboard">
                        <i className="ti ti-users sidemenu-icon"></i>
                        <span className="nav-link-title">Customers</span>
                    </Link>
                </div> */}
                {/* <div className={`nav-item ${loc.pathname.includes("/company/category") ? " active" : ""}`}>
                    <Link className="nav-link" to={`/company/category/list/${1}`}>
                        <i className="ti ti-checklist sidemenu-icon"></i>
                        <span className="nav-link-title">Category</span>
                    </Link>
                </div>
                <div className={`nav-item ${loc.pathname.includes("/company/sub_category") ? " active" : ""}`}>
                    <Link className="nav-link" to={`/company/sub_category/list/${1}`}>
                        <i className="ti ti-checklist sidemenu-icon"></i>
                        <span className="nav-link-title">Sub Category</span>
                    </Link>
                </div>
                <div className={`nav-item ${loc.pathname.includes("/company/inventory") ? " active" : ""}`}>
                    <Link className="nav-link" to={`/company/inventory/list/${1}`}>
                        <i className="ti ti-checklist sidemenu-icon"></i>
                        <span className="nav-link-title">Inventory</span>
                    </Link>
                </div> */}
                {/* <div className={`nav-item ${loc.pathname.includes("/company/routing-station") ? " active" : ""}`}>
                    <Link end className="nav-link" to={`routing-station/groups/list/${1}`}>
                        <i className="ti ti-router sidemenu-icon"></i>
                        <span className="nav-link-title">Routing Station</span>
                    </Link>
                </div> */}
                {/* <div className={`nav-item ${loc.pathname.includes("/company/waste_management") ? " active" : ""}`}>
                    <Link className="nav-link" to="/company/waste_management/list">
                        <i className="ti ti-recycle sidemenu-icon"></i>
                        <span className="nav-link-title">Waste management</span>
                    </Link>
                </div> */}
                {/* {checkPermission('suppliers', 'view') ?
                    <div className={`nav-item ${loc.pathname.includes("/company/supplier") ? " active" : ""}`}>
                        <Link className="nav-link" to={`/company/supplier_manage/list/${1}`}>
                            <i className="ti ti-truck-delivery sidemenu-icon"></i>
                            <span className="nav-link-title">Suppliers</span>
                        </Link>
                    </div>
                    : ''} */}

                {/* <div className={`nav-item ${loc.pathname.includes("/company/transfer_stock") ? " active" : ""}`}>
                    <Link className="nav-link" to={`/company/transfer_stock/transfer_in/list/${1}`}>
                        <i className="ti ti-transform sidemenu-icon"></i>
                        <span className="nav-link-title">Transfer stock</span>
                    </Link>
                </div> */}

                {/* <div className="nav-item">
                    <Link className="nav-link" to="/company/tran">
                        <i className="ti ti-transform sidemenu-icon"></i>
                        <span className="nav-link-title">Transfer stock</span>
                    </Link>
                </div> */}
                {/* {checkPermission('purchase order', 'view') ?
                    <div className={`nav-item ${loc.pathname.includes("/company/purchase_order") ? " active" : ""}`}>
                        <Link className="nav-link" to={`/company/purchase_order/list/${1}`}>
                            <i className="ti ti-notes sidemenu-icon"></i>
                            <span className="nav-link-title">Purchase order</span>
                        </Link>
                    </div>
                    : ''} */}
                {/* <div className="nav-item">
                    <Link className="nav-link" to="/company/dashboard">
                        <i className="ti ti-notes sidemenu-icon"></i>
                        <span className="nav-link-title">Purchase order</span>
                    </Link>
                </div> */}
                {/* <div className={`nav-item ${loc.pathname.includes("/company/menu") ? " active" : ""}`}>
                    <Link className="nav-link" to={`/company/menu/categories/list/${1}`}>
                        <i className="ti ti-tools-kitchen sidemenu-icon"></i>
                        <span className="nav-link-title">Menu</span>
                    </Link>
                </div> */}
                {/* <div className="nav-item">
                    <Link className="nav-link" to="/company/dashboard">
                        <i className="ti ti-tag sidemenu-icon"></i>
                        <span className="nav-link-title">Coupons</span>
                    </Link>
                </div> */}
                {/* {checkPermission('coupons', 'view') ?
                    <div className={`nav-item ${loc.pathname.includes("/company/coupons") ? " active" : ""}`}>
                        <Link className="nav-link" to={`/company/coupons/coupon/list/${1}`}>
                            <i className="ti ti-tag sidemenu-icon"></i>
                            <span className="nav-link-title">Coupons</span>
                        </Link>
                    </div>
                    : ''}
                <div className="nav-item">
                    <Link className="nav-link" to="/company/dashboard">
                        <i className="ti ti-coins sidemenu-icon"></i>
                        <span className="nav-link-title">Loyalty Points</span>
                    </Link>
                </div> */}
                {/* <div className="nav-item">
                    <Link className="nav-link" to="/company/dashboard">
                        <i className="ti ti-package sidemenu-icon"></i>
                        <span className="nav-link-title">Orders</span>
                    </Link>
                </div> */}
                {/* <div className="nav-item">
                    <Link className="nav-link" to={`/company/supplier_manage/list/${1}`}>
                        <i className="ti ti-truck-delivery sidemenu-icon"></i>
                        <span className="nav-link-title">Suppliers</span>
                    </Link>
                </div> */}



                {/* <div className="nav-item">
                    <Link className="nav-link" to="/company/dashboard">
                        <i className="ti ti-receipt-refund sidemenu-icon"></i>
                        <span className="nav-link-title">Refund</span>
                    </Link>
                </div>
                <div className="nav-item">
                    <Link className="nav-link" to="/company/dashboard">
                        <i className="ti ti-transition-right sidemenu-icon"></i>
                        <span className="nav-link-title">Transaction</span>
                    </Link>
                </div> */}
                {/* <div className="nav-item">
                    <Link className="nav-link" to="/company/dashboard">
                        <i className="ti ti-chart-histogram sidemenu-icon"></i>
                        <span className="nav-link-title">Reports</span>
                    </Link>
                </div> */}
                {/* {checkPermission('subscription', 'view') ?
                    <div className="nav-item">
                        <Link className="nav-link" to="/company/subscription">
                            <i className="ti ti-layout-distribute-horizontal sidemenu-icon"></i>
                            <span className="nav-link-title">Subscription</span>
                        </Link>
                    </div>
                    : ''} */}
                {/* <div className={`nav-item ${loc.pathname.includes("/company/setting") ? " active" : ""}`}>
                    <Link className="nav-link" to={`/company/setting/order-mode-configuration/list/${1}`}>
                        <i className="ti ti-settings sidemenu-icon"></i>
                        <span className="nav-link-title">Settings</span>
                    </Link>
                </div>

                <div className="nav-item">
                    <Link className="nav-link" to="/company/socket">
                        <i className="ti ti-transition-right sidemenu-icon"></i>
                        <span className="nav-link-title">Socket</span>
                    </Link>
                </div> */}
                {/* <div className="nav-item">
                <a className="nav-link active" href="form.html" role="button">
                    <i className="ti ti-home"></i>
                    <span className="nav-link-title">Customers</span>
                </a>
                </div>
                <div className="nav-item">
                <a className="nav-link" href="table.html" role="button">
                    <i className="ti ti-home"></i>
                    <span className="nav-link-title">Table</span>
                </a>
                </div>
                <div className="nav-item">
                <a className="nav-link dropdown-toggle collapsed" href="#menu-row-1" role="button" data-bs-toggle="collapse" data-bs-target="#menu-row-1" aria-expanded="false" aria-controls="menu-row-1">
                    <i className="ti ti-home"></i>
                    <span className="nav-link-title">User Profile</span>
                </a>

                <div id="menu-row-1" className="nav-collapse collapse" data-bs-parent="#navbarVerticalMenuPagesMenu" hs-parent-area="#navbarVerticalMenu" style={{top: '0px',opacity: 1}}>
                    <a className="nav-link " href="./user-profile.html">Profile</a>
                    <a className="nav-link " href="./user-profile-teams.html">Teams</a>
                    <a className="nav-link " href="./user-profile-projects.html">Projects</a>
                    <a className="nav-link " href="./user-profile-connections.html">Connections</a>
                    <a className="nav-link " href="./user-profile-my-profile.html">My Profile</a>
                </div>
                </div> */}