
import React, { useState, useEffect } from 'react'
import { Formik, Field, ErrorMessage, useFormik } from 'formik';
import { CreateOutlet } from '../../../services/company_panel/outletService';
import { GetAllCountries, GetAllStates, GetAllCities } from '../../../services/globalService';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import $ from "jquery";
import TimePicker from "@ashwinthomas/react-time-picker-dropdown";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, getBrandId, getCompany, getOutletId, SpecialCharacter } from '../../../helpers/commonFunctions';
import GoogleMapModal from '../../outlet/GoogleMapModal';
import { GetBrandList } from '../../../services/brandService';
import { Dropdown } from 'semantic-ui-react';

import { Table, Button, Modal, Form, } from 'react-bootstrap';
import axios from 'axios';
import { GetAllParentCategories } from '../../../services/company_panel/menuCategoryService';
import { GetAllActiveTaxes, GetTaxList } from '../../../services/company_panel/setting/taxConfigurationService';
import { GetActiveOrderModes, GetOrderModeList } from '../../../services/company_panel/setting/orderModeConfigurationService';
import { CreateAddOn, GetAddOnCategoryList, GetAllAddOnCategory } from '../../../services/company_panel/menuAddOnCategory';
import { GetAllInventories, GetInventoryList, GetSingleInventory } from '../../../services/company_panel/inventoryService';
import CustomMultiSelect from '../../../components/common/CustomMultiSelect';
import * as Yup from "yup";

const AddOnsAdd = () => {
  const [btnDisable, setBtnDisable] = useState(true);
  const [btnClicked, setBtnClicked] = useState(false);
  const [page, setPage] = useState(1);
  const [location, setLocation] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [selectedOption, setSelectedOption] = useState('automatic');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [show, setShow] = useState(false);
  const [recipes, setRecipes] = useState([]);
  const [taxList, setTaxList] = useState([]);
  // const [orderList, setOrderList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [rawList, setRawList] = useState({});
  const navigate = useNavigate()
  const [submitted, setSubmitted] = useState(false)
  const [brandList, setBrandList] = useState([])
  const [orderList, setOrderList] = useState([]);
  const [addOnDataList, setAddOnDataList] = useState([]);

  const handleClose = () => {
    formik?.resetForm();
    setShow(false);
  };

  const resetForm = () => {
    setRawList({ id: 0, name: '' });
    setShow(false);
  }
  

  const breadcrumbs = [
    {
      title: "Menu",
      url: "/company/menu/menu/categories/list/1"
    },
    {
      title: "Add-Ons",
      url: "/company/menu/menu/add_ons/list/1"
    },
    {
      title: "Add-Ons Listing",
      url: "/company/menu/menu/add_ons/list/1"
    },
    {
      title: "Create an Add-On",
      url: ""
    },
  ]

  useEffect(() => {
    const formData = new FormData();
    formData.append('brand_id', getBrandId());
    GetAllAddOnCategory(formData)
      .then(data => {
        console.log("data------>", data);
        setBrandList(data && data.data && data.data.response ? data.data.response : []);
      })
      .catch(error => {
        console.log("error ====> ", error);
      });
  }, []);

  useEffect(() => {
    const formData = new FormData()
    formData.append('brand_id', getBrandId());
    formData.append('type', 'tax-groups');
    GetAllActiveTaxes(formData).then(data => {
      setTaxList(data && data.data && data.data.response ? data.data.response : [])
    }).catch(error => {
      console.log("error ====> ", error);
    })
  }, [])

  useEffect(() => {
    const formData = new FormData()
    formData.append('brand_id', getBrandId());
    GetAllInventories(formData).then(data => {
      setInventoryList(data && data.data && data.data.response ? data.data.response.rows : [])
    }).catch(error => {
      console.log("error ====> ", error);
    })
  }, [])

  const nextStep = (selected) => {
    setPage(page + 1);
    var currentStepNum = $('#checkout-progress').data('current-step');
    var nextStepNum = (currentStepNum + 1);
    var currentStep = $('.step.step-' + currentStepNum);
    var nextStep = $('.step.step-' + nextStepNum);
    var progressBar = $('#checkout-progress');
    $('.btn-prev').show();
    $('#section' + currentStepNum).toggle();
    $('#section' + nextStepNum).toggle();
    if (nextStepNum == 3) {
      $(this).toggle();
      $('.btn-submit').toggle();
      $('.btn-next').hide();
    }

    $('.checkout-progress').removeClass('.step-' + currentStepNum).addClass('.step-' + (currentStepNum + 1));

    currentStep.removeClass('active').addClass('valid');
    currentStep.find('span').addClass('opaque');
    currentStep.find('.fa.fa-check').removeClass('opaque');

    nextStep.addClass('active');
    progressBar.removeAttr('class').addClass('step-' + nextStepNum).data('current-step', nextStepNum);
  }
  const prevStep = (selected) => {
    setPage(page - 1);
    var currentStepNum = $('#checkout-progress').data('current-step');
    var prevStepNum = (currentStepNum - 1);
    var currentStep = $('.step.step-' + currentStepNum);
    var prevStep = $('.step.step-' + prevStepNum);
    var progressBar = $('#checkout-progress');
    $('.btn-next').removeClass('disabled');
    $('#section' + currentStepNum).toggle();
    $('#section' + prevStepNum).toggle();
    if (currentStepNum == 3) {
      $('.btn-submit').toggle();
      $('.btn-next').toggle();
    }

    if (currentStepNum == 2) {
      $('.btn-prev').hide();
    }
    if (currentStepNum == 1) {
      $('.btn-prev').hide();
      return false;
    }
    if (prevStepNum == 1) {
      $(this).addClass('disabled');
    }
    $('.checkout-progress').removeClass('.step-' + currentStepNum).addClass('.step-' + (prevStepNum));

    currentStep.removeClass('active');
    prevStep.find('span').removeClass('opaque');
    prevStep.find('.fa.fa-check').addClass('opaque');

    prevStep.addClass('active').removeClass('valid');
    progressBar.removeAttr('class').addClass('step-' + prevStepNum).data('current-step', prevStepNum);
  };

  const brandOptions = brandList?.map(row => ({
    key: row.id,
    text: capitalizeAllFirstLetters(row.name),
    value: row.id,
  }));


  const [activeStep, setActiveStep] = useState(1);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const [recipeEditableIndex, setRecipeEditableIndex] = useState(null);

  const handleChangeModel = (e) => {
    const { value } = e.target;
    if (Number(value)) {
      GetSingleInventory({ id: Number(value), outlet_id: getOutletId() })
        .then(data => {
          let inventory = data?.data?.response || {};
          const inventoryData = inventory?.inventory_unit_converisons.filter((item) => {
            return item.unit_type == 'Usage Unit';
          });
          let myData = { ...formik?.values };
          myData.rawMaterial = value;
          myData.usageUOM = inventoryData[0]?.uomDetail?.uom_code;
          myData.quantity = 1;
          myData.price = inventoryData[0]?.price;
          myData.calories = inventoryData[0]?.calories;
          myData.raw_material_price = inventoryData[0]?.price;
          console.log("*****----myData", myData);
          formik.setValues(myData);
        })
        .catch(error => {
          console.log("error ====> ", error);
        });
    } else {
      formik.setFieldValue("rawMaterial", "");
    }

  };

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      rawMaterial: Yup.string().trim().required("Raw material is required"),
      quantity: Yup.number().integer("Quantity must be a integer").min(1, "Quantity must be greater than 0").required("Quantity is required"),
    }),
    initialValues: {
      rawMaterial: '',
      usageUOM: '',
      quantity: '',
      price: '',
      calories: '',
      orderMode: []
    },
    onSubmit: (values) => {
      if (recipeEditableIndex === null) {
        setAddOnDataList(prevState => [
          ...prevState,
          values
        ]);
      } else {
        let existingData = [...addOnDataList];
        existingData[recipeEditableIndex] = values;
        setAddOnDataList(existingData);
        setRecipeEditableIndex(null);
      }
      handleClose();
    },
  });

  const handleRemoveItem = (index) => {
    let updatedList = [...addOnDataList];
    updatedList.splice(index, 1);
    setAddOnDataList(updatedList);
  };

  const handleEditItem = (index) => {
    setRecipeEditableIndex(index);
    formik.setValues(addOnDataList[index]);
    setShow(true);
  };


  const getRecordFromList = (list, id) => {
    return list.filter(item => Number(item.id) === Number(id))[0];
  }

  useEffect(() => {
    const formData = new FormData()
    formData.append("page", 1)
    formData.append("per_page", 1000)
    formData.append("sort", JSON.stringify(1))
    formData.append("search", JSON.stringify(1))
    formData.append("global_search", 1)
    formData.append('brand_id', getBrandId());
    GetOrderModeList(formData).then(data => {
      setOrderList(data && data.data && data.data.response ? data.data.response.rows : [])
    }).catch(error => {
      console.log("error ====> ", error);
    })
  }, [])



  return (
    <>
      <div class="page-header d-flex align-items-center">
        <div class="page-header-left">
          <Breadcrums data={breadcrumbs} />
          <h4>Menu</h4>
        </div>
        <div class="page-header-right ms-auto">
        </div>
      </div>
      <Formik
        initialValues={{
          outlet_name: '',
          outlet_name_ar: '',
          brand_id: '',
          tax_id: '',
          short_code: '',
          vat_information: '',
          email: '',
          short_description: '',
          short_description_ar: '',
        }}
        validate={values => {
          const error = {};
          if (!values.outlet_name) {
            error.outlet_name = "Name is required";
          } else if (values.outlet_name.length > 60) {
            error.outlet_name = "Name should not exceed 60 characters";
          }
          if (!values.outlet_name_ar) {
            error.outlet_name_ar = "Name (Arabic) is required";
          } else if (values.outlet_name_ar.length > 60) {
            error.outlet_name_ar = "Name (Arabic) should not exceed 60 characters";
          }
          if (!values.brand_id) error.brand_id = "Category is required";
          if (!values.short_code) error.short_code = "Price is required";

          if (values.short_description && values?.short_description?.length > 250) {
            error.short_description = 'Short description cannot exceed 250 characters.';
          }
          if (values.short_description_ar && values?.short_description_ar?.length > 250) {
            error.short_description_ar = 'Short description arabic cannot exceed 250 characters.';
          }
          if (page === 1) {
            if (error.outlet_name || error.outlet_name_ar || error.brand_id || error.short_code || error?.short_description?.length < 250 || error?.short_description_ar?.length < 250) {
              setBtnDisable(true);
            } else {
              setBtnDisable(false);
            }
          } else if (page === 2 || addOnDataList.length === 1) {
            setBtnDisable(true);
          } else {
            setBtnDisable(false);
          }
          return error;
        }}
        onSubmit={(values, { setSubmitting }) => {
          let mySetData = []
          let recipeDataToSend = addOnDataList.map((item) => {
            return { inventory_id: item?.rawMaterial, order_mode_id: item?.orderMode, quantity: item?.quantity };
          });
          setSubmitted(true)
          let formData = new FormData();
          formData.append('name', values.outlet_name.trim());
          formData.append('name_ar', values.outlet_name_ar.trim());
          formData.append('add_on_category_id', JSON.stringify(values.brand_id));
          formData.append('tax_group_id', values.tax_id);
          formData.append('brand_id', getBrandId());
          formData.append('price', values.short_code);
          formData.append('vat_information', values.vat_information);
          formData.append('email', values.email);
          formData.append('short_description', values.short_description);
          formData.append('short_description_ar', values.short_description_ar);
          if (addOnDataList?.length > 0) {
            addOnDataList?.map((item) => {
              if (item.rawMaterial != "") {
                mySetData.push({
                  inventory_id: item.rawMaterial.id,
                  quantity: item.quantity,
                  order_mode_id: item.orderMode
                })
              }
            })
          }
          formData.append('reciepes', JSON.stringify(recipeDataToSend));
          formData.append('company_id', getCompany().company_id);
          CreateAddOn(formData).then(response => {
            setSubmitting(false);
            setSubmitted(false);
            if (response.data.status === 1 || response.data.status === "1") {
              Swal.fire({
                customClass: 'swal-wide',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                position: 'top-right',
              });
              setTimeout(() => {
                navigate(`/company/menu/menu/add_ons/list/${1}`)
              }, 1000);
            } else {
              Swal.fire({
                customClass: 'swal-wide',
                icon: 'error',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000,
                toast: true,
                position: 'top-right',
              })
              setSubmitted(false)
            }
          }).catch(error => {
            console.log("error ====> ", error);
          })
        }}
      >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className='steps'>
            <ul>
              <li className={`step step-1 ${activeStep >= 1 ? 'active' : ''}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(1)}>
                <span>1</span><p>Add-On</p>
              </li>
              <li className={`step step-2 ${activeStep >= 2 ? 'active' : 'tag_disabled'}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(2)}>
                <span>2</span><p>Recipe</p>
              </li>
              <li className={`step step-3 ${activeStep >= 3 ? 'active' : 'tag_disabled'}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(3)}>
                <span>3</span><p>Settings</p>
              </li>

            </ul>
          </div>
          <div className="card custom-card">
            <div className="card-body">
              <div className='row row-sm'>
                <div className="step-1" id="checkout-progress" data-current-step="1" style={{ display: 'none' }}>
                  <div className="progress-bar">
                    <div className="step step-1 active"><span> 1</span>
                      <div className="fa fa-check opaque"></div>
                      <div className="step-label"> Add-On</div>
                    </div>
                    <div className="step step-2"><span> 2</span>
                      <div className="fa fa-check opaque"></div>
                      <div className="step-label"> Recipe</div>
                    </div>
                    <div className="step step-3"><span> 3</span>
                      <div className="fa fa-check opaque"></div>
                      <div className="step-label">Settings</div>
                    </div>

                  </div>
                </div>
                <section id="section1" className="section1" style={{ display: 'block' }}>
                  <div className='row'>
                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='outlet_name' className='text-left d-flex'>Name <span className="requirestar">*</span></label>
                      <input name='outlet_name' id="outlet_name" type='text' onChange={handleChange} onBlur={handleBlur} value={values.outlet_name} className='form-control' autoComplete='off' placeholder='Enter Name' />
                      <span className='text-danger d-flex text-left'>{errors.outlet_name && touched.outlet_name && errors.outlet_name}</span>
                    </div>
                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='outlet_name_ar' className='text-left d-flex'>Name (arabic) <span className="requirestar">*</span></label>
                      <input name='outlet_name_ar' id="outlet_name_ar" type='text' onChange={handleChange} onBlur={handleBlur} value={values.outlet_name_ar} className='form-control' autoComplete='off' placeholder='Enter Name in Arabic' />
                      <span className='text-danger d-flex text-left'>{errors.outlet_name_ar && touched.outlet_name_ar && errors.outlet_name_ar}</span>
                    </div>
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='brand_id' className='text-left d-flex'>
                        Category: <span className="requirestar">*</span>
                      </label>
                      <Dropdown

                        className='form-control'
                        id="brand_id"
                        placeholder='Select category'
                        search
                        fluid
                        selection
                        options={brandOptions}
                        value={values.brand_id}
                        onChange={(event, data) => setFieldValue("brand_id", data.value)}
                      />
                      <span className='text-danger d-flex text-left'>
                        {errors.brand_id && touched.brand_id && errors.brand_id}
                      </span>
                    </div>
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='short_code' className='text-left d-flex'>Price : <span className="requirestar">*</span></label>
                      <input name='short_code' id="short_code" type='number' onChange={handleChange} onBlur={handleBlur} value={values.short_code} className='form-control' autoComplete='off' placeholder='Enter Price' />
                      <span className='text-danger d-flex text-left'>{errors.short_code && touched.short_code && errors.short_code}</span>
                    </div>

                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='short_description' className='text-left d-flex'>Short description : </label>
                      <textarea name='short_description' id="short_description" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description} className='form-control' />
                      <span className='text-danger d-flex text-left'>{errors.short_description && touched.short_description && errors.short_description}</span>
                    </div>
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='short_description_ar' className='text-left d-flex'>Short description (arabic) : </label>
                      <textarea name='short_description_ar' id="short_description_ar" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description_ar} className='form-control' />
                      <span className='text-danger d-flex text-left'>{errors.short_description_ar && touched.short_description_ar && errors.short_description_ar}</span>
                    </div>
                    <div className='row'>

                    </div>
                  </div>
                </section>
                <section id="section2" className="section2" style={{ display: 'none', paddingTop: '0' }}>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary btn-lg"
                      type="button"
                      onClick={() => { setRecipeEditableIndex(null); setShow(true) }}
                      style={{ marginTop: '10px' }}
                    >
                      Add New Recipe
                    </button>
                  </div>
                  <div className="tabler-wrap">
                    <h2>Recipe List</h2>
                    <table className="table table-custom">
                      <thead>
                        <tr>
                          <th>Raw Material</th>
                          <th>Usage UOM</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Calories</th>
                          <th>Order Mode</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {addOnDataList && addOnDataList?.length ? (
                          addOnDataList?.map((recipe, index) => (
                            <tr key={index}>
                              <td>{capitalizeAllFirstLetters(getRecordFromList(inventoryList, recipe?.rawMaterial)?.item_name)}</td>
                              <td>{recipe?.usageUOM}</td>
                              <td>{recipe?.quantity}</td>
                              {/* <td>{recipe?.price}</td> */}
                              <td>{recipe?.price ? recipe?.price * recipe?.quantity  : null}</td>
                              <td>{recipe?.calories}</td>
                              <td>
                                {
                                  recipe?.orderMode?.map((item, i) => {
                                    return <span key={i}>{capitalizeAllFirstLetters(SpecialCharacter(getRecordFromList(orderList, item)?.name))}{i < (recipe?.orderMode?.length - 1) ? ", " : ""}</span>
                                  })
                                }
                              </td>
                              <td className='d-flex'>
                                <button type='button' className='btn btn-primary me-3' onClick={() => handleEditItem(index)}>Edit</button>
                                <button type='button' className='btn btn-danger' onClick={() => handleRemoveItem(index)}>Remove</button>
                              </td>
                            </tr>

                          ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center">No recipes found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </section>
                <section id="section3" className="section3 " style={{ display: 'none' }}>
                  <div className='row'>
                    <div className='col-lg-8 form-group' style={{ width: '50%' }}>
                      <div className='form-group mt-3'>
                        <label htmlFor='tax_id' className='d-flex'>Tax Group :<span className="requirestar"></span></label>
                        <select id="tax_id" className='form-control' name='tax_id' onChange={handleChange} onBlur={handleBlur}>

                          <option value="">Select Tax Group</option>
                          {taxList?.length ? taxList?.map((row) => (
                            <option key={row.id} value={row.id}>{capitalizeFirstLetter(row?.name)}</option>
                          )) : ''}
                        </select>
                        <span className='text-danger d-flex'>{errors.tax_id && touched.tax_id && errors.tax_id}</span>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="button-container mt-4 p-10">
                  <button className="btn btn-outline-primary btn-prev" type="button" onClick={prevStep} style={{ display: 'none' }}>Back</button>
                  <button className="btn btn-next btn-primary ms-2" type="button" onClick={nextStep} disabled={btnDisable}> Next</button>
                  <button className="btn btn-primary ms-2 btn-submit" onClick={() => { setBtnClicked(true) }} type="submit" style={{ display: 'none' }}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      </Formik>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={true | 1000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
      <Modal show={show} onHide={handleClose} backdrop={'static'} >
        <Modal.Header closeButton>
          <Modal.Title>{recipeEditableIndex === null ? "Add" : "Edit"} New Recipe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className='col-lg-6 text-center form-group mb-3 w-100' controlId="rawMaterial">
                <label className='text-left d-flex'>Raw Material</label>
                <select as="select" className='form-control' name="rawMaterial" onBlur={formik?.handleBlur} value={formik?.values?.rawMaterial} onChange={handleChangeModel} >
                  <option value="">Select Raw Material</option>
                  {inventoryList.map((order) => {
                    const isDisabled = addOnDataList.some(item => item.rawMaterial && Number(item.rawMaterial) === order.id);
                    return (
                      <option key={order.id} value={order.id} disabled={isDisabled}>
                        {capitalizeAllFirstLetters(order.item_name)}
                      </option>
                    );
                  })}

                </select>
                <span className="text-danger d-flex text-left">
                  {formik.errors.rawMaterial &&
                    formik.touched.rawMaterial &&
                    formik.errors.rawMaterial}
                </span>
              </div>
              <Form.Group className="mb-3" controlId="usageUOM">
                <Form.Label>Unit of Measure</Form.Label>
                <Form.Control disabled type="text" name="usageUOM" placeholder="Enter Unit of Measure" value={formik?.values?.usageUOM} onChange={formik?.handleChange} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="quantity">
                <Form.Label>Quantity</Form.Label>
                <Form.Control type="number" name="quantity" placeholder="Enter Quantity" onBlur={formik?.handleBlur} value={formik?.values?.quantity} onChange={(event) => { formik?.handleChange(event); }} />
                <span className="text-danger d-flex text-left">{formik.errors.quantity && formik.touched.quantity && formik.errors.quantity} </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="price">
                <Form.Label>Price</Form.Label>
                <Form.Control disabled type="number" name="price" placeholder="Enter Price" value={formik?.values?.price * (formik?.values?.quantity || 1)} onChange={formik?.handleChange} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="calories">
                <Form.Label>Calories</Form.Label>
                <Form.Control disabled type="number" name="calories" placeholder="Enter Calories" value={formik?.values?.calories} onChange={formik?.handleChange} />
              </Form.Group>
              <div className='col-lg-6 text-center form-group mb-3 w-100' controlId="orderMode">
                <label className='text-left d-flex'>Order Mode</label>
                <CustomMultiSelect data={orderList.map((item) => {
                  return {
                    label: capitalizeFirstLetter(SpecialCharacter(item.name)),
                    value: item.id
                  }
                })} form={formik} name='orderMode' overFlowVisibleOptions={1} menuClassName="popup-select" />
              </div>
              <Button variant="primary" onClick={resetForm} style={{ marginRight: '10px' }}> Cancel</Button>
              <Button variant="primary" type='submit' disabled={!formik.dirty || !formik.isValid}>
                {recipeEditableIndex === null ? "Add" : "Update"} Recipe
              </Button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddOnsAdd
