import React, { useEffect, useMemo, useState } from 'react'
import PageHeaders from '../components/common/pageHeader'
import chart1 from '../assets/company/img/chart1.jpg'
import chart2 from '../assets/company/img/chart2.jpg'
import chart3 from '../assets/company/img/chart3.jpg'
import chart4 from '../assets/company/img/chart4.jpg'

const Dashboard = () => {
	return (
		<>
			<div className='card'>
				<div className='card-header d-flex justify-content-between'>
					<div className="card-header-left">
						<ul class="nav nav-pills" id="pills-tab" role="tablist">
							<li class="nav-item" role="presentation">
								<button class="nav-link active" id="pills-Daily-tab" data-bs-toggle="pill" data-bs-target="#pills-Daily" type="button" role="tab" aria-controls="pills-Daily" aria-selected="true">Logged Users</button>
							</li>
							<li class="nav-item" role="presentation">
								<button class="nav-link" id="pills-Weekly-tab" data-bs-toggle="pill" data-bs-target="#pills-Weekly" type="button" role="tab" aria-controls="pills-Weekly" aria-selected="false">Weekly</button>
							</li>
							<li class="nav-item" role="presentation">
								<button class="nav-link" id="pills-Monthly-tab" data-bs-toggle="pill" data-bs-target="#pills-Monthly" type="button" role="tab" aria-controls="pills-Monthly" aria-selected="false">Monthly</button>
							</li>
							<li class="nav-item" role="presentation">
								<button class="nav-link" id="pills-Date-tab" data-bs-toggle="pill" data-bs-target="#pills-Date" type="button" role="tab" aria-controls="pills-Date" aria-selected="false">Date <i className="ti ti-calendar-event"></i></button>
							</li>
						</ul>
					</div>
					<div className="card-header-right">
						<div className='d-flex gap-2'>
							<div className='form-group header-drop'>
								<select className='form-control'>
									<option>Brand</option>
									<option>Brand</option>
									<option>Brand</option>
									<option>Brand</option>
									<option>Brand</option>
								</select>
							</div>
							<div className='form-group header-drop'>
								<select className='form-control'>
									<option>Order Type</option>
									<option>Order Type</option>
									<option>Order Type</option>
									<option>Order Type</option>
									<option>Order Type</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className='card-body'>
				<div class="tab-content" id="pills-tabContent">
					<div class="tab-pane fade show active" id="pills-Daily" role="tabpanel" aria-labelledby="pills-Daily-tab" tabindex="0">
						<div className='row'>
							<div className='col-md-3'>
								<img src={chart1} style={{ width: "100%" }} />
							</div>
							<div className='col-md-3'>
								<img src={chart2}  style={{ width: "100%" }} />
							</div>
							<div className='col-md-3'>
								<img src={chart3}  style={{ width: "100%" }} />
							</div>
							<div className='col-md-3'>
								<img src={chart4}  style={{ width: "100%" }} />
							</div>
						</div>
					</div>
					<div class="tab-pane fade" id="pills-Weekly" role="tabpanel" aria-labelledby="pills-Weekly-tab" tabindex="0">...</div>
					<div class="tab-pane fade" id="pills-Monthly" role="tabpanel" aria-labelledby="pills-Monthly-tab" tabindex="0">...</div>
					<div class="tab-pane fade" id="pills-Date" role="tabpanel" aria-labelledby="pills-Date-tab" tabindex="0">...</div>
				</div>

				</div>
			</div>
			<div className='card mt-4'>
				<div className='card-header d-flex align-items-center justify-content-between'>
					<div className="card-header-left">
						<h4>Recent Activities</h4>
					</div>
					<div className="card-header-right">
						<ul class="nav nav-pills" id="pills-tab2" role="tablist">
							<li class="nav-item" role="presentation">
								<button class="nav-link active" id="pills-Logged-Users-tab" data-bs-toggle="pill" data-bs-target="#pills-Logged-Users" type="button" role="tab" aria-controls="pills-Logged-Users" aria-selected="true">Daily</button>
							</li>
							<li class="nav-item" role="presentation">
								<button class="nav-link" id="pills-Today-orders-tab" data-bs-toggle="pill" data-bs-target="#pills-Today-orders" type="button" role="tab" aria-controls="pills-Today-orders" aria-selected="false">Today's orders</button>
							</li>
							<li class="nav-item" role="presentation">
								<button class="nav-link" id="pills-Today-payment-tab" data-bs-toggle="pill" data-bs-target="#pills-Today-payment" type="button" role="tab" aria-controls="pills-Today-payment" aria-selected="false">Monthly</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

export default Dashboard
