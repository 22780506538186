import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Formik } from 'formik';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { GetChangeStatus, GetTaxList, DeleteTax, CreateTax, GetTaxDetail, GetEditTax, GetAllActiveTaxes } from '../../../services/company_panel/setting/taxConfigurationService';
import Swal from 'sweetalert2'
import CustomPagination from '../../../components/company_panel/common/custompagination'
import Sorting from '../../../components/company_panel/common/sorting';
import Loader from '../../../components/company_panel/common/loader'
import $ from "jquery";
import { capitalizeAllFirstLetters, getBrandId, getCompany, removeModalBackdrops } from '../../../helpers/commonFunctions';
import Select from 'react-select';
import { GetActiveOrderModes } from '../../../services/company_panel/setting/orderModeConfigurationService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationTab from '../configurationTab';
import { Dropdown } from 'semantic-ui-react';
import notFound from '../../../assets/company/img/no-record-found.png'
import { LOADER_TIME } from '../../../utils/Constants';


const TaxList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const params = useParams()
    const addFormRef = useRef();
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const [taxType, setTaxType] = useState('Taxes')
    const [name, setName] = useState('Taxes')
    const [showDefault, setShowDefault] = useState({})
    const [actionType, setActionType] = useState('add');
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    // const [orderMode, setOrderMode] = useState([]);
    const [primaryTaxList, setPrimaryTaxList] = useState([]);


    // const handleChangeOrderMode = (selected) => {
    //     setOrderMode(selected);
    // };


    // useEffect(() => {
    //     GetActiveOrderModes().then(data => {
    //         setOrderModeList(data && data.data && data.data.response ? data.data.response : [])
    //     }).catch(error => {
    //         console.log("error ====> ", error);
    //     })
    // }, [])

    useEffect(() => {
        return () => {
            removeModalBackdrops();
        }
    }, []);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('tax_type', taxType);
            formData.append('brand_id', getBrandId());
            formData.append('type', taxType === 'Taxes' ? 'taxes' : 'tax-groups');
            GetTaxList(formData).then(data => {
                // console.log(data.data.response);
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])

                // if (taxType == 'Taxes') {
                //     const primaryTaxOptions = data.data.response.rows;
                //     let primaryTaxMapped = [];
                //     primaryTaxMapped.push({
                //         key: "",
                //         text: "Please select",
                //         value: "",
                //     })
                //     const options = primaryTaxOptions.map((option) => ({
                //         key: option.id,
                //         text: capitalizeAllFirstLetters(option.name),
                //         value: option.id,
                //     }))
                //     primaryTaxMapped = [...primaryTaxMapped, ...options];
                //     console.log('list', primaryTaxMapped);
                //     setPrimaryTaxList(primaryTaxMapped);
                // }
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
            }).catch(error => {
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
                console.log("error ====> ", error);
            })
        }

    }, [location, statsUpdate, sorting, search, globalSearch, taxType,isBrandChanged])

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append('brand_id', getBrandId());
            formData.append('type', 'taxes');
            GetAllActiveTaxes(formData).then(data => {
                console.log(data.data.response);
                // if (taxType == 'Taxes') {
                    const primaryTaxOptions = data.data.response;
                    let primaryTaxMapped = [];
                    primaryTaxMapped.push({
                        key: "",
                        text: "Please select",
                        value: "",
                    })
                    const options = primaryTaxOptions.map((option) => ({
                        key: option.id,
                        text: capitalizeAllFirstLetters(option.name),
                        value: option.id,
                    }))
                    primaryTaxMapped = [...primaryTaxMapped, ...options];
                    console.log('list', primaryTaxMapped);
                    setPrimaryTaxList(primaryTaxMapped);
                // }
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
            }).catch(error => {
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
                console.log("error ====> ", error);
            })
        }

    }, [location, statsUpdate, sorting, search, globalSearch, taxType, isBrandChanged]);
    
    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        setSearch({})
        prepareSearch()
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                DeleteTax(row).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            // title: "Tax group deleted successfully",
                            title: taxType === 'Taxes' ? "Tax deleted successfully" : 'Tax group deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }

    // Change Status functionality

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                GetChangeStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    // edit functionality

    const editFunction = (row) => {

        GetTaxDetail(row).then(data => {

            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            // setOrderMode(data && data.data && data.data.response ? data.data.response.order_modes : []);
        }).catch(error => {
            console.log("error=====>", error)
        })
        setActionType('edit');
        $('#addCardModal').show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
    }
    const copyOutletFunction = (row) => {
        GetTaxDetail(row).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            // setOrderMode(data && data.data && data.data.response ? data.data.response.order_modes : []);
        }).catch(error => {
            console.log("error=====>", error)
        })
        setActionType('add');
        $('#addCardModal').show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    // addMore

    const taxConfiguration = (value) => {
        setTaxType(value);
        let nameEn = name;

        if (value === 'Taxes') {
            nameEn = 'Taxes';
        } else {
            nameEn = 'Tax Groups'; // or any default value you want
        }
        setName(nameEn);
    }




    const closeModel = (id) => {
        $('#addCardModal').hide();
        $('.modal-backdrop').remove();
        setShowDefault({});
        setStatusUpdate(!statsUpdate)

    }

    const openModel = (id, type) => {
        setActionType(type);
        $('#' + id).show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
        // setOrderMode([]);
    }


    return (
        <>
            {/* <Breadcrums data={breadcrumbs} /> */}
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Settings</h4>
                    </div>
                    <div className='page-header-right ms-auto'>

                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationTab tab='tax' />
                            </div>
                        </div>
                    </div>
                    <div className='gap-20 ps-0 pb-0 pe-0'>
                        <ul className='d-flex setting submenu-list border-bottom mb-0 p-0'>
                            <li className={taxType === 'Taxes' ? 'active' : ''} onClick={() => { taxConfiguration('Taxes') }}>Taxes</li>
                            <li className={taxType === 'Tax Groups' ? 'active' : ''} onClick={() => { taxConfiguration('Tax Groups') }}>Tax Groups</li>
                        </ul>
                    </div>
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            <div className='page-header-left'></div>
                            <div className='page-header-right ms-auto'>
                                <button className="btn btn-primary" type="button" onClick={(e) => openModel('addCardModal', 'add')}>Add {name}</button>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">

                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>

                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>ID  </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="id" />
                                            </div>
                                        </th>
                                        <th width="20%">
                                            <div className='sorting_column'>
                                                <span>Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="name" />
                                            </div>
                                        </th>
                                        <th width="20%">
                                            <div className='sorting_column'>
                                                <span>Name (Arabic)</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="name_ar" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>{name === 'Taxes' ? 'Tax' : 'Primary Tax'} </span>
                                                {/* <span>Tax</span> */}
                                                <Sorting sort={sorting} handleSort={handleSort} column={name === 'Taxes' ? 'percentage' : 'primary_tax'} />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>{name === 'Taxes' ? 'Tax Type' : 'Secondary Tax'}   </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column={name === 'Taxes' ? 'tax_type' : 'secondary_tax'} />
                                            </div>
                                        </th>
                                        <th className='status_head text-center' width="10%">Status</th>
                                        <th className='action_head text-end' width="10%">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{row?.id}</td>
                                                    <td>{capitalizeAllFirstLetters(row?.name)}</td>
                                                    <td>{capitalizeAllFirstLetters(row?.name_ar)}</td>
                                                    <td>{name === 'Taxes' ? row?.percentage : capitalizeAllFirstLetters(row?.primaryTax?.name)}</td>
                                                    <td>{name === 'Taxes' ? capitalizeAllFirstLetters(row?.tax_type) : capitalizeAllFirstLetters(row?.secondaryTax?.name)}</td>
                                                    <td>
                                                        <div className="form-check form-switch w-25 mx-auto">
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" checked={row.status == 1 ? 'checked' : ''} onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }} />
                                                            <label className="form-check-label" for="status-change"></label>
                                                        </div>
                                                    </td>
                                                    <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><div className='dropdown-item' onClick={() => { editFunction(row.id) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                                <li> <div className='dropdown-item' onClick={() => { copyOutletFunction(row.id) }}><i class="ti ti-copy" aria-hidden="true"></i> Copy tax</div></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={11} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No record found</p>
                                                </td>
                                            </tr>
                                            }</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "outlet-management", path: "/company/setting/tax-configuration/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Modal*/}

                <Formik
                    innerRef={addFormRef}
                    enableReinitialize
                    initialValues={{
                        name: showDefault && showDefault.name ? showDefault.name : "",
                        name_ar: showDefault && showDefault.name_ar ? showDefault.name_ar : "",
                        tax_type: showDefault && showDefault.tax_type ? showDefault.tax_type : "",
                        percentage: showDefault && showDefault.percentage ? showDefault.percentage : "",
                        secondary_tax: showDefault && showDefault.secondary_tax ? Number(showDefault.secondary_tax) : "",
                        primary_tax: showDefault && showDefault.primary_tax ? Number(showDefault.primary_tax) : "",
                        status: showDefault && showDefault.status == 1 ? 'on' : "",
                    }}
                    validate={values => {
                        const error = {};
                        if (!values.name.trim()) {
                            error.name = `Name is required`;
                        } else if (values.name.trim().length < 3 || values.name.trim().length > 30) {
                            error.name = `Name must be between 3 and 30 characters`;
                        }

                        // Validate 'name_ar' field (Arabic name)
                        if (!values.name_ar.trim()) {
                            error.name_ar = `Name (Arabic) is required`;
                        } else if (values.name_ar.trim().length < 3 || values.name_ar.trim().length > 30) {
                            error.name_ar = `Name (Arabic) must be between 3 and 30 characters`;
                        }
                        // if (taxType == 'Taxes' && !values.percentage) error.percentage = `Percentage is required`;
                        // if (taxType == 'Taxes' && !Number.isInteger(Number(values.percentage))) error.percentage = `Percentage must be an integer`;
                        // if (taxType == 'Taxes' && values.percentage > 100) error.percentage = `Percentage must between 1 to 100 .`;
                        if (taxType === 'Taxes') {
                            if (!values.percentage) {
                                error.percentage = `Percentage is required`;
                            } else if (!Number.isInteger(Number(values.percentage))) {
                                error.percentage = `Percentage must be an integer`;
                            } else if (/\s/.test(values.percentage)) {
                                error.percentage = `Percentage must not contain spaces`;
                            } else if (values.percentage > 100 || values.percentage < 1) {
                                error.percentage = `Percentage must be between 1 to 100.`;
                            }
                        }


                        if (taxType == 'Taxes' && !values.tax_type) error.tax_type = "Tax type is required";

                        if (taxType == 'Tax Groups' && !values.primary_tax) error.primary_tax = "Primary tax is required";
                        if (taxType == 'Tax Groups' && !Number.isInteger(Number(values.primary_tax))) error.primary_tax = "Primary tax must be an integer";
                        // if (taxType=='Tax Groups' && values.primary_tax > 100) error.primary_tax = "Primary tax must between 1 to 100.";

                        // if (taxType=='Tax Groups' && !values.secondary_tax) error.secondary_tax = "Secondary tax is required";
                        // if (taxType=='Tax Groups' && !Number.isInteger(Number(values.secondary_tax))) error.secondary_tax = "Secondary tax must be an integer";
                        // if (taxType=='Tax Groups' && values.secondary_tax > 100) error.secondary_tax = "Secondary tax must between 1 to 100.";


                        return error;
                    }}

                    onSubmit={(values, { setSubmitting, resetForm }) => {

                        setSubmitted(true)
                        let formData = new FormData();
                        formData.append('name', values.name.trim());
                        formData.append('name_ar', values.name_ar.trim());
                        formData.append('percentage', values.percentage);
                        formData.append('tax_type', values.tax_type);
                        formData.append('secondary_tax', values.secondary_tax);
                        formData.append('primary_tax', values.primary_tax);

                        formData.append('status', values.status == 'on' ? 1 : 0);
                        formData.append('company_id', getCompany().company_id);
                        formData.append('brand_id', getBrandId());
                        formData.append('type', taxType === 'Taxes' ? 'taxes' : 'tax-groups');
                        formData.append('id', showDefault && showDefault.id ? showDefault.id : "");



                        if (actionType === 'edit') {
                            GetEditTax(formData).then(response => {

                                setSubmitting(false);
                                if (response.data.status === 1 || response.data.status === "1") {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'success',
                                        title: taxType === 'Taxes' ? "Tax updated successfully" : 'Tax group updated successfully',
                                        showConfirmButton: false,
                                        timer: 1500,
                                        toast: true,
                                        position: 'top-right',
                                    });
                                    setTimeout(() => {
                                        $('#addCardModal').hide();
                                        $('.modal-backdrop').remove();
                                        setStatusUpdate(!statsUpdate)
                                        resetForm();
                                        setShowDefault({});
                                    }, 1000);
                                } else {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'error',
                                        title: response.data.message,
                                        showConfirmButton: false,
                                        timer: 5000,
                                        toast: true,
                                        position: 'top-right',
                                    })
                                    setSubmitted(false)
                                }
                            }).catch(error => {
                                console.log("error ====> ", error);
                            })

                        } else {
                            CreateTax(formData).then(response => {

                                setSubmitting(false);
                                if (response.data.status === 1 || response.data.status === "1") {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'success',
                                        title: taxType === 'Taxes' ? "Tax added successfully" : 'Tax group added successfully',
                                        showConfirmButton: false,
                                        timer: 1500,
                                        toast: true,
                                        position: 'top-right',
                                    });
                                    setTimeout(() => {
                                        $('#addCardModal').hide();
                                        $('.modal-backdrop').remove();
                                        setStatusUpdate(!statsUpdate)
                                        resetForm();
                                        setShowDefault({});
                                    }, 1000);
                                } else {
                                    Swal.fire({
                                        customClass: 'swal-wide',
                                        icon: 'error',
                                        title: response.data.message,
                                        showConfirmButton: false,
                                        timer: 5000,
                                        toast: true,
                                        position: 'top-right',
                                    })
                                    setSubmitted(false)
                                }
                            }).catch(error => {
                                console.log("error ====> ", error);
                            })

                        }

                    }}

                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                }) => (

                    <div className="modal fade" id="addCardModal" aria-labelledby="addCardModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2 className="modal-title" id="addCardModalLabel">{actionType === 'edit' ? 'Edit' : 'Add'} {name}</h2>
                                    <button type="button" className="btn-close" onClick={(e) => { closeModel('addCardModal'); addFormRef?.current?.resetForm(); }} data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-body">
                                        <div className="mb-3">
                                            <label for="name" className="col-form-label">Name <span className="requirestar">*</span></label>
                                            <input type="text" className="form-control" id="name" name="name" placeholder='Enter Name' onChange={handleChange} onBlur={handleBlur} value={values.name} autoComplete='off' />
                                            <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                                        </div>
                                        <div className="mb-3">
                                            <label for="name_ar" className="col-form-label">Name (Arabic)<span className="requirestar">*</span></label>
                                            <input type="text" className="form-control" name="name_ar" id="name_ar" placeholder='Enter Name (Arabic)' onChange={handleChange} onBlur={handleBlur} value={values.name_ar} autoComplete='off' />
                                            <span className='text-danger d-flex text-left'>{errors.name_ar && touched.name_ar && errors.name_ar}</span>
                                        </div>
                                        {name === 'Taxes' ?
                                            <div>
                                                <div className="mb-3">
                                                    <label for="percentage" className="col-form-label">Percentage<span className="requirestar">*</span></label>
                                                    <input type="text" name="percentage" className="form-control" id="percentage" placeholder='Enter percentage' onChange={handleChange} onBlur={handleBlur} value={values.percentage} autoComplete='off' />
                                                    <span className='text-danger d-flex text-left'>{errors.percentage && touched.percentage && errors.percentage}</span>
                                                </div>
                                                <div className="mb-3">
                                                    <label for="order_mode" className="col-form-label">Tax Type<span className="requirestar">*</span></label>
                                                    <div className='row ps-3'>
                                                        <div className="form-check w-25">
                                                            <input className="form-check-input" type="radio" name="tax_type" id="inclusive" value="inclusive" autoComplete='off' onChange={handleChange} onBlur={handleBlur} checked={values.tax_type == 'inclusive' ? 'checked' : ''} />
                                                            <label className="form-check-label" for="inclusive">Inclusive</label>
                                                        </div>
                                                        <div className="form-check w-25">
                                                            <input className="form-check-input" type="radio" name="tax_type" id="exclusive" value="exclusive" autoComplete='off' onChange={handleChange} onBlur={handleBlur} checked={values.tax_type == 'exclusive' ? 'checked' : ''} />
                                                            <label className="form-check-label" for="exclusive">Exclusive</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-danger d-flex text-left'>{errors.tax_type && touched.tax_type && errors.tax_type}</span>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className="mb-3">
                                                    <label for="primary_tax" className="col-form-label">Primary Tax (%)<span className="requirestar">*</span></label>
                                                    {/* <input type="text" name="primary_tax" className="form-control" id="primary_tax" placeholder='Enter Primary Tax' onChange={handleChange} onBlur={handleBlur} value={values.primary_tax} autoComplete='off'/> */}
                                                    <Dropdown
                                                        className='form-control'
                                                        name="primary_tax"
                                                        id="primary_tax"
                                                        placeholder='Select primary tax'
                                                        search
                                                        fluid
                                                        selection
                                                        options={primaryTaxList}
                                                        value={values.primary_tax}
                                                        onChange={(event, data) => { setFieldValue("primary_tax", data.value) }}
                                                    />
                                                    <span className='text-danger d-flex text-left'>{errors.primary_tax && touched.primary_tax && errors.primary_tax}</span>
                                                </div>
                                                <div className="mb-3">
                                                    <label for="secondary_tax" className="col-form-label">Secondary Tax (%)</label>
                                                    {/* <input type="number" name="secondary_tax" className="form-control" id="secondary_tax" placeholder='Enter Secondary Tax' onChange={handleChange} onBlur={handleBlur} value={values.secondary_tax}  autoComplete='off'/> */}
                                                    <Dropdown
                                                        className='form-control'
                                                        name="secondary_tax"
                                                        id="secondary_tax"
                                                        placeholder='Select secondary tax'
                                                        search
                                                        fluid
                                                        selection
                                                        options={primaryTaxList}
                                                        value={values.secondary_tax}
                                                        onChange={(event, data) => { setFieldValue("secondary_tax", data.value) }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className="form-check form-switch w-25">
                                            <input className="form-check-input" type="checkbox" id="status" name="status" onChange={handleChange} onBlur={handleBlur} checked={values.status == 'on' ? 'checked' : ''} autoComplete='off' />
                                            <label className="form-check-label" for="status">Status</label>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-primary" style={{ width: '48%' }} data-bs-dismiss="modal" onClick={(e) => { closeModel('addCardModal'); addFormRef?.current?.resetForm() }}>Cancel</button>
                                        <button type="submit" className="btn btn-primary" style={{ width: '48%' }}>{actionType === 'edit' ? 'Update' : 'Add'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
                </Formik>

                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />

            </div>
        </>
    );
}

export default TaxList