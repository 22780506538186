import axiosInstance from "../../utils/axiosCompany";

export const CreateOutlet = async (values) => {
    return await axiosInstance().post("company/save-outlet-management", values);
}

export const GetOutletList = async (values) => {
    return await axiosInstance().post("company/list-outlet-management", values);
}

export const GetEditOutlet  = async (values) => {
    return await axiosInstance().post("company/edit-outlet-management", values);
}

export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("company/change-status-outlet-management", values)
}

export const GetDeleteOutlet = async (values) => {
    return await axiosInstance().post("company/delete-outlet-management", values)
}

export const GetOutletDetail = async (id) => {
    return await axiosInstance().get(`company/get-edit-outlet-management?id=${id}`);
};

export const GetBrandList = async (company_id) => {
    return await axiosInstance().get(`company/brand-list?company_id=${company_id}`);
};
export const GetBrandWithOutlets = async (company_id) => {
    return await axiosInstance().get(`company/get-brands-with-outlets?company_id=${company_id}`);
};

export const DeleteHardwareDevice = async (values) => {
    return await axiosInstance().post("company/delete-hardware-device", values)
}

export const GetOutletAllList = async (company_id) => {
    return await axiosInstance().get(`company/get-active-outlets-list?company_id=${company_id}`);
};

export const GetBrandOutletsList = async (values) => {
    return await axiosInstance().post(`company/get-brand-outlets-list`, values);
};