import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Formik, Field, ErrorMessage, useFormik } from 'formik';
import { CreateOutlet, GetBrandList } from '../../../services/company_panel/platform_user/platformUserService';
import { GetAllCountries, GetAllStates, GetAllCities } from '../../../services/company_panel/countryService';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Swal from "sweetalert2"
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import $ from "jquery";
import TimePicker from "@ashwinthomas/react-time-picker-dropdown";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, getBrandId, getCompany, getOutletId, SpecialCharacter } from '../../../helpers/commonFunctions';
import GoogleMapModal from '../../outlet/GoogleMapModal';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Table, Modal, } from 'react-bootstrap';
import { GetAllInventories, GetInventoryList, GetSingleInventory } from '../../../services/company_panel/inventoryService';
import { GetAllActiveTaxes, GetTaxList } from '../../../services/company_panel/setting/taxConfigurationService';
import { GetAllParentCategories, GetCategoryList, GetAllChildCategories } from '../../../services/company_panel/menuCategoryService';
import { CreateMenuItems, editMenuItems, GetSingleMenuItems } from '../../../services/company_panel/menuItems';
import { GetActiveOrderModes, GetOrderModeAllDetails } from '../../../services/company_panel/setting/orderModeConfigurationService';
import { GetAllAddOnCategory } from '../../../services/company_panel/menuAddOnCategory';
import OutletPriceModal from './OutletPriceModal';
import { GetAggregatorAllDetails } from '../../../services/company_panel/setting/aggregatorConfigurationService';
import { GetBrandOutletsList } from '../../../services/company_panel/outletService';
import defaultImage from '../../../assets/company/img/default_img.png';
import { TagPicker } from 'rsuite';
import CustomDatetimepicker from '../../../helpers/customDatepicker';
import { WEEKDAYS } from '../../../utils/Constants';
import CustomMultiSelect from '../../../components/common/CustomMultiSelect';
import * as Yup from "yup";


const MenuItemsEdit = () => {
    const formRef = useRef();
    const [btnDisable, setBtnDisable] = useState(false);
    const [btnClicked, setBtnClicked] = useState(false);
    const [page, setPage] = useState(1);
    const [showDefault, setShowDefault] = useState({})
    const params = useParams()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const [allOrderMode, setAllOrderMode] = useState([])
    const [allAggregator, setAllAggregator] = useState([])
    const [previewImage, setPreviewImage] = useState("");
    const [inventoryList, setInventoryList] = useState([]);
    const [rawList, setRawList] = useState({});
    const [taxList, setTaxList] = useState([]);
    const [list, setList] = useState([])
    const [secondaryCategoryList, setSecondaryCategoryList] = useState([]);
    const [menuItems, setMenuItems] = useState([])
    const [show, setShow] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [deliveryDate, setDeliveryDate] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [checkedCategory, setCheckedCategory] = useState([])
    const [outlets, setOutlets] = useState([])
    const [orderMode, setOrderMode] = useState([])
    const [recipeEditableIndex, setRecipeEditableIndex] = useState(null);
    const [refreshPricing, setRefreshPricing] = useState(false);
    const [addOnDataList, setAddOnDataList] = useState([]);/**{
      rawMaterial: '',
      usageUOM: '',
      quantity: '',
      price: '',
      calories: '',
      orderMode: '',
    } */

    const [formData, setFormData] = useState({
        rawMaterial: '',
        usageUOM: '',
        quantity: '',
        price: '',
        calories: '',

    });
    const [showEdit, setShowEdit] = useState(false);

    const formik = useFormik({
        validationSchema: Yup.object().shape({
            rawMaterial: Yup.string().trim().required("Raw material is required"),
            // quantity: Yup.string().required("Quantity is required"),
            quantity: Yup.number().integer("Quantity must be a integer").min(1, "Quantity must be greater than 0").required("Quantity is required"),
        }),
        initialValues: {
            rawMaterial: '',
            usageUOM: '',
            quantity: '',
            price: '',
            calories: '',
            orderMode: [],
            raw_material_price: 0
        },
        onSubmit: (values, { resetForm }) => {
            // saveData(e);
            if (recipeEditableIndex === null) {
                setAddOnDataList(prevState => [
                    ...prevState,
                    values
                ]);
            } else {
                let existingData = [...addOnDataList];
                existingData[recipeEditableIndex] = values;
                setAddOnDataList(existingData);
                setRecipeEditableIndex(null);
            }
            handleClose();
        },
    });

    const handleClose = () => {
        setShow(false);
        formik?.resetForm();
        setRecipeEditableIndex(null);
    };
    const [selectedRecipeId, setSelectedRecipeId] = useState(null)
    const [selectedRecipeType, setSelectedRecipeType] = useState(null)
    const [itemToEdit, setItemToEdit] = useState(null);
    const [menuRecipes, setMenuRecipes] = useState([])
    const [loadingSecondary, setLoadingSecondary] = useState(false);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentBrand] = useState(getBrandId());

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        if (currentBrand != getBrandId()) {
            navigate(`/company/menu/menu/menu_items/list/${1}`);
        }
    });

    useEffect(() => {
        const formData = new FormData()
        formData.append('brand_id', getBrandId());
        formData.append('type', 'tax-groups');
        GetAllActiveTaxes(formData).then(data => {
            // console.log(data.data.response);
            setTaxList(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [isBrandChanged]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [aggregatorResponse, orderModeResponse, defaultOutletPrice, allCategories] = await Promise.all([
                    GetAggregatorAllDetails({ brand_id: getBrandId() }),
                    GetOrderModeAllDetails({ brand_id: getBrandId() }),
                    GetSingleMenuItems({ id: params?.id }),
                    GetAllAddOnCategory({ brand_id: getBrandId() })
                ]);
                const myData = defaultOutletPrice?.data?.response;
                if (defaultOutletPrice) {
                    setPreviewImage(myData?.logo);
                    setDeliveryDate(myData?.updates);
                    setShowDefault(myData ? myData : {});
                    console.log("myData",myData);
                    setMenuRecipes(myData?.menu_item_reciepes && myData?.menu_item_reciepes.length > 0 ? myData?.menu_item_reciepes : [])
                    formRef?.current?.setFieldValue('logo', myData?.logo);
                    formRef?.current?.setFieldValue('short_description', myData?.short_description);
                    formRef?.current?.setFieldValue('short_description_ar', myData?.short_description_ar);
                    formRef?.current?.setFieldValue('sku', myData?.sku);
                    formRef?.current?.setFieldValue('calories_type', myData?.calories_type);
                    formRef?.current?.setFieldValue('calories', myData?.calories);

                    formRef?.current?.setFieldValue('availability', myData?.availability);
                    formRef?.current?.setFieldValue('start_date', new Date(myData?.start_date));
                    formRef?.current?.setFieldValue('end_date', new Date(myData?.end_date));
                    formRef?.current?.setFieldValue('start_time', myData?.start_time);
                    formRef?.current?.setFieldValue('end_time', myData?.end_time);
                    formRef?.current?.setFieldValue('week', (myData?.week));

                    formRef?.current?.setFieldValue('default_price', myData?.default_price);
                    formRef?.current?.setFieldValue('name', myData?.item_name);
                    formRef?.current?.setFieldValue('name_ar', myData?.item_name_ar);
                    formRef?.current?.setFieldValue('tax_id', myData?.tax_group_id);
                    formRef?.current?.setFieldValue('status', myData?.status);
                    formRef?.current?.setFieldValue('primary_category', myData?.menu_category?.menu_category?.id);
                    formRef?.current?.setFieldValue('secondary_category', myData?.menu_category?.id);
                    formRef?.current?.setFieldValue('updateType', myData?.updates_type);
                    formRef?.current?.setFieldValue('calorie_option', myData?.calories_type);
                    formRef?.current?.setFieldValue('calorie', myData?.calories);
                    formRef?.current?.setFieldValue('pricingType', myData?.pricing_type);
                    formRef?.current?.setFieldValue('status_change', myData?.status);
                    setDeliveryDate(myData?.updates)
                    await getSubcategories(myData?.menu_category?.menu_category?.id);
                }
                if (allCategories) {
                    setCategoryList(allCategories && allCategories.data && allCategories.data.response ? allCategories.data.response : []);
                    const mainData = defaultOutletPrice?.data?.response?.menu_item_add_ons;
                    const listData = allCategories?.data?.response?.map((item) => {
                        const selectedData = mainData.filter(item2 => {
                            return item2.menu_add_on_category_id === item.id;
                        })[0];
                        if (selectedData) {
                            return { ...item, isChecked: true, min: selectedData.min, max: selectedData.max, free: selectedData.free };
                        } else {
                            return { ...item, isChecked: false, min: '', max: '', free: '' };
                        }
                    });
                    formRef.current.setFieldValue('categories', listData);
                }
                const getOutletPriceData = (record, type = "orderMode") => {
                    const result = myData?.menu_item_outlet_default_prices?.filter((item) => {
                        return item?.price_slug === record?.slug && item?.price_type === type;
                    })[0];
                    return result;
                };
                const aggregatorData = aggregatorResponse?.data?.response || [];
                const orderModeData = orderModeResponse?.data?.response || [];
                const getOutletPriceName = (slug, type = "orderMode") => {
                    let result = [];
                    if (type === "aggregator") {
                        result = aggregatorData?.filter((item) => {
                            return item?.slug === slug;
                        })[0];
                    } else {
                        result = orderModeData?.filter((item) => {
                            return item?.slug === slug;
                        })[0];
                    }
                    return result?.name || "";
                };
                setAllAggregator(aggregatorData);
                setAllOrderMode(orderModeData);
                if (formRef.current) {
                    formRef.current.setFieldValue('outlet_prices', orderModeData.map((record) => {
                        const result = getOutletPriceData(record);
                        return {
                            price_slug: result?.price_slug || record.slug,
                            price_type: result?.price_type,
                            price: result?.price,
                        }
                    }));
                    formRef.current.setFieldValue('aggregator_prices', aggregatorData.map((record) => {
                        const result = getOutletPriceData(record, "aggregator");
                        return {
                            price_slug: result?.price_slug || record.slug,
                            price_type: result?.price_type,
                            price: result?.price,
                        };
                    }));                    
                    
                    const outlets = [];
                    myData?.menu_item_outlet_specific_prices?.forEach((item) => {
                        if (outlets.indexOf(item.outlet_id) === -1) {
                            outlets.push(item.outlet_id);
                        }
                    });
                    const outletPreselectedData = [];
                    outlets.forEach((outlet) => {
                        const orderModeSelectedData = myData?.menu_item_outlet_specific_prices?.filter((item) => {
                            return Number(item.outlet_id) === Number(outlet) && item?.price_type === "orderMode";
                        });
                        const aggregatorSelectedData = myData?.menu_item_outlet_specific_prices?.filter((item) => {
                            return Number(item.outlet_id) === Number(outlet) && item?.price_type === "aggregator";
                        });
                        const getFilledPrice = (slug, type = "orderMode") => {
                            let result = {};
                            if (type === "aggregator") {
                                result = aggregatorSelectedData?.filter((item) => {
                                    return item?.price_slug === slug;
                                })[0];
                            } else {
                                result = orderModeSelectedData?.filter((item) => {
                                    return item?.price_slug === slug;
                                })[0];
                            }
                            return result || {};
                        };
                        const record = {
                            outlet: outlet,
                            orderMode: orderModeData.map((record) => ({
                                name: getOutletPriceName(record.slug),
                                price_slug: record?.slug,
                                price_type: 'orderMode',
                                price: getFilledPrice(record?.slug)?.price,
                            })),
                            aggregator: aggregatorData.map((record) => ({
                                name: getOutletPriceName(record.slug, "aggregator"),
                                price_slug: record?.slug,
                                price_type: 'aggregator',
                                price: getFilledPrice(record?.slug, "aggregator")?.price,
                            }))
                        };
                        outletPreselectedData.push(record);
                    });
                    if (outletPreselectedData.length === 0) {
                        outletPreselectedData.push(
                            {
                                outlet: "",
                                orderMode: orderModeData.map((record) => ({
                                    name: record.name,
                                    price_slug: record?.slug || '',
                                    price_type: 'orderMode',
                                    price: '',
                                })),
                                aggregator: aggregatorData.map((record) => ({
                                    name: record.name,
                                    price_slug: record?.slug || '',
                                    price_type: 'aggregator',
                                    price: '',
                                }))
                            }
                        )
                    }
                    formRef.current.setFieldValue('outlet_prices_specific', outletPreselectedData);
                }
                const uomsData = myData.uoms;
                let addedRecipe = myData?.menu_item_reciepes.map((record) => {
                    const unitsData = (record?.inventory?.inventory_unit_converisons || []).filter(item => item?.unit_ty === "Usage Unit")[0];
                    const UOM = uomsData.filter(item => item.id === unitsData.uom)[0];
                    console.log("ramveer **", unitsData, record);
                    return {
                        rawMaterial: record?.inventory_id,
                        usageUOM: UOM?.uom_code,
                        quantity: record?.quantity,
                        price: unitsData?.price * record?.quantity,
                        raw_material_price: unitsData?.price,
                        calories: unitsData?.calorie,
                        orderMode: Array.isArray(record?.menu_item_reciepe_order_modes) ? record?.menu_item_reciepe_order_modes?.map((orderModeItem) => orderModeItem?.order_mode_id) : [],
                    }
                });
                setAddOnDataList(addedRecipe);
                setBtnDisable(false);
            } catch (error) {
                console.log("error ====> ", error);
            }
        };

        fetchData();
    }, [isBrandChanged]);

    useEffect(() => {
        GetBrandOutletsList({ brand_id: getBrandId() }).then(data => {
            setOutlets(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })

    }, [isBrandChanged])

    const handleChangeModel = (e) => {
        const { value } = e.target;
        if (Number(value)) {
            GetSingleInventory({ id: Number(value), outlet_id: getOutletId() })
                .then(data => {
                    let inventory = data?.data?.response || {};
                    const inventoryData = inventory?.inventory_unit_converisons.filter((item) => {
                        return item.unit_type == 'Usage Unit';
                    });
                    let myData = { ...formik?.values };
                    myData.rawMaterial = value;
                    myData.usageUOM = inventoryData[0]?.uomDetail?.uom_code;
                    myData.quantity = 1;
                    myData.price = inventoryData[0]?.price;
                    myData.calories = inventoryData[0]?.calories;
                    myData.raw_material_price = inventoryData[0]?.price;
                    formik.setValues(myData);
                })
                .catch(error => {
                    console.log("error ====> ", error);
                });
        }
        else {
            formik.setFieldValue("rawMaterial", "");
        }

    };
    const orderChange = (val) => {
        /** Don't remove this commented code */
        /* let data = {...formData};
        data.orderMode = val;
        setFormData(data); */
        setFormData(record => {
            return {
                ...record, orderMode: val
            }
        })
    }


    const resetForm = () => {
        // setFormData({
        //     usageUOM: '',
        //     quantity: '',
        //     price: '',
        //     calories: '',
        // });
        setRawList({ id: 0, name: '' });
        setOrderMode('');
        setShow(false);
    }


    useEffect(() => {
        const formData = new FormData()
        formData.append('brand_id', getBrandId());
        GetAllInventories(formData).then(data => {
            setInventoryList(data && data.data && data.data.response ? data.data.response.rows : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [isBrandChanged])


    useEffect(() => {
        GetActiveOrderModes(getBrandId()).then(data => {
            setOrderList(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [isBrandChanged])

    const breadcrumbs = [
        {
            title: "Menu",
            url: "/company/menu/menu/menu_items/list/1"
        },
        {
            title: "Edit Menu Items",
            url: ""
        },
    ]

    useEffect(()=>{
        console.log("showDefaultshowDefault",showDefault, formRef?.current?.values?.pricingType);
        if(formRef.current && showDefault && showDefault?.pricing_type === "ask_for_price" && formRef?.current?.values?.pricingType === "fixed"){
            console.log("****selected", formRef.current?.values?.default_price);
            if(page === 2 && formRef.current){
              let outletPricesValues = formRef.current?.values?.outlet_prices?.map(item => {
                return {...item, price:formRef.current?.values?.default_price};
              });
              formRef.current.setFieldValue('outlet_prices', outletPricesValues);
        
              let aggregatorPricesValues = formRef.current?.values?.aggregator_prices?.map(item => {
                return {...item, price:formRef.current?.values?.default_price};
              });
              formRef.current.setFieldValue('aggregator_prices', aggregatorPricesValues);
            }else if(page === 3 && formRef.current){
              let outletPricesSpecificPricesValues = formRef.current?.values?.outlet_prices_specific?.map(item => {
                return {
                  ...item, 
                  orderMode:item?.orderMode?.map((record)=>{
                    return {
                      ...record,
                      price:formRef.current?.values?.default_price
                    }
                  }),
                  aggregator:item?.aggregator?.map((record)=>{
                    return {
                      ...record,
                      price:formRef.current?.values?.default_price
                    }
                  })
                };
              });
              formRef.current.setFieldValue('outlet_prices_specific', outletPricesSpecificPricesValues);
            }
        }
      },[page, refreshPricing, showDefault]);

    const nextStep = (selected) => {
        const nextPage = page + 1;
        // if (nextPage === 2) {
        //     setBtnDisable(true);
        // }
        setPage(nextPage);
        var currentStepNum = $('#checkout-progress').data('current-step');
        var nextStepNum = (currentStepNum + 1);
        var currentStep = $('.step.step-' + currentStepNum);
        var nextStep = $('.step.step-' + nextStepNum);
        var progressBar = $('#checkout-progress');
        $('.btn-prev').show();
        $('#section' + currentStepNum).toggle();
        $('#section' + nextStepNum).toggle();
        if (nextStepNum == 6) {
            $(this).toggle();
            $('.btn-submit').toggle();
            $('.btn-next').hide();
        }

        $('.checkout-progress').removeClass('.step-' + currentStepNum).addClass('.step-' + (currentStepNum + 1));

        currentStep.removeClass('active').addClass('valid');
        currentStep.find('span').addClass('opaque');
        currentStep.find('.fa.fa-check').removeClass('opaque');

        nextStep.addClass('active');
        progressBar.removeAttr('class').addClass('step-' + nextStepNum).data('current-step', nextStepNum);
    }

    const prevStep = (selected) => {
        setPage(page - 1);
        var currentStepNum = $('#checkout-progress').data('current-step');
        var prevStepNum = (currentStepNum - 1);
        var currentStep = $('.step.step-' + currentStepNum);
        var prevStep = $('.step.step-' + prevStepNum);
        var progressBar = $('#checkout-progress');
        $('.btn-next').removeClass('disabled');
        $('#section' + currentStepNum).toggle();
        $('#section' + prevStepNum).toggle();
        if (currentStepNum == 6) {
            $('.btn-submit').toggle();
            $('.btn-next').toggle();
        }

        if (currentStepNum == 2) {
            $('.btn-prev').hide();
        }
        if (currentStepNum == 1) {
            $('.btn-prev').hide();
            return false;
        }
        if (prevStepNum == 1) {
            $(this).addClass('disabled');
        }
        $('.checkout-progress').removeClass('.step-' + currentStepNum).addClass('.step-' + (prevStepNum));

        currentStep.removeClass('active');
        prevStep.find('span').removeClass('opaque');
        prevStep.find('.fa.fa-check').addClass('opaque');

        prevStep.addClass('active').removeClass('valid');
        progressBar.removeAttr('class').addClass('step-' + prevStepNum).data('current-step', prevStepNum);
    };
    useEffect(() => {
        if(page === 2){
          if(formRef?.current?.values){
            console.log("kkkkkk",);
            if(formRef?.current?.values?.pricingType === "ask_for_price"){
                setBtnDisable(false);
            }else{
                setTimeout(() => {
                    console.log("formRef?.current?.values",formRef?.current?.values, isAllPriceFilled(formRef?.current?.values))
                    // if(isAllPriceFilled(formRef?.current?.values)){
                        
                    // }
                    setBtnDisable(!isAllPriceFilled(formRef?.current?.values));
              }, 500);
            }
          }
        }
      }, [page, formRef?.current?.values]);

    useEffect(() => {
        const formData = new FormData()
        formData.append("page", 1)
        formData.append("per_page", 1000)
        formData.append("sort", "{}")
        formData.append("search", "{}")
        formData.append("global_search", "")
        formData.append('brand_id', getBrandId());
        GetAllParentCategories(formData).then(data => {
            setList(data && data.data && data.data.response ? data.data.response.rows : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [isBrandChanged])


    const generateSKU = (length = 8, setFieldValue) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let sku = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            sku += characters[randomIndex];
        }
        setFieldValue('sku', sku);
    }

    const addPriceBox = (setFieldValue, values) => {
        const newArr = [...values];
        newArr.push({
            outlet: "",
            orderMode: allOrderMode.map((record) => ({
                name: record.name,
                price_slug: record?.slug || '',
                price_type: 'orderMode',
                price: '',
            })),
            aggregator: allAggregator.map((record) => ({
                name: record.name,
                price_slug: record?.slug || '',
                price_type: 'aggregator',
                price: '',
            }))
        });
        setFieldValue('outlet_prices_specific', newArr);
    };

    const removePriceBox = (index, setFieldValue, values) => {
        let newArr = [...values];
        newArr.splice(index, 1);
        setFieldValue('outlet_prices_specific', newArr);
    };

    const handleCheckboxChange = (e, categories, setFieldValue, category, index) => {
        const updatedCheckedState = e.target.checked;
        let newData = [...categories];
        newData[index].isChecked = updatedCheckedState;
        setFieldValue('categories', newData);
        console.log("newData", newData);
    };


    const [activeStep, setActiveStep] = useState(1);

    // Function to handle step changes
    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const isAllPriceFilled = (values) => {
        let flag = false;
        let flag2 = false;
        if (values && values?.outlet_prices?.length && values?.aggregator_prices?.length) {
            for (let i = 0; i < values?.outlet_prices?.length; i++) {
                if (values.outlet_prices[i]?.price) {
                    flag = true;
                } else {
                    flag = false;
                    break;
                }
            }
            for (let i = 0; i < values?.aggregator_prices?.length; i++) {
                if (values.aggregator_prices[i]?.price) {
                    flag2 = true;
                } else {
                    flag2 = false;
                    break;
                }
            }
        }
        return flag === true && flag2 === true;
    }

    const handleRemoveItem = (index) => {
        let updatedList = [...addOnDataList];
        updatedList.splice(index, 1);
        setAddOnDataList(updatedList);
    };


    const saveData = (e) => {
        e.preventDefault();
        if (recipeEditableIndex === null) {
            setAddOnDataList(prevState => [
                ...prevState,
                formData
            ]);
        } else {
            let existingData = [...addOnDataList];
            existingData[recipeEditableIndex] = formData;
            setAddOnDataList(existingData);
            setRecipeEditableIndex(null);
        }
        setFormData({
            rawMaterial: "",
            usageUOM: "",
            quantity: "",
            price: "",
            calories: "",
            orderMode: ""
        });
        setShow(false);
    };
    const getRecordFromList = (list, id) => {
        return list.filter(item => Number(item.id) === Number(id))[0];
    };

    const handleEditItem = (index) => {
        setRecipeEditableIndex(index);
        console.log("ramveer----", addOnDataList[index])
        // setFormData(addOnDataList[index]);
        formik?.setValues(addOnDataList[index]);
        setShow(true);
    };


    const handleChangeEditRecipe = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const editData = (e, item) => {
        e.preventDefault();
        console.log(menuRecipes, e, "menureipes")
        // if (selectedRecipeType === 'default') {
        //     resetForm();
        // }
    };

    const handleCloseEdit = () => {
        setShowEdit(false);
        setItemToEdit(null);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logic to update the item; replace with your actual update logic
        console.log('Updated Data:', formData);
        handleCloseEdit();
    };

    const handleCancel = () => {
        handleCloseEdit();
    };
    // useEffect(() => {
    //     if (page === 5) {
    //         setBtnDisable(addOnDataList.length <= 0);
    //     }
    // }, [page, addOnDataList]);

    const getSubcategories = async (categoryId) => {
        if (!categoryId) return;

        setLoadingSecondary(true); // Set loading state for secondary categories

        try {
            const formData = new FormData();
            formData.append("category_id", categoryId);
            formData.append('brand_id', getBrandId());

            const response = await GetAllChildCategories(formData);
            setSecondaryCategoryList(response?.data?.response?.rows || []);
        } catch (error) {
            console.log("error ====> ", error);
        } finally {
            setLoadingSecondary(false); // Reset loading state
        }
    };
    useEffect(() => {
        setLoadingSecondary();
    }, [isBrandChanged])

    const onChangeQuantity = (quantity, values) => {
        console.log("values.raw_material_price", values.raw_material_price, quantity)
        formik.setFieldValue(
            'price', values.raw_material_price * quantity
        )
    }

    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Menu</h4>
                </div>
                <div class="page-header-right ms-auto">

                </div>
            </div>
            <Formik
                innerRef={formRef}
                initialValues={{
                    formStep:"",
                    logo: "",
                    short_description: "",
                    short_description_ar: "",
                    sku: '',
                    calorie_option: "",
                    calorie: "",
                    availability: '',
                    start_date: '',
                    end_date: '',
                    start_time: '',
                    end_time: '',
                    week: [],

                    default_price: "",
                    name_ar: "",
                    name: "",
                    tax_id: "",
                    status_change: "",
                    immediate: '',
                    schedule: '',
                    primary_category: "",
                    secondary_category: "",
                    updateType: 'immediate',
                    calorie_option: "",
                    pricingType: '',
                    categories: [{ id: '', isChecked: false, min: "", max: "", free: "" },],
                    outlet_prices_specific: [
                        {
                            outlet: "",
                            orderMode: [

                            ],
                            aggregator: [

                            ]
                        }
                    ],
                    outlet_prices: [],
                }}
                enableReinitialize={true}
                validate={values => {
                    const error = {};
                    if (!values.name) {
                        error.name = "Name is required";
                    } else if (values.name.length > 60) {
                        error.name = "Name should not exceed 60 characters";
                    }
                    if (!values.primary_category) {
                        error.primary_category = "Primary Category is required";
                    }
                    if (!values.secondary_category) {
                        error.secondary_category = "Secondary Category is required";
                    }
                    if (values.short_description && values.short_description.length > 150) {
                        error.short_description = "Short Description should not exceed 150 characters";
                    }

                    if (values.short_description_ar && values.short_description_ar.length > 150) {
                        error.short_description_ar = "Short Description (Arabic) should not exceed 150 characters";
                    }
                    if (!values.name_ar) {
                        error.name_ar = "Name (Arabic) is required";
                    } else if (values.name_ar.length > 60) {
                        error.name_ar = "Name (Arabic) should not exceed 60 characters";
                    }
                    if (!values.status_change) {
                        error.status_change = "Status is required";
                    }
                    if (values.logo) {
                        const maxSize = 5 * 1024 * 1024; // 4MB in bytes
                        if (values.logo.size > maxSize) {
                            error.logo = "Logo should not exceed 5MB";
                        }
                    }
                    if (!values.sku) {
                        error.sku = "Sku is required";
                    }
                    if (!values.default_price) {
                        error.default_price = "Default price is required";
                    }
                    if (!values.start_date) {
                        error.start_date = "Start date is required";
                    }
                    if (!values.end_date) {
                        error.end_date = "End date is required";
                    }
                    if (!values.start_time) {
                        error.start_time = "Start time is required";
                    }
                    if (!values.end_time) {
                        error.end_time = "End time is required";
                    }
                    if (page == 1) {
                        if (error.name || error.name_ar || error.primary_category || error.secondary_category || error.sku || error.default_price || error.start_date || error.end_date || error.start_time || error.end_time) {
                            setBtnDisable(true);
                        } else {
                            setBtnDisable(false);
                        }
                    }
                    if (page == 2) {
                        if (values?.pricingType === "fixed") {
                            setBtnDisable(!isAllPriceFilled(values));
                        } else {
                            setBtnDisable(false);
                        }
                    }
                    if (page == 3) {
                        setBtnDisable(false);
                    }
                    console.log(error)
                    return error;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    let recipeDataToSend = addOnDataList.map((item) => {
                        return { inventory_id: item?.rawMaterial, order_mode_id: item?.orderMode, quantity: item?.quantity };
                    });
                    setSubmitted(true)
                    let formData = new FormData();
                    formData.append('menu_item_logo', values.logo);
                    formData.append('item_name', values.name);
                    formData.append('item_name_ar', values.name_ar);
                    formData.append('sub_category_id', values.secondary_category);
                    formData.append('category_id', values.primary_category);
                    formData.append('tax_group_id', values.tax_id);
                    formData.append('calories', values.calorie);
                    formData.append('short_description', values.short_description);
                    formData.append('short_description_ar', values.short_description_ar ? values.short_description_ar : '');
                    formData.append('sku', values.sku.trim());
                    formData.append('calories_type', values.calorie_option);

                    // formData.append('availability', values.availability);
                    formData.append("start_date", values.start_date);
                    formData.append("end_date", values.end_date);
                    formData.append("start_time", values.start_time);
                    formData.append("end_time", values.end_time);
                    formData.append("week", JSON.stringify(values.week));

                    formData.append('default_price', values.default_price);
                    formData.append('status', values.status_change);
                    formData.append('updates_type', values.updateType);
                    formData.append('updates', deliveryDate);
                    const dataToSend = {
                        outlet_prices: values?.outlet_prices?.map((price) => {
                            console.log('Outlet Price Slug:', price); // Log the outlet price slug
                            return {
                                price_slug: price.price_slug,
                                price_type: 'orderMode',
                                price: parseFloat(price.price) || 0,
                            };
                        }),
                        aggregator_prices: values?.aggregator_prices?.map((price) => {
                            console.log('Aggregator Price Slug:', price);
                            return {
                                price_slug: price.price_slug,
                                price_type: 'aggregator',
                                price: parseFloat(price.price) || 0,
                            };
                        }),
                    };
                    formData.append('default_outlet_prices', JSON.stringify(dataToSend));
                    formData.append('specific_outlet_prices', JSON.stringify(values.outlet_prices_specific) || []);
                    formData.append('pricing_type', values.pricingType);
                    const selectedCategories = values.categories.filter((item) => item?.isChecked);
                    formData.append('menu_item_add_on_list', JSON.stringify(selectedCategories));
                    formData.append('reciepes', JSON.stringify(recipeDataToSend));
                    formData.append('company_id', getCompany().company_id);
                    formData.append('brand_id', getBrandId());
                    formData.append('id', params.id);
                    // return;
                    if (!searchParams.get('copy')) {
                        editMenuItems(formData).then(response => {
                            setSubmitting(false);
                            setSubmitted(false);
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate(`/company/menu/menu/menu_items/list/${1}`)
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                })
                                setSubmitted(false)
                            }
                        }).catch(error => {
                            console.log("error ====> ", error);
                        })
                    } else {
                        CreateMenuItems(formData).then(response => {
                            setSubmitting(false);
                            setSubmitted(false);
                            if (response?.data?.status === 1 || response?.data?.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate(`/company/menu/menu/menu_items/list/${1}`)
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                })
                                setSubmitted(false)
                            }
                        }).catch(error => {
                            console.log("error ====> ", error);
                        })
                    }
                }}


            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
            }) => (

                <form onSubmit={handleSubmit}>
                    {console.log("**values", values)}
                    <div className='steps'>
                        <ul>
                            <li className={`step step-1 ${activeStep >= 1 ? 'active' : ''}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(1)}>
                                <span>1</span><p>Menu Info</p>
                            </li>
                            <li className={`step step-2 ${activeStep >= 2 ? 'active' : 'tag_disabled'}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(2)}>
                                <span>2</span><p>Pricing</p>
                            </li>
                            <li className={`step step-3 ${activeStep >= 3 ? 'active' : 'tag_disabled'}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(3)}>
                                <span>3</span><p>Outlet Pricing</p>
                            </li>
                            <li className={`step step-4 ${activeStep >= 4 ? 'active' : 'tag_disabled'}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(4)}>
                                <span>4</span><p>Add-On</p>
                            </li>
                            <li className={`step step-5 ${activeStep >= 5 ? 'active' : 'tag_disabled'}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(5)}>
                                <span>4</span><p>Recipe </p>
                            </li>
                            <li className={`step step-6 ${activeStep >= 6 ? 'active' : 'tag_disabled'}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(6)}>
                                <span>5</span><p>Setting</p>
                            </li>
                        </ul>
                    </div>
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className='row row-sm'>

                                <div className="step-1" id="checkout-progress" data-current-step="1" style={{ display: 'none' }}>
                                    <div className="progress-bar">
                                        <div className="step step-1 active"><span> 1</span>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="step-label"> Menu Info</div>
                                        </div>
                                        <div className="step step-2"><span> 2</span>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="step-label"> Pricing</div>
                                        </div>
                                        <div className="step step-3"><span> 3</span>
                                            <div className="fa fa-check opaque"></div>

                                            <div className="step-label"> Outlet-pricing</div>
                                        </div>

                                        <div className="step step-4"><span> 4</span>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="step-label"> Add-On</div>
                                        </div>
                                        <div className="step step-4"><span> 5</span>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="step-label"> Recipe</div>
                                        </div>
                                        <div className="step step-4"><span> 6</span>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="step-label"> Setting</div>
                                        </div>

                                    </div>
                                </div>
                                <section id="section1" className="section1 " style={{ display: 'block' }}>
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <div className='upload-logo d-flex align-items-center gap-3 mb-3'>
                                                <div class="avatar avatar-xl">

                                                    {previewImage ?

                                                        <img alt="avatar" src={previewImage} className={previewImage ? "" : "d-none"} />
                                                        :
                                                        <img alt="avatar" src={defaultImage} />
                                                    }

                                                </div>
                                                <div className='btn btn-black btn-file-type'>Upload logo <input id="logo" name="logo" onBlur={handleBlur} accept="image/*" type="file" onChange={(event) => {
                                                    setFieldValue("logo", event.currentTarget.files[0] || "");
                                                    { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                                                }}
                                                /></div>
                                            </div>
                                            <span className='text-danger d-flex text-left' id='errortext'>
                                                {errors.logo && touched.logo && errors.logo}
                                            </span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            {/* {list?.length === 0 ?
                                                <div className="alert alert-warning">
                                                    There is no primary category created yet. Please{' '}
                                                    <Link to="/company/menu/menu/categories/list/1">
                                                        create a primary category
                                                    </Link>{' '}
                                                </div> :
                                                null} */}
                                            <label htmlFor='primary_category' className='text-left d-flex'>Primary category<span className="requirestar">*</span></label>
                                            <select id='primary_category' as="select" className='form-control' onBlur={handleBlur} name="primary_category" onChange={(event) => { handleChange(event); getSubcategories(event.target.value) }} value={values.primary_category} >
                                                <option value="">Select Primary Category</option>
                                                {list?.map((order) => (
                                                    <option key={order.id} value={order.id}>
                                                        {capitalizeAllFirstLetters(order?.name)}
                                                    </option>
                                                ))}
                                            </select>
                                            <span className='text-danger d-flex text-left'>{errors.primary_category && touched.primary_category && errors.primary_category}</span>
                                        </div>

                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            {/* {secondaryCategoryList?.length === 0 ?
                                                <div className="alert alert-warning">
                                                    There is no secondary category created yet. Please{' '}
                                                    <Link to="/company/menu/menu/categories/list/1">
                                                        create a secondary category
                                                    </Link>{' '}
                                                </div> :
                                                null} */}
                                            <label htmlFor='secondary_category' className='text-left d-flex'>Secondary category<span className="requirestar">*</span></label>
                                            <select as="select" id='secondary_category' className='form-control' onBlur={handleBlur} name="secondary_category" onChange={handleChange} value={values.secondary_category}>
                                                <option value="">Select Secondary Category</option>
                                                {secondaryCategoryList?.map((order) => (
                                                    <option key={order.id} value={order.id}>
                                                        {capitalizeAllFirstLetters(order?.name)}
                                                    </option>
                                                ))}
                                            </select>
                                            <span className='text-danger d-flex text-left'>{errors.secondary_category && touched.secondary_category && errors.secondary_category}</span>

                                        </div>


                                        <div className="col-lg-6 text-left form-group mb-3">
                                            <label for="sku" className="col-form-label">SKU<span className="requirestar">*</span></label>
                                            <div className="set-plus-icon-input">
                                                <input type="text" name="sku" className="form-control" id="sku" placeholder='Enter SKU' onChange={handleChange} onBlur={handleBlur} value={values.sku} autoComplete='off' />
                                                <div className="icon-box">
                                                    <a onClick={(e) => generateSKU(8, setFieldValue)}><i className="fa fa-rotate-right"></i></a>
                                                </div>
                                            </div>
                                            <span className='text-danger d-flex text-left'>{errors.sku && touched.sku && errors.sku}</span>
                                        </div>
                                        <div className='col-lg-3  form-group  mt-2'>
                                            <label htmlFor='name' className='text-left d-flex'>Item Name<span className="requirestar">*</span></label>
                                            <input name='name' id="name" placeholder='Enter Item Name ' type='text' onChange={handleChange} onBlur={handleBlur} value={values.name} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                                        </div>
                                        <div className='col-lg-3 text-center form-group mt-2'>
                                            <label htmlFor='name_ar' className='text-left d-flex'>Item Name (arabic)<span className="requirestar">*</span></label>
                                            <input name='name_ar' placeholder='Enter Item Name  ' id="name_ar" type='text' onChange={handleChange} onBlur={handleBlur} value={values.name_ar} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.name_ar && touched.name_ar && errors.name_ar}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group required mb-3'>
                                            <label htmlFor='short_description' className='text-left d-flex'>Short description<span className="requirestar"></span> </label>
                                            <textarea name='short_description' placeholder='Enter Short description' id="short_description" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description} className='form-control' style={{ height: "100px" }} />
                                            <span className='text-danger d-flex text-left'>{errors.short_description && touched.short_description && errors.short_description}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group required mb-3'>
                                            <label htmlFor='short_description_ar' className='text-left d-flex'>Short description (Arabic) <span className="requirestar"></span> </label>
                                            <textarea name='short_description_ar' placeholder='Enter Short description (Arabic)' id="short_description_ar" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description_ar} className='form-control' style={{ height: "100px" }} />
                                            <span className='text-danger d-flex text-left'>{errors.short_description_ar && touched.short_description_ar && errors.short_description_ar}</span>
                                        </div>



                                    </div>
                                    <div className="row">
                                        {/* <div className='col-lg-6 form-group required mb-3'>
                                            <label htmlFor='availability' className='text-left d-flex'>Availability<span className="requirestar"></span></label>
                                            <input type="date" onClick={(e) => e.target.showPicker()} name='availability' placeholder='Select Availability' id="availability" onChange={handleChange} onBlur={handleBlur} value={values.availability} className='form-control cp' />
                                            <span className='text-danger d-flex text-left'>{errors.availability && touched.availability && errors.availability}</span>
                                        </div> */}




                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='start_date' className='text-left d-flex'>Start Date <span className="requirestar">*</span></label>
                                            <CustomDatetimepicker
                                                placeholder={"Start date"}
                                                setField={setFieldValue}
                                                fieldname={`start_date`}
                                                setFieldTouched={setFieldTouched}
                                                value={values.start_date}
                                            />
                                            <span className='text-danger d-flex text-left'>{errors.start_date && touched.start_date && errors.start_date}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='end_date' className='text-left d-flex'>End Date <span className="requirestar">*</span></label>
                                            <CustomDatetimepicker
                                                placeholder={"End date"}
                                                setField={setFieldValue}
                                                fieldname={`end_date`}
                                                setFieldTouched={setFieldTouched}
                                                value={values.end_date}
                                            />
                                            <span className='text-danger d-flex text-left'>{errors.end_date && touched.end_date && errors.end_date}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='start_time' className='text-left d-flex'>Start Time <span className="requirestar">*</span></label>
                                            <input
                                                placeholder={"Select"}
                                                fieldname={`start_time`}
                                                value={values.start_time}
                                                type="time"
                                                className='form-control cp'
                                                onChange={(e) => { setFieldValue("start_time", e?.target?.value) }}
                                                onClick={(e) => e.target.showPicker()}
                                            />
                                            <span className='text-danger d-flex text-left'>{errors.start_time && touched.start_time && errors.start_time}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='end_time' className='text-left d-flex'>End Time :<span className="requirestar">*</span></label>
                                            <input
                                                placeholder={"Select"}
                                                fieldname={`end_time`}
                                                value={values.end_time}
                                                type="time"
                                                className='form-control cp'
                                                onChange={(e) => { setFieldValue("end_time", e?.target?.value) }}
                                                onClick={(e) => e.target.showPicker()}
                                            />
                                            <span className='text-danger d-flex text-left'>{errors.end_time && touched.end_time && errors.end_time}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='week' className='text-left d-flex'>Week Selection<span className="requirestar">*</span></label>
                                            {/* <TagPicker
                                                data={WEEKDAYS}
                                                defaultValue={values.week}
                                                className='form-control form-control-select'
                                                onChange={(e) => { setFieldValue("week", e) }}
                                                cleanable={false}
                                                value={values.week}
                                                placeholder='Select week'
                                            /> */}
                                            {
                                                formRef?.current ? <>
                                                    <CustomMultiSelect data={WEEKDAYS} form={formRef?.current} name='week' />
                                                </> : null
                                            }
                                            <span className='text-danger d-flex text-left'>{errors.week && touched.week && errors.week}</span>
                                        </div>

                                        {/**------------------------ */}

                                        {/* Default Price */}
                                        <div className='col-lg-6 form-group required mb-3'>
                                            <label htmlFor='default_price' className='text-left d-flex'>Default Price<span className="requirestar">*</span></label>
                                            <input type='number' name='default_price' placeholder='Enter Default Price' id="default_price" onChange={handleChange} onBlur={handleBlur} value={values.default_price} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.default_price && touched.default_price && errors.default_price}</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {/* Calorie */}
                                        <div className='col-lg-6 form-group required mb-3'>
                                            <label htmlFor="calorie" className="text-left mr-2">Calories<span className="requirestar">*</span></label>
                                            <div className="d-flex align-items-center">

                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input cp"
                                                        type="radio"
                                                        name="calorie_option"
                                                        id="automatic_calorie"
                                                        value="automatic"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        checked={values.calorie_option === 'automatic'}
                                                    />
                                                    <label className="form-check-label cp" htmlFor="automatic_calorie">Automatic</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input cp"
                                                        type="radio"
                                                        name="calorie_option"
                                                        id="default_calorie"
                                                        value="default"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        checked={values.calorie_option === 'default'}
                                                    />
                                                    <label className="form-check-label cp" htmlFor="default_calorie">Default</label>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            values.calorie_option !== 'automatic' && (
                                                <div className="col-6 form-group mb-3">
                                                    <label htmlFor="calorie" className="text-left d-flex">
                                                        Calorie :
                                                        <span className="requirestar">*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        name="calorie"
                                                        id="calorie"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.calorie}
                                                        className="form-control"
                                                        disabled={values.calorie_option === 'automatic'}
                                                        placeholder='Enter calories'
                                                    />
                                                    <span className="text-danger d-flex text-left">
                                                        {errors.calorie && touched.calorie && errors.calorie}
                                                    </span>
                                                </div>
                                            )}
                                    </div>
                                </section>
                                <section id="section2" className="section2" style={{ display: 'none' }}>
                                    <div className="add-price-box">
                                        <h4 className='mb-2 heading-text'>Pricing Type</h4>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="form-group form-group-flex mt-2 d-flex align-items-center">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input cp"
                                                                type="radio"
                                                                name="pricingType"
                                                                id="fixed"
                                                                value="fixed"
                                                                onChange={(e)=>{
                                                                    handleChange(e);
                                                                    setTimeout(() => {
                                                                        setRefreshPricing(!refreshPricing);
                                                                    }, 100);
                                                                }}
                                                                checked={values?.pricingType === "fixed"}
                                                            />
                                                            <label className="form-check-label cp" htmlFor="fixed">Fixed</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input cp"
                                                                type="radio"
                                                                name="pricingType"
                                                                id="ask_for_price"
                                                                value="ask_for_price"
                                                                onChange={(e)=>{
                                                                    handleChange(e);
                                                                    setTimeout(() => {
                                                                        setRefreshPricing(!refreshPricing);
                                                                    }, 100);
                                                                }}
                                                                checked={values?.pricingType === "ask_for_price"}
                                                            />
                                                            <label className="form-check-label cp" htmlFor="ask_for_price">Ask for price</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {values.pricingType !== 'ask_for_price' ? <>
                                        <h4 className='mt-4 heading-text'>Add Price for Outlet</h4>
                                        {allOrderMode.map((record, i) => (
                                            <div key={i} className="add-price-box">
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className="form-group form-group-flex mb-3">
                                                                    <label htmlFor={`price_${i}`}>{capitalizeAllFirstLetters(SpecialCharacter(record?.name))}</label>
                                                                    <Field
                                                                        className="form-control mb-2"
                                                                        type='number'
                                                                        name={`outlet_prices[${i}].price`}
                                                                        id={`price_${i}`}
                                                                        placeholder={`Enter ${SpecialCharacter(record?.name)} price`}
                                                                    />
                                                                    <Field
                                                                        type="hidden"
                                                                        name={`outlet_prices[${i}].price_slug`}
                                                                        value={record?.slug || ''}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <h4 className='mb-2 heading-text'>Outsource Pricing</h4>
                                        {allAggregator.map((item, index) => (
                                            <div key={index} className="add-price-box">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group form-group-flex mb-3">
                                                            <label htmlFor={`outsource_price_${index}`}>{capitalizeAllFirstLetters(SpecialCharacter(item?.name))}</label>
                                                            <Field
                                                                className="form-control mb-2"
                                                                type='number'
                                                                name={`aggregator_prices[${index}].price`}
                                                                id={`outsource_price_${index}`}
                                                                placeholder={`Enter ${SpecialCharacter(item?.name)} price`}
                                                            />
                                                            <Field
                                                                type="hidden"
                                                                name={`aggregator_prices[${index}].price_slug`}
                                                                value={item?.slug || ''}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                        : null}
                                </section>
                                <section id="section3" className="section3" style={{ display: 'none' }}>
                                    <div>
                                        {values.pricingType !== 'ask_for_price' ? <>
                                            {values?.outlet_prices_specific?.map((record, i) => {
                                                const prefix = `outlet_prices_specific[${i}]`;
                                                return (
                                                    <div key={i}>
                                                        <div className="add-price-box">
                                                            <h4 className='mt-4 heading-text'>Add Price for Outlet</h4>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='row'>
                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mb-3">
                                                                                <label htmlFor={`outlet_${i}`}>Select Outlet</label>
                                                                                <select
                                                                                    className="form-control mb-2"
                                                                                    id={`outlet_${i}`}
                                                                                    name={`${prefix}.outlet`}
                                                                                    onChange={handleChange}
                                                                                    value={record.outlet || ''}
                                                                                >
                                                                                    <option value="">Select outlet</option>
                                                                                    {outlets?.map((outlet) => (
                                                                                        <option key={outlet.id} value={outlet.id}>{capitalizeAllFirstLetters(outlet.outlet_name)}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-6'></div>
                                                                        <div className='col-md-6'>
                                                                            {record?.orderMode?.map((mode, index) => (
                                                                                <div key={index} className="form-group form-group-flex mb-3">
                                                                                    <label htmlFor={`mode_${mode.slug}_${i}_${index}`}>{capitalizeAllFirstLetters(SpecialCharacter(mode?.name))}</label>
                                                                                    <input type="hidden" name={`${prefix}.orderMode[${index}].slug`} value={mode.slug} />
                                                                                    <Field
                                                                                        className="form-control mb-2"
                                                                                        type='number'
                                                                                        name={`${prefix}.orderMode[${index}].price`}
                                                                                        id={`mode_${mode.slug}_${i}_${index}`}
                                                                                        placeholder={`Enter ${SpecialCharacter(mode?.name)} price`}
                                                                                        value={mode.price}
                                                                                    />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="add-price-box">
                                                            <h4 className='mb-2 heading-text'>Outsource Pricing</h4>
                                                            <div className='row'>
                                                                {record?.aggregator?.map((agg, index) => (
                                                                    <div key={index} className='col-md-6'>
                                                                        <div className="form-group form-group-flex mb-3">
                                                                            <label htmlFor={`aggregator_${agg.slug}_${i}_${index}`}>{capitalizeAllFirstLetters(SpecialCharacter(agg?.name))}</label>
                                                                            <input type="hidden" name={`${prefix}.aggregator[${index}].slug`} value={agg.slug} />
                                                                            <Field
                                                                                className="form-control mb-2"
                                                                                type='number'
                                                                                name={`${prefix}.aggregator[${index}].price`}
                                                                                id={`aggregator_${agg.slug}_${i}_${index}`}
                                                                                placeholder={`Enter ${SpecialCharacter(agg?.name)} price`}
                                                                                value={agg?.price}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            {i !== 0 && (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger"
                                                                    onClick={() => removePriceBox(i, setFieldValue, values.outlet_prices_specific)}
                                                                    style={{ width: '10%' }}
                                                                >
                                                                    Remove
                                                                </button>
                                                            )}
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => addPriceBox(setFieldValue, values.outlet_prices_specific)}
                                                                style={{ width: '12%', marginLeft: i !== 0 ? '10px' : '0' }}
                                                            >
                                                                + Add price for outlet
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                            : <p style={{ color: 'red' }}>You are selecting the pricing as ask for price,therefore outlet pricing is not applicable.</p>}
                                    </div>
                                </section>
                                <section id="section4" className="section4 mt-5" style={{ display: 'none', padding: '0' }}>
                                    <div className="d-flex justify-content-end">

                                    </div>

                                    <div className="tabler-wrap">

                                        <table className="table table-custom">
                                            <thead>
                                                <tr>
                                                    <th>Categories</th>
                                                    <th>Min</th>
                                                    <th>Max</th>
                                                    <th>Free</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {values?.categories?.map((category, index) => (
                                                    <tr key={category.id}>
                                                        <td>
                                                            <div className="form-check w-10 h-10">
                                                                <Field
                                                                    className="form-check-input cp"
                                                                    type="checkbox"
                                                                    name={`categories[${index}].isChecked`}
                                                                    id={`category_${category.id}`}
                                                                    checked={category.isChecked}
                                                                    onChange={(e) => handleCheckboxChange(e, values?.categories, setFieldValue, category, index)}
                                                                    autoComplete="off"
                                                                />

                                                                <label className="form-check-label cp" htmlFor={`category_${category.id}`}>
                                                                    {capitalizeFirstLetter(category?.name)}
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="form-group mb-3">
                                                                <Field
                                                                    className="form-control mb-2"
                                                                    id={`key_venture_${category.id}_1`}
                                                                    type="number"
                                                                    placeholder="Enter minimum quantity"
                                                                    name={`categories[${index}].min`}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="form-group mb-3">
                                                                <Field
                                                                    className="form-control mb-2"
                                                                    id={`key_venture_${category.id}_2`}
                                                                    type="number"
                                                                    placeholder="Enter maximum quantity"
                                                                    name={`categories[${index}].max`}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="form-group mb-3">
                                                                <Field
                                                                    className="form-control mb-2"
                                                                    id={`key_venture_${category.id}_3`}
                                                                    type="number"
                                                                    placeholder="Enter free quantity"
                                                                    name={`categories[${index}].free`}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                </section>

                                <section id="section5" className="section5" style={{ display: 'none', padding: '0' }}>

                                    <div className="d-flex justify-content-end">
                                        <button
                                            className="btn btn-primary btn-lg"
                                            type="button"
                                            onClick={() => setShow(true)}
                                            style={{ marginBottom: '10px' }}
                                        >
                                            Add New Recipe
                                        </button>
                                    </div>

                                    <div className="tabler-wrap">

                                        <table className="table table-custom">
                                            <thead>
                                                <tr>
                                                    <th>Raw Material</th>
                                                    <th>Usage UOM</th>
                                                    <th>Quantity</th>
                                                    <th>Price</th>
                                                    <th>Calories</th>
                                                    <th>Order Mode</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {addOnDataList && addOnDataList?.length ? (
                                                    addOnDataList?.map((recipe, index) => (
                                                        <tr key={index}>
                                                            <td>{capitalizeAllFirstLetters(getRecordFromList(inventoryList, recipe?.rawMaterial)?.item_name)}</td>
                                                            <td>{recipe?.usageUOM}</td>
                                                            <td>{recipe?.quantity}</td>
                                                            <td>{recipe?.price}</td>
                                                            <td>{recipe?.calories}</td>
                                                            {/* <td>{capitalizeAllFirstLetters(getRecordFromList(orderList, recipe?.orderMode)?.name)}</td> */}
                                                            <td>
                                                                {/* {
                                  recipe?.orderMode?.map((item, i)=>{
                                    return <span key={i}>{capitalizeAllFirstLetters(SpecialCharacter(getRecordFromList(orderList, item)?.name))}{i<(recipe?.orderMode?.length -1) ? ", ":""}</span>
                                  })
                                } */}
                                                                {
                                                                    recipe?.orderMode?.map((item, i) => {
                                                                        return <span key={i}>{capitalizeAllFirstLetters(SpecialCharacter(getRecordFromList(orderList, item)?.name))}{i < (recipe?.orderMode?.length - 1) ? ", " : ""}</span>
                                                                    })
                                                                }

                                                            </td>
                                                            <td className='d-flex'>
                                                                <button type='button' className='btn btn-primary me-3 mt-2' onClick={() => handleEditItem(index)}>Edit</button>
                                                                <button type='button' className='btn btn-danger mt-2' onClick={() => handleRemoveItem(index)}>Remove</button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="7" className="text-center">No recipes found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </section>


                                <section id="section6" className="section6 " style={{ display: 'none', paddingTop: '0' }}>

                                    <div className='row'>
                                        <div className='col-lg-8 form-group' style={{ width: '50%' }}>
                                            <div className='form-group mt-3'>
                                                <label htmlFor='tax_id' className='d-flex'>Tax Group :<span className="requirestar"></span></label>
                                                <select id="tax_id" className='form-control' name='tax_id' value={values?.tax_id} onChange={handleChange} onBlur={handleBlur}>

                                                    <option value="">Select Tax Group</option>
                                                    {taxList?.length ? taxList?.map((row) => (
                                                        <option key={row.id} value={row.id}>{capitalizeAllFirstLetters(row?.name)}</option>
                                                    )) : ''}
                                                </select>
                                                <span className='text-danger d-flex'>{errors.tax_id && touched.tax_id && errors.tax_id}</span>
                                            </div>
                                            <div className='form-group mt-3'>
                                                <label htmlFor='status-change' className='d-flex'>
                                                    Status:
                                                    <span className="requirestar"></span>
                                                </label>
                                                <div className='form-group mt-3 form-switch w-25 d-flex align-items-center'>
                                                    <Field
                                                        className="form-check-input mr-100"
                                                        type="checkbox"
                                                        id="status-change"
                                                        name="status_change"
                                                        onChange={handleChange}
                                                        checked={values?.status_change}
                                                        onBlur={handleBlur}
                                                    />
                                                    {/* <p style={{ marginLeft: '10px' }}>Menu Status</p> */}
                                                </div>

                                                    <span className="text-danger d-flex text-left">
                                                        {errors.status_change && touched.status_change && errors.status_change}
                                                    </span>

                                            </div>


                                            <div className='col-lg-6 text-center form-group mt-3'>
                                                <label for="" className="text-left d-flex">Update To Be Done:<span className="requirestar ms-1"></span></label>
                                                <div className='row ps-3 mt-3'>
                                                    <div className="form-check w-25 text-start me-3">
                                                        <input className="form-check-input" type="radio" id="immediate"
                                                            name="updateType"
                                                            value="immediate"
                                                            autoComplete='off'
                                                            onChange={handleChange}
                                                            checked={values.updateType === 'immediate'} />
                                                        <label className="form-check-label ms-2 cp" for="immediate">Immediate</label>
                                                    </div>
                                                    <div className="form-check w-25 text-start ms-2">
                                                        <input className="form-check-input" type="radio" id="schedule"
                                                            name="updateType"
                                                            value="schedule"
                                                            autoComplete='off'
                                                            onChange={handleChange}
                                                            checked={values.updateType === 'schedule'}
                                                        />
                                                        <label className="form-check-label ms-2 cp" for="schedule">Schedule</label>
                                                    </div>
                                                </div>
                                                {values.updateType === 'schedule' ?
                                                    <div className='row mt-3'>

                                                        <Form.Group controlId="deliveryDate">

                                                            <Form.Control
                                                                style={{ width: '754px', marginBottom: '20px', cursor: 'pointer' }}
                                                                type="date"
                                                                name="deliveryDate"
                                                                placeholder="Select Delivery Date"
                                                                value={deliveryDate}
                                                                onChange={(e) => setDeliveryDate(e.target.value)}
                                                                // min={getTodayDate()}
                                                                onClick={(e) => e.target.showPicker()}
                                                                className={`form-control `}
                                                            />

                                                        </Form.Group>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>

                                </section>
                                <div className="button-container mt-4 pl-3">
                                    <button className="btn btn-outline-primary btn-prev " type="button" onClick={() => { setBtnDisable(false); prevStep(); }} style={{ display: 'none' }}>Back</button>
                                    <button className="btn btn-next btn-primary ms-2" type="button" onClick={(e) => { nextStep(e) }} disabled={btnDisable} > Next</button>
                                    <button className="btn btn-primary ms-2 btn-submit" onClick={() => { setBtnClicked(true) }} type="submit" style={{ display: 'none' }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik >
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
            <Modal show={show} onHide={handleClose} backdrop={'static'} >
                <Modal.Header closeButton>
                    <Modal.Title>{recipeEditableIndex === null ? "Add" : "Edit"} New Recipe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Form onSubmit={(e) => saveData(e)}>
                     */}
                    <div>
                        <form onSubmit={formik.handleSubmit}>

                            <div className='col-lg-6 text-center form-group mb-3 w-100' controlId="rawMaterial">
                                <label className='text-left d-flex'>Raw Material</label>
                                <select as="select" className='form-control' name="rawMaterial" onBlur={formik?.handleBlur} value={formik?.values?.rawMaterial} onChange={handleChangeModel} >
                                    <option value="">Select Raw Material</option>
                                    {inventoryList.map((order) => {
                                        const isDisabled = addOnDataList.some(item => item.rawMaterial && Number(item.rawMaterial) === order.id);
                                        return (
                                            <option key={order.id} value={order.id} disabled={isDisabled}>
                                                {capitalizeAllFirstLetters(order.item_name)}
                                            </option>
                                        );
                                    })}

                                </select>
                            </div>
                            <Form.Group className="mb-3" controlId="usageUOM">
                                <Form.Label>Unit of Measure</Form.Label>
                                <Form.Control disabled type="text" name="usageUOM" placeholder="Enter Unit of Measure" value={formik?.values?.usageUOM} onChange={formik?.handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="quantity">
                                <Form.Label>Quantity</Form.Label>
                                <Form.Control type="number" name="quantity" onBlur={formik?.handleBlur} placeholder="Enter Quantity" value={formik?.values?.quantity} onChange={(event) => { formik?.handleChange(event); onChangeQuantity(event.target.value, formik.values) }} />
                                <span className="text-danger d-flex text-left">{formik.errors.quantity && formik.touched.quantity && formik.errors.quantity} </span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="price">
                                <Form.Label>Price</Form.Label>
                                <Form.Control disabled type="number" name="price" placeholder="Enter Price" value={formik?.values?.price} onChange={formik?.handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="calories">
                                <Form.Label>Calories</Form.Label>
                                <Form.Control disabled type="number" name="calories" placeholder="Enter Calories" value={formik?.values?.calories} onChange={formik?.handleChange} />
                            </Form.Group>

                            <div className='col-lg-6 text-center form-group mb-3 w-100' controlId="orderMode">
                                <label className='text-left d-flex'>Order Mode</label>
                                <CustomMultiSelect data={orderList.map((item) => {
                                    return {
                                        label: capitalizeFirstLetter(SpecialCharacter(item.name)),
                                        value: item.id
                                    }
                                })} form={formik} name='orderMode' overFlowVisibleOptions={1} menuClassName="popup-select" />
                            </div>

                            <Button variant="primary" onClick={resetForm} style={{ marginRight: '10px' }}> Cancel</Button>
                            {/* <Button variant="primary" type='submit' disabled={formData.orderMode == '' || formData.calories == '' || formData.price == '' || formData.quantity == '' || formData.usageUOM == "" || formData.rawMaterial == ""}>
                            {recipeEditableIndex === null ? "Add" : "Edit"} Recipe
                        </Button> */}
                            <Button variant="primary" type='submit' disabled={!formik.dirty || !formik.isValid}>
                                {recipeEditableIndex === null ? "Add" : "Update"} Recipe
                            </Button>
                            {/* </Form> */}
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default MenuItemsEdit
