import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetOutletDetail, DeleteHardwareDevice } from '../../services/company_panel/outletService';
import toHtml from 'html-react-parser'
import { formateDate, getBrandId, SpecialCharacter, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/company_panel/common/breadcrumbs'
import Loader from '../../components/company_panel/common/loader'
import Swal from 'sweetalert2'
import { ListGroups } from '../../services/company_panel/routingStationService';


const OutletView = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Outlet", url: "/company/business/outlet/list/1" }, { title: "Outlet Detail", url: "" }]
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [routingGroupList, setRoutingGroupList] = useState([])

    useEffect(() => {
        GetOutletDetail(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })

        ListGroups({ brand_id: getBrandId() }).then(data => {
            setRoutingGroupList(data?.data?.response.rows);
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [statsUpdate]);

    console.log("routingGroupList", routingGroupList);


    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                DeleteHardwareDevice(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Device deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };


    return (
        <>

            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Outlets</h4>
                </div>
                <div class="page-header-right ms-auto">

                </div>
            </div>
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h3 className="main-content-label mb-3">Outlet Info</h3>
                            </div>
                            {showDefault && Object.keys(showDefault).length > 0 ? <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table table">
                                        <tbody>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Id</td>
                                                    <td>{showDefault && showDefault.id ? showDefault.id : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Outlet Name</td>
                                                    <td>{showDefault && showDefault.outlet_name ? capitalizeFirstLetter(showDefault.outlet_name) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Outlet Name (Arabic)</td>
                                                    <td>{showDefault && showDefault.outlet_name_ar ? capitalizeFirstLetter(showDefault.outlet_name_ar) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Shortcode</td>
                                                    <td>{showDefault && showDefault.short_code ? capitalizeFirstLetter(showDefault.short_code) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>VAT information</td>
                                                    <td>{showDefault && showDefault.vat_information ? capitalizeFirstLetter(showDefault.vat_information) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Brand Name</td>
                                                    <td>{showDefault && showDefault.brand ? capitalizeFirstLetter(showDefault.brand.name) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Created Date</td>
                                                    <td>{showDefault && showDefault.created_at ? formateDate(showDefault.created_at) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            {/* <tr>
                                                <td>Order type</td>
                                                <td>N/A</td>
                                            </tr> */}
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Email</td>
                                                    <td>{showDefault && showDefault.email ? showDefault.email : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Address</td>
                                                    <td>{showDefault && showDefault.location ? capitalizeFirstLetter(showDefault.location) : "N/A"}</td>
                                                </div>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className='row row-sm'>
                                        <div className='col-lg-6 col-md-6 animation_fade'>
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                    <h3 className="main-content-label mb-3">Hardware Details</h3>
                                                    {showDefault.outlet_hardware_details.length ? showDefault.outlet_hardware_details.map((row, i) => (
                                                        <table id="simple-table" className="table ">
                                                            <div className='d-flex justify-content-between align-items-center py-3' >
                                                                <h3>{row && row?.hardware_used ? capitalizeFirstLetter(SpecialCharacter(row?.hardware_used)) : "N/A"}</h3>
                                                                <a className="" onClick={() => { deleteFunction(row.id) }} style={{ cursor: 'pointer' }}>Delete Device</a>
                                                            </div>
                                                            {row?.hardware_used == "printer" || row?.hardware_used == "pos" ?
                                                                <>
                                                                    <tbody style={{ border: 'none' }}>
                                                                        <tr>
                                                                            <div className='d-flex justify-content-between'>
                                                                                <td>Registration ID :</td>
                                                                                <td>{row && row.registration_id ? capitalizeFirstLetter(row.registration_id) : "-"}</td>
                                                                            </div>
                                                                        </tr>
                                                                        <tr>
                                                                            <div className='d-flex justify-content-between'>
                                                                                <td>Name :</td>
                                                                                <td>{row && row.name ? capitalizeFirstLetter(row.name) : "-"}</td>
                                                                            </div>
                                                                        </tr>
                                                                        <tr>
                                                                            <div className='d-flex justify-content-between'>
                                                                                <td>SDK ID :</td>
                                                                                <td>{row && row.sdk_id ? capitalizeFirstLetter(row.sdk_id) : "-"}</td>
                                                                            </div>
                                                                        </tr>
                                                                        {row?.outlet_routing_group_relations.length > 0 &&
                                                                            <tr>
                                                                                <div className='d-flex justify-content-between'>
                                                                                    <td>Routing Station:</td>
                                                                                    <td className="d-flex justify-content-end">
                                                                                        <div className='w-75 d-flex justify-content-end flex-wrap gap-1'>
                                                                                            {row?.outlet_routing_group_relations?.map((station, idx) => {
                                                                                                const matchedGroup = routingGroupList.find(group => group.id == station?.routing_);
                                                                                                const isLast = idx === row.outlet_routing_group_relations.length - 1;
                                                                                                return <div key={idx}>{" "}{matchedGroup?.name}{!isLast && matchedGroup?.name ? "," : ""}</div>;
                                                                                            })}
                                                                                        </div>
                                                                                    </td>
                                                                                </div>
                                                                            </tr>
                                                                        }
                                                                        {
                                                                            row?.printer_type !== null && (
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <span>Printer Type :</span>
                                                                                            <span>{row.printer_type ? capitalizeFirstLetter(row.printer_type) : "-"}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }

                                                                    </tbody>
                                                                </>
                                                                : null}
                                                        </table>
                                                    )) : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 animation_fade'>
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                    <h3 className="main-content-label mb-3">Store Settings</h3>
                                                    <table id="simple-table" className="table  ">
                                                        <tbody>
                                                            <tr>
                                                                <div>
                                                                    <td>Invoice Header Text Info</td>
                                                                    <div>{showDefault && showDefault.invoice_header_text_info ? showDefault.invoice_header_text_info : ""}</div>
                                                                </div>
                                                            </tr>
                                                            <tr>
                                                                <div>
                                                                    <td>Invoice Footer Text Info</td>
                                                                    <div>{showDefault && showDefault.invoice_footer_text_info ? showDefault.invoice_footer_text_info : ""}</div>
                                                                </div>
                                                            </tr>
                                                            <tr>
                                                                <div>
                                                                    <td>End of the day configuration</td>
                                                                    <div>{showDefault && showDefault.end_of_the_day_configuration ? showDefault.end_of_the_day_configuration : ""}: {showDefault && showDefault.end_of_the_day_time ? showDefault.end_of_the_day_time : ""}</div>
                                                                </div>
                                                            </tr>
                                                            <td colSpan="3">
                                                                <td>Store opening and closing time</td>
                                                                <div className='row'>
                                                                    {showDefault.monday_opening_time === 'null' || showDefault.monday_closing_time === 'null'
                                                                        ? '' :
                                                                        <div className='col-6'>Monday : {showDefault.monday_opening_time} - {showDefault.monday_closing_time}</div>}


                                                                    {showDefault.tuesday_opening_time === 'null' || showDefault.tuesday_closing_time === 'null'
                                                                        ? '' :
                                                                        <div className='col-6'>Tuesday : {showDefault.tuesday_opening_time} - {showDefault.tuesday_closing_time} </div>}


                                                                    {showDefault.wednesday_opening_time === 'null' || showDefault.wednesday_closing_time === 'null'
                                                                        ? '' :
                                                                        <div className='col-6'> Wednesday : {showDefault.wednesday_opening_time} - {showDefault.wednesday_closing_time}</div>}


                                                                    {showDefault.thursday_opening_time === 'null' || showDefault.thursday_closing_time === 'null'
                                                                        ? '' :
                                                                        <div className='col-6'>Thursday : {showDefault.thursday_opening_time} - {showDefault.thursday_closing_time}</div>}


                                                                    {showDefault.friday_opening_time === 'null' || showDefault.friday_closing_time === 'null'
                                                                        ? '' :
                                                                        <div className='col-6'>Friday : {showDefault.friday_opening_time} - {showDefault.friday_closing_time}</div>}


                                                                    {showDefault.saturday_opening_time === 'null' || showDefault.saturday_closing_time === 'null'
                                                                        ? '' :
                                                                        <div className='col-6'>Saturday : {showDefault.saturday_opening_time} - {showDefault.saturday_closing_time}</div>}


                                                                    {showDefault.sunday_opening_time === 'null' || showDefault.sunday_closing_time === 'null'
                                                                        ? '' :
                                                                        <div className='col-6'>Sunday : {showDefault.sunday_opening_time} - {showDefault.sunday_closing_time}</div>}

                                                                </div>
                                                            </td>

                                                            <tr>
                                                                <div className='d-flex justify-content-between'>
                                                                    <td>How many copy of the check will be print ?</td>
                                                                    <td>{showDefault && showDefault.copy_print_count ? showDefault.copy_print_count : " "}</td>
                                                                </div>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" mt-2">
                                        <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                            {/* <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i> */}
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </div> : <Loader />}
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-5 col-md-5 animation_fade"></div> */}
            </div>

        </>
    )
}

export default OutletView