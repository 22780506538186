import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/settingsService'

const Footer = () => {

	const [dataList, setDataList] = useState({});



useEffect(()=>{
	GetData().then((data)=>{
				
		setDataList(data.data.response);
		console.log(data.data.response);
	}).catch((error)=>{
			console.log('error in get global-settings api', error);
	})
},[])


	return (
			<div className="main-footer text-center">
				<div className="container">
					<div className="row row-sm">
						<div className="col-md-12">
							{/* <span>Copyright © 2024 <a href="/admin/dashboard">Pos</a>. Designed by <a href="/admin/dashboard">Pos</a> All rights reserved.</span> */}
							<span>{dataList.copyright_text}</span>
						</div>
					</div>
				</div>
			</div>
	)
}

export default Footer;