
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { GetSingleInventory } from '../../services/company_panel/inventoryService'
import toHtml from 'html-react-parser'
import { capitalizeAllFirstLetters, formateDate, getOutletId, removeHyphens, removeSpecialCharacter, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import Loader from '../../components/company_panel/common/loader'


const InventoryView = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Inventory", url: "/company/raw_materials/inventory/list/1" }, { title: "View", url: "" }]
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useEffect(() => {
        GetSingleInventory({ id: params.id, outlet_id : getOutletId() }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [isOutletChanged]);

    useMemo(()=>{
		document.addEventListener('outlet-changed', function(){
			setIsOutletChanged(!isOutletChanged);
		});
	});

    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Inventory</h4>
                </div>
            </div>
            <div className="card custom-card">
                {showDefault && Object.keys(showDefault).length > 0 ?
                    <div className="card-body">
                        <div className="card custom-card">
                            <div className="card-body">
                                <h3 className="main-content-label mb-3">Inventory Info</h3>

                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table ">
                                        <tbody>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Category</td>
                                                    <td>{showDefault && showDefault?.category_id ? capitalizeAllFirstLetters(showDefault?.category?.category?.name) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Sub category</td>
                                                    <td>{showDefault && showDefault?.sub_category_id ? capitalizeAllFirstLetters(showDefault?.category?.name) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Item type </td>
                                                    <td>{showDefault && showDefault?.item_type ? removeHyphens(capitalizeAllFirstLetters(showDefault?.item_type)) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            {showDefault?.item_type === 'production' &&
                                                <tr>
                                                    <div className='d-flex justify-content-between'>
                                                        <td>Production item </td>
                                                        <td>{showDefault && showDefault?.item_type ? removeHyphens(capitalizeAllFirstLetters(showDefault?.production_item)) : "N/A"}</td>
                                                    </div>
                                                </tr>
                                            }
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Item name </td>
                                                    <td>{showDefault && showDefault?.item_type ? removeHyphens(capitalizeAllFirstLetters(showDefault?.item_name)) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Primary unit of measure</td>
                                                    <td>{showDefault && showDefault?.uom ? showDefault?.uom?.uom : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Calories as per primary unit</td>
                                                    <td>{showDefault && showDefault?.calories_as_per_primary_unit ? showDefault?.calories_as_per_primary_unit : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Price of raw material</td>
                                                    <td>{showDefault && showDefault?.raw_material_price ? showDefault?.raw_material_price : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Stock (quantity)</td>
                                                    <td>{showDefault && showDefault?.inventory_stocks[0] ? showDefault?.inventory_stocks[0]?.quantity : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Can change later</td>
                                                    <td>{showDefault && showDefault?.can_change_later ? "YES" : "NO"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Transferable</td>
                                                    <td>{showDefault && (showDefault?.transferable === true || showDefault?.transferable === 'true') ? "YES" : "NO"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Company</td>
                                                    <td>{showDefault && showDefault?.company_id ? capitalizeAllFirstLetters(showDefault?.company.name) : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Status </td>
                                                    <td>{showDefault && showDefault?.status ? "Active" : "Inactive"}</td>
                                                </div>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="card custom-card  ">
                            <div className="card-body p-0">
                                <h1 className='p-3'>Unit of conversion</h1>
                                <table className="table table-custom p-0 w-100 table-sm w-100" style={{ padding: '0!important' }}>

                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>UOM</th>
                                            <th>Conversion</th>
                                            <th>Price</th>
                                            <th>Calories</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Primary Unit of Measurement</td>
                                            <td className='col-2'> {showDefault?.uom?.uom_code} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[0]?.conversion} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[0]?.price} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[0]?.calories} </td>
                                        </tr>
                                        <tr>
                                            <td className='col-1'>Transfer Unit of Measure</td>
                                            <td className='col-2'> {showDefault?.inventory_unit_converisons[1]?.uomDetail?.uom_code} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[1]?.conversion} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[1]?.price} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[1]?.calories} </td>
                                        </tr>
                                        <tr>
                                            <td className='col-1'>Usage Unit of Measure</td>
                                            <td className='col-2'> {showDefault?.inventory_unit_converisons[2]?.uomDetail?.uom_code} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[2]?.conversion} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[2]?.price} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[2]?.calories} </td>
                                        </tr>
                                        <tr>
                                            <td className='col-1'>Count Unit of Measurement</td>
                                            <td className='col-2'> {showDefault?.inventory_unit_converisons[3]?.uomDetail?.uom_code} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[3]?.conversion} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[3]?.price} </td>
                                            <td className='col-2'>{showDefault?.inventory_unit_converisons[3]?.calories} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    : <Loader />}

                <div className='my-4 mx-2'>
                    <Link to={`/company/raw_materials/inventory/1/edit/${params.id}`} className="btn btn-primary ms-2 align-content-center">Edit Inventory</Link>
                    <Link to={`/company/store_manager/transfer_stock/transfer_out/list/1`} className="btn btn-outline-primary ms-2 align-content-center">Transfer Stock</Link>
                </div>
            </div>
        </>
    )
}

export default InventoryView