import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { GetChangeStatus, GetTaxDetail } from '../../../services/company_panel/setting/taxConfigurationService';
import Swal from 'sweetalert2'
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationTab from '../configurationTab';
import { LOADER_TIME } from '../../../utils/Constants';
import { AddWasteConfiguration, GetWasteConfigurationDetails, StatusWasteConfiguration } from '../../../services/company_panel/setting/wasteConfigurationService';
import { getOutletId } from '../../../helpers/commonFunctions';
import * as Yup from 'yup';


const WasteConfiguration = () => {

    const navigate = useNavigate()
    const params = useParams()
    const addFormRef = useRef();
    const location = useLocation()
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [showDefault, setShowDefault] = useState({})
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('brand-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
    });


    useEffect(() => {
        GetWasteConfigurationDetails().then(data => {
            setShowDefault(data && data?.data && data?.data?.response ? data?.data?.response[0] : {})
            console.log("data-->", data?.data?.response[0]);

        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [statsUpdate]);

    const validateSchema = Yup.object().shape({
        wasteType: Yup.string().required("Waste type is required"),
        percentage: Yup.string()
            .test(
                'is-percentage-required',
                'Waste percentage is required',
                function (value) {
                    const { wasteType } = this.parent;
                    if (wasteType == 'percentageOfDay') {
                        return !!value;
                    }
                    return true;
                }
            )
            .test(
                'is-percentage-valid',
                'Percentage must be between 0 and 100',
                function (value) {
                    const { wasteType } = this.parent;
                    if (wasteType === 'percentageOfDay' && value) {
                        const numValue = Number(value);
                        return numValue >= 0 && numValue <= 100;
                    }
                    return true;
                }
            ),
        actualAmount: Yup.string()
            .test(
                'is-actual-amount-required',
                'Actual waste amount is required',
                function (value) {
                    const { wasteType } = this.parent;
                    if (wasteType == 'actualOfDay') {
                        return !!value;
                    }
                    return true;
                }
            )
            .test(
                'is-actual-amount-valid',
                'Actual waste amount must be non-negative and no more than 8 digits',
                function (value) {
                    const { wasteType } = this.parent;
                    if (wasteType === 'actualOfDay' && value) {
                        const numValue = Number(value);
                        return numValue >= 0 && /^\d{1,8}$/.test(value); 
                    }
                    return true;
                }
            ),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            wasteType: showDefault && showDefault?.waste_type ? showDefault?.waste_type : "",
            percentage: showDefault && showDefault?.limited_percentage ? showDefault?.limited_percentage : "",
            actualAmount: showDefault && showDefault?.actual_amount ? showDefault?.actual_amount : "",
        },
        validationSchema: validateSchema,
        onSubmit: values => {
            let formData = new FormData()
            formData.append('outlet_id', getOutletId());
            formData.append('waste_type', values.wasteType);
            if (values.wasteType == "percentageOfDay") {
                formData.append('limited_percentage', values.percentage);
            } else if (values.wasteType == "actualOfDay") {
                formData.append('actual_amount', values.actualAmount);
            }

            AddWasteConfiguration(formData)
                .then(response => {
                    if (response.data.status === 1 || response.data.status === '1') {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                    }
                })
                .catch(error => {
                    console.log('error ====> ', error)
                })
        }
    })

    console.log("formik-->", formik.values, "error", formik.errors);


    const ChangeStatus = (Num) => {
        const formData = new FormData()
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                StatusWasteConfiguration(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }


    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Settings</h4>
                    </div>
                    <div className='page-header-right ms-auto'>

                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationTab tab='waste_configuration' />
                            </div>
                        </div>
                    </div>

                    <div className='gap-20 pb-2 pe-0 border-bottom'>
                        <div className="form-check form-switch mx-auto d-flex justify-content-start">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="status-change"
                                name="status"
                                checked={showDefault?.status ? "checked" : ""}
                                onClick={() => { ChangeStatus(showDefault?.status == 1 ? 0 : 1); }}
                            />
                            <label
                                className="form-check-label"
                                for="status-change"
                            >Enable</label>
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="gap-20">
                            <div className='row'>
                                <div className='col-lg-8 text-center form-group mt-3'>
                                    <label className="text-left d-flex">Waste Type<span className="requirestar">*</span></label>
                                    <div className='row ps-3 mt-3'>
                                        <div className="form-check w-25 text-start">
                                            <input className="form-check-input cp" type="radio" name="wasteType" id="unlimited" value="unlimited" checked={formik.values.wasteType === 'unlimited'} autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" htmlFor="unlimited">Unlimited</label>
                                        </div>
                                        <div className="form-check w-50 text-start">
                                            <input className="form-check-input cp" type="radio" name="wasteType" id="percentageOfDay" value="percentageOfDay" checked={formik.values.wasteType === 'percentageOfDay'} autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" htmlFor="percentageOfDay">Limited as percentage of total sale of day</label>
                                        </div>
                                        <div className="form-check w-25 text-start">
                                            <input className="form-check-input cp" type="radio" name="wasteType" id="actualOfDay" value="actualOfDay" checked={formik.values.wasteType === 'actualOfDay'} autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" htmlFor="actualOfDay">Actual amount of the day</label>
                                        </div>
                                    </div>
                                    {(formik.values.wasteType === 'percentageOfDay') && (
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='percentage' className='text-left d-flex'></label>
                                            <input name='percentage' type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.percentage} placeholder={`Enter percentage`} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{formik.errors.percentage && formik.touched.percentage && formik.errors.percentage}</span>
                                        </div>
                                    )}
                                    {(formik.values.wasteType === 'actualOfDay') && (
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='actualAmount' className='text-left d-flex'></label>
                                            <input name='actualAmount' type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.actualAmount} placeholder={`Enter actual amount`} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{formik.errors.actualAmount && formik.touched.actualAmount && formik.errors.actualAmount}</span>
                                        </div>
                                    )}
                                    <span className='text-danger d-flex text-left'>{formik.errors.wasteType && formik.touched.wasteType && formik.errors.wasteType}</span>
                                </div>
                            </div>
                            <div className="mt-4">
                                <button className="btn btn-primary mr-2" type="submit" >
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>


                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />

            </div>
        </>
    );
}

export default WasteConfiguration;