import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import Swal from 'sweetalert2'
import { blockInvalidChar } from '../../helpers/commonFunctions';
import { Dropdown } from 'semantic-ui-react';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { countries } from 'countries-list';
import { UpdateCompanyProfile, GetCompanyProfileDetail } from '../../services/company_panel/companyService';
import toHtml from 'html-react-parser';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactCountryFlag from 'react-country-flag';
import { SelectPicker } from 'rsuite';
import { IMG_MAX_SIZE } from '../../utils/Constants';


const EditCompanyProfile = () => {
    const navigate = useNavigate()
    const [showDefault, setShowDefault] = useState({})
    const [previewImage, setPreviewImage] = useState("")
    const [profileid, setProfileId] = useState()
    const [updateData, setUpdateData] = useState('false')
    const [updateHeader, setupdateHeader] = useState('1')
    const [countrylist, setCoutryList] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const breadcrumbs = [{ title: "Company Profile", url: "/company/company-profile" }, { title: "Edit Company Profile", url: "" }]
    const [selectedCountryCode, setSelectedCountryCode] = useState([]);
    const [year, setYear] = useState([])





    useEffect(() => {
        const id = JSON.parse(localStorage.getItem("company_detail"))
        setProfileId(id.id)
        GetCompanyProfileDetail(id.id).then(data => {
            // localStorage.setItem("user", JSON.stringify(data.data.response));
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            setPreviewImage(data && data.data && data.data.response && data.data.response.logo ? data.data.response.logo : "");
        }).catch(error => {
            console.log("error ====> ", error);
        })


        const options = Object.keys(countries).map(countryCode => ({
            label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReactCountryFlag
                        countryCode={countryCode}
                        style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }}
                        aria-label={countryCode}
                    />
                    {` + ${countries[countryCode].phone}`}
                </div>
            ),
            value: countries[countryCode].phone
        }));
        setSelectedCountryCode(options);

        var currentYear = new Date().getFullYear();
        let yearArray = [];
        for (var i = 1800; i <= currentYear; i++) {
            yearArray.push(i);
        }

        const years = yearArray.map(countryCode => ({
            key: countryCode,
            text: countryCode,
            value: countryCode,
        }));

        setYear(years)

    }, [updateData])


    return (
        <>
            <div className='page-header-left'>
                <h4>Company Profile</h4>
                <Breadcrums data={breadcrumbs} />
            </div>
            <Formik
                enableReinitialize
                initialValues={{
                    name: showDefault && showDefault.name ? showDefault.name : "",
                    logo: showDefault && showDefault.logo ? showDefault.logo : "",
                    country_code: showDefault && showDefault.country_code ? [Number(showDefault.country_code)] : "",
                    short_description: showDefault && showDefault.short_description ? showDefault.short_description : "",
                    established_year: showDefault && showDefault.established_year ? showDefault.established_year : "",
                    official_address: showDefault && showDefault.official_address ? showDefault.official_address : "",
                    official_email: showDefault && showDefault.official_email ? showDefault.official_email : "",
                    long_description: showDefault && showDefault.long_description ? showDefault.long_description : "",
                    official_phone_number: showDefault && showDefault.official_phone_number ? showDefault.official_phone_number : "",
                    licence_number: showDefault && showDefault.licence_number ? showDefault.licence_number : "",
                    vat_number: showDefault && showDefault.vat_number ? showDefault.vat_number : "",
                    company_id: showDefault && showDefault.company_id ? showDefault.company_id : "",
                }}
                validate={values => {
                    const error = {};

                    if (!values.logo || values.logo == undefined) {
                        error.logo = 'Image is required';
                        $("#errortext").text("Image is required");
                    
                    } else if (values.logo.size >= IMG_MAX_SIZE.value) {
                        error.logo = `Image size exceeds the maximum limit of ${IMG_MAX_SIZE.display_name}`;
                        $("#errortext").text(`Image size exceeds the maximum limit of ${IMG_MAX_SIZE.display_name}`);
                    } else {
                        $("#errortext").text(""); // Clear error if all validations pass
                    }

                    if (!values.name) {
                        error.name = "Name is required";
                    } else if (values.name.trim().length < 3 || values.name.trim().length > 100) {
                        error.name = "Name should be between 3 and 100 characters";
                    } else if (/^\s*$/.test(values.name)) {
                        error.name = "Name should not be just spaces";
                    }
                    if (!values.country_code) error.country_code = "Country code is required";
                    if (!values.official_email) {
                        error.official_email = "Email is required";
                    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.official_email)) {
                        error.official_email = 'Invalid email address';
                    } else if (/[?()=&]/.test(values.official_email)) {
                        error.official_email = 'Invalid email address';
                    }

                    if (!values.vat_number) {
                        error.vat_number = "VAT number is required"
                    } else if (!/^\d{15}$/.test(values.vat_number)) {
                        error.vat_number = 'Please enter a valid 15-digits VAT number';
                    }

                    if (!values.official_phone_number) {
                        error.official_phone_number = "Phone number is required";
                    } else if (!/^\d{5,15}$/.test(values.official_phone_number)) {
                        error.official_phone_number = 'Please enter a valid phone number between 5 and 15 digits';
                    }
                    else if (/^0+$/.test(values.official_phone_number)) {
                        error.official_phone_number = "Phone number cannot be all zeros";
                    }
                    if (!values.licence_number) {
                        error.licence_number = "License number is required"
                    } else if (!/^\d{10}$/.test(values.licence_number)) {
                        error.licence_number = 'Please enter a valid 10-digits License number';
                    }
                    if (!values.official_address) {
                        error.official_address = "Business Address is required";
                    } else if (values.official_address.trim().length < 10 || values.official_address.trim().length > 200) {
                        error.official_address = "Business Address should be between 10 and 200 characters";
                    } else if (/^\s*$/.test(values.official_address)) {
                        error.official_address = "Business Address should not be just spaces";
                    }

                    if (!values.short_description) {
                        error.short_description = "Short description is required";
                    } else if (values.short_description.trim().length < 20) {
                        error.short_description = "Short description must be at least 20 characters";
                    } else if (values.short_description.trim().length > 500) {
                        error.short_description = "Short description cannot exceed 500 characters";
                    }
                    if (!values.long_description) {
                        error.long_description = "Long description is required";
                    } else if (values.long_description.trim().length < 20) {
                        error.long_description = "Long description must be at least 20 characters";
                    } else if (values.long_description.trim().length > 500) {
                        error.long_description = "Long description cannot exceed 500 characters";
                    }
                    return error;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitted(true)
                    let formData = new FormData();
                    formData.append('id', profileid);
                    formData.append('name', values.name);
                    formData.append('logo', values.logo);
                    formData.append('short_description', values.short_description);
                    formData.append('established_year', values.established_year);
                    formData.append('country_code', values.country_code);
                    formData.append('official_address', values.official_address);
                    formData.append('official_email', values.official_email);
                    formData.append('long_description', values.long_description);
                    formData.append('official_phone_number', values.official_phone_number);
                    formData.append('licence_number', values.licence_number);
                    formData.append('vat_number', values.vat_number);
                    formData.append('company_id', values.company_id);

                    UpdateCompanyProfile(formData).then(response => {
                        setSubmitted(false);
                        if (response.data.status === 1 || response.data.status === "1") {
                            Swal.fire({
                                customClass: 'swal-wide',
                                position: 'top-right',
                                icon: 'success',
                                title: "Company profile updated successfully",
                                showConfirmButton: false,
                                timer: 1500,
                                toast: true
                            });
                            resetForm({ values: '' });
                            setUpdateData(!updateData)
                        } else {
                            Swal.fire({
                                customClass: 'swal-wide',
                                position: 'top-right',
                                icon: 'error',
                                title: response.data.error[0].msg,
                                showConfirmButton: false,
                                timer: 5000,
                                toast: true
                            })
                        }
                    }).catch(error => {
                        setSubmitted(false)
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    {console.log("form Error", values.country_code)}
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="main-content-body tab-pane px-4 border-top-0 active" id="edit">
                                    {/* <div className="profile-tab tab-menu-heading mt-5">
                                            <nav className="nav main-nav-line p-3 mb-3 tabs-menu profile-nav-line bg-gray-100">
                                                <span className="nav-link pl-2" data-toggle="tab" href="#edit">Company Profile</span>
                                            </nav>
                                        </div> */}
                                    <div className="my-4">
                                        <div className='row'>
                                            <div className='col-lg-12 text-center form-group mb-3'>
                                                {/* <div className='form-group'>
                                                        <input className='form-control imgInput' id="logo" name="logo" type="file" onChange={(event) => {
                                                            setFieldValue("logo", event.currentTarget.files[0] || "");
                                                            { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                                                        }} />
                                                        <span className='text-danger d-flex text-left' id='imagefileerror'>{errors.logo && touched.logo && errors.logo}</span>
                                                        <div className='d-flex justify-content-start mt-3'>
                                                            <div className={previewImage ? "" : "d-none"}>
                                                                <img src={previewImage} style={{ height: "100px" }} />
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                <div className='upload-logo d-flex align-items-center gap-3 mb-3'>
                                                    <div class="avatar avatar-xl">
                                                        {previewImage ?
                                                            <img alt="avatar" src={previewImage} className={previewImage ? "" : "d-none"} />
                                                            :
                                                            <img alt="avatar" src="https://pointofsalesapi.devtechnosys.tech/dummy_images/dummy_company_image.png" />
                                                        }
                                                    </div>
                                                    <div className='btn btn-black btn-file-type'>Upload logo <input id="logo" name="logo" accept="image/*" type="file" onChange={(event) => {
                                                        setFieldValue("logo", event.currentTarget.files[0] || "");
                                                        { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                                                    }}
                                                    /></div>
                                                </div>
                                                <span className='text-danger d-flex text-left' id='errortext'>
                                                    {errors.logo && touched.logo && errors.logo}
                                                </span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='company_id' className='text-left d-flex'>Company ID </label>
                                                <input name='company_id' id="company_id" type='text' onChange={handleChange} onBlur={handleBlur} value={values.company_id} className='form-control' placeholder='Enter First name' disabled />
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='name' className='text-left d-flex'>Business name <span className="requirestar">*</span></label>
                                                <input name='name' id="name" type='text' onChange={handleChange} onBlur={handleBlur} value={values.name} className='form-control' placeholder='Enter business name' />
                                                <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='official_email' className='text-left d-flex'>Email ID</label>
                                                <input name='official_email' id="official_email" type='text' onChange={handleChange} onBlur={handleBlur} value={values.official_email} className='form-control' placeholder='Enter email Id' />
                                                <span className='text-danger d-flex text-left'>{errors.official_email && touched.official_email && errors.official_email}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='name' className='text-left d-flex'>Phone number <span className="requirestar">*</span></label>
                                                <div className="input-group">
                                                    <span className=''>
                                                        <SelectPicker
                                                            style={{ border: 'none', paddingLeft: '0', paddingRight: '0' }}
                                                            data={selectedCountryCode}
                                                            name='country_code'
                                                            className='form-control'
                                                            id='country_code'
                                                            onChange={(value) => setFieldValue("country_code", value)}
                                                            onBlur={handleBlur}
                                                            value={values.country_code}
                                                            placeholder="Code"
                                                        />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        name='official_phone_number'
                                                        id="official_phone_number"
                                                        className="form-control"
                                                        placeholder="Enter phone number"
                                                        value={values.official_phone_number}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onKeyDown={blockInvalidChar}
                                                    />
                                                </div>
                                                <span className='text-danger d-flex text-left'>{errors.official_phone_number && touched.official_phone_number && errors.official_phone_number}</span>
                                                <span className='text-danger d-flex text-left'>{errors.country_code && touched.country_code && errors.country_code}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='short_description' className='text-left d-flex'>Short description <span className="requirestar">*</span></label>
                                                <textarea name='short_description' id="short_description" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description} className='form-control' placeholder='Enter short description' />
                                                <span className='text-danger d-flex text-left'>{errors.short_description && touched.short_description && errors.short_description}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='long_description' className='text-left d-flex'>Long description <span className="requirestar">*</span></label>
                                                <textarea name='long_description' id="long_description" type='text' onChange={handleChange} onBlur={handleBlur} value={values.long_description} className='form-control' placeholder='Enter long description' />
                                                <span className='text-danger d-flex text-left'>{errors.long_description && touched.long_description && errors.long_description}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='name' className='text-left d-flex'>Established year <span className="requirestar">*</span></label>
                                                <Dropdown
                                                    className='form-control'
                                                    id="established_year"
                                                    placeholder='Select established year'
                                                    search
                                                    fluid
                                                    selection
                                                    options={year}
                                                    value={values.established_year}
                                                    onChange={(event, data) => { setFieldValue("established_year", data.value) }}
                                                />
                                                <span className='text-danger d-flex text-left'>{errors.established_year && touched.established_year && errors.established_year}</span>
                                            </div>
                                            {/* <div className='col-lg-12 text-center form-group'>
                                                    <label htmlFor='long_description' className='text-left d-flex'>Long description :<span className="requirestar">*</span></label>
                                                    {
                                                        Boolean(showDefault) ?
                                                            <>
                                                                <CKEditor
                                                                    editor={ ClassicEditor }
                                                                    // config={ editorConfiguration }
                                                                    data={showDefault.long_description}
                                                                    onReady={ editor => {
                                                                        console.log( 'Editor is ready to use!', editor );
                                                                    } }
                                                                    onChange={ ( event, editor ) => {
                                                                        setFieldValue("long_description", editor.getData());
                                                                    } }
                                                                    onBlur={ ( event, editor ) => {
                                                                        console.log( 'Blur.', editor );
                                                                    } }
                                                                    onFocus={ ( event, editor ) => {
                                                                        console.log( 'Focus.', editor );
                                                                    } }
                                                                />
                                                            </>
                                                            : ""
                                                    }
                                                    <span className='text-danger d-flex text-left'>{errors.long_description && touched.long_description && errors.long_description}</span>
                                                </div> */}

                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='licence_number' className='text-left d-flex'>License number <span className="requirestar">*</span></label>
                                                <input name='licence_number' id="licence_number" type='text' onChange={handleChange} onBlur={handleBlur} value={values.licence_number} className='form-control' placeholder='Enter license number' />
                                                <span className='text-danger d-flex text-left'>{errors.licence_number && touched.licence_number && errors.licence_number}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='official_address' className='text-left d-flex'>Business address <span className="requirestar">*</span></label>
                                                <input name='official_address' id="official_address" type='text' onChange={handleChange} onBlur={handleBlur} value={values.official_address} className='form-control' placeholder='Enter business address' />
                                                <span className='text-danger d-flex text-left'>{errors.official_address && touched.official_address && errors.official_address}</span>
                                            </div>
                                            {/* <div className='col-lg-6 text-center form-group mb-3'>
                                                    <label htmlFor='city' className='text-left d-flex'>City <span className="requirestar">*</span></label>
                                                    <input name='city' id="city" type='text' onChange={handleChange} onBlur={handleBlur} value={values.city}  className='form-control' placeholder='Enter city' />
                                                    <span className='text-danger d-flex text-left'>{errors.city && touched.city && errors.city}</span>
                                                </div>
                                                <div className='col-lg-6 text-center form-group mb-3'>
                                                    <label htmlFor='zip_code' className='text-left d-flex'>Zip code <span className="requirestar">*</span></label>
                                                    <input name='zip_code' id="zip_code" type='text' onChange={handleChange} onBlur={handleBlur} value={values.zip_code}  className='form-control' placeholder='Enter Zip Code' />
                                                    <span className='text-danger d-flex text-left'>{errors.zip_code && touched.zip_code && errors.zip_code}</span>
                                                </div> */}
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='vat_number' className='text-left d-flex'>Company VAT number <span className="requirestar">*</span></label>
                                                <input name='vat_number' id="vat_number" type='text' onChange={handleChange} onBlur={handleBlur} value={values.vat_number} className='form-control' placeholder='Enter company VAT number' />
                                                <span className='text-danger d-flex text-left'>{errors.vat_number && touched.vat_number && errors.vat_number}</span>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <button className="btn btn-dark mr-2" type="submit" disabled={submitted ? true : null}>
                                                Update Profile
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
        </>
    )
}

export default EditCompanyProfile