import React, { useEffect, useMemo, useState } from 'react'
import { Formik, Field } from 'formik'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CreateCategory, GetAllParentCategories, GetParentCategories, GetSingleCategory, editCategory } from '../../services/company_panel/categoryService'
import Swal from "sweetalert2"
import Breadcrums from '../../components/company_panel/common/breadcrumbs'
import Loader from '../../components/company_panel/common/loader'
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { capitalizeAllFirstLetters, getBrandId, getCompany } from '../../helpers/commonFunctions';
import { globalLoader } from '../../helpers/global'

const SubCategoryEdit = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [searchParams] = useSearchParams();
    const [showDefault, setShowDefault] = useState({})
    const [submitted, setSubmitted] = useState(false);
    const [parentCategories, setParentCategories] = useState([]);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentBrand] = useState(getBrandId());

    const breadcrumbs = [
        {
            title: "Sub Categories",
            url: "/company/raw_materials/sub_category/list/1"
        },
        {
            title: "Edit Sub Category",
            url: ""
        },
    ]

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        if (currentBrand != getBrandId()) {
            navigate(`/company/raw_materials/sub_category/list/${1}`);
        }
    });

    useEffect(() => {
        GetSingleCategory({ id: params.id }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    useEffect(() => {
        const formData = new FormData()
        formData.append('brand_id', getBrandId());
        GetParentCategories(formData).then(data => {
            setParentCategories(data && data.data && data.data.response ? data.data.response.rows : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [isBrandChanged])

    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                </div>
            </div>
            <Formik
                enableReinitialize
                initialValues={{
                    name: showDefault && showDefault.name ? showDefault.name : "",
                    parent_id: showDefault && showDefault.parent_id ? showDefault.parent_id : "",
                }}

                validate={values => {
                    const error = {};
                    if (!values.name.trim()) {
                        error.name = 'Sub category name is required';
                    }
                    // Check if the name length exceeds 100 characters
                    else if (values.name.length > 100) {
                        error.name = 'Sub category cannot be longer than 100 characters';
                    }
                    if (!values.parent_id) error.parent_id = "Please select category"
                    return error;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    globalLoader(true);
                    setSubmitted(true)
                    let formData = new FormData();
                    formData.append("id", params.id)
                    formData.append('name', values.name);
                    formData.append('parent_id', values.parent_id);
                    formData.append('category_type', 'subCategory');
                    formData.append("brand_id", getBrandId());
                    formData.append("company_id", getCompany().company_id);
                    if (!searchParams.get('copy')) {
                        editCategory(formData).then(response => {
                            setSubmitting(false);
                            setSubmitted(false);
                            globalLoader(false);
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate(`/company/raw_materials/sub_category/list/${params.pgno}`)
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                })
                                setSubmitted(false)
                            }
                        }).catch(error => {
                            globalLoader(false);
                            console.log("error ====> ", error);
                        })
                    } else {
                        CreateCategory(formData).then(response => {
                            setSubmitting(false);
                            setSubmitted(false);
                            globalLoader(false);
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate(`/company/raw_materials/sub_category/list/${1}`)
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                })
                                setSubmitted(false)
                            }
                        }).catch(error => {
                            globalLoader(false);
                            console.log("error ====> ", error);
                        })
                    }
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="card custom-card">
                            <div className="card-body">
                                {showDefault && Object.keys(showDefault).length > 0 ?
                                    <div className="row row-sm">
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='parent_id' className='text-left d-flex'>Category<span className="requirestar">*</span></label>
                                            <select id="parent_id" className='form-control' name='parent_id' onChange={handleChange} onBlur={handleBlur} value={values.parent_id}>
                                                <option value="">Select category</option>
                                                {parentCategories.length ? parentCategories.map((row) => (
                                                    <option value={row.id}>{capitalizeAllFirstLetters(row.name)}</option>
                                                )) : ''}
                                            </select>
                                            <span className='text-danger d-flex text-left'>{errors.parent_id && touched.name && errors.parent_id}</span>
                                        </div>

                                        <div className='col-lg-6'></div>

                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='name' className='text-left d-flex'>Sub Category<span className="requirestar">*</span></label>
                                            <input name='name' id="name" type='text' onChange={handleChange} onBlur={handleBlur} value={values.name} placeholder='Enter sub category' className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                                        </div>

                                        <div className="mt-2">
                                            <button className="btn btn-primary mr-2" type="submit" disabled={submitted ? true : null}>
                                                Save
                                            </button>
                                        </div>
                                    </div> : <Loader />}
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>

    )
}

export default SubCategoryEdit