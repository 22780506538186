import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { GetModulesAndPermissions, EditRole, GetRoleDetail } from '../../../services/company_panel/platform_user/platformUserService';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import { getCompany } from '../../../helpers/commonFunctions';
import * as yup from 'yup';
import { ToastContainer } from 'react-toastify';

const RoleEdit = () => {
  const breadcrumbs = [
    { title: "Platform User", url: "/company/business/platform-User/outlet-user/list/1" },
    { title: "Roles", url: "/company/business/platform-User/role/list/1" },
    { title: "Edit Role", url: "" },
  ];

  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const params = useParams();
  const [moduleList, setModuleList] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);
  const [showDefault, setShowDefault] = useState({});
  const [role, setRole] = useState([]);
  const [cashierModuleList, setCashierModuleList] = useState([]);
  const [cashierRole, setCashierRole] = useState([]);

  const [checked, setChecked] = useState([true, true, true, true, true, true, true, true]);

  useEffect(() => {
    GetRoleDetail(params.id)
      .then((data) => {
        const dataOutlet = data?.data?.response || [];
        setShowDefault(dataOutlet);

        if (dataOutlet) {
          GetModulesAndPermissions()
            .then((data) => {
              const moduleData = data?.data?.response?.modules || [];
              const permissionData = data?.data?.response?.permissions || [];
              setModuleList(moduleData);
              setPermissionsList(permissionData);

              const roleAll = moduleData.map((item) => {
                const data = {};
                permissionData.forEach((permission) => {
                  const permissions = dataOutlet.module_wise_permissions || [];
                  console.log('----------permissions', permissions);
                  const permissionsCheck = permissions.some(
                    (value) =>
                      value.module_id === item.id &&
                      value.permission_id === permission.id
                  );
                  data[permission.id] = permissionsCheck;

                  let tempChecked = checked;
                  tempChecked[permission.id - 1] = tempChecked[permission.id - 1] && permissionsCheck
                  setChecked(tempChecked);
                });
                console.log('---------data', data);
                return { module_name: item.name, module_id: item.id, data };
              });
              console.log('-----roleAll', roleAll);
              setRole(roleAll);

              let cashierModules = [];
              let cashierRoles = [];
              for (let i = 0; i <= 1; i++) {
                cashierModules.push(moduleData[i]);
                const data = {};
                permissionData.forEach((permission) => {
                  const permissions = dataOutlet.cashier_module_wise_permissions || [];
                  const permissionsCheck = permissions.some(
                    (value) =>
                      value.module_id === moduleData[i].id &&
                      value.permission_id === permission.id
                  );
                  data[permission.id] = permissionsCheck;

                  let tempChecked = checked;
                  tempChecked[4 + permission.id - 1] = tempChecked[4 + permission.id - 1] && permissionsCheck
                  setChecked(tempChecked);
                });
                cashierRoles.push({
                  module_name: moduleData[i].name,
                  module_id: moduleData[i].id,
                  data,
                });
              }
              setCashierModuleList(cashierModules);
              setCashierRole(cashierRoles);
            })
            .catch((error) => {
              console.log("error=====>", error);
            });
        }
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, []);

  // Helper function to check if any permissions are selected
  const hasSelectedPermissions = (permissions) => {
    return permissions.some((module) =>
      Object.values(module.data).some((value) => value === true)
    );
  };

  const selectAllModulesAndPermissions = (key, outletStorePermission, checkedIndex) => {
    if (outletStorePermission) {
      const roleAll = moduleList.map((item, index) => {
        return { module_name: item.name, module_id: item.id, data: { ...role[index].data, [key]: !checked[checkedIndex] } };
      });
      setRole(roleAll);
    } else {
      const cashierRoles = cashierModuleList.map((item, index) => {
        return { module_name: item.name, module_id: item.id, data: { ...cashierRole[index].data, [key]: !checked[checkedIndex] } };
      });
      setCashierRole(cashierRoles);
    }

    let tempChecked = checked;
    tempChecked[checkedIndex] = !tempChecked[checkedIndex];
    setChecked(tempChecked);
  }

  const handleRole = (moduleId, keyId, newValue, key) => {
    if (!newValue) {
      let tempChecked = checked;
      tempChecked[key] = false;
      setChecked(tempChecked);
    }
    setRole((prevRole) =>
      prevRole.map((module) =>
        module.module_id === moduleId
          ? {
            ...module,
            data: {
              ...module.data,
              [keyId]: newValue,
            },
          }
          : module
      )
    );

    // Set formik value to trigger validation update
    formik.setFieldValue('permissions', hasSelectedPermissions(role) || hasSelectedPermissions(cashierRole));
  };

  const handleCashierRole = (moduleId, keyId, newValue, key) => {
    if (!newValue) {
      let tempChecked = checked;
      tempChecked[4 + key] = false;
      setChecked(tempChecked);
    }
    setCashierRole((prevRole) =>
      prevRole.map((module) =>
        module.module_id === moduleId
          ? {
            ...module,
            data: {
              ...module.data,
              [keyId]: newValue,
            },
          }
          : module
      )
    );

    // Set formik value to trigger validation update
    formik.setFieldValue('permissions', hasSelectedPermissions(role) || hasSelectedPermissions(cashierRole));
  };

  // Define validation schema with Yup
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required('Name is required')
      .max(40, 'Name must be at most 40 characters'),
    name_ar: yup
      .string()
      .trim()
      .required('Name (Arabic) is required')
      .max(40, 'Name (Arabic) must be at most 40 characters'),
    permissions: yup.mixed().test(
      'permissions-selected',
      'Please select at least one permission from either Outlet Store User or Cashier.',
      function () {
        return hasSelectedPermissions(role) || hasSelectedPermissions(cashierRole);
      }
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: showDefault.name || "",
      name_ar: showDefault.name_ar || "",
      permissions: {}, // Field used to trigger Yup validation
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitted(true);

      if (!hasSelectedPermissions(role) && !hasSelectedPermissions(cashierRole)) {
        return; // Prevent form submission if no permissions are selected
      }

      let formData = new FormData();
      formData.append("id", params.id);
      formData.append("name", values.name.trim());
      formData.append("name_ar", values.name_ar.trim());
      formData.append("company_id", getCompany().company_id);
      formData.append("rolePermissions", JSON.stringify(role));
      formData.append("cashierRolePermissions", JSON.stringify(cashierRole));

      EditRole(formData)
        .then((response) => {
          if (response.data.status === 1 || response.data.status === "1") {
            Swal.fire({
              customClass: "swal-wide",
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
              toast: true,
              position: "top-right",
            });
            setTimeout(() => {
              navigate(`/company/business/platform-User/role/list/${1}`);
            }, 1000);
          } else {
            Swal.fire({
              customClass: "swal-wide",
              icon: "error",
              title: response.data.message,
              showConfirmButton: false,
              timer: 5000,
              toast: true,
              position: "top-right",
            });
            setSubmitted(false);
          }
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    },
  });

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <form onSubmit={formik.handleSubmit}>
        <div className="row row-sm">
          <div className="col-lg-12 col-md-12 animation_fade">
            <div className="card custom-card">
              <div className="card-body">
                <div>
                  <h2 className="main-content-label mb-3">Edit Role</h2>
                </div>
                <div className="row row-sm">
                  <div className="col-lg-6 text-center form-group mt-3">
                    <label htmlFor="name" className="text-left d-flex">
                      Name <span className="requirestar">*</span>
                    </label>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      placeholder="Enter name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      className="form-control"
                    />
                    <span className="text-danger d-flex text-left">
                      {formik.errors.name && formik.touched.name && formik.errors.name}
                    </span>
                  </div>
                  <div className="col-lg-6 text-center form-group mt-3">
                    <label htmlFor="name_ar" className="text-left d-flex">
                      Name (Arabic) <span className="requirestar">*</span>
                    </label>
                    <input
                      name="name_ar"
                      id="name_ar"
                      type="text"
                      placeholder="Enter name (Arabic)"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name_ar}
                      className="form-control"
                    />
                    <span className="text-danger d-flex text-left">
                      {formik.errors.name_ar && formik.touched.name_ar && formik.errors.name_ar}
                    </span>
                  </div>
                  <div className="col-lg-6 text-center form-group mt-3">
                    <label htmlFor="permissions" className="text-left d-flex">
                      <b>Outlet Store User Permissions</b>
                      <span className="requirestar">*</span>
                    </label>
                    <div className="user_permission mt-3">
                      {/* <div className='row mb-3'>
                        <div className='col-5'>Select all</div>
                        <div className='col-7'>
                          <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(1, true, 0) }} checked={checked[0] === true ? true : false} />
                          <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(2, true, 1) }} checked={checked[1] === true ? true : false} />
                          <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(3, true, 2) }} checked={checked[2] === true ? true : false} />
                          <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(4, true, 3) }} checked={checked[3] === true ? true : false} />
                        </div>
                      </div> */}

                      <div className='row mb-3'>
                        <div className='col-5'>
                          <p><b>Select all</b></p>
                        </div>
                        <div className='col-7'>
                          <ul className='row'>
                            {permissionsList.length ? permissionsList.map((value, key) => (
                              <li key={key}>
                                <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(value?.id, true, value?.id - 1) }} checked={checked[value?.id - 1] === true ? true : false} />
                                <label className="form-check-label">{value.action}</label>
                              </li>
                            )) : ''}
                          </ul>
                        </div>
                      </div>

                      {moduleList.length
                        ? moduleList.map((row, i) => (
                          <div className="row" key={i}>
                            <div className="col-5">
                              <p>
                                <b>{row.name}</b>
                              </p>
                            </div>
                            <div className="col-7">
                              <ul className="row">
                                {permissionsList.length
                                  ? permissionsList.map((value, key) => (
                                    <li key={key}>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="permissions"
                                        id={value.action}
                                        onBlur={formik.handleBlur}
                                        onChange={(e) => {
                                          handleRole(row.id, value.id, e.target.checked, key);
                                          console.log("hasSelectedPermissions(role)", hasSelectedPermissions(role))
                                          if (hasSelectedPermissions(role)) {

                                          }
                                        }
                                        }
                                        checked={
                                          role[i]?.data[value.id] === true ? true : false
                                        }
                                      />
                                      <label className="form-check-label"></label>
                                      {value.action}
                                    </li>
                                  ))
                                  : ''}
                              </ul>
                            </div>
                          </div>
                        ))
                        : ''}
                    </div>
                  </div>
                  <div className="col-lg-6 text-center form-group mt-3">
                    <label htmlFor="permissions" className="text-left d-flex">
                      <b>Cashier Permissions</b>
                      <span className="requirestar">*</span>
                    </label>
                    <div className="user_permission mt-3">
                      {/* <div className='row mb-3'>
                    <div className='col-5'>Select all</div>
                        <div className='col-7'>
                          <input className="form-check-input" type="checkbox" onChange={()=> {selectAllModulesAndPermissions(1, false, 4)}} checked={ checked[4] === true ? true : false }/>
                          <input className="form-check-input" type="checkbox" onChange={()=> {selectAllModulesAndPermissions(2, false, 5)}} checked={ checked[5] === true ? true : false }/>
                          <input className="form-check-input" type="checkbox" onChange={()=> {selectAllModulesAndPermissions(3, false, 6)}} checked={ checked[6] === true ? true : false }/>
                          <input className="form-check-input" type="checkbox" onChange={()=> {selectAllModulesAndPermissions(4, false, 7)}} checked={ checked[7] === true ? true : false }/>
                        </div>
                    </div> */}

                      <div className='row mb-3'>
                        <div className='col-5'>
                          <p><b>Select all</b></p>
                        </div>
                        <div className='col-7'>
                          <ul className='row'>
                            {permissionsList.length ? permissionsList.map((value, key) => (
                              <li key={key}>
                                <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(value?.id, false, (permissionsList?.length + value?.id) - 1) }} checked={checked[permissionsList?.length + value?.id - 1] === true ? true : false} />
                                <label className="form-check-label">{value.action}</label>
                              </li>
                            )) : ''}
                          </ul>
                        </div>
                      </div>

                      {cashierModuleList.length
                        ? cashierModuleList.map((row, i) => (
                          <div className="row" key={i}>
                            <div className="col-5">
                              <p>
                                <b>{row.name}</b>
                              </p>
                            </div>
                            <div className="col-7">
                              <ul className="row">
                                {permissionsList.length
                                  ? permissionsList.map((value, key) => (
                                    <li key={key}>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="shift"
                                        id={value.action}
                                        onChange={(e) =>
                                          handleCashierRole(row.id, value.id, e.target.checked, key)
                                        }
                                        checked={
                                          cashierRole[i]?.data[value.id] === true ? true : false
                                        }
                                      />
                                      <label className="form-check-label"></label>
                                      {value.action}
                                    </li>
                                  ))
                                  : ''}
                              </ul>
                            </div>
                          </div>
                        ))
                        : ''}
                    </div>
                  </div>
                </div>
                {/* Validation Message for Permissions */}
                {formik.touched.permissions && (!hasSelectedPermissions(role) && !hasSelectedPermissions(cashierRole)) && (
                  <p className="text-danger text-left mt-2">
                    Please select at least one permission from either Outlet Store User or Cashier.
                  </p>
                )}
                <div className="mt-2">
                  <button
                    className="btn btn-primary mr-2"
                    type="submit" // Mark as touched when button clicked
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={true | 1000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
    </>
  );
};

export default RoleEdit;
