import axiosInstance from "../../utils/axiosCompany";

export const ListWaste = async (values) => {
    return await axiosInstance().post("company/waste-management/list-waste", values);
}
export const WasteAdd = async (values) => {
    return await axiosInstance().post("company/waste-management/add-waste", values);
}
export const WasteDetails  = async (values) => {
    return await axiosInstance().post("company/waste-management/get-waste", values);
}
export const DeleteWaste = async (values) => {
    return await axiosInstance().post("company/waste-management/delete-waste", values);
}
export const UpdateWaste = async (values) => {
    return await axiosInstance().post("company/waste-management/update-waste", values);
}
export const ChangeStatusWaste = async (values) => {
    return await axiosInstance().post("company/waste-management/change-status-waste", values)
}