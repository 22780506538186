import axiosInstance from "../../utils/axiosCompany";

export const CreateCategory = async (values) => {
    return await axiosInstance().post("company/menu-category/add-category", values);
}

export const GetCategoryList = async (values) => {
    return await axiosInstance().post("company/menu-category/list-categories", values);
}

export const GetAllParentCategories = async (values) => {
    return await axiosInstance().post("company/menu-category/get-all-parent-categories", values);
}

export const GetAllChildCategories = async (values) => {
    return await axiosInstance().post("company/menu-category/get-all-child-categories", values);
}

export const GetSingleCategory = async (values) => {
    return await axiosInstance().post("company/menu-category/get-single-category", values);
}

export const editCategory = async (values) => {
    return await axiosInstance().post("company/menu-category/update-category", values)
}

export const ChangeCategoryStatus = async (values) => {
    return await axiosInstance().post("company/menu-category/change-category-status", values)
}

export const DeleteCategory = async (values) => {
    return await axiosInstance().post("company/menu-category/delete-category", values);
}

export const getParentCategories = async (values) => {
    return await axiosInstance().post("company/menu-category/get-parent-categories", values);
}