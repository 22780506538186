import axiosInstanceCashier from "../../utils/axiosCashier";
import axiosInstance from "../../utils/axiosCompany";


export const GetMenuItemsOrder = async (values) => {
    return await axiosInstance().post("company/menu-item/menu-items-list", values);
}

export const GetSingleOrderDetails = async (values) => {
    return await axiosInstance().post("company/order/get-single-order", values);
}

export const GetMealCombosOrder = async (values) => {
    return await axiosInstanceCashier().post("cashier/order/combos", values);
}
export const CreateOrders = async (values) => {
    return await axiosInstance().post("company/order/create-order", values);
}

export const GetOrdersList = async (values) => {
    return await axiosInstance().post("company/order/list-orders", values);
}

export const RefundStatus = async (values) => {
    return await axiosInstance().post("company/order/change-payment-status", values);
}

export const CouponValidator = async (values) => {
    return await axiosInstanceCashier().post(`cashier/order/validate-coupon`, values);
};

// export const GetEditSupplier = async (values) => {
//     return await axiosInstance().post("company/supplier/update-supplier", values);
// }

// export const changeStatus = async (values) => {
//     return await axiosInstance().post("company/supplier/change-supplier-status", values);
// }

// export const deleteSupplier = async (values) => {
//     return await axiosInstance().post("company/supplier/delete-supplier", values);
// }

// export const updateSupplierInventoryRelation = async (values) => {
//     return await axiosInstance().post("company/supplier/update-supplier-inventory-relations", values);
// }

// export const getInventoriesFromSupplier = async (values) => {
//     return await axiosInstance().post("company/supplier/get-inventories-from-supplier", values);
// }