import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { json, useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { globalLoader } from '../../helpers/global';
import Swal from 'sweetalert2';
import { blockInvalidChar, getBrandId, getCompany, getOutletId, showPrice } from '../../helpers/commonFunctions';
import { AddCoupon } from '../../services/company_panel/coupons.service';
import { CreateOrders } from '../../services/company_panel/orderService';
import { SelectPicker } from 'rsuite';
import ReactCountryFlag from 'react-country-flag';
import { countries } from 'countries-list';
import * as yup from 'yup';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';

export const ContinueOrderAdd = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [orderData, setOrderData] = useState(location.state);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [selectedCountryCode, setSelectedCountryCode] = useState([]);

    useEffect(() => {
        // Transform countries into the format required by SelectPicker
        const options = Object.keys(countries).map(countryCode => ({
            label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReactCountryFlag
                        countryCode={countryCode}
                        style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }}
                        aria-label={countryCode}
                    />
                    {` + ${countries[countryCode].phone}`}
                </div>
            ),
            value: countryCode
        }));
        setSelectedCountryCode(options);
    }, [countries]);
    useEffect(() => {
        if (orderData?.items?.length) {
            const items = orderData.items.map(item => ({
                id: item?.id,
                name: item?.item_name,
                price: item?.default_price,
                quantity: item?.quantity,
                image: item?.logo,
                type: item?.type
            }));

            formik.setFieldValue('orderItems', items);
            formik.setFieldValue('subTotal', orderData.pricing?.subtotal);
            formik.setFieldValue('taxes', orderData.pricing.taxes || 0);
            formik.setFieldValue('discount', orderData.pricing.discount || 0);
            formik.setFieldValue('surcharge', orderData.pricing.surcharge || 0);
            formik.setFieldValue('total', orderData.pricing?.total);
            formik.setFieldValue('orderModeId', orderData.order_id);
            formik.setFieldValue('coupon_id', orderData.coupon_id);
            formik.setFieldValue('customerInstructions', orderData.customer_note);
        } else {
            navigate(`/company/transaction/order/add`);
        }
    }, [orderData, navigate]);

    const validationSchema = yup.object().shape({
        customerName: yup
          .string()
          .trim()
          .required('Name is required')
          .max(30, 'Name cannot be longer than 30 characters'),
        
        customerPhone: yup
          .string()
          .trim()
          .required('Phone number is required')
          .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
      });
      
    const formik = useFormik({
        initialValues: {
            orderItems: [],
            subTotal: 0,
            taxes: 0,
            discount: 0,
            surcharge: 0,
            total: 0,
            paymentMethod: '',
            customerName: '',
            customerPhone: '',
            orderModeId: '',
            customerInstructions: '',
            meal_combos: [],
            country_code: '',
            coupon_id: null
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            let menuItemsId = values.orderItems.map(value => ({

                id: value.id,
                quantity: value.quantity,
                type: value.type
            }));
            let addOnsId = [];
            orderData?.items?.forEach(value => {
                value.menu_addons?.forEach(item => {
                    const index = addOnsId.findIndex(data => data.id === item.id);
                    if (index === -1) {
                        addOnsId.push({
                            id: item.id,
                            quantity: item.quantity,
                        });
                    } else {
                        addOnsId[index].quantity += item.quantity;
                    }
                });
            });

            let formData = new FormData();
            formData.append('sub_total_price', values.subTotal);
            formData.append('tax_total_price', values.taxes);
            formData.append('discount_price', values.discount);
            formData.append('surcharge_price', values.surcharge);
            formData.append('total_price', values.total);
            formData.append('customer_name', values.customerName);
            formData.append('customer_number', values.customerPhone);
            formData.append('menu_items_id', JSON.stringify(menuItemsId));
            formData.append('add_ons_id', JSON.stringify(addOnsId));
            formData.append('order_mode_id', values.orderModeId);
            formData.append('coupon_id', values.coupon_id);
            formData.append('customer_address', 'jaipur');
            formData.append('customer_instruction', values?.customerInstructions);
            formData.append('transaction_id', '12346');
            formData.append('payment_method', values.paymentMethod.toLowerCase());
            formData.append('company_id', getCompany().company_id);
            formData.append('brand_id', getBrandId());
            formData.append('outlet_id', getOutletId());
            CreateOrders(formData).then(response => {
                if (response.data.status === 1 || response.data.status === "1") {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true,
                        position: 'top-right',
                    });
                    globalLoader(false);
                    setTimeout(() => {
                        navigate(`/company/transaction/order/list/${1}`)
                    }, 1000);
                } else {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'error',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 5000,
                        toast: true,
                        position: 'top-right',
                    })
                    globalLoader(false);
                }
            }).catch(error => {
                console.log("error ====> ", error);
            })

        }
    });
    const handlePaymentMethodClick = (method) => {
        formik.setFieldValue('paymentMethod', method);
        if (method === 'Cash') {
            setShowModal(true);
        }
    };

    const handleNextClick = () => {
        setShowModal(false);
        formik.handleSubmit();
    };

    const breadcrumbs = [
        {
          title: "Orders ",
          url: "/company/transaction/order/list/1"
        },
        {
          title: "Create Order",
          url: ""
        },
      ]

      
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                <Breadcrums data={breadcrumbs} />
                    <h4>Orders</h4>
                    {/* <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/company/transaction/order/add">Loyalty Points</a>
                            </li>
                            <li className="breadcrumb-item">
                                <a className="active disableclick" href="/company/transaction/order/add">Create Order</a>
                            </li>
                        </ol>
                    </nav> */}
                </div>
                <div className="page-header-right ms-auto"></div>
            </div>

            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-5'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h4>Order Summary</h4>
                                    {/* Order Items List */}
                                    {formik.values.orderItems.map((item, index) => (
                                        <div key={item.id} className="order-item mb-3 d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <img src={item.image} alt={item.name} className="order-item-img me-3" />
                                                <div>
                                                    <p className="order-item-name" style={{ fontWeight: '500' }}>{item.name}</p>
                                                    <p className="order-item-price">{item.price} SAR</p>
                                                </div>
                                            </div>
                                            <span className="order-item-quantity" style={{ fontWeight: '500' }}>x{item.quantity}</span>
                                        </div>
                                    ))}

                                    {/* Order Summary Details */}
                                    <div className="order-summary-details mt-4">
                                        <div className="d-flex justify-content-between">
                                            <span>Sub-Total</span>
                                            <span>{formik.values.subTotal} SAR</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>Taxes</span>
                                            <span>{formik.values.taxes} SAR</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>Discount</span>
                                            <span>{formik.values.discount} SAR</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>Surcharge</span>
                                            <span>{formik.values.surcharge} SAR</span>
                                        </div>
                                        <div className="d-flex justify-content-between fw-bold mt-3">
                                            <span>Total</span>
                                            <span>{showPrice(formik.values.total)} SAR</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-7'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h4>Payment Method</h4>
                                    <div className="payment-method d-flex gap-3">
                                        {['Cash', 'Card', 'Credit', 'Online'].map((method, index) => (
                                            <button
                                                key={index}
                                                type="button"
                                                className={`btn btn-outline-primary ${formik.values.paymentMethod === method ? 'active' : ''}`}
                                                onClick={() => handlePaymentMethodClick(method)}
                                            >
                                                {method}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="d-flex justify-content-start mt-3">
                            <button type="submit" className="btn btn-primary">Place Order</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Customer Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>Name<span className="requirestar">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="customerName"
                            value={formik.values.customerName}
                            onChange={formik.handleChange}
                        />
                           <span className='text-danger d-flex text-left'>{formik.errors.customerName && formik.touched.customerName && formik.errors.customerName}</span>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor='name' className='text-left d-flex'>Phone number<span className="requirestar">*</span></label>
                        <div className="input-group">
                            {/* <span >
                                <SelectPicker
                                    style={{ border: 'none', paddingLeft: '0', paddingRight: '0', zIndex: 1 }}
                                    data={selectedCountryCode}
                                    name='country_code'
                                    className='form-control'
                                    id='country_code'
                                    onChange={(value) => formik.setFieldValue("country_code", value)}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.country_code}
                                    placeholder="Code"
                                />
                            </span> */}
                            <input
                                type="text"
                                name='customerPhone'
                                id="customerPhone"
                                className="form-control bordered"
                                style={{ border: '1px solid #e8e8e8' }}
                                placeholder="Enter phone number"
                                value={formik.values.customerPhone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                onKeyDown={blockInvalidChar}
                            />
                        </div>
                        <span className='text-danger d-flex text-left'>{formik.errors.customerPhone && formik.touched.customerPhone && formik.errors.customerPhone}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-outline-primary"
                        onClick={() => {
                            setShowModal(false);
                            formik.setFieldValue('customerName', '');
                            formik.setFieldValue('customerPhone', '');
                            formik.setFieldValue('country_code', '');
                        }}
                    >
                        Skip
                    </button>

                    <Button variant="primary" onClick={handleNextClick}  disabled={!(formik.isValid && formik.dirty)}>
                        Next
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Success Popup */}
            {
                showSuccessPopup && (
                    <Modal show={showSuccessPopup} onHide={() => setShowSuccessPopup(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Order Created Successfully</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Order has been created successfully. You can view it in Order History</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => setShowSuccessPopup(false)}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )
            }

        </form >
    );
};
