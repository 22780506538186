import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import { GetModulesAndPermissions, CreateRole } from '../../../services/company_panel/platform_user/platformUserService';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import { getCompany } from '../../../helpers/commonFunctions';
import * as yup from 'yup';
import { ToastContainer } from 'react-toastify';

const RoleAdd = () => {

  const breadcrumbs = [
    { title: "Platform User", url: "/company/business/platform-User/outlet-user/list/1" },
    { title: "Roles", url: "/company/business/platform-User/role/list/1" },
    { title: "Create role", url: "" },
  ];

  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [moduleList, setModuleList] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);
  const [role, setRole] = useState([]);
  const [cashierModuleList, setCashierModuleList] = useState([]);
  const [cashierRole, setCashierRole] = useState([]);

  const [checked, setChecked] = useState([false, false, false, false, false, false, false, false]);

  useEffect(() => {
    GetModulesAndPermissions().then(data => {
      const moduleData = data?.data?.response?.modules || [];
      const permissionData = data?.data?.response?.permissions || [];

      setModuleList(moduleData);
      setPermissionsList(permissionData);

      const roleAll = moduleData.map(item => ({
        module_name: item.name,
        module_id: item.id,
        data: permissionData.reduce((acc, permission) => ({ ...acc, [permission.id]: false }), {}),
      }));
      setRole(roleAll);

      const cashierModules = moduleData.slice(0, 2);
      const cashierRoles = cashierModules.map(item => ({
        module_name: item.name,
        module_id: item.id,
        data: permissionData.reduce((acc, permission) => ({ ...acc, [permission.id]: false }), {}),
      }));
      setCashierModuleList(cashierModules);
      setCashierRole(cashierRoles);
    }).catch(error => {
      console.log("error=====>", error)
    });
  }, []);

  const selectAllModulesAndPermissions = (key, outletStorePermission, checkedIndex) => {
    if (outletStorePermission) {
      const roleAll = moduleList.map((item, index) => {
        return { module_name: item.name, module_id: item.id, data: { ...role[index].data, [key]: !checked[checkedIndex] } };
      });
      setRole(roleAll);
    } else {
      const cashierRoles = cashierModuleList.map((item, index) => {
        return { module_name: item.name, module_id: item.id, data: { ...cashierRole[index].data, [key]: !checked[checkedIndex] } };
      });
      setCashierRole(cashierRoles);
    }

    let tempChecked = checked;
    tempChecked[checkedIndex] = !tempChecked[checkedIndex];
    setChecked(tempChecked);
  }

  const handleRole = (moduleId, keyId, newValue, key) => {
    if (!newValue) {
      let tempChecked = checked;
      tempChecked[key] = false;
      setChecked(tempChecked);
    }
    setRole(prevRole =>
      prevRole.map(module =>
        module.module_id === moduleId
          ? { ...module, data: { ...module.data, [keyId]: newValue } }
          : module
      )
    );
    formik.setFieldValue('role', 'updated'); // Trigger validation
  };

  const handleCashierRole = (moduleId, keyId, newValue, key) => {
    if (!newValue) {
      let tempChecked = checked;
      tempChecked[4 + key] = false;
      setChecked(tempChecked);
    }
    setCashierRole(prevRole =>
      prevRole.map(module =>
        module.module_id === moduleId
          ? { ...module, data: { ...module.data, [keyId]: newValue } }
          : module
      )
    );
    formik.setFieldValue('role', 'updated'); // Trigger validation
  };

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required('Name is required').max(40, 'Name must be 40 characters or less'),
    name_ar: yup.string().trim().required('Name (Arabic) is required').max(40, 'Name (Arabic) must be 40 characters or less'),
    role: yup.mixed().test('role-check', 'Please select at least one permission in either Outlet Store User Permission or Cashier Permissions.', function () {
      const isRoleSelected = role.some(module => Object.values(module.data).includes(true));
      const isCashierRoleSelected = cashierRole.some(module => Object.values(module.data).includes(true));
      return isRoleSelected || isCashierRoleSelected;
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      name_ar: "",
      role: "",
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setSubmitted(true);
      let formData = new FormData();
      formData.append('name', values.name.trim());
      formData.append('name_ar', values.name_ar.trim());
      formData.append('company_id', getCompany().company_id);
      formData.append('rolePermissions', JSON.stringify(role));
      formData.append('cashierRolePermissions', JSON.stringify(cashierRole));

      CreateRole(formData).then(response => {
        if (response.data.status === 1 || response.data.status === "1") {
          Swal.fire({
            customClass: 'swal-wide',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            position: 'top-right',
          });
          setTimeout(() => {
            navigate(`/company/business/platform-User/role/list/${1}`);
          }, 1000);
        } else {
          Swal.fire({
            customClass: 'swal-wide',
            icon: 'error',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
            position: 'top-right',
          });
          setSubmitted(false);
        }
      }).catch(error => {
        console.log("error ====> ", error);
      });
    }
  });
  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <form onSubmit={formik.handleSubmit}>
        <div className="row row-sm">
          <div className="col-lg-12 col-md-12 animation_fade">
            <div className="card custom-card">
              <div className="card-body">
                <div>
                  <h2 className="main-content-label mb-3">Create Role</h2>
                </div>
                <div className="row row-sm">
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='name' className='text-left d-flex'>Name<span className="requirestar">*</span></label>
                    <input name='name' id="name" type='text' onChange={formik.handleChange} placeholder='Enter name' onBlur={formik.handleBlur} value={formik.values.name} className='form-control' />
                    <span className='text-danger d-flex text-left'>{formik.errors.name && formik.touched.name && formik.errors.name}</span>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='name_ar' className='text-left d-flex'>Name (Arabic)<span className="requirestar">*</span></label>
                    <input name='name_ar' id="name_ar" type='text' placeholder='Enter name (Arabic)' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name_ar} className='form-control' />
                    <span className='text-danger d-flex text-left'>{formik.errors.name_ar && formik.touched.name_ar && formik.errors.name_ar}</span>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='role' className='text-left d-flex'><b>Outlet Store User Permissions</b><span className="requirestar">*</span></label>
                    <div className='user_permission mt-3'>

                      <div className='row mb-3'>
                        <div className='col-5'>
                          <p><b>Select all</b></p>
                        </div>
                        <div className='col-7'>
                          {/* <ul className='row'>
                            <li>
                              <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(1, true, 0) }} checked={checked[0] === true ? true : false} />
                            </li>
                            <li>
                              <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(2, true, 1) }} checked={checked[1] === true ? true : false} />
                            </li>
                            <li>
                              <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(3, true, 2) }} checked={checked[2] === true ? true : false} />
                            </li>
                            <li>
                              <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(4, true, 3) }} checked={checked[3] === true ? true : false} />
                            </li>
                          </ul> */}
                          <ul className='row'>
                              {permissionsList.length ? permissionsList.map((value, key) => (
                                <li key={key}>
                                  <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(value?.id, true, value?.id - 1) }} checked={checked[value?.id - 1] === true ? true : false} />
                                  <label className="form-check-label">{value.action}</label>
                                </li>
                              )) : ''}
                            </ul>
                        </div>
                      </div>

                      {moduleList.length ? moduleList.map((row, i) => (
                        <div className='row' key={i}>
                          <div className='col-5'>
                            <p><b>{row.name}</b></p>
                          </div>
                          <div className='col-7'>
                            <ul className='row'>
                              {permissionsList.length ? permissionsList.map((value, key) => (
                                <li key={key}>
                                  <input className="form-check-input" type="checkbox" name="shift" id={value.action}
                                    onChange={e => handleRole(row.id, value.id, e.target.checked, key)}
                                    checked={
                                      role[i]?.data[value.id] === true ? true : false
                                    }
                                  />
                                  <label className="form-check-label">{value.action}</label>
                                </li>
                              )) : ''}
                            </ul>
                          </div>
                        </div>
                      )) : ''}

                    </div>
                  </div>
                  <div className='col-lg-6 text-center form-group mt-3'>
                    <label htmlFor='cashierRole' className='text-left d-flex'><b>Cashier Permissions</b><span className="requirestar">*</span></label>
                    <div className='user_permission mt-3'>

                      {/* <div className='row mb-3'>
                        <div className='col-5'>Select all</div>
                        <div className='col-7'>
                          <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(1, false, 4) }} checked={checked[4] === true ? true : false} />
                          <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(2, false, 5) }} checked={checked[5] === true ? true : false} />
                          <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(3, false, 6) }} checked={checked[6] === true ? true : false} />
                          <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(4, false, 7) }} checked={checked[7] === true ? true : false} />
                        </div>
                      </div> */}
                       <div className='row mb-3'>
                        <div className='col-5'>
                          <p><b>Select all</b></p>
                        </div>
                        <div className='col-7'>
                          <ul className='row'>
                              {permissionsList.length ? permissionsList.map((value, key) => (
                                <li key={key}>
                                  {/* {console.log("permission list --->",value,(permissionsList?.length + value?.id) - 1)} */}
                                  <input className="form-check-input" type="checkbox" onChange={() => { selectAllModulesAndPermissions(value?.id, false, (permissionsList?.length + value?.id) - 1) }} checked={checked[permissionsList?.length + value?.id - 1] === true ? true : false} />
                                  <label className="form-check-label">{value.action}</label>
                                </li>
                              )) : ''}
                            </ul>
                        </div>
                      </div>

                      {cashierModuleList.length ? cashierModuleList.map((row, i) => (
                        <div className='row' key={i}>
                          <div className='col-5'>
                            <p><b>{row.name}</b></p>
                          </div>
                          <div className='col-7'>
                            <ul className='row'>
                              {permissionsList.length ? permissionsList.map((value, key) => (
                                <li key={key}>
                                  <input className="form-check-input" type="checkbox" name="shift" id={value.action}
                                    onChange={e => handleCashierRole(row.id, value.id, e.target.checked, key)}
                                    checked={
                                      cashierRole[i]?.data[value.id] === true ? true : false
                                    }
                                  />
                                  <label className="form-check-label">{value.action}</label>
                                </li>
                              )) : ''}
                            </ul>
                          </div>
                        </div>
                      )) : ''}
                    </div>
                  </div>
                  {formik.errors.cashierRole && formik.touched.cashierRole && (
                    <div className='text-danger text-left'>{formik.errors.cashierRole}</div>
                  )}
                  {formik.errors.role && formik.touched.role && (
                    <div className='text-danger text-left mb-2'>{formik.errors.role}</div>
                  )}
                </div>

                <div className='form-group text-start mt-2'>
                  <button type="submit" className="btn btn-primary">Add</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </form>
    </>
  );
};

export default RoleAdd;
