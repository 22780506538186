import React, { useState, useEffect } from 'react'

const ReportList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    
    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Report</h4>
                    </div>
                    {/* <div className='page-header-right ms-auto'>
                        <button className="btn btn-primary ms-2">Create Loyalty Points</button>
                       
                    </div> */}
                </div>
                {/* <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <ul className="d-flex setting w-100 mb-0 p-0">
                                <li className="active"><Link to={`/company/coupons/coupon/list/${1}`} className="btn btn-primary">Coupons</Link></li>
                                <li className="ms-3"><Link to={`/company/coupons/promos/list/${1}`} className="btn">Promos</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                                <button className="btn btn-primary mr-2"> Export Coupon</button>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th className="sr_head" width="9%">S.No</th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="profile_image" />
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Coupon Code</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="first_name" />
                                            </div>
                                        </th>
                                        <th width="13%">
                                            <div className='sorting_column'>
                                                <span>Type </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="email" />
                                            </div>
                                        </th>
                                        <th width="9%">
                                            <div className='sorting_column'>
                                                <span>Discount</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="brandname" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Validity  </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="outletname" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Customer Type</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="outletlocation" />
                                            </div>
                                        </th>
                                        <th className="created_head" width="12%">
                                            <div className='sorting_column'>
                                                <span>Created date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                            </div>
                                        </th>
                                        <th className='action_head' width="4%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={9}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{(params.id * itemPerPage) - (itemPerPage) + (++i)}</td>
                                                    <td className='ps-0'>{capitalizeAllFirstLetters(row.name)}</td>
                                                    <td>{row.coupon_code}</td>
                                                    <td>{row.type ? removeSpecialCharacter(capitalizeAllFirstLetters(row.type)) : 'N/A'}</td>
                                                    <td>{row.maximum_discount_amount ? row.maximum_discount_amount : 'N/A'}</td>
                                                    <td>{row.duration ? row.duration + " " + 'Month' : 'N/A'}</td>
                                                    <td>{row.customer_type ? capitalizeAllFirstLetters(row.customer_type) : 'N/A'}</td>
                                                    <td>{row.created_at ? formateDate(row.created_at) : "N/A"}</td>
                                                    
                                                    <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><div className='dropdown-item' onClick={() => { viewFunction(row.id) }}><i class="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                                                               
                                                                <li><div className='dropdown-item' onClick={() => { editFunction(row) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                
                                                            
                                                                <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                                
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr><td colSpan={9} className="text-center">No records</td></tr>}</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite">
                                    <b>Total Records : {dataLength ? dataLength : "0"}</b>
                                </div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "coupons", path: "/company/coupons/coupon/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default ReportList