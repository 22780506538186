import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import $ from "jquery";
import { GetOutletUserList } from '../../../services/company_panel/platform_user/platformUserService';
import { CUSTOMER_TYPE, LOADER_TIME, showFilterlist } from '../../../utils/Constants';
import Sorting from '../../../components/company_panel/common/sorting';
import { SpecialCharacter, TrimText, capitalizeAllFirstLetters, formateDate, getBrandId, getCompany } from '../../../helpers/commonFunctions';
import CustomPagination from '../../../components/company_panel/common/custompagination';
import CustomRangepicker from '../../../components/company_panel/common/rangepicker';
import StatusFilter from '../../../components/company_panel/common/statusFilter';
import Loader from '../../../components/company_panel/common/loader';
import { ChangeStatusPromo, DeletePromo, ListPromo } from '../../../services/company_panel/coupons.service';
import notFound from '../../../assets/company/img/no-record-found.png'


const PromosList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },]
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false)
    const [customerType] = useState(
        Object.keys(CUSTOMER_TYPE).map((item) => {
          return {
            label: item,
            value: CUSTOMER_TYPE[item],
          };
        })
      );

      const [isBrandChanged, setIsBrandChanged] = useState(false);

      useMemo(() => {
        document.addEventListener('brand-changed', function () {
          setIsBrandChanged(!isBrandChanged);
        });
      });


    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('brand_id', getBrandId());
            ListPromo(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setTimeout(() => {
                    setLoader(false);
                }, LOADER_TIME);
            }).catch(error => {
                setTimeout(() => {
                    setLoader(false);
                }, LOADER_TIME);
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch,isBrandChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
        $("#resetFilter")[0].selectedIndex = 0;
    }

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row);
                DeletePromo(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Promo deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }

    // Change Status functionality

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                ChangeStatusPromo(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    const viewFunction = (row) => {
        navigate(`/company/crm/promos/view/${row}`)
    }
    const editFunction = (row) => {
        navigate(`/company/crm/promos/${params.id}/edit/${row.id}`)
    }
    const copyFunction = (row) => {
        navigate(`/company/crm/promos/${params.id}/edit/${row.id}?copy=true`);
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const handleFilterChange = (e) => {
        if (e.target.value) {
          prepareSearch("type", e.target.value);
        } else {
          prepareSearch("type", "");
        }
      };


    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Promos</h4>
                    </div>
                    <div className='page-header-right ms-auto'><button className="btn btn-primary ms-2" onClick={() => navigate(`/company/crm/coupons/promos/add`)}>Create Promos</button></div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <ul className="d-flex setting w-100 mb-0 p-0">
                                <li className=""><Link to={`/company/crm/coupons/coupon/list/${1}`} className="btn">Coupons</Link></li>
                                <li className="ms-3 active"><Link to={`/company/crm/coupons/promos/list/${1}`} className="btn btn-primary">Promos</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                <div className="me-3">
                                <div className="form-group mb-0 filter_icon_container filter_design">
                                    <div className="select-down-arrow">
                                        <select
                                            className="form-control cp"
                                            onChange={handleFilterChange}
                                            id="resetFilter"
                                            style={{ width: "180px" }}
                                        >
                                            <option value="">Customer Type</option>
                                            {customerType &&
                                                customerType.length > 0 &&
                                                customerType.map((option, i) => {
                                                    return (
                                                        <option key={i} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="7%">
                                            <div className='sorting_column'>
                                                <span>Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="name" />
                                            </div>
                                        </th>
                                        <th width="7%">
                                            <div className='sorting_column'>
                                                <span>Buy</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="buy" />
                                            </div>
                                        </th>
                                        {/* <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Buy Items </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="buy_items" />
                                            </div>
                                        </th> */}
                                        <th width="7%">
                                            <div className='sorting_column'>
                                                <span>Offer Type</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="offer_type" />
                                            </div>
                                        </th>
                                        <th width="7%">
                                            <div className='sorting_column'>
                                                <span>Get</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="get" />
                                            </div>
                                        </th>
                                        {/* <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Get Item  </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="get_items" />
                                            </div>
                                        </th> */}
                                       <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Customer Type  </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customer_type" />
                                            </div>
                                        </th>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Usage Condition </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="usage_condition" />
                                            </div>
                                        </th>
                                        <th  width="13%">
                                            <div className='sorting_column'>
                                                <span>Discount Item Restriction</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="discount_item_restriction" />
                                            </div>
                                        </th>
                                        <th width="13%">
                                            <div className='sorting_column'>
                                                <span>Authentication Type</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="authentication_type" />
                                            </div>
                                        </th>
                                        <th className="created_head" width="10%">
                                            <div className='sorting_column'>
                                                <span>Created date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                            </div>
                                        </th>
                                        <th className='status_head' width="5%">Status</th>
                                        <th className='action_head text-end' width="104%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={12}><Loader /></td>
                                        </tr> : <>
                                            {list?.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{capitalizeAllFirstLetters(TrimText(row?.name,30))}</td>
                                                    <td>{row?.buy}</td>
                                                    {/* <td>{row.buy_items ? showBuyData(row.buy_items) : 'N/A'}</td> */}
                                                    <td>{row.offer_type ? capitalizeAllFirstLetters(SpecialCharacter(row.offer_type)) : 'N/A'}</td>
                                                    <td>{row.get ? row.get : ''}</td>
                                                    {/* <td>{row.get_items ? showGetData((row.get_items)) : 'N/A'}</td> */}
                                                    <td>{row.customer_type ? capitalizeAllFirstLetters(SpecialCharacter(row.customer_type) ) : 'N/A'}</td>
                                                    <td>{row.usage_condition ? capitalizeAllFirstLetters(SpecialCharacter(row.usage_condition))  : 'N/A'}</td>
                                                    <td>{capitalizeAllFirstLetters(SpecialCharacter(row?.discount_item_restriction))}</td>
                                                    <td>{capitalizeAllFirstLetters(SpecialCharacter(row?.authentication_type))}</td>
                                                    <td>{row.created_at ? formateDate(row.created_at) : "N/A"}</td>
                                                    <td>
                                                        <div className="form-check form-switch w-25 mx-auto">
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" checked={row.status == 1 ? 'checked' : ''} onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }} />
                                                            <label className="form-check-label" for="status-change"></label>
                                                        </div>
                                                    </td>
                                                    <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><div className='dropdown-item' onClick={() => { viewFunction(row.id) }}><i class="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                                                                {/* {checkPermission('platform User','edit')? */}
                                                                <li><div className='dropdown-item' onClick={() => { editFunction(row) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                {/* :''} */}
                                                                {/* {row.role == 3 && checkPermission('platform User','delete') ? */}
                                                                <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                                {/* :''} */}
                                                                <li> <div className='dropdown-item' onClick={() => { copyFunction(row) }}><i class="ti ti-copy" aria-hidden="true"></i> Copy Promos</div></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr> 
                                                <td colSpan={12} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No Record</p>
                                                </td>
                                                </tr>}</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite">
                                    <b>Total Records : {dataLength ? dataLength : "0"}</b>
                                </div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "promos", path: "/company/crm/coupons/promos/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PromosList