import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Formik } from 'formik'
import { GetEditProfile, GetProfileDetail } from '../../services/company_panel/LoginCompanyServices';
import Swal from 'sweetalert2'
import { blockInvalidChar, getCompany } from '../../helpers/commonFunctions';
import { Dropdown } from 'semantic-ui-react';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { countries } from 'countries-list';
import $ from 'jquery';
import ReactCountryFlag from 'react-country-flag';
import { SelectPicker } from 'rsuite';
import { IMG_MAX_SIZE } from '../../utils/Constants';



const EditProfile = () => {
    const navigate = useNavigate()
    const [showdefault, setShowDefault] = useState({})
    const [previewImage, setPreviewImage] = useState("")
    const [profileid, setProfileId] = useState()
    const [updateData, setUpdateData] = useState('false')
    const [updateHeader, setupdateHeader] = useState(1)
    const [submitted, setSubmitted] = useState(false)
    const [selectedCountryCode, setSelectedCountryCode] = useState([]);
    const breadcrumbs = [{ title: "My Profile", url: "/company/profile" }, { title: "Edit Profile", url: "" }]

    useEffect(() => {
        const id = JSON.parse(localStorage.getItem("company_user"))
        setProfileId(id.id)
        GetProfileDetail(id.id).then(data => {
            localStorage.setItem("company_user", JSON.stringify(data.data.response));
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            setPreviewImage(data && data.data && data.data.response && data.data.response.profile_image ? data.data.response.profile_image : "");
        }).catch(error => {
            console.log("error ====> ", error);
        })

        const options = Object.keys(countries).map(countryCode => ({
            label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReactCountryFlag
                        countryCode={countryCode}
                        style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }}
                        aria-label={countryCode}
                    />
                    {` + ${countries[countryCode].phone}`}
                </div>
            ),
            value: countries[countryCode].phone
        }));
        setSelectedCountryCode(options);

    }, [updateData])


    return (
        <>
            <div className='page-header d-flex align-items-center'>
                <div className='page-header-left'>
                    <h4>My Profile</h4>
                    <Breadcrums data={breadcrumbs} />
                </div>
                <div className='page-header-right ms-auto'>
                    {/* <Link className="btn btn-primary" to={"/company/profile/edit"}>
                        Edit Profile
                    </Link> */}
                </div>
            </div>


            <Formik
                enableReinitialize
                initialValues={{
                    first_name: showdefault && showdefault.first_name ? showdefault.first_name : "",
                    last_name: showdefault && showdefault.last_name ? showdefault.last_name : "",
                    first_name_ar: showdefault && showdefault.first_name_ar ? showdefault.first_name_ar : "",
                    last_name_ar: showdefault && showdefault.last_name_ar ? showdefault.last_name_ar : "",
                    email: showdefault && showdefault.email ? showdefault.email : "",
                    country_code: showdefault && showdefault.country_code ? [Number(showdefault.country_code)] : "",
                    mobile: showdefault && showdefault.mobile ? showdefault.mobile : "",
                    profile_image: showdefault && showdefault.profile_image ? showdefault.profile_image : "",

                }}
                validate={values => {
                    console.log("values", values)
                    const error = {};
                    if (!values.first_name.trim()) {
                        error.first_name = "First name is required";
                    } else if (values.first_name.trim().length < 3 || values.first_name.trim().length > 30) {
                        error.first_name = "First name must be between 3 and 30 characters";
                    }

                    if (!values.last_name.trim()) {
                        error.last_name = "Last name is required";
                    } else if (values.last_name.trim().length < 3 || values.last_name.trim().length > 30) {
                        error.last_name = "Last name must be between 3 and 30 characters";
                    }

                    if (!values.first_name_ar.trim()) {
                        error.first_name_ar = "First name (Arabic) is required";
                    } else if (values.first_name_ar.trim().length < 3 || values.first_name_ar.trim().length > 30) {
                        error.first_name_ar = "First name (Arabic) must be between 3 and 30 characters";
                    }

                    if (!values.last_name_ar.trim()) {
                        error.last_name_ar = "Last name (Arabic) is required";
                    } else if (values?.last_name_ar?.trim()?.length < 3 || values?.last_name_ar?.trim()?.length > 30) {
                        error.last_name_ar = "Last name (Arabic) must be between 3 and 30 characters";
                    }
                    if (!values.country_code) error.country_code = "Country code is required";
                    // if (!values.mobile) {
                    //     error.mobile = "Mobile is required";
                    // } else if (values.mobile.length < 6 || values.mobile.length > 15) {
                    //     error.mobile = "Mobile number should be between 6 and 15 digits";
                    // } else if (!/^\d+$/.test(values.mobile)) {
                    //     error.mobile = "Mobile number should only contain digits";
                    // }

                    if (!values.mobile) {
                        error.mobile = "Phone number is required";
                    } else if (values.mobile.length < 6 || values.mobile.length > 15) {
                        error.mobile = "Phone number should be between 6 and 15 digits";
                    } else if (!/^\d+$/.test(values.mobile)) {
                        error.mobile = "Phone number should only contain digits";
                    } else if (/^0+$/.test(values.mobile)) {
                        error.mobile = "Phone number cannot be all zeros";
                    }


                    if (!values.email) {
                        error.email = 'Email is required';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        error.email = 'Invalid email address';
                    }

                    if (!values.profile_image || values.profile_image == undefined) {
                        error.profile_image = 'Image is required';
                        $("#errortext").text("Image is required");
                    }
                    //  else if (!values?.profile_image?.name?.match(/\.(jpg|jpeg|png|webp|avif|gif)$/)) {
                    //     error.profile_image = 'Invalid Image Format';
                    //     $("#errortext").text("Invalid Image Format");
                    // } 
                    else if (values.profile_image.size >= IMG_MAX_SIZE.value) {
                        error.profile_image = `Image size exceeds the maximum limit of ${IMG_MAX_SIZE.display_name}`;
                        $("#errortext").text(`Image size exceeds the maximum limit of ${IMG_MAX_SIZE.display_name}`);
                    } else {
                        $("#errortext").text(""); // Clear error if all validations pass
                    }

                    return error;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {

                    setSubmitted(true)
                    let formData = new FormData();
                    formData.append('id', profileid);
                    formData.append('first_name', values.first_name.trim());
                    formData.append('last_name', values.last_name.trim());
                    formData.append('first_name_ar', values.first_name_ar.trim());
                    formData.append('last_name_ar', values.last_name_ar.trim());
                    formData.append('country_code', values.country_code);
                    formData.append('mobile', values.mobile);
                    formData.append('email', values.email);
                    formData.append('profile_image', values.profile_image);
                    formData.append('company_id', getCompany().company_id);
                    GetEditProfile(formData).then(response => {
                        setSubmitted(false);
                        if (response.data.status === 1 || response.data.status === "1") {
                            Swal.fire({
                                customClass: 'swal-wide',
                                position: 'top-right',
                                icon: 'success',
                                title: response.data.message,
                                showConfirmButton: false,
                                timer: 1500,
                                toast: true
                            });
                            resetForm({ values: '' });
                            setUpdateData(!updateData)
                            // setTimeout(() => {
                            //     setupdateHeader(Math.random());
                            //     navigate(`/profile`)
                            // }, 1000);
                        } else {
                            Swal.fire({
                                customClass: 'swal-wide',
                                position: 'top-right',
                                icon: 'error',
                                title: response.data.error[0].msg,
                                showConfirmButton: false,
                                timer: 5000,
                                toast: true
                            })
                        }
                    }).catch(error => {
                        setSubmitted(false)
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">

                                <div className='gap-20 border-bottom'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <ul className="d-flex setting w-100 mb-0 p-0">
                                            <li className="active"><Link to={"/company/profile/edit"} className="btn btn-primary">Edit Profile</Link></li>
                                            <li className="ms-3"><Link to={"/company/changepassword"} className="btn">Change Password</Link></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="main-content-body tab-pane px-4 border-top-0 active" id="edit">
                                    {/* <div className="profile-tab tab-menu-heading mt-5">
                                        <nav className="nav main-nav-line p-3 mb-3 tabs-menu profile-nav-line bg-gray-100">
                                            <span className="nav-link pl-2" data-toggle="tab" href="#edit">My profile</span>
                                        </nav>
                                    </div> */}
                                    <div className="mb-4">
                                        <div className='row'>
                                            {/* <div className='col-lg-6 text-center form-group'>
                                                <input className='form-control imgInput' id="profile_image" name="profile_image" accept="image/*" type="file"
                                                    onChange={(event) => {
                                                        setFieldValue("profile_image", event.currentTarget.files[0] || "");
                                                        { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                                                    }}
                                                />
                                                <span className='text-danger d-flex text-left' id='errortext'>
                                                    {errors.profile_image && touched.profile_image && errors.profile_image}
                                                </span>
                                                <div className='d-flex justify-content-start mt-3'>
                                                    <div className={previewImage ? "" : "d-none"}>
                                                        <img src={previewImage} style={{ height: "150px" }} />
                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* ----- */}

                                            <div className='col-md-12'>
                                                <div className='upload-logo d-flex align-items-center gap-3 mb-3'>
                                                    {previewImage ?
                                                        <div class="avatar avatar-xl mt-2">
                                                            <img alt="avatar" src={previewImage} className={previewImage ? "" : "d-none"} />
                                                        </div>
                                                        :
                                                        <div class="avatar avatar-xl mt-2">
                                                            <img alt="avatar" src="https://pointofsalesapi.devtechnosys.tech/dummy_images/dummy_company_image.png" />
                                                        </div>
                                                        // null
                                                    }

                                                    <div className='btn btn-black btn-file-type'>Upload Photo <input id="profile_image" name="profile_image" accept="image/*" type="file" onChange={(event) => {
                                                        setFieldValue("profile_image", event.currentTarget.files[0] || "");
                                                        { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                                                    }}
                                                    /></div>
                                                </div>
                                                <span className='text-danger d-flex text-left' id='errortext'>
                                                    {errors.profile_image && touched.profile_image && errors.profile_image}
                                                </span>
                                            </div>

                                            {/* <div className='col-lg-6 text-center form-group mb-3'></div> */}
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='first_name' className='text-left d-flex'>First name<span className="requirestar">*</span></label>
                                                <input name='first_name' id="first_name" type='text' onChange={handleChange} onBlur={handleBlur} value={values.first_name} className='form-control' placeholder='Enter first name' />
                                                <span className='text-danger d-flex text-left'>{errors.first_name && touched.first_name && errors.first_name}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='last_name' className='text-left d-flex'>Last name<span className="requirestar">*</span></label>
                                                <input name='last_name' id="last_name" type='text' onChange={handleChange} onBlur={handleBlur} value={values.last_name} className='form-control' placeholder='Enter last name' />
                                                <span className='text-danger d-flex text-left'>{errors.last_name && touched.last_name && errors.last_name}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='first_name_ar' className='text-left d-flex mb-3'>First name (Arabic)<span className="requirestar">*</span></label>
                                                <input name='first_name_ar' id="first_name_ar" type='text' onChange={handleChange} onBlur={handleBlur} value={values.first_name_ar} className='form-control' placeholder='Enter first name (Arabic)' />
                                                <span className='text-danger d-flex text-left'>{errors.first_name_ar && touched.first_name_ar && errors.first_name_ar}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='last_name_ar' className='text-left d-flex'>Last name (Arabic)<span className="requirestar">*</span></label>
                                                <input name='last_name_ar' id="last_name_ar" type='text' onChange={handleChange} onBlur={handleBlur} value={values.last_name_ar} className='form-control' placeholder='Enter last name (Arabic)' />
                                                <span className='text-danger d-flex text-left'>{errors.last_name_ar && touched.last_name_ar && errors.last_name_ar}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='email' className='text-left d-flex'>Email ID<span className="requirestar"></span></label>
                                                <input name='email' id="email" type='text' onChange={handleChange} onBlur={handleBlur} value={values.email} className='form-control' placeholder='Enter Email ID'/>
                                                <span className='text-danger d-flex text-left'>{errors.email && touched.email && errors.email}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='name' className='text-left d-flex'>Phone number<span className="requirestar">*</span></label>
                                                <div className="input-group">
                                                    <span className=''>
                                                        <SelectPicker
                                                            style={{ border: 'none', paddingLeft: '0', paddingRight: '0' }}
                                                            data={selectedCountryCode}
                                                            name='country_code'
                                                            className='form-control'
                                                            id='country_code'
                                                            onChange={(value) => { setFieldValue("country_code", value); }}
                                                            onBlur={handleBlur}
                                                            value={values.country_code}
                                                            placeholder="Code"
                                                        />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        name='mobile'
                                                        id="mobile"
                                                        className="form-control"
                                                        placeholder="Enter phone number"
                                                        value={values.mobile}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onKeyDown={blockInvalidChar}
                                                    />
                                                </div>
                                                <span className='text-danger d-flex text-left'>{errors.mobile && touched.mobile && errors.mobile}</span>
                                                <span className='text-danger d-flex text-left'>{errors.country_code && touched.country_code && errors.country_code}</span>
                                            </div>
                                        </div>
                                        <div className="my-3">
                                            <button className="btn btn-primary mr-2" type="submit" disabled={submitted ? true : null}>
                                                Update Profile
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>

        </>
    )
}

export default EditProfile