import React, { useState, useEffect } from 'react'
import { showFilterlist } from '../../utils/Constants';
import CustomRangepicker from '../../components/company_panel/common/rangepicker';
import StatusFilter from '../../components/company_panel/common/statusFilter';
import Sorting from '../../components/company_panel/common/sorting';
import { useNavigate, useParams } from 'react-router-dom';
import { ChangeStatusLoyaltyPoint, ListLoyaltyPoints } from '../../services/company_panel/loyaltypoints.service';
import Loader from '../../components/company_panel/common/loader';
import { formateDate, getBrandId } from '../../helpers/commonFunctions';
import Swal from 'sweetalert2';
import notFound from '../../assets/company/img/no-record-found.png'

const LoyaltyPointsList = () => {
    const params = useParams()
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [list, setList] = useState([])
    const [loader, setLoader] = useState(true)
    const [statsUpdate, setStatusUpdate] = useState("false")
    const navigate = useNavigate();


    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            formData.append('brand_id', getBrandId());
            ListLoyaltyPoints(formData).then(data => {
                setDataLength(data.data.response.count);
                // setList(data && data.data && data.data.response ? data.data.response.rows : [])
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [sorting, search, globalSearch, statsUpdate])


    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        // $("#defaultstatus")[0].selectedIndex = 0
    }


    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }
    const viewFunction = (row) => {
        navigate(`/company/loyalty-points/view/${row}`)
    }

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                ChangeStatusLoyaltyPoint(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }


    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Loyalty Points</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                        <button className="btn btn-primary ms-2" onClick={() => navigate(`/company/crm/loyalty-points/add`)}>Configure</button>

                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        {/* <th className="sr_head">S.No</th> */}
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Loyalty Point</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="profile_image" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Currency Value</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="first_name" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Bill Value Greater Than</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="email" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Duration Of The Expiry</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="brandname" />
                                            </div>
                                        </th>
                                        <th className="created_head">
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                            </div>
                                        </th>
                                        <th className='status text-end'>Status</th>
                                        <th className='action_head text-end'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={9}><Loader /></td>
                                        </tr> : <>
                                            {list?.length ? list.map((row, i) => (
                                                <tr>
                                                    {/* <td>{(params.id * itemPerPage) - (itemPerPage) + (++i)}</td> */}
                                                    <td>{row.loyalty_point + " " + "SAR"}</td>
                                                    <td>{row.currency_value + " " + "SAR"}</td>
                                                    <td>{row.bill_value ? row.bill_value + " " + "SAR" : 'N/A'}</td>
                                                    <td>{row.duration ? row.duration + " " + "Month" : 'N/A'}</td>
                                                    <td>{row.created_at ? formateDate(row.created_at) : "N/A"}</td>
                                                    <td>
                                                        <div className="form-check form-switch  mx-auto">
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" checked={row.status == 1 ? 'checked' : ''} onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }} />
                                                            <label className="form-check-label" for="status-change"></label>
                                                        </div>
                                                    </td>
                                                    <td className=''>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><div className='dropdown-item' onClick={() => { viewFunction(row.id) }}><i class="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    {/* <td colSpan={9} className="text-center">No records</td> */}
                                                    <td colSpan={9} className="text-center">
                                                        <img src={notFound} style={{width:"5%"}} alt="Not Found" />
                                                        <p>No record found</p>
                                                    </td>
                                                </tr>
                                            }</>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoyaltyPointsList