
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginService } from '../../services/company_panel/LoginCompanyServices';
import $ from 'jquery';
import LoginLayout from '../../companyloginlayout';
import Logo from '../../assets/company/img/logo.png';
import loginBg from '../../assets/company/img/login1.jpg';
import CryptoJS from 'crypto-js';
import { Modal } from 'react-bootstrap';
import Cookies from 'js-cookie';

const SECRET_KEY = "1526365"


const CompanyLogin = () => {
  const formikRef = useRef();
  const navigate = useNavigate()
  const [show, setShow] = useState('false')
  const [loader, setLoader] = useState('false')
  const [outletList, setOutletList] = useState([]);
  const [initialValues, setInitialValues] = useState({
    email: '',
    password: '',
    company_id: ''
  });
  const cookieValue = Cookies.get('rememberData');
  const deserializedArray = cookieValue ? JSON?.parse(cookieValue) : [];
  const [isRememberMe, setIsRememberMe] = useState(false)
  const dataRemember = localStorage.getItem("rememberData") ?? []
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const rememberedDetails = localStorage.getItem('rememberedUser');
    if (rememberedDetails) {
      const { email, company_id } = JSON.parse(rememberedDetails);
      setInitialValues({ ...initialValues, email, company_id });
    }
  }, []);
  const handleRememberMe = (values) => {
    // localStorage.setItem('rememberedUser', JSON.stringify({
    //   email: values.email,
    //   company_id: values.company_id
    // }));
  };

  const clearRememberedUser = () => {
    localStorage.removeItem('rememberedUser');
  };

  const handleshow = () => {
    setShow(!show)
  }

  const fillValue = (val) => {
    const bytes = CryptoJS.AES.decrypt(val.password, SECRET_KEY);
    const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
    if (formikRef.current) {
      formikRef.current.setFieldValue('email', val.email);
      formikRef.current.setFieldValue('password', decryptedPassword);
      formikRef.current.setFieldValue('company_id', val.companyId)
    }
    setShowModal(false)

  }

  // useEffect(() => {

  //   if (dataRemember.length > 0) {
  //     const updateData = JSON.parse(dataRemember)
  //     const bytes = CryptoJS.AES.decrypt(updateData[updateData.length - 1].password, SECRET_KEY);
  //     const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
  //     if (formikRef.current) {
  //       formikRef.current.setFieldValue('email', updateData[updateData.length - 1].email);
  //       formikRef.current.setFieldValue('password', decryptedPassword);
  //       formikRef.current.setFieldValue('company_id', updateData[updateData.length - 1].companyId)
  //     }
  //   }
  // }, [])



  useEffect(() => {
    if (deserializedArray.length > 0) {
      const updateData = deserializedArray
      const bytes = CryptoJS.AES.decrypt(updateData[updateData.length - 1].password, SECRET_KEY);
      const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
      if (formikRef.current) {
        formikRef.current.setFieldValue('email', updateData[updateData.length - 1].email);
        formikRef.current.setFieldValue('password', decryptedPassword);
        formikRef.current.setFieldValue('company_id', updateData[updateData.length - 1].companyId)
        setIsRememberMe(true)
      }
    }
  }, [])


  const removeClass = () => {
    const modalBackdrop = document.querySelector('.modal-backdrop');
    if (modalBackdrop) {
      modalBackdrop.classList.remove('modal-backdrop');
    }
  }



  return (
    <LoginLayout>
      <Formik
        innerRef={formikRef}
        initialValues={{ email: '', password: '', company_id: '' }}
        validate={values => {
          const errors = {};
          const trimmedEmail = values.email.trim();
          if (!trimmedEmail) {
            errors.email = 'Email address is required';
          } else if (!/^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i.test(trimmedEmail)) {
            errors.email = 'Invalid email address';
          }
          if (!values.password) {
            errors.password = 'Password is required';
          }
          if (!values.company_id) {
            errors.company_id = 'Company ID is required';
          } else if (!/^\d{6}$/.test(values.company_id)) {
            errors.company_id = 'Company ID must be  6 digits';
          } else if (!/^\d+$/.test(values.company_id)) {
            errors.company_id = 'Please enter a valid company ID';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoader(true)
          let formData = new FormData();
          formData.append("email", values.email);
          formData.append("password", values.password);
          formData.append("company_id", values.company_id);
          // console.log(isRememberMe, JSON?.parse(dataRemember)?.length > 0)

          if (isRememberMe) {
            if (dataRemember?.length > 0) {
              let mg = JSON.parse(dataRemember)
              let filterData = mg.find((itm) => itm.companyId == values.company_id)
              if (!filterData) {
                mg?.push({ email: values.email, password: CryptoJS.AES.encrypt(values.password, SECRET_KEY).toString(), companyId: values.company_id })
                localStorage.setItem("rememberData", JSON.stringify(mg))
              }


            } else {
              dataRemember.push({ email: values.email, password: CryptoJS.AES.encrypt(values.password, SECRET_KEY).toString(), companyId: values.company_id })
              localStorage.setItem("rememberData", JSON.stringify(dataRemember))

            }
            setIsRememberMe(false)
            const serializedArray = JSON.stringify([{ email: values.email, password: CryptoJS.AES.encrypt(values.password, SECRET_KEY).toString(), companyId: values.company_id }]);

            Cookies.set('rememberData', serializedArray, { expires: 7 });

            // To retrieve the array from the cookie:

          }
          LoginService(formData).then(data => {
            if (data) {
              if (data.data.status === 1 || data.data.status === "1") {
                setIsRememberMe(false)
                setTimeout(() => {
                  setLoader(false)

                  const twoFactor = data && data.data && data.data.response ? data.data.response.two_factor : [];
                  if (twoFactor) {
                    toast(data.data.message);

                    setTimeout(() => {
                      navigate(`/company/otpverification/${values.email}?type=login&company_id=${data.data.response.company_id}`)
                    }, 2000);

                  } else {
                    const brands = data && data.data && data.data.response ? data.data.response.brands : [];
                    setOutletList(brands)

                    // localStorage.setItem('outlet_id', data.data.response.outlet_id);

                    if (brands.length > 1) {
                      localStorage.setItem("brand_list", JSON.stringify(brands));

                      navigate('/company/brand')

                      const loginDetail = {
                        'company_token': data?.data?.response?.token,
                        'company_user': data?.data?.response,
                        'company_detail': data?.data?.response?.companyDetail,
                        'permission': data.data.response.roleDetails.module_wise_permissions,
                      }



                      localStorage.setItem("login_detail", JSON.stringify(loginDetail));
                      console.log(localStorage.getItem("login_detail"));

                    } else {

                      localStorage.setItem("company_token", data.data.response.token);
                      localStorage.setItem("company_user", JSON.stringify(data.data.response));
                      localStorage.setItem("company_detail", JSON.stringify(data.data.response.companyDetail));
                      localStorage.setItem("permission", JSON.stringify(data.data.response.roleDetails.module_wise_permissions));
                      localStorage.setItem("cashier_permission", JSON.stringify(data.data.response.roleDetails.cashier_module_wise_permissions));
                      toast(data.data.message);


                      if (brands.length == 1) {
                        localStorage.setItem("brand_id", brands[0].id);
                      }

                      setTimeout(() => {
                        setLoader(false)
                        navigate(`/company/dashboard`)
                      }, 2000);
                    }
                  }
                }, 2000);
              } else {
                toast(data.data.message.message);
                setIsRememberMe(false)

                setTimeout(() => {
                  setLoader(false)
                }, 2000);
              }
            } else {
              setLoader(false);
            }
          }).catch(error => {
            console.log("error: ", error);
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          // and other goodies
        }) => (
          <div className="page">

            <div className="login-wraper">
              <div className='login-wraper-in'>
                <div className='login-left'>
                  <figure>
                    <img src={loginBg} />
                  </figure>
                  <div className='login-text'>
                    <h4>Efficiency at your fingertips! </h4>
                    <p>With POS Restaurant Admin, managing your  restaurant has never been easier. From orders to inventory, we've got  you covered.</p>
                  </div>
                </div>
                <div className='login-right'>
                  <div className='login-right-in'>
                    <div class="login-head">
                      <Link to="/"> <img src={Logo} /> </Link>
                      <div class="login-head-text">
                        <h4>Log in to your account</h4>
                        <p>Please enter your information to login </p>
                      </div>
                    </div>
                    <div className='login-body'>
                      <div className='form-box'>
                        <form onSubmit={handleSubmit}>
                          <div className="form-group text-left">
                            <label>Company ID</label>
                            <input className={"form-control"}
                              placeholder="Enter Company ID"
                              type="text"
                              name="company_id"
                              onChange={handleChange}
                              onClick={() => { setShowModal(true); removeClass() }}

                              onBlur={handleBlur}
                              value={values.company_id}
                              autoComplete="off" />
                            <span className='text-danger text-left d-blockerr-spn'>
                              {errors.company_id && touched.company_id && errors.company_id}
                            </span>
                          </div>
                          <div className="form-group text-left">
                            <label>Email Address</label>
                            <input className={"form-control"}
                              placeholder="Enter Email Address "
                              type="email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              onClick={() => { setShowModal(true); removeClass() }}
                              autoComplete="off"
                              id='username' />
                            <span className='text-danger text-left d-blockerr-spn'>
                              {errors.email && touched.email && errors.email}
                            </span>
                          </div>
                          <div className="form-group text-left">
                            <label>Password</label>
                            <div className="input-group">
                              <input className={"form-control"}
                                placeholder="Enter Password"
                                type={!show === true ? "text" : "password"}
                                name="password"
                                onClick={() => { setShowModal(true); removeClass() }}

                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                autoComplete="off" />
                              <span className='input-group-text' onClick={handleshow}>{(!show === true ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>)}</span>
                            </div>
                            <span className='text-danger text-left d-block err-spn'>
                              {errors.password && touched.password && errors.password}
                            </span>
                          </div>
                          <div className="row text-left mt-3 ml-0 mb-4">
                            <div class="col-6 pl-0">
                              <div class="form-check">
                                {/* <input class="form-check-input" id="terms_and_conditions" name='terms_and_conditions' type="checkbox" value="" /> */}
                                <input class="form-check-input" id="terms_and_conditions" type="checkbox" name='rememberMe' checked={isRememberMe} value={isRememberMe} onChange={() => { setIsRememberMe(!isRememberMe) }} />
                                <label class="form-check-label" for="terms_and_conditions">

                                  Remember me
                                </label>
                              </div>
                            </div>
                            <div className="col-6 text-end">
                              <Link to="/company/forgot-password">Forgot password?</Link>
                            </div>
                          </div>
                          <button className={"btn btn-primary w-100 position-relative" + (loader === true ? " disabled" : "")} type="submit">Log In
                            {/* <button className={"btn btn-primary w-100 position-relative"} type="submit">Log In */}

                            {loader === true ?
                              <div className="spinner-border text-white mt-2" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                              : ""}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      </Formik>
      {/* <Modal show={showModal} onHide={() => setShowModal(false)} >



         <div style={{ position: "absolute", top: "289px", left: "380px", width: "338px" }}> 
        <Modal.Body >
          <table>
            {
              dataRemember.length > 0 && JSON.parse(dataRemember)?.map((val) => {
                return (

                  <tr onClick={() => fillValue(val)}>
                    <td>{val.email}</td>
                  </tr>


                )
              })
            }
          </table>
        </Modal.Body>
       </div>

      </Modal>  */}
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={true | 1000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
    </LoginLayout>
  );
}

export default CompanyLogin
// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import { Formik } from 'formik';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { LoginService } from '../../services/company_panel/LoginCompanyServices';
// import $ from 'jquery';
// import LoginLayout from '../../companyloginlayout';
// import Logo from '../../assets/company/img/logo.png';
// import loginBg from '../../assets/company/img/login1.jpg';

// const CompanyLogin = () => {
//   const navigate = useNavigate();
//   const [show, setShow] = useState(false);
//   const [loader, setLoader] = useState(false);
//   const [outletList, setOutletList] = useState([]);

//   // Load remembered user details from localStorage
//   useEffect(() => {
//     const rememberedDetails = localStorage.getItem('rememberedUser');
//     if (rememberedDetails) {
//       const { email, company_id } = JSON.parse(rememberedDetails);
//       setInitialValues({ ...initialValues, email, company_id });
//     }
//   }, []);

//   const [initialValues, setInitialValues] = useState({
//     email: '',
//     password: '',
//     company_id: ''
//   });

//   const handleShow = () => {
//     setShow(!show);
//   };

//   const handleRememberMe = (values) => {
//     localStorage.setItem('rememberedUser', JSON.stringify({
//       email: values.email,
//       company_id: values.company_id
//     }));
//   };

//   const clearRememberedUser = () => {
//     localStorage.removeItem('rememberedUser');
//   };

//   return (
//     <LoginLayout>
//       <Formik
//         initialValues={initialValues}
//         validate={values => {
//           const errors = {};
//           const trimmedEmail = values.email.trim();
//           if (!trimmedEmail) {
//             errors.email = 'Email address is required';
//           } else if (!/^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i.test(trimmedEmail)) {
//             errors.email = 'Invalid email address';
//           }
//           if (!values.password) {
//             errors.password = 'Password is required';
//           }
//           if (!values.company_id) {
//             errors.company_id = 'Company ID is required';
//           } else if (!/^\d{6}$/.test(values.company_id)) {
//             errors.company_id = 'Company ID must be 6 digits';
//           } else if (!/^\d+$/.test(values.company_id)) {
//             errors.company_id = 'Please enter a valid company ID';
//           }
//           return errors;
//         }}
//         onSubmit={(values, { setSubmitting }) => {
//           setLoader(true);
//           let formData = new FormData();
//           formData.append('email', values.email);
//           formData.append('password', values.password);
//           formData.append('company_id', values.company_id);
//           LoginService(formData).then(data => {
//             if (data) {
//               if (data.data.status === 1 || data.data.status === '1') {
//                 setTimeout(() => {
//                   setLoader(false);

//                   const twoFactor = data && data.data && data.data.response ? data.data.response.two_factor : [];
//                   if (twoFactor) {
//                     toast(data.data.message);

//                     setTimeout(() => {
//                       navigate(`/company/otpverification/${values.email}?type=login&company_id=${data.data.response.company_id}`);
//                     }, 2000);

//                   } else {
//                     const brands = data && data.data && data.data.response ? data.data.response.brands : [];
//                     setOutletList(brands);
//                     if (brands.length > 1) {
//                       localStorage.setItem('brand_list', JSON.stringify(brands));

//                       navigate('/company/brand');

//                       const loginDetail = {
//                         'company_token': data?.data?.response?.token,
//                         'company_user': data?.data?.response,
//                         'company_detail': data?.data?.response?.companyDetail,
//                         'permission': data.data.response.roleDetails.module_wise_permissions,
//                       };

//                       localStorage.setItem('login_detail', JSON.stringify(loginDetail));
//                       console.log(localStorage.getItem('login_detail'));

//                     } else {

//                       localStorage.setItem('company_token', data.data.response.token);
//                       localStorage.setItem('company_user', JSON.stringify(data.data.response));
//                       localStorage.setItem('company_detail', JSON.stringify(data.data.response.companyDetail));
//                       localStorage.setItem('permission', JSON.stringify(data.data.response.roleDetails.module_wise_permissions));
//                       toast(data.data.message);

//                       if (brands.length == 1) {
//                         localStorage.setItem('brand_id', brands[0].id);
//                       }

//                       setTimeout(() => {
//                         setLoader(false);
//                         navigate(`/company/dashboard`);
//                       }, 2000);
//                     }
//                   }
//                 }, 2000);
//               } else {
//                 toast(data.data.message.message);
//                 setTimeout(() => {
//                   setLoader(false);
//                 }, 2000);
//               }
//             } else {
//               setLoader(false);
//             }
//           }).catch(error => {
//             console.log('error: ', error);
//             setLoader(false);
//           });
//         }}
//       >
//         {({
//           values,
//           errors,
//           touched,
//           handleChange,
//           handleBlur,
//           handleSubmit,
//           isSubmitting,
//           setFieldValue,
//           // and other goodies
//         }) => (
//           <div className="page">
//             <div className="login-wraper">
//               <div className='login-wraper-in'>
//                 <div className='login-left'>
//                   <figure>
//                     <img src={loginBg} />
//                   </figure>
//                   <div className='login-text'>
//                     <h4>Efficiency at your fingertips! </h4>
//                     <p>With POS Restaurant Admin, managing your  restaurant has never been easier. From orders to inventory, we've got  you covered.</p>
//                   </div>
//                 </div>
//                 <div className='login-right'>
//                   <div className='login-right-in'>
//                     <div className="login-head">
//                       <Link to="/"> <img src={Logo} /> </Link>
//                       <div className="login-head-text">
//                         <h4>Log in to your account</h4>
//                         <p>Please enter your information to login </p>
//                       </div>
//                     </div>
//                     <div className='login-body'>
//                       <div className='form-box'>
//                         <form onSubmit={handleSubmit}>
//                           <div className="form-group text-left">
//                             <label>Company Id</label>
//                             <input className={"form-control" + (errors.company_id && touched.company_id ? " is-invalid state-invalid" : "")}
//                               placeholder="Enter Company Id"
//                               type="text"
//                               name="company_id"
//                               onChange={handleChange}
//                               onBlur={handleBlur}
//                               value={values.company_id}
//                               autoComplete="off" />
//                             <span className='text-danger text-left d-block err-spn'>
//                               {errors.company_id && touched.company_id && errors.company_id}
//                             </span>
//                           </div>
//                           <div className="form-group text-left">
//                             <label>Email Address</label>
//                             <input className={"form-control" + (errors.email && touched.email ? " is-invalid state-invalid" : "")}
//                               placeholder="Enter Email Address "
//                               type="email"
//                               name="email"
//                               onChange={handleChange}
//                               onBlur={handleBlur}
//                               value={values.email}
//                               autoComplete="off"
//                               id='username' />
//                             <span className='text-danger text-left d-block err-spn'>
//                               {errors.email && touched.email && errors.email}
//                             </span>
//                           </div>
//                           <div className="form-group text-left">
//                             <label>Password</label>
//                             <div className="input-group">
//                               <input className={"form-control" + (errors.password && touched.password ? " is-invalid state-invalid" : "")}
//                                 placeholder="Enter Password"
//                                 type={show ? "text" : "password"}
//                                 name="password"
//                                 onChange={handleChange}
//                                 onBlur={handleBlur}
//                                 value={values.password}
//                                 autoComplete="off" />
//                               <span className='input-group-text' onClick={handleShow}>{show ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>}</span>
//                             </div>
//                             <span className='text-danger text-left d-block err-spn'>
//                               {errors.password && touched.password && errors.password}
//                             </span>
//                           </div>
//                           <div className="row text-left mt-3 ml-0 mb-4">
//                             <div className="col-6 pl-0">
//                               <div className="checkbox">
//                                 <label>
//                                   <input type="checkbox" name='rememberMe' onChange={() => handleRememberMe(values)} />
//                                   Remember Me
//                                 </label>
//                               </div>
//                             </div>
//                             <div className="col-6 pl-0 text-right">
//                               <Link to='/forgotpassword'>Forgot Password?</Link>
//                             </div>
//                           </div>
//                           <div className="form-group">
//                             <button className="btn btn-primary btn-block" type="submit" disabled={isSubmitting}>Log In
//                               {loader &&
//                                 <div className="loader-overly"> <div className="loader"></div></div>
//                               }
//                             </button>
//                           </div>
//                         </form>
//                       </div>
//                     </div>
//                     <div className="form-footer">
//                       <p>Don’t have an account? <Link to="/companysignup">Sign Up</Link></p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <ToastContainer
//                     position="top-right"
//                     hideProgressBar={false}
//                     autoClose={true | 1000}
//                     newestOnTop={true}
//                     closeOnClick={false}
//                     draggable={false}
//                     rtl={false}
//                   />
//           </div>
//         )}
//       </Formik>
//     </LoginLayout>
//   );
// };

// export default CompanyLogin;
