import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import toHtml from 'html-react-parser'
import { GetCompanyProfileDetail } from '../../services/company_panel/companyService';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { capitalizeAllFirstLetters, capitalizeFirstLetter } from '../../helpers/commonFunctions';


const ViewCompanyProfile = () => {
    const navigate = useNavigate()
    const [showdefault, setShowDefault] = useState({})
    const [updateHeader, setupdateHeader] = useState(1)

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Company Profile", url: "" }]

    useEffect(() => {
        const id = JSON.parse(localStorage.getItem("company_detail"))

        GetCompanyProfileDetail(id.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            console.log("data.data.response--->", data.data.response);
        }).catch(error => {
            console.log("error ====> ", error);
        })

    }, [])


    return (


        <>
            <div className='page-header d-flex align-items-center'>
                <div className='page-header-left'>
                    <h4>Company Profile</h4>
                    <Breadcrums data={breadcrumbs} />
                </div>
                <div className='page-header-right ms-auto'>
                    <Link className="btn btn-primary" to={"/company/company-profile/edit"}>
                        Edit Profile
                    </Link>
                </div>
            </div>
            <div className="card custom-card">
                <div className='card-body'>
                    <div class="brand-banner">
                        <div class="avatar avatar-xl mx-auto">
                            {showdefault && showdefault.logo ? <img src={showdefault.logo} /> : ""}
                        </div>
                        <h4> {showdefault && showdefault?.name ? capitalizeFirstLetter(showdefault?.name) : ""} </h4>
                    </div>
                    <div className='profile-info row mt-5'>
                        <div className='col-md-4'>
                            <div className='profile-info-in'>
                                <h5>Company name</h5>
                                <p>{showdefault && showdefault?.name ? capitalizeFirstLetter(showdefault?.name) : ""}</p>
                            </div>
                            {/* <div className='profile-info-in mt-3'>
                                <h5>Phone Number</h5>
                                <p>{showdefault && showdefault.country_code ? '+' + showdefault.country_code : 'N/A'} {showdefault && showdefault.mobile ? showdefault.mobile : ""}</p>
                            </div> */}
                        </div>
                        <div className='col-md-4'>
                            <div className='profile-info-in'>
                                <h5>Email</h5>
                                <p>{showdefault && showdefault?.official_email ? showdefault?.official_email : ""}</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='profile-info-in'>
                                <h5>Phone Number</h5>
                                <p>{showdefault && showdefault?.country_code ? '+' + showdefault?.country_code : ""} {showdefault && showdefault?.official_phone_number ? showdefault?.official_phone_number : ""}</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='profile-info-in'>
                                <h5>Established year</h5>
                                <p>{showdefault && showdefault?.established_year ? showdefault?.established_year : ""}</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='profile-info-in'>
                                <h5>Licence number</h5>
                                <p>{showdefault && showdefault?.licence_number ? showdefault?.licence_number : ""}</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='profile-info-in'>
                                <h5>Business Address</h5>
                                <p>{showdefault && showdefault?.official_address ? capitalizeFirstLetter(showdefault?.official_address) : ""}</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='profile-info-in'>
                                <h5>Company VAT number</h5>
                                <p>{showdefault && showdefault?.vat_number ? showdefault?.vat_number : ""}</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='profile-info-in'>
                                <h5>Short Description</h5>
                                <p>{showdefault && showdefault?.short_description ? capitalizeFirstLetter(showdefault?.short_description) : ""}</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='profile-info-in'>
                                <h5>Long Description</h5>
                                <p>{toHtml(showdefault && showdefault.long_description ? capitalizeFirstLetter(showdefault.long_description) : "")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

        // <>
        //     <div className="row row-sm">
        //         <div className="col-lg-6 col-md-6 animation_fade">
        //             <div className="card custom-card">
        //                 <div className="main-content-body tab-pane px-4 border-top-0 active" id="edit">
        //                     <div className="profile-tab tab-menu-heading mt-5">
        //                         <nav className="nav main-nav-line p-3 mb-3 tabs-menu profile-nav-line bg-gray-100">
        //                             <span className="nav-link pl-2" data-toggle="tab" href="#edit">Company Profile</span>
        //                         </nav>
        //                     </div>
        //                     <div className="card-body border mb-5 bg-light">
        //                         <div className='row'>
        //                             <div className='col-2'>
        //                                 {showdefault && showdefault.logo ? <img src={showdefault.logo} style={{ height: "50px" }}/> : "N/A"}
        //                             </div>
        //                             <div className='col-7'>
        //                                 <h2> {showdefault && showdefault.name ? showdefault.name : "N/A"}</h2>
        //                                 <p>{showdefault && showdefault.company_id ? showdefault.company_id : "N/A"}</p>
        //                             </div>
        //                             <div className='col-3'>
        //                                 <Link className="btn btn-dark" to={"/company/company-profile/edit"}>
        //                                         Edit Profile
        //                                 </Link>
        //                             </div>
        //                         </div>
        //                                                         </div> 
        //                     <div className="card-body">
        //                         <div>
        //                             <h6 className="main-content-label mb-3">Company Details</h6>
        //                         </div>
        //                         <div className='row'>
        //                             <div className="col-lg-12 form-group">
        //                                 <table id="simple-table" className="table  table-bordered table-hover">
        //                                     <tbody>
        //                                         <tr>
        //                                             <th>Name 

        //                                             </th>
        //                                             <td>{showdefault && showdefault.name ? showdefault.name : "N/A"}</td>
        //                                         </tr>
        //                                         <tr>
        //                                             <th>Email</th>
        //                                             <td>{showdefault && showdefault.official_email ? showdefault.official_email : "N/A"}</td>
        //                                         </tr>
        //                                         <tr>
        //                                             <th>Phone number</th>
        //                                             <td>+{showdefault && showdefault.country_code?showdefault.country_code:''} {showdefault && showdefault.official_phone_number ? showdefault.official_phone_number : "N/A"}</td>
        //                                         </tr>
        //                                         <tr>
        //                                             <th>Short description</th>
        //                                             <td>{showdefault && showdefault.short_description ? showdefault.short_description : "N/A"}</td>
        //                                         </tr>
        //                                         <tr>
        //                                             <th>Long description</th>
        //                                             <td>
        //                                                 <div className="editorcontant">{toHtml(showdefault && showdefault.long_description ? showdefault.long_description : "N/A")}</div>
        //                                             </td>
        //                                         </tr>
        //                                         <tr>
        //                                             <th>Established year</th>
        //                                             <td>{showdefault && showdefault.established_year ? showdefault.established_year : "N/A"}</td>
        //                                         </tr>
        //                                         <tr>
        //                                             <th>Licence number</th>
        //                                             <td>{showdefault && showdefault.licence_number ? showdefault.licence_number : "N/A"}</td>
        //                                         </tr>
        //                                         <tr>
        //                                             <th>Business address</th>
        //                                             <td>{showdefault && showdefault.official_address ? showdefault.official_address : "N/A"}</td>
        //                                         </tr>
        //                                         <tr>
        //                                             <th>Company VAT Number</th>
        //                                             <td>{showdefault && showdefault.vat_number ? showdefault.vat_number : "N/A"}</td>
        //                                         </tr>
        //                                     </tbody>
        //                                 </table>
        //                             </div>
        //                         </div> 
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="col-lg-6 col-md-6 animation_fade"></div>
        //     </div>
        // </>
    )
}

export default ViewCompanyProfile