import React, { useEffect, useMemo, useState } from 'react'
import $ from 'jquery'
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import { getCompany, getOutletId, logOutCompany } from '../../../helpers/commonFunctions';
import { GetOutletAllList, GetBrandList, GetBrandOutletsList, GetBrandWithOutlets } from '../../../services/company_panel/outletService';
import { Dropdown } from 'semantic-ui-react';


const Header = ({ updateHeader = null }) => {
	const [userData, setUserData] = useState({});
	const [outletList, setOutletList] = useState([]);
	const [brandList, setBrandList] = useState([]);
	const [isBrandChanged, setIsBrandChanged] = useState(false);
	const [isOutletChanged, setIsOutletChanged] = useState(false);
	const [isOutletListModified, setIsOutletListModified] = useState(false);
	const [isBrandListModified, setIsBrandListModified] = useState(false);

	const [brandId, setBrandId] = useState(JSON.parse(localStorage.getItem('brand_id')));
	const [outletId, setOutletId] = useState(JSON.parse(localStorage.getItem('outlet_id')));

	const handleReload = () => {
		window.location.reload();
	};

	useEffect(() => {

		GetBrandWithOutlets(getCompany().company_id).then(data => {
			const allBrands = data && data.data && data.data.response ? data.data.response : [];
			const brandDropdown = allBrands.map((res) => ({
				key: res.id,
				text: res.name.charAt(0).toUpperCase() + res.name.slice(1),
				value: res.id,
			}))
			setBrandList(brandDropdown);
			if (allBrands.length) {
				const selectedBrandId = localStorage.getItem('brand_id');
				if (selectedBrandId) {
					const selectedBrand = allBrands.filter(item => item.id === Number(selectedBrandId))[0];
					if (selectedBrand) {
						setBrandId(Number(selectedBrandId));
					} else {
						setBrandId(allBrands[0].id);
						localStorage.setItem('brand_id', allBrands[0].id);
					}
				} else {
					setBrandId(allBrands[0].id);
					localStorage.setItem('brand_id', allBrands[0].id);
				}
			}
			GetBrandOutletsList({ brand_id: JSON.parse(localStorage.getItem('brand_id')) }).then(data => {
				const allOutlet = data && data.data && data.data.response ? data.data.response : []
				const outletDropdown = allOutlet.map((res) => ({
					key: res.id,
					text: res.outlet_name.charAt(0).toUpperCase() + res.outlet_name.slice(1),
					value: res.id,
				}))
				setOutletList(outletDropdown)
				if (allOutlet.length) {
					// setOutletId(localStorage.getItem('outlet_id') ? JSON.parse(localStorage.getItem('outlet_id')) : allOutlet[0].id );
					// localStorage.setItem(
					// 	'outlet_id', 
					// 	localStorage.getItem('outlet_id') ? JSON.parse(localStorage.getItem('outlet_id')) : allOutlet[0].id
					// );
					const selectedId = localStorage.getItem('outlet_id');
					if (selectedId) {
						const selectedOutlet = allOutlet.filter(item => item.id === Number(selectedId))[0];
						if (selectedOutlet) {
							setOutletId(Number(selectedId));
						} else {
							setOutletId(allOutlet[0].id);
							localStorage.setItem('outlet_id', allOutlet[0].id);
						}
					} else {
						setOutletId(allOutlet[0].id);
						localStorage.setItem('outlet_id', allOutlet[0].id);
					}
				}
			}).catch(error => {
				console.log("error=====>", error)
			})
		}).catch(error => {
			console.log("error=====>", error)
		})
	}, [isBrandListModified, isOutletListModified])

	useEffect(() => {
		GetBrandOutletsList({ brand_id: JSON.parse(localStorage.getItem('brand_id')) }).then(data => {
			const allOutlet = data && data.data && data.data.response ? data.data.response : []
			const outletDropdown = allOutlet.map((res) => ({
				key: res.id,
				text: res.outlet_name.charAt(0).toUpperCase() + res.outlet_name.slice(1),
				value: res.id,
			}))
			setOutletList(outletDropdown)
			if (allOutlet.length) {
				// setOutletId(allOutlet[0].id);
				// localStorage.setItem('outlet_id', allOutlet[0].id);
				const selectedId = getOutletId();
				if (selectedId) {
					const selectedOutlet = allOutlet.filter(item => item.id === Number(selectedId))[0];
					if (selectedOutlet) {
						setOutletId(Number(selectedId));
					} else {
						setOutletId(allOutlet[0].id);
						localStorage.setItem('outlet_id', allOutlet[0].id);
					}
				} else {
					setOutletId(allOutlet[0].id);
					localStorage.setItem('outlet_id', allOutlet[0].id);
				}
				// handleReload();
			}
			console.log("outlet-->", getOutletId());

		}).catch(error => {
			console.log("error=====>", error)
		})
	}, [isBrandChanged, isBrandListModified, isOutletListModified]);


	const logout = () => {
		Swal.fire({
			customClass: 'swal-wide',
			title: 'Are you sure?',
			text: "You want to logout!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#2f9bff',
			cancelButtonColor: '#dc3545',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.isConfirmed) {
				logOutCompany();
			}
		})

	}
	useEffect(() => {
		setUserData(getCompany());
	}, [updateHeader])



	const toggleSidebar = (e) => {
		e.preventDefault();
		if ($("body").hasClass("main-sidebar-hide")) {
			$("body").removeClass("main-sidebar-hide")
		} else {
			$("body").addClass("main-sidebar-hide")
		}
	}

	$(document).ready(function () {
		$('.showpop').click(function () {
			$('.main-profile-menu').addClass("show");
		})
		$('.fixed_bg').click(function () {
			$('.main-profile-menu').removeClass("show");
		})
	});
	function handleChangeBrand(data) {
		console.log(data);
		setBrandId(data);
		localStorage.setItem('brand_id', data);
		const evt = new CustomEvent('brand-changed', { detail: { brandID: data } });
		document.dispatchEvent(evt);
	}
	function handleChangeOutlet(data) {
		console.log(data);
		setOutletId(data);
		localStorage.setItem('outlet_id', data);
		const evt = new CustomEvent('outlet-changed', { detail: { outletID: data } });
		document.dispatchEvent(evt);
	}

	useMemo(() => {
		document.addEventListener('brand-changed', function () {
			setIsBrandChanged(!isBrandChanged);
		});
		document.addEventListener('outlet-changed', function () {
			setIsOutletChanged(!isOutletChanged);
		});
	});

	useMemo(() => {
		document.addEventListener('outlet-list-modified', function () {
			setIsOutletListModified(!isOutletListModified);
		});
	});

	useMemo(() => {
		document.addEventListener('brand-list-modified', function () {
			setIsBrandListModified(!isBrandListModified);
		});
	});

	return (
		<>
			{/* <!-- Main Header--> */}
			<header className="justify-content-between d-flex align-items-center">
				<div className="header-in d-flex w-100 justify-content-between align-items-center">
					<div className="header-search">
						{/* <input className="form-control" type="text" placeholder="Default input" aria-label="default input example"> */}
					</div>
					<div className="header-right">
						<ul className="header-right-menu d-flex gap-2 m-0">
							<li>
								<div className='form-group header-drop'>
									<Dropdown
										className='form-control'
										id="brand_id"
										placeholder='Select Brand'
										search
										fluid
										selection
										options={brandList}
										value={brandId}
										onChange={(event, data) => { handleChangeBrand(data.value); }}
									/>
								</div>
							</li>
							<li>
								<div className='form-group header-drop'>
									<Dropdown
										className='form-control'
										id="outlet_id"
										placeholder='Select Outlet'
										search
										fluid
										selection
										options={outletList}
										value={outletId}
										onChange={(event, data) => { handleChangeOutlet(data.value); }}
									// onChange={(event, data) => { localStorage.setItem('outlet_id', data.value); setOutletId(data.value); handleReload(); }}
									/>
								</div>
							</li>
							<li>
								<div className="dropdown">
									<button className="btn p-0 d-flex align-items-center dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
										<div className='avatar'>
											<img alt="avatar" src={userData.profile_image} />
										</div>
									</button>
									<ul className="dropdown-menu">
										<li><Link className="dropdown-item " to={"/company/profile"}><i className="ti ti-user"></i> My Profile</Link></li>
										<li><Link className="dropdown-item " to={"/company/company-profile"}><i className="ti ti-building-skyscraper"></i> Company Profile</Link></li>
										<li><button className="dropdown-item " onClick={() => logout()}><i className="ti ti-logout"></i> Log Out</button></li>
									</ul>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</header>
			{/* <div className="main-header side-header">
				<div className="container-fluid">
					<div className="main-header-left">
						<a className="main-header-menu-icon e_none" href="#" id="mainSidebarToggle" onClick={toggleSidebar}><span></span></a>
					</div>
					<div className="main-header-center">
						<div className="responsive-logo">
							
						</div>
					</div>
					<div className="main-header-right">
						<div className={`dropdown main-profile-menu d-flex align-items-center`}>
							
							<button className="d-flex border-0 bg-white showpop">
								<span className="main-img-user"><img alt="avatar" src={userData.profile_image} /></span>
							</button>
							<div className="dropdown-menu">
								<span className='fixed_bg'></span>
								<div className='position-relative headerdropdown' style={{ zIndex: "99999" }}>
									<div className="header-navheading">
										<h4 className="main-notification-title">{userData.full_name}</h4>
										<p className="main-notification-text">{userData.role == 1 ? "Admin" : "Company"}</p>
									</div>
									<Link className="dropdown-item " to={"/company/profile"}>
										<i className="fe fe-edit"></i> My Profile
									</Link>
									<Link className="dropdown-item " to={"/company/company-profile"}>
										<i className="fe fe-edit"></i> Company Profile
									</Link>
									<button className="dropdown-item " onClick={() => logout()}>
										<i className="fe fe-power"></i> Log Out
									</button>
								</div>
							</div>
						</div>
						<button className="navbar-toggler navresponsive-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-4"
							aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
							<i className="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
						</button>
					</div>
				</div>
			</div> */}
			{/* <!-- End Main Header--> */}
		</>
	)
}

export default Header;