import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import Layout from '../../layout'
import Swal from "sweetalert2"
import PageHeaders from '../../components/common/pageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import toHtml from 'html-react-parser'
import { DEVMODE } from '../../utils/Constants';
import { getSessionKey, removeSessionKey, setSessionKey } from '../../helpers/commonFunctions';
import { AddTemplate} from '../../services/emailtemplatesservice'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Breadcrums from '../../components/common/breadcrumbs'
import { GetLanguageoptionList } from '../../services/globalService'





const EmailTempAdd = (props) => {
  const navigate = useNavigate()
  const params = useParams()
  const [options, setOptions] = useState(['']);
  const [submitted, setSubmitted] = useState(false)
  const breadcrumbs = [
    {
        title: "Dashboard",
        url: "/admin/dashboard"
    },
    {
        title: "Email Templates",
        url: "/admin/email-templates/1"
    },
    {
        title: "Add",
        url: ""
    },
]

 
  useEffect(() => {
    /**DEV MODE >>>> */
    document.addEventListener('keydown',function(e){
        // checkDevModeSession();
    });
    document.addEventListener('mousemove',function(e){
        // checkDevModeSession();
    });
    // checkDevModeSession();
    /**DEV MODE <<<< */
}, [])
    const setOptionValue = (setFieldValue)=>{
        const inputs = document.getElementsByClassName("option_input");
        let values = [];
        for(let i = 0; i < inputs.length; i++){
            if(inputs[i].value || document.activeElement === inputs[i]){
                values.push(inputs[i].value);
            }
        }
        setOptions(values);
        setFieldValue('options',values.toString());
    }

    const addOption = ()=>{
        let inputs = document.getElementsByClassName("option_input");
        let values = [...options];
        values.push("");
        setOptions(values);
        setTimeout(() => {
            inputs = document.getElementsByClassName("option_input");
            inputs[inputs.length-1].focus();
        }, 200);
    }

    const removeOption = (setFieldValue,ind)=>{
        let values = [...options];
        if(values.length<=1){
            values=[""];
        }else{
            values.splice(ind,1);
        }
        setOptions(values);
        setFieldValue('options',values.toString());
    }

    // const validateOptions = ()=>{
    //     let flag = false;
    //     let inputs = document.getElementsByClassName("option_input");
    //     let inputsErrors = document.getElementsByClassName("option_errors");
    //     if(inputs.length > 1){
    //         for(let i = 0; i < inputs.length; i++){
    //             if(!inputs[i].value || inputs[i].value === ""){
    //                 flag = false;
    //                 break
    //             }else{
    //                 flag = true;
    //             }
    //         }
    //         for(let i = 0; i < inputs.length; i++){
    //             if(!inputs[i].value || inputs[i].value === ""){
    //                 inputsErrors[i].innerText = "This field is required";
    //             }else{
    //                 inputsErrors[i].innerText = "";
    //             }
    //         }
    //     }else{
    //         flag = true;
    //     }
    //     return flag;
    // }

    const validateOptions = () => {
        let flag = true;
        let inputs = document.getElementsByClassName("option_input");
        let inputsErrors = document.getElementsByClassName("option_errors");
        for (let i = 0; i < inputs.length; i++) {
            let value = inputs[i].value.trim();
            if (!value || value === "") {
                inputsErrors[i].innerText = "This field is required";
                flag = false;
            } else if (value.length > 20) {
                inputsErrors[i].innerText = "Cannot exceed 20 characters";
                flag = false;
            } else {
                inputsErrors[i].innerText = "";
            }
        }
    
        return flag;
    };


const checkDevModeSession = ()=>{
    try{
        let sKey = getSessionKey('devmodetime');
        let oldDate = new Date(sKey);
        let oldTime = oldDate.getTime();
        let newDate = new Date();
        let newTime = newDate.getTime();
        let diff = newTime - oldTime;
        if(diff <= DEVMODE.SESSION_TIME){
            
        }else{
            logoutDevMode();
        }
    }catch(err){
        logoutDevMode();
    }
}

const logoutDevMode = ()=>{
    removeSessionKey('devmode');
    removeSessionKey('devmodetime');
    if(window.location.href.includes('/email-templates/add')){
        navigate('/admin/email-templates/1');
    }
}



  return (
    <>
    <Layout>
        <Breadcrums data={breadcrumbs}/>
        <Formik
            enableReinitialize 
            initialValues={{
                title: "",
                subject: "",
                description: "",
                options: "",
                slug: "",
                type: "",
            }}
            // validate={values => {
            //     const error = {};
            //     if (!values.title) error.title = "Title is required";
            //     if (!values.subject) error.subject = "Subject is required";
            //     if (!values.description) error.description = "Description is required";
            //     if (!values.slug) error.slug = "Slug is required";
            //     if (!values.type) error.type = "Type is required";
            //     if (!validateOptions()) error.options = "Option is required";
            //     return error;
            // }}
            validate={values => {
                const error = {};
                if (!values.title) error.title = "Title is required";
                if (!values.subject) error.subject = "Subject is required";
                if (!values.description) error.description = "Description is required";
                if (!values.slug) error.slug = "Slug is required";
                if (!values.type) error.type = "Type is required";
                if (!validateOptions()) error.options = "Option is required";
                if (values.title && values.title.length > 20) error.title = "Title cannot exceed 20 characters";
                if (values.description && values.description.length > 200) error.description = "Description cannot exceed 200 characters";
                if (values.subject && values.subject.length > 20) error.subject = "Subject cannot exceed 20 characters";
                if (values.slug && values.slug.length > 20) error.slug = "Slug cannot exceed 20 characters";
                if (values.type && values.type.length > 20) error.type = "Type cannot exceed 20 characters";
                return error;
            }}
            
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitted(true)
                let formData = new FormData();
                formData.append('title', values.title);
                formData.append('subject', values.subject);
                formData.append('description', values.description);
                formData.append('options', values.options);
                formData.append('type', values.type);
                formData.append('slug', values.slug);
                AddTemplate(formData).then(response => {
                    setSubmitting(false);
                    if(response.data.status === 1 || response.data.status === "1"){
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response?.data?.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            navigate(`/admin/email-templates/1`)
                        }, 1000);
                    }else{
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.error[0].msg,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        setSubmitted(false)
                    }
                    // resetForm({ values: '' });
                }).catch(error => {
                    console.log("error ====> ", error);
                })
            }}
        >{({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,

        }) => (
            <form onSubmit={handleSubmit}>
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-3">Add Email Template</h6>
                                </div>
                                <div className="row row-sm">
                                    <div className='col-md-12 text-center form-group'>
                                        <label htmlFor='title' className='text-left d-flex'>Title <span className="requirestar">*</span> </label>
                                        <input name='title' id='title' placeholder='Enter title' type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
                                        <span className='text-danger d-flex text-left'>{errors.title && touched.title && errors.title}</span>
                                    </div>
                                    <div className='col-md-12 text-center form-group'>
                                        <label htmlFor='subject' className='text-left d-flex'>Subject <span className="requirestar">*</span></label>
                                        <input name='subject' type='text'  placeholder='Enter subject' id="subject" onChange={handleChange} onBlur={handleBlur} value={values.subject} className='form-control' />
                                        <span className='text-danger d-flex text-left'>{errors.subject && touched.subject && errors.subject}</span>
                                    </div>
                                    <div className='col-md-6 text-center form-group'>
                                        <label htmlFor='slug' className='text-left d-flex'>Slug<span className="requirestar">*</span> </label>
                                        <input name='slug' type='text' id="slug"  placeholder='Enter slug' onChange={handleChange} onBlur={handleBlur} value={values.slug} className='form-control' />
                                        <span className='text-danger d-flex text-left'>{errors.slug && touched.slug && errors.slug}</span>
                                    </div>
                                    <div className='col-md-6 text-center form-group'>
                                        <label htmlFor='type' className='text-left d-flex'>Type<span className="requirestar">*</span> </label>
                                        <input name='type' type='text'   placeholder='Enter type' id="type" onChange={handleChange} onBlur={handleBlur} value={values.type} className='form-control' />
                                        <span className='text-danger d-flex text-left'>{errors.type && touched.type && errors.type}</span>
                                    </div>
                                    <div className='col-12'>
                                        <label htmlFor={"option"+(options.length-1)} className='text-left d-flex'>Dynamic Options<span className="requirestar">*</span> </label>
                                    </div>
                                    {
                                        options.map((opt,optInd)=>{
                                            return (
                                                <div className='col-xl-3 col-md-4 text-center form-group' key={optInd}>
                                                    <div className='d-flex'>
                                                        <input name='option'  placeholder='Enter dynamic option' style={{flex:1}} type='text' id={"option"+optInd}
                                                        onChange={()=>{setOptionValue(setFieldValue)}} onBlur={handleBlur} 
                                                        value={opt} className='form-control option_input' />
                                                        {/* <button type="button" className='ml-2 op_button danger' onClick={()=>{removeOption(setFieldValue,optInd)}}>
                                                                <i className="ti-close"></i>
                                                            </button> */}
                                                        {
                                                            optInd === 0?
                                                            opt !=="" ?
                                                            <button type="button" className='ml-2 op_button danger' onClick={()=>{removeOption(setFieldValue,optInd)}}>
                                                                    <i className="ti-close"></i>
                                                                </button>
                                                                :""
                                                                :
                                                                <button type="button" className='ml-2 op_button danger' onClick={()=>{removeOption(setFieldValue,optInd)}}>
                                                                <i className="ti-close"></i>
                                                            </button>
                                                        }
                                                        
                                                        {
                                                            optInd===(options.length-1)?
                                                            <button type="button" className='ml-2 op_button success' onClick={addOption}>
                                                                <i className="ti-plus"></i>
                                                            </button>
                                                            :""
                                                        }
                                                        
                                                    </div>
                                                    <span className='text-danger option_errors d-flex text-left'></span>
                                                </div>
                                            )
                                        })
                                    }
                                    
                                    <div className='col-lg-12 text-center form-group'>
                                    <label htmlFor='subject' className='text-left d-flex'>Description <span className="requirestar">*</span></label>
                                        {/* <CKEditor 
                                            initData={toHtml(values.description)}
                                            onChange={ ( { editor } ) => {
                                                setFieldValue("description",editor.getData());
                                            } }
                                            onInstanceReady={ ( { editor } ) => {
                                                editor.setData(toHtml(values.description));
                                            } }
                                        /> */}

                                        <CKEditor
                                            editor={ ClassicEditor }
                                            // config={ editorConfiguration }
                                            data={values.description}
                                            config={{
                                                placeholder: 'Enter description',
                                              }}
                                            onReady={ editor => {
                                                console.log( 'Editor is ready to use!', editor );
                                            } }
                                            onChange={ ( event, editor ) => {
                                                setFieldValue("description", editor.getData());
                                            } }
                                            onBlur={ ( event, editor ) => {
                                                console.log( 'Blur.', editor );
                                            } }
                                            onFocus={ ( event, editor ) => {
                                                console.log( 'Focus.', editor );
                                            } }
                                        />
                                        <span className='text-danger d-flex text-left'>{errors.description && touched.description && errors.description}</span>
                                    </div>
                                    <div className="mt-5">
                                        <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                            Submit
                                        </button>
                                        <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                            <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )}
        </Formik>
    </Layout>
</>
  )
}

export default EmailTempAdd