import React, { useState } from 'react';
import { Card, Container, Row, Col, Form, Button, Table, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import Breadcrums from '../../components/company_panel/common/breadcrumbs';

const OrderEdit = () => {
    const breadcrumbs = [
        {
            title: "Purchase Order",
            url: "/company/store_manager/purchase_order/list/1"
        },
        {
            title: "Edit Order",
            url: ""
        },
    ];

    const [selectedOutlet, setSelectedOutlet] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [arrayData, setArrayData] = useState([]);
    const [isItem, setIsItem] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');


    const getTodayDate = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1); // Set to tomorrow's date
        const year = tomorrow.getFullYear();
        let month = tomorrow.getMonth() + 1;
        let day = tomorrow.getDate();

        if (month < 10) {
            month = `0${month}`; // Add leading zero for months < 10
        }
        if (day < 10) {
            day = `0${day}`; // Add leading zero for days < 10
        }

        return `${year}-${month}-${day}`;
    };

    const validationSchema = Yup.object().shape({
        brand_id: Yup.string().required('Supplier is required'),
        outlet: Yup.string().required('Outlet is required'),
        deliveryDate: Yup.date()
            .required('Delivery Date is required')
            .min(Yup.ref('deliveryDate'), 'Delivery Date must be after or equal to today')
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            brand_id: '',
            outlet: '',
            deliveryDate: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // Handle form submission
            console.log(values);
        },
    });

    const handleDecrement = (index) => {
        const updatedArrayData = [...arrayData];
        if (updatedArrayData[index].quantity > 0) {
            updatedArrayData[index].quantity -= 1;
            setArrayData(updatedArrayData);
        }
    };

    const handleIncrement = (index) => {
        const updatedArrayData = [...arrayData];
        updatedArrayData[index].quantity += 1;
        setArrayData(updatedArrayData);
    };

    const handleRemoveItem = (index) => {
        const updatedArrayData = arrayData.filter((_, i) => i !== index);
        setArrayData(updatedArrayData);
    };

    const handleAddHandler = (val) => {
        let filterData = arrayData.find((item) => item.name == val)
        if (filterData) {
            let ms = arrayData.filter((item) => item.name != val)
            setArrayData(ms)
        } else {
            setArrayData((prev) => [...prev, { name: val, quantity: 1 }])
        }

    }

    const handleAddItem = () => {
        setShowModal(false);
    };

    const handleSearch = () => {
        // Handle search functionality
    };

    const handlePriceChange = (e, index) => {
        const { value } = e.target;
        const updatedArrayData = [...arrayData];
        updatedArrayData[index].price = Number(value);
        setArrayData(updatedArrayData);
    };

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Purchase order</h4>
                </div>
                <div className="page-header-right ms-auto">
                    {/* Any content for page header right */}
                </div>
            </div>

            <div className="row row-sm">
                <div className="col-lg-12">
                    <Card className="custom-card">
                        <Card.Body>
                            <div className="transfer-form-container">
                                <Container fluid className="p-0">
                                    <Row className="m-0">
                                        <Col lg={12} md={12} className="p-0">
                                            <Card style={{ border: 'none' }}>
                                                <Card.Body>
                                                    <Form onSubmit={formik.handleSubmit}>
                                                        <Form.Group controlId="outletSelect" className="row">
                                                            <div className="col-lg-6 form-group mt-3">
                                                                <label htmlFor="brand_id" className="text-left">
                                                                    Supplier: <span className="requirestar">*</span>
                                                                </label>
                                                                <InputGroup style={{ width: '100%', marginBottom: '20px' }}>
                                                                    <Form.Control
                                                                        as="select"
                                                                        id="brand_id"
                                                                        className={`form-control ${formik.touched.brand_id && formik.errors.brand_id ? 'is-invalid' : ''}`}
                                                                        name="brand_id"
                                                                        value={formik.values.brand_id}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    >
                                                                        <option value="">Select Supplier*</option>
                                                                        {/* {brandList.length ? brandList.map((row) => (
                                       <option key={row.id} value={row.id}>{row.name}</option>
                                         )) : ''} */}
                                                                    </Form.Control>
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {formik.touched.brand_id && formik.errors.brand_id}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </div>


                                                            {/* Outlet Dropdown */}
                                                            <div className="col-lg-6">
                                                                <Form.Label style={{ width: '100%', marginTop: '11px' }}>Outlet:*</Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    value={selectedOutlet}
                                                                    onChange={(e) => setSelectedOutlet(e.target.value)}
                                                                    className={`form-control ${formik.touched.outlet && formik.errors.outlet ? 'is-invalid' : ''}`}
                                                                >
                                                                    <option value="">United States</option>
                                                                    {/* {outlets.map((outlet) => (
        <option key={outlet.id} value={outlet.id}>{outlet.name}</option>
      ))} */}
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {formik.touched.outlet && formik.errors.outlet}
                                                                </Form.Control.Feedback>
                                                            </div>
                                                        </Form.Group>

                                                        {/* Delivery Date */}
                                                        <Form.Group controlId="deliveryDate">
                                                            <Form.Label>Delivery Date:*</Form.Label>
                                                            <Form.Control
                                                                style={{ width: '754px', marginBottom: '20px' }}
                                                                type="date"
                                                                name="deliveryDate"
                                                                placeholder="Select Delivery Date"
                                                                value={formik.values.deliveryDate}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                min={getTodayDate()}
                                                                onClick={(e) => e.target.showPicker()}
                                                                className={`form-control ${formik.touched.deliveryDate && formik.errors.deliveryDate ? 'is-invalid' : ''}`}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.touched.deliveryDate && formik.errors.deliveryDate}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <div className="d-flex justify-content-between align-items-center mt-4 mb-4">
                                                            <h2 style={{ fontSize: "18px" }}>Items List</h2>
                                                            <b onClick={() => setShowModal(true)} style={{ cursor: 'pointer' }}>
                                                                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />  Browse Items
                                                            </b>
                                                        </div>

                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Item Name</th>
                                                                    <th>Quantity</th>
                                                                    <th>Price</th>
                                                                    <th>Total Price</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {isItem && arrayData.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item.name}</td>
                                                                        <td className="d-flex align-items-center">
                                                                            <Button variant="outline-secondary" size="sm" onClick={() => handleDecrement(index)}>-</Button>
                                                                            <span className="mx-2">{item.quantity}</span>
                                                                            <Button variant="outline-secondary" size="sm" onClick={() => handleIncrement(index)}>+</Button>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>SAR</InputGroup.Text>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    value={item.price}
                                                                                    onChange={(e) => handlePriceChange(e, index)}
                                                                                />
                                                                            </InputGroup>
                                                                        </td>
                                                                        <td>${item.quantity * item.price}</td>
                                                                        <td>
                                                                            <Button variant='danger' size="sm" onClick={() => handleRemoveItem(index)}>
                                                                                <FontAwesomeIcon icon={faTimes} />
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>

                                                        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Browse Items</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <InputGroup className="mb-3">
                                                                    <FormControl
                                                                        placeholder="Search items"
                                                                        value={searchTerm}
                                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                                    />
                                                                    <Button variant="outline-secondary" onClick={handleSearch}>Search</Button>
                                                                </InputGroup>
                                                                {['Item 1', 'Item 2', 'Item 3'].map((item, index) => (
                                                                    <Form.Check
                                                                        key={index}
                                                                        type="checkbox"
                                                                        label={item}
                                                                        onChange={() => handleAddHandler(item)}
                                                                    />
                                                                ))}
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                                                                <Button variant="primary" onClick={handleAddItem}>Add Item</Button>
                                                            </Modal.Footer>
                                                        </Modal>

                                                        <div style={{ paddingTop: '50px' }}>
                                                            <Button variant="primary" type="submit" size="lg" disabled={!formik.isValid}>
                                                                Next
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default OrderEdit;
