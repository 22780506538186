import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import { useNavigate, useParams } from 'react-router-dom'
import { GetCompanyDetail } from '../../services/companyService'
import toHtml from 'html-react-parser'
import { formateDate, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/common/breadcrumbs'
import Loader from '../../components/common/loader'
import { GetSingleSubscription } from '../../services/subscriptionService'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ViewSubscription = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})
    const breadcrumbs = [{title: "Dashboard",url: "/admin/dashboard"},{title: "Subscription",url: "/admin/subscription"},{title: "View",url: ""}]

    useEffect(() => {
        GetSingleSubscription({id: params.id}).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])


    return (
        <>
            <Layout>
                <Breadcrums data={breadcrumbs} />
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-3">View Subscription</h6>
                                </div>
                                {showDefault && Object.keys(showDefault).length > 0 ? <div className='row'>
                                    <div className="col-lg-12 form-group">
                                        <table id="simple-table" className="table  table-bordered table-hover">
                                            <tbody>
                                                <tr>
                                                    <th>Name</th>
                                                    <td>{showDefault && showDefault.name ? showDefault.name : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Amount</th>
                                                    <td>{showDefault && showDefault.amount ? showDefault.amount : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Months</th>
                                                    <td>{showDefault && showDefault.month ? showDefault.month : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Access and Benefits</th>
                                                    {/* <td>{showDefault && showDefault.access_and_benefits ? showDefault.access_and_benefits : "N/A"}</td> */}
                                                    <CKEditor disabled={true}
                                                        editor={ ClassicEditor }
                                                        // config={ editorConfiguration }
                                                        data={showDefault.access_and_benefits}
                                                        onReady={ editor => {
                                                            console.log( 'Editor is ready to use!', editor );
                                                        } }
                                                        onBlur={ ( event, editor ) => {
                                                            console.log( 'Blur.', editor );
                                                        } }
                                                        onFocus={ ( event, editor ) => {
                                                            console.log( 'Focus.', editor );
                                                        } }
                                                    />
                                                </tr>
                                                  
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{showDefault && showDefault.status ? "Active" : "Inactive"}</td>
                                                </tr>

                                            </tbody>
                                          
                                        </table>
                                        <div className="">
                                            <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div> : <Loader />}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ViewSubscription