import axiosInstance from "../../utils/axiosCompany";

export const CreateInventory = async (values) => {
    return await axiosInstance().post("company/inventory/add-inventory", values);
}

export const GetInventoryList = async (values) => {
    return await axiosInstance().post("company/inventory/list-inventories", values);
}

export const GetAllInventories = async (values) => {
    return await axiosInstance().post("company/inventory/get-all-inventories", values);
}

export const GetSingleInventory  = async (values) => {
    return await axiosInstance().post("company/inventory/get-single-inventory", values);
}

export const EditInventory = async (values) => {
    return await axiosInstance().post("company/inventory/update-inventory", values)
}

export const ChangeInventoryStatus = async (values) => {
    return await axiosInstance().post("company/inventory/change-inventory-status", values)
}

export const DeleteInventory = async (values) => {
    return await axiosInstance().post("company/inventory/delete-inventory", values);
}


export const getOutletInventoryStockList = async (values) => {
    return await axiosInstance().post("company/inventory/get-outlet-inventory-stock-list", values);
}

export const ImportedInventory = async (values) => {
    return await axiosInstance().post("company/inventory/validate-import-file", values);
}

// export const ImportedInventoryValidate = async (values) => {
//     return await axiosInstance().post("company/inventory/validate-imported-file", values);
// }
