import React, { useState, useEffect, useMemo } from 'react'
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { GetChangeStatus, GetTenderList, DeleteTender, CreateTender, GetTenderDetail, GetEditTender } from '../../../services/company_panel/setting/tenderConfigurationService';
import Swal from 'sweetalert2'
import CustomPagination from '../../../components/company_panel/common/custompagination'
import Sorting from '../../../components/company_panel/common/sorting';
import Loader from '../../../components/company_panel/common/loader'
import $ from "jquery";
import { capitalizeFirstLetter, getBrandId, getCompany, removeModalBackdrops } from '../../../helpers/commonFunctions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationTab from '../configurationTab';
import * as yup from 'yup';
import { ChangeAddOnCategoryStatus, ChangeStatusAddOn, CreateAddOnCategory, DeleteAddOn, DeleteAddOnCategory, editAddOnCategory, GetAddOnCategoryList, GetSingleAddOnCategory, ListAddOn } from '../../../services/company_panel/menuAddOnCategory';
import moment from 'moment';
import CustomRangepicker from '../../../components/company_panel/common/rangepicker';
import { showFilterlist } from '../../../utils/Constants';
import StatusFilter from '../../../components/company_panel/common/statusFilter';
import notFound from '../../../assets/company/img/no-record-found.png'


const AddOnsList = () => {
    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const [tenderType, setTenderType] = useState('AddOns')
    const [name, setName] = useState('Name')
    const [showDefault, setShowDefault] = useState({})
    const [actionType, setActionType] = useState('add');
    const [catId, setCatId] = useState(null)
    const [orderMode, setOrderMode] = useState([]);
    const [isBrandChanged, setIsBrandChanged] = useState(false);



    const handleChangeOrderMode = (selected) => {
        formik.setFieldValue("orderMode", selected);
    };

    useEffect(() => {
        return () => {
          removeModalBackdrops();
        }
      }, []);



    useEffect(() => {
        if (tenderType === 'Category') {
            if (search) {
                setLoader(true);
                const formData = new FormData();
                formData.append("page", params.id);
                formData.append("per_page", itemPerPage);
                formData.append("sort", JSON.stringify(sorting));
                formData.append("search", JSON.stringify(search));
                formData.append("global_search", globalSearch);
                formData.append('tender_type', tenderType);
                formData.append('company_id', getCompany().company_id);
                formData.append('brand_id', getBrandId());
                GetAddOnCategoryList(formData)
                    .then(data => {
                        setDataLength(data.data.response.count);
                        setList(data && data.data && data.data.response ? data.data.response.rows : []);
                        setLoader(false);
                    })
                    .catch(error => {
                        console.log("error ====> ", error);
                    });
            }
            generateSKU();
        }
    }, [location, statsUpdate, sorting, search, globalSearch, tenderType, isBrandChanged]);

    useEffect(() => {

        if (tenderType === 'AddOns') {
            if (search) {
                setLoader(true);
                const formData = new FormData();
                formData.append("page", params.id);
                formData.append("per_page", itemPerPage);
                formData.append("sort", JSON.stringify(sorting));
                formData.append("search", JSON.stringify(search));
                formData.append("global_search", globalSearch);
                formData.append('tender_type', tenderType);

                formData.append('brand_id', getBrandId());
                ListAddOn(formData)
                    .then(data => {
                        setDataLength(data.data.response.count);
                        setList(data && data.data && data.data.response ? data.data.response.rows : []);
                        setLoader(false);
                    })
                    .catch(error => {
                        console.log("error ====> ", error);
                    });
            }
            generateSKU();
        }
    }, [location, statsUpdate, sorting, search, globalSearch, tenderType, isBrandChanged]);



    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        setSearch({})
        // prepareSearch()
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    // Delete functionality
    function deleteFunction(row) {
        if (tenderType === 'Category') {
            console.log(row);
            Swal.fire({
                customClass: 'swal-wide',
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2f9bff',
                cancelButtonColor: '#dc3545',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    const formdata = new FormData();
                    formdata.append("id", row);
                    DeleteAddOnCategory(formdata).then(data => {
                        if (data.status === 200) {
                            Swal.fire({
                                customClass: 'swal-wide',
                                position: 'absolute',
                                icon: 'success',
                                title: data.data.message,
                                showConfirmButton: false,
                                timer: 2000,
                                toast: true,
                                position: 'top-right',
                            });
                            setStatusUpdate(!statsUpdate);
                        }
                    }).catch(error => {
                        console.log("deleteError", error);
                    });
                }
            });
        }
        if (tenderType === 'AddOns') {
            console.log(row);
            Swal.fire({
                customClass: 'swal-wide',
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2f9bff',
                cancelButtonColor: '#dc3545',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    const formdata = new FormData();
                    formdata.append("id", row);
                    DeleteAddOn(formdata).then(data => {
                        if (data.status === 200) {
                            Swal.fire({
                                customClass: 'swal-wide',
                                position: 'absolute',
                                icon: 'success',
                                title: data.data.message,
                                showConfirmButton: false,
                                timer: 2000,
                                toast: true,
                                position: 'top-right',
                            });
                            setStatusUpdate(!statsUpdate);
                        }
                    }).catch(error => {
                        console.log("deleteError", error);
                    });
                }
            });
        }
    }

    // Change Status functionality

    const ChangeStatus = (row, Num) => {
        if (tenderType === 'Category') {
            const formData = new FormData()
            formData.append("id", row)
            formData.append("status", Num)
            Swal.fire({
                customClass: 'swal-wide',
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#403fad',
                cancelButtonColor: '#f1388b',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    ChangeAddOnCategoryStatus(formData).then(data => {
                        if (data.status === 200) {
                            Swal.fire({
                                customClass: 'swal-wide',
                                position: 'absolute',
                                icon: 'success',
                                title: data.data.message,
                                showConfirmButton: false,
                                timer: 2000,
                                toast: true,
                                position: 'top-right',
                            })
                            setStatusUpdate(!statsUpdate)
                        }
                    }).catch(error => {
                        console.log("error===>")
                    })
                }
            })
        }
        if (tenderType === 'AddOns') {
            const formData = new FormData()
            formData.append("id", row)
            formData.append("status", Num)
            Swal.fire({
                customClass: 'swal-wide',
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#403fad',
                cancelButtonColor: '#f1388b',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    ChangeStatusAddOn(formData).then(data => {
                        if (data.status === 200) {
                            Swal.fire({
                                customClass: 'swal-wide',
                                position: 'absolute',
                                icon: 'success',
                                title: data.data.message,
                                showConfirmButton: false,
                                timer: 2000,
                                toast: true,
                                position: 'top-right',
                            })
                            setStatusUpdate(!statsUpdate)
                        }
                    }).catch(error => {
                        console.log("error===>")
                    })
                }
            })
        }
    }

    const handleEdit = (id) => {
        navigate(`/company/menu/menu/add_ons/edit/${id}`);
    }
    const handleView = (id) => {
        console.log("view", id)
        navigate(`/company/menu/menu/add_ons/view/${id}`);
    }
    const copyOutletFunction = (id) => {
        navigate(`/company/menu/menu/add_ons/edit/${id}?copy=true`);
    }
    // edit functionality

    const editFunction = (row) => {
        setCatId(row)
        GetSingleAddOnCategory({ 'id': row }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
            setOrderMode(data && data.data && data.data.response ? data.data.response.order_modes : []);
        }).catch(error => {
            console.log("error=====>", error)
        })
        setActionType('edit');
        $('#addCardModal').show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end



    // addMore 

    const tenderConfiguration = (value) => {
        setTenderType(value);
        let nameEn = name;

        if (value === 'AddOns') {
            nameEn = 'Name';
        } else if (value === 'Category') {
            nameEn = 'Name';
        }
        setName(nameEn);
    }


    const generateSKU = (length = 8) => {

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let sku = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            sku += characters[randomIndex];
        }
        formik.setFieldValue("sku", sku);
    }

    const closeModel = (id) => {
        formik.setErrors({});
        formik.resetForm();
        $('#addCardModal').hide();
        $('.modal-backdrop').remove();
        setShowDefault({});
        setStatusUpdate(!statsUpdate)
    }

    const openModel = (id, type) => {
        formik.setErrors({});
        formik.resetForm();
        setActionType(type);
        $('#' + id).show().css('opacity', 1).addClass('show');
        $('body').append('<div class="modal-backdrop fade show"></div>')
        setOrderMode([]);
    }




    const validationSchema = yup.object().shape({
        name: yup.string().trim().required('Name is required').min(3, 'Name must be at least 3 characters').max(30, 'Name must be at most 30 characters'),
        name_ar: yup.string().trim().required('Name (arabic) is required').min(3, 'Name (arabic) must be at least 3 characters').max(30, 'Name (arabic) must be at most 30 characters'),
        sku: yup.string().required('Sku is required')
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: showDefault && showDefault.name ? showDefault.name : "",
            name_ar: showDefault && showDefault.name_ar ? showDefault.name_ar : "",
            sku: showDefault && showDefault.sku ? showDefault.sku : "",
            commission: showDefault && showDefault.commission ? showDefault.commission : "",
            orderMode: showDefault && showDefault.order_modes ? showDefault.order_modes : "",
            status: showDefault && showDefault.status == 1 ? 'on' : "",
            tax: showDefault && showDefault.tax ? showDefault.tax : ""
        },
        validationSchema: validationSchema,
        onSubmit: values => {

            setSubmitted(true)
            let formData = new FormData();
            formData.append('name', values?.name?.trim());
            formData.append('name_ar', values?.name_ar?.trim());
            formData.append('status', values.status == 'on' ? 1 : 0);
            formData.append('sku', values?.sku?.trim());
            formData.append('company_id', getCompany().company_id);
            formData.append('brand_id', getBrandId());
            if (actionType === 'edit') {
                const formData = new FormData()
                formData.append('name', values?.name?.trim());
                formData.append('name_ar', values?.name_ar?.trim());
                formData.append('sku', values?.sku?.trim());
                formData.append('id', catId);
                formData.append('brand_id', getBrandId());
                editAddOnCategory(formData).then(response => {


                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            $('#addCardModal').hide();
                            $('.modal-backdrop').remove();
                            setStatusUpdate(!statsUpdate)
                            formik.resetForm();
                            setShowDefault({});
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        setSubmitted(false)
                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })

            } else {
                CreateAddOnCategory(formData).then(response => {
                    if (response.data.status === 1 || response.data.status === "1") {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            $('#addCardModal').hide();
                            $('.modal-backdrop').remove();
                            setStatusUpdate(!statsUpdate)
                            formik.resetForm();
                            setShowDefault({});
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        })
                        setSubmitted(false)
                    }
                }).catch(error => {
                    console.log("error ====> ", error);
                })

            }

        },
    });


    useEffect(() => {
        if (tenderType == 'AddOns') {
            setList([])
        }
    }, [tenderType])

    useMemo(()=>{
		document.addEventListener('brand-changed', function(){
            console.log("done *****")
			setIsBrandChanged(!isBrandChanged);
		});
	})


    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Menu</h4>
                    </div>
                    <div className='page-header-right ms-auto'>

                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationTab tab='addOns' />
                            </div>
                        </div>
                    </div>
                    <div className='gap-20 ps-0 pb-0 pe-0'>
                        <ul className='d-flex setting submenu-list border-bottom mb-0 p-0'>
                            <li className={tenderType === 'AddOns' ? 'active' : ''} onClick={() => { tenderConfiguration('AddOns') }}>Add-Ons Listing</li>
                            <li className={tenderType === 'Category' ? 'active' : ''} onClick={() => { tenderConfiguration('Category') }}>Categories</li>
                        </ul>
                    </div>
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            <div className='page-header-left'>
                                <div className='gap-2'>
                                    <div className='filter-header d-flex justify-content-between align-items-center'>
                                        <div className='d-flex gap-3 align-items-center'>
                                            <div className="form-group mb-0 rangepicker_container">
                                                <input
                                                    type="search"
                                                    className="form-control"
                                                    value={globalSearch}
                                                    placeholder="Search"
                                                    onChange={(e) => { setGlobalSearch(e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex gap-4 align-items-center' style={{ marginLeft: '10px' }} >

                                            <StatusFilter
                                                data={showFilterlist}
                                                prepareSearch={prepareSearch}
                                            />
                                            {/* <div className="date-input">
                                                <CustomRangepicker
                                                    GetDateRange={(e) => { prepareSearch("created", e) }}
                                                    resetdate={resetdate}
                                                />
                                            </div> */}
                                            <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='page-header-right ms-auto'>
                                <button className="btn btn-primary btn-lg" type="button" onClick={(e) => {
                                    if (tenderType === 'AddOns') {
                                        navigate(`/company/menu/menu/add_ons/add`);
                                    } else {
                                        openModel('addCardModal', 'add');
                                    }
                                }}>
                                    {tenderType === "AddOns" ? 'Create Add Ons' : 'Create an Add-On Category'}
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        {/* <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Sr. No  </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="id" />
                                            </div>
                                        </th> */}
                                        <th width="20%">
                                            <div className='sorting_column'>
                                                <span>{name}</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="name" />
                                            </div>
                                        </th>
                                        <th width="20%">
                                            <div className='sorting_column'>
                                                <span>{name + ' (arabic)'}</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="name_ar" />
                                            </div>
                                        </th>
                                        {/* <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Category </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="sku" />
                                            </div>
                                        </th> */}
                                        {tenderType === "Category" && (
                                            <>
                                                <th width="20%">
                                                    <div className='sorting_column'>
                                                        <span>SKU </span>
                                                        <Sorting sort={sorting} handleSort={handleSort} column="sku" />
                                                    </div>
                                                </th>
                                                <th width="15%">
                                                    <div className='sorting_column'>
                                                        <span>CreateAt </span>
                                                        <Sorting sort={sorting} handleSort={handleSort} column="sku" />
                                                    </div>
                                                </th>
                                            </>)
                                        }
                                        {tenderType === "AddOns" && (<th width="19%">
                                            <span>Category  </span>
                                        </th>)}

                                        <th width="5%">
                                            <span>Status  </span>
                                        </th>
                                        {/* <th className='status_head text-center' width="10%">Status</th> */}
                                        <th className='action_head text-end' width="20%">Actions</th>


                                    </tr>
                                </thead>
                                {tenderType === 'AddOns' && <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={12}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    {/* <td>{i + 1}</td> */}
                                                    <td>{capitalizeFirstLetter(row?.name)}</td>
                                                    <td>{capitalizeFirstLetter(row?.name_ar)}</td>

                                                    <td>{capitalizeFirstLetter(row?.menu_add_on_category?.name)}</td>
                                                    {/* <td>{moment(row?.created_at).format("DD-MM-YYYY")}</td> */}

                                                    <td>
                                                        <div className="form-check form-switch w-25 mx-auto">
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" checked={row.status == 1 ? 'checked' : ''} onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }} />
                                                            <label className="form-check-label" for="status-change"></label>
                                                        </div>
                                                    </td>
                                                    <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><div className='dropdown-item' onClick={() => { handleView(row.id) }}><i className="ti ti-eye" aria-hidden="true"></i>View</div></li>
                                                                <li><div className='dropdown-item' onClick={() => { handleEdit(row.id) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li>
                                                                <li> <div className='dropdown-item' onClick={() => { copyOutletFunction(row.id) }}><i class="ti ti-copy" aria-hidden="true"></i> Copy add-ons</div></li>

                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={11} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No record found</p>
                                                </td>
                                            </tr>
                                            }</>}
                                </tbody>}
                                {tenderType === 'Category' && <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    {/* <td>{i + 1}</td> */}
                                                    <td>{row?.name}</td>
                                                    <td>{row?.name_ar}</td>

                                                    <td>{row?.sku}</td>
                                                    <td>{moment(row?.created_at).format("DD-MM-YYYY")}</td>

                                                    <td>
                                                        <div className="form-check form-switch w-25 mx-auto">
                                                            <input className="form-check-input" type="checkbox" id="status-change" name="status" checked={row.status == 1 ? 'checked' : ''} onClick={() => { ChangeStatus(row.id, row.status == 1 ? 0 : 1) }} />
                                                            <label className="form-check-label" for="status-change"></label>
                                                        </div>
                                                    </td>
                                                    <td className='text-end'>
                                                        <div class="dropdown  d-inline-block">
                                                            <button class="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><div className='dropdown-item' onClick={() => { editFunction(row.id) }}><i class="ti ti-edit" aria-hidden="true"></i> Edit</div></li>
                                                                <li> <div className='dropdown-item' onClick={() => { deleteFunction(row.id) }}><i class="ti ti-trash" aria-hidden="true"></i> Delete</div></li>

                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr><td colSpan={11} className="text-center">No records</td></tr>}</>}
                                </tbody>}
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "add-on", path: "/company/menu/menu/add_ons/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>

                {/*  Modal*/}
                <div className="modal fade" id="addCardModal" aria-labelledby="addCardModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title lg" style={{ fontSize: '15px' }} id="addCardModalLabel">{actionType === 'edit' ? 'Edit' : 'Add'} {name}</h5>
                                <button type="button" className="btn-close" onClick={(e) => closeModel('addCardModal')} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label for="name" className="col-form-label">{name}:<span className="requirestar">*</span></label>
                                        <input type="text" className="form-control" id="name" name="name" placeholder={'Enter ' + name} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} autoComplete='off' />
                                        <span className='text-danger d-flex text-left'>{formik.errors.name && formik.touched.name && formik.errors.name}</span>
                                    </div>
                                    <div className="mb-3">
                                        <label for="name_ar" className="col-form-label">{name + ' (arabic)'}:<span className="requirestar">*</span></label>
                                        <input type="text" className="form-control" name="name_ar" id="name_ar" placeholder={'Enter ' + name} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name_ar} autoComplete='off' />
                                        <span className='text-danger d-flex text-left'>{formik.errors.name_ar && formik.touched.name_ar && formik.errors.name_ar}</span>
                                    </div>
                                    <div className="mb-3">
                                        <label for="sku" className="col-form-label">SKU:<span className="requirestar">*</span></label>
                                        <div className="set-plus-icon-input">
                                            <input type="text" name="sku" className="form-control" id="sku" placeholder='Enter SKU' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.sku} autoComplete='off' />
                                            <div className="icon-box">
                                                <a onClick={(e) => generateSKU(8)}><i className="fa fa-rotate-right"></i></a>
                                            </div>
                                        </div>
                                        <span className='text-danger d-flex text-left'>{formik.errors.sku && formik.touched.sku && formik.errors.sku}</span>
                                    </div>



                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-dark" style={{ width: '48%' }} data-bs-dismiss="modal" onClick={(e) => closeModel('addCardModal')}>Cancel</button>
                                    <button type="submit" className="btn btn-primary" style={{ width: '48%' }}>{actionType === 'edit' ? 'Update' : 'Add'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />

            </div>
        </>
    );
}

export default AddOnsList