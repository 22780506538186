import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetOutletDetail, DeleteHardwareDevice, GetBrandOutletsList } from '../../services/company_panel/outletService';
import { GetSingleOrderDetails, RefundStatus } from '../../services/company_panel/orderService';
import toHtml from 'html-react-parser'
import { capitalizeAllFirstLetters, formateDate, formateDateWithTime, getBrandId, SpecialCharacter, TrimText } from '../../helpers/commonFunctions';
import Breadcrums from '../../components/company_panel/common/breadcrumbs'
import Loader from '../../components/company_panel/common/loader'
import Swal from 'sweetalert2'
import { Modal, Button, Form } from 'react-bootstrap';


const RefundOrderView = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [orderDetails, setOrderDetails] = useState({});
    const [outletList, setOutletList] = useState({});
    const breadcrumbs = [
        {
          title: "Orders ",
          url: "/company/transaction/order/list/1"
        },
        {
          title: "Order Detail",
          url: ""
        },
      ]
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [refundOrderId, setRefundOrderId] = useState(null);
    const [refundReason, setRefundReason] = useState('');

    useEffect(() => {
        GetSingleOrderDetails({ id: params.id }).then(data => {
            setOrderDetails(data && data?.data && data?.data?.response ? data?.data?.response : [])
            setRefundOrderId(data?.data?.response?.id)
        }).catch(error => {
            console.log("error=====>", error)
        })
        GetBrandOutletsList({ brand_id: getBrandId() }).then(data => {
            setOutletList(data && data?.data && data?.data?.response ? data?.data?.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [statsUpdate]);

    console.log("refundOrderId--->", refundOrderId);

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };
    const handleRefundSubmit = () => {
        const formData = new FormData()
        formData.append("id", refundOrderId)
        formData.append("refund_reason", refundReason)
        formData.append("payment_status", "refunded")
        RefundStatus(formData).then(response => {
            setShowRefundModal(false);
            Swal.fire({
                customClass: 'swal-wide',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000,
                toast: true,
                position: 'top-right',
            })
            setRefundReason('')
            setStatusUpdate(!statsUpdate);
        }).catch(error => {
            console.log("error ====> ", error);
        })

    };

    return (
        <>

            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Orders</h4>
                </div>
                <div class="page-header-right ms-auto">

                </div>
            </div>
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h3 className="main-content-label mb-3">Orders Info</h3>
                            </div>
                            <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table table">
                                        <tbody>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Oder ID</td>
                                                    <td>{orderDetails?.order_id ? orderDetails.order_id : "N/A"}</td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Date & Time</td>
                                                    <td>{orderDetails && orderDetails.updated_at ? formateDateWithTime(orderDetails?.updated_at) : "N/A"}</td>
                                                </div>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <div>
                                        <h3 className="main-content-label mb-3">Outlet Details</h3>
                                    </div>
                                    <table id="simple-table" className="table table">
                                        <tbody>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Outlet name</td>
                                                    <td>
                                                        {/* {orderDetails?.outlet_id} */}
                                                        {capitalizeFirstLetter(orderDetails?.outlet?.outlet_name)}
                                                        {/* {outletList?.find((outlet) => outlet?.id === Number(orderDetails?.outlet_id))?.outlet_name} */}
                                                    </td>
                                                </div>
                                            </tr>
                                            <tr>
                                                <div className='d-flex justify-content-between'>
                                                    <td>Outlet location</td>
                                                    <td>{capitalizeAllFirstLetters(orderDetails?.outlet?.location) ? capitalizeAllFirstLetters(orderDetails?.outlet?.location) : 'N/A'}</td>
                                                </div>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <div className='row row-sm'>
                                        <div className='col-lg-6 col-md-6 animation_fade'>
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                    <h3 className="main-content-label mb-3">Other Details</h3>
                                                    <table id="simple-table" className="table ">
                                                        <>
                                                            <tbody style={{ border: 'none' }}>
                                                                <tr>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td>Customer name :</td>
                                                                        <td>{capitalizeAllFirstLetters(orderDetails?.customer?.customer_name)}</td>
                                                                    </div>
                                                                </tr>
                                                                {/* <tr>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td>Customer name (Arabic) :</td>
                                                                        <td>{orderDetails?.customer_name_ar ? orderDetails?.customer_name_ar : 'N/A'}</td>
                                                                    </div>
                                                                </tr> */}
                                                                <tr>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td>Customer Phone Number :</td>
                                                                        <td>{orderDetails?.customer?.customer_number}</td>
                                                                    </div>
                                                                    {/* <div className='d-flex justify-content-between'>
                                                                        <td>Customer Address :</td>
                                                                        <td>{capitalizeAllFirstLetters(orderDetails?.customer_address)}</td>
                                                                    </div> */}
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td> Payment method :</td>
                                                                        <td>{capitalizeFirstLetter(orderDetails?.transaction?.payment_method)}</td>
                                                                    </div>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td> Order payment status :</td>
                                                                        <td>{capitalizeFirstLetter(orderDetails?.transaction?.payment_status)}</td>
                                                                    </div>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td>Order Status :</td>
                                                                        <td>{capitalizeFirstLetter(orderDetails?.order_status)}</td>
                                                                    </div>
                                                                </tr>

                                                            </tbody>
                                                        </>
                                                    </table>
                                                </div>
                                                <div className="card-body">
                                                    <h3 className="main-content-label mb-3">Tip Details</h3>
                                                    <table id="simple-table" className="table ">
                                                        <>
                                                            <tbody style={{ border: 'none' }}>
                                                                <tr>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td>Tip Amount :</td>
                                                                        <td>{orderDetails?.tip_amount ? orderDetails?.tip_amount  : 'N/A'}</td>
                                                                    </div>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <td>Tip Type :</td>
                                                                        <td>{capitalizeFirstLetter(orderDetails?.tip_type)}</td>
                                                                    </div>
                                                                </tr>
                                                            </tbody>
                                                        </>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 animation_fade">
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                    <h3 className="main-content-label mb-3">Order Summary</h3>

                                                    {/* Order Items List */}
                                                    {orderDetails?.customer_order_menu_item_relations?.map((menuItem, index) => (
                                                        <div key={index} className="order-item mb-3 d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <img src={menuItem?.menu_item?.logo} alt="Cheese Burger" className="order-item-img me-3" />
                                                                <div>
                                                                    <p className="order-item-name" style={{ fontWeight: '500' }}>{capitalizeAllFirstLetters(menuItem?.menu_item?.item_name)}</p>
                                                                    <p className="order-item-price">{menuItem?.menu_item?.default_price} {menuItem?.menu_item?.default_price ? "SAR" : ""}</p>
                                                                </div>
                                                            </div>
                                                            <span className="order-item-quantity" style={{ fontWeight: '500' }}>x{menuItem?.quantity}</span>
                                                        </div>
                                                    ))}
                                                    {orderDetails?.customer_order_meal_combo_relations?.map((mealCombo, index) => (
                                                        <div key={index} className="order-item mb-3 d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <img src={mealCombo?.menu_meal_combo?.logo} alt="Cheese Burger" className="order-item-img me-3" />
                                                                <div>
                                                                    <p className="order-item-name" style={{ fontWeight: '500' }}>{capitalizeAllFirstLetters(mealCombo?.menu_meal_combo?.name)}</p>
                                                                    <p className="order-item-price">{mealCombo?.menu_meal_combo?.price} {mealCombo?.menu_meal_combo?.price ? "SAR" : ""}</p>
                                                                </div>
                                                            </div>
                                                            <span className="order-item-quantity" style={{ fontWeight: '500' }}>x{mealCombo?.quantity}</span>
                                                        </div>
                                                    ))}
                                                    {/* Order Summary Details */}
                                                    <div className="order-summary-details mt-4">
                                                        <div className="d-flex justify-content-between">
                                                            <span>Sub-Total</span>
                                                            <span>{orderDetails?.sub_total_price} SAR</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span>Taxes</span>
                                                            <span>{orderDetails?.tax_total_price} SAR</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span>Discount</span>
                                                            <span>{orderDetails?.discount_price} SAR</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span>Surcharge</span>
                                                            <span>{orderDetails?.surcharge_price} SAR</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between fw-bold mt-3">
                                                            <span>Total</span>
                                                            <span>{orderDetails?.total_price} SAR</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                     {orderDetails?.transaction?.payment_status === 'paid' && orderDetails?.order_status === 'pending' ? 
                                    <div className=" mt-2">
                                        <button className="btn btn-primary" type='button' onClick={() => { setShowRefundModal(true); }}>
                                            {/* <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i> */}
                                            Refund Order
                                        </button>
                                    </div>
                                    :null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-5 col-md-5 animation_fade"></div> */}
            </div>
            <Modal show={showRefundModal} onHide={() => setShowRefundModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Refund Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="refundReason">
                            <Form.Label>Enter reason to refund order</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={refundReason}
                                onChange={(e) => setRefundReason(e.target.value)}
                                placeholder="Enter reason"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-primary" onClick={() => { setShowRefundModal(false); setRefundReason(''); }}>
                        Close
                    </button>
                    <Button variant="primary" onClick={handleRefundSubmit} disabled={!refundReason}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RefundOrderView