import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrums from '../../components/company_panel/common/breadcrumbs'
import { LoyaltyPointDetails } from '../../services/company_panel/loyaltypoints.service'
import Loader from '../../components/company_panel/common/loader'

const LoyaltyPointView = () => {

    const breadcrumbs = [{ title: "Loyalty Points", url: "/company/crm/loyalty-points/list/1" },{
        title: "Loyalty Point Details",
        url: ""
    },]

    const navigate = useNavigate()
    const params = useParams()
    const [showDefault, setShowDefault] = useState({})

    useEffect(() => {
        LoyaltyPointDetails({ id: params.id }).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Loyalty Point</h4>
                </div>
            </div>
            <div className="card custom-card">
            {showDefault && Object.keys(showDefault).length > 0 ?
                    <div className="card-body">
                        <div className="col-lg-12 form-group">
                            <table id="simple-table" className="table  table-bordered table-hover">
                                <tbody>
                                    <tr>
                                        <th>Loyalty Point</th>
                                        <td>{showDefault && showDefault.loyalty_point ? showDefault.loyalty_point + " " + "SAR" : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Currency Value</th>
                                        <td>{showDefault && showDefault.currency_value ? showDefault.currency_value + " " + "SAR"  : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Bill Value Greater Than</th>
                                        <td>{showDefault && showDefault.bill_value ? showDefault.bill_value + " " + "SAR"  : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Duration Of The Expiry</th>
                                        <td>{showDefault && showDefault.duration ? showDefault.duration : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th>Calculate For Offer Or Not</th>
                                        <td>{showDefault && (showDefault.calculate_for_offer_or_not === true || showDefault.calculate_for_offer_or_not === "true")  ? "Yes"   : "No"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                     : <Loader />}
            </div>
        </>
    )
}

export default LoyaltyPointView