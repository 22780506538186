import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { Formik } from 'formik';
import { Modal, Button } from 'react-bootstrap';
import { capitalizeAllFirstLetters } from '../../helpers/commonFunctions';
import { resetServerContext } from 'react-beautiful-dnd';

const RefundReasons = () => {
    const breadcrumbs = [
        {
            title: "Refund ",
            url: "/company/transaction/refund/list/1"
        },
        {
            title: "Reasons",
            url: ""
        },
    ]
    const [showModal, setShowModal] = useState(false);
    const [reasons, setReasons] = useState([]);

    useEffect(() => {
        const savedReasons = JSON.parse(localStorage.getItem('reasons')) || [];
        setReasons(savedReasons);
    }, []);

    const saveReasonsToLocalStorage = (updatedReasons) => {
        localStorage.setItem('reasons', JSON.stringify(updatedReasons));
    }

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        document.getElementById('reason-form').reset();
    };

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <Breadcrums data={breadcrumbs} />
                        <h4>Refund</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <ul className="d-flex setting w-100 mb-0 p-0">
                                <li className=""><Link to={`/company/transaction/refund/configuration`} className="btn">Refund Configuration</Link></li>
                                <li className="ms-3 active"><Link to={`/company/transaction/refund/reasons/list`} className="btn btn-primary">Reasons</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="7%">
                                            <div className='sorting_column'>
                                                <span>Reason</span>
                                            </div>
                                        </th>
                                        <th width="7%">
                                            <div className='sorting_column'>
                                                <span>Type of Refund</span>
                                            </div>
                                        </th>
                                        <th width="7%">
                                            <div className='sorting_column'>
                                                <span>Return to Inventory</span>
                                            </div>
                                        </th>
                                        <th width="7%">
                                            <div className='sorting_column'>
                                                <span>Percent of Refund</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reasons.map((item, index) => (
                                        <tr key={index}>
                                            <td>{capitalizeAllFirstLetters(item.reason)}</td>
                                            <td>{capitalizeAllFirstLetters(item.type_of_refund)}</td>
                                            <td>{capitalizeAllFirstLetters(item.return_to_inventory)}</td>
                                            <td>{(item.percent_of_refund + " " + "%")}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="text-start mt-3 ms-2" type= "button" onClick={handleOpenModal}>
                                <Link>  + Add New Reason </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        reason: '',
                        type_of_refund: '',
                        return_to_inventory: '',
                        percent_of_refund: '',
                    }}
                    validate={values => {
                        const error = {};
                        if (!values.reason.trim()) {
                            error.reason = `Reason is required`;
                        }
                        if (!values.type_of_refund.trim()) {
                            error.type_of_refund = `Refund type is required`;
                        }
                        if (!values.return_to_inventory.trim()) {
                            error.return_to_inventory = `This field is required`;
                        }
                        if (!values.percent_of_refund.trim()) {
                            error.percent_of_refund = `Percentage of refund is required`;
                        } else {
                            const percentValue = parseFloat(values.percent_of_refund);
                            if (isNaN(percentValue)) {
                                error.percent_of_refund = `Invalid number format`;
                            } else if (percentValue <= 0) {
                                error.percent_of_refund = `Percentage of refund must be greater than 0`;
                            } else if (percentValue < 1) {
                                error.percent_of_refund = `Percentage of refund must be at least 1%`;
                            } else if (percentValue > 100) {
                                error.percent_of_refund = `Percentage of refund cannot exceed 100%`;
                            } else {
                                delete error.percent_of_refund;
                            }
                        }
                        return error;
                    }}
                    onSubmit={(values, { resetForm }) => {
                        const updatedReasons = [...reasons, values];
                        setReasons(updatedReasons);
                        saveReasonsToLocalStorage(updatedReasons);
                        resetForm();
                        handleCloseModal();
                    }}
                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                }) => {
                    return (
                        <Modal show={showModal} onHide={handleCloseModal} size="lg" backdrop={'static'}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Reason</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form id="reason-form" onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="reason" className="form-label">Reason<span className="requirestar">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="reason"
                                            name="reason"
                                            placeholder='Enter Reason'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values?.reason}
                                            autoComplete='off'
                                        />
                                        <div className='text-danger'>{errors.reason && touched.reason && errors.reason}</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="type_of_refund" className="form-label">Type of Refund<span className="requirestar">*</span></label>
                                        <select
                                            className="form-control"
                                            name="type_of_refund"
                                            id="type_of_refund"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values?.type_of_refund}
                                            autoComplete='off'
                                        >
                                            <option value="">Select refund type</option>
                                            <option value="full">Full Refund</option>
                                            <option value="partial">Partial Refund</option>
                                        </select>
                                        <div className='text-danger'>
                                            {errors.type_of_refund && touched.type_of_refund && errors.type_of_refund}
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="return_to_inventory" className="form-label">Return to Inventory<span className="requirestar">*</span></label>
                                        <select
                                            className="form-control"
                                            name="return_to_inventory"
                                            id="return_to_inventory"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values?.return_to_inventory}
                                            autoComplete='off'
                                        >
                                            <option value="">Please select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                        <div className='text-danger'>{errors.return_to_inventory && touched.return_to_inventory && errors.return_to_inventory}</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="percent_of_refund" className="form-label">Percent of Refund<span className="requirestar">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="percent_of_refund"
                                            id="percent_of_refund"
                                            placeholder='Enter percent of refund'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values?.percent_of_refund}
                                            autoComplete='off'
                                        />
                                        <div className='text-danger'>{errors.percent_of_refund && touched.percent_of_refund && errors.percent_of_refund}</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <Button variant="outline-primary" onClick={() => { resetForm(); handleCloseModal(); }}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" type="submit">
                                            Save
                                        </Button>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>
                    );
                }}
                </Formik>
            </div>
        </>
    );
}

export default RefundReasons
