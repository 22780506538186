
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AddMeals, getAllMenuItems } from '../../../services/company_panel/mealService';
import { capitalizeAllFirstLetters, getBrandId, getCompany, removeModalBackdrops } from '../../../helpers/commonFunctions'
import defaultImage from '../../../assets/company/img/default_img.png';
import { IMG_MAX_SIZE, SUPPORTED_FORMATS } from '../../../utils/Constants';

const CreateMealsCombos = () => {

    const breadcrumbs = [
        {
            title: "Meals/Combos",
            url: "/company/menu/menu/meals_combos/list/1"
        },
        {
            title: "Create Meal",
            url: ""
        },
    ]
    const navigate = useNavigate()
    const [previewImage, setPreviewImage] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [browseItemData, setBrowseItemData] = useState([
    ]);
    const [searchInput, setSearchInput] = useState(""); // Add search input state
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
    });

    useEffect(() => {
        return () => {
            removeModalBackdrops();
        }
    }, []);

    useEffect(() => {
        getAllMenuItems({ brand_id: getBrandId() }).then(data => {
            if (data && data.data && data.data.response) {
                setBrowseItemData(data.data.response.rows);
            }
        }).catch(error => {
            console.log("error=====>", error);
        });
    }, [isBrandChanged]);



    const [currentIndex, setCurrentIndex] = useState(null);
    const IMG_MAX_SIZE = 5 * 1024 * 1024;

    const validateSchema = Yup.object().shape({
        logo: Yup.mixed()
            .required('Image is required')
            .test(
                'fileSize',
                `Image size exceeds the maximum limit of 5 MB`,
                (value) => value && value.size <= IMG_MAX_SIZE
            ),
        name: Yup.string()
            .required('Brand name is required')
            .max(30, 'Brand name should not exceed 30 characters'),
        price: Yup.number()
            .required('Price is required')
            .positive('Price must be a positive number')
            .integer('Price must be an integer'),
        name_ar: Yup.string()
            .required('Brand name (Arabic) is required')
            .max(30, 'Brand name (Arabic) should not exceed 30 characters'),
        description: Yup.string().trim().max(250, 'Description should not exceed 250 characters'),
        description_ar: Yup.string().trim().max(250, 'Description (Arabic) should not exceed 250 characters'),
        items: Yup.array()
            .of(Yup.object().shape({
                item_type: Yup.string()
                    .required('Item Type is required')
                    .max(20, 'Item Type cannot be longer than 20 characters'),
                quantity: Yup.string()
                    .required('Quantity is required'),
                subItems: Yup.array().min(1, "Browse Items Cannot be Empty")
            })
            )
            .test('has-errors', 'Invalid social media data', function (items) {
                return items && items.some(item => {
                    return Object.keys(item).some(key => item[key] !== undefined);
                });
            }),
    });



    const formik = useFormik({
        initialValues: {
            logo: '',
            name: '',
            name_ar: '',
            price: '',
            description: '',
            description_ar: '',
            items: [{ item_type: '', quantity: '', subItems: [] }],
        },
        validationSchema: validateSchema,
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append("logo", values.logo);
            formData.append("name", values.name);
            formData.append("name_ar", values.name_ar);
            formData.append("price", values.price);
            formData.append("description", values.description);
            formData.append("description_ar", values.description_ar);
            formData.append("brand_id", getBrandId());
            formData.append('company_id', getCompany().company_id);
            const transformedItems = values.items.map(item => ({
                item_type: item.item_type,
                quantity: item.quantity,
                menuItems: item.subItems.map(subItem => ({
                    id: subItem.id,
                    price: subItem?.default_price
                }))
            }));
            console.log('******************', transformedItems);
            formData.append("items", JSON.stringify(transformedItems));
            AddMeals(formData)
                .then((response) => {
                    if (response.data.status === 1 || response.data.status === '1') {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500,
                            toast: true,
                            position: 'top-right',
                        });
                        setTimeout(() => {
                            navigate(`/company/menu/menu/meals_combos/list/${1}`);
                        }, 1000);
                    } else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            position: 'top-right',
                        });
                    }
                })
                .catch(error => {
                    console.log("error ====> ", error);
                });
        }
    })


    const handleAdd = () => {
        formik.setFieldValue('items', [
            ...formik.values.items,
            { item_type: '', quantity: '', subItems: [] },
        ]);
    };


    const handleRemove = (index) => {
        formik.setFieldValue(
            'items',
            formik.values.items.filter((_, i) => i !== index)
        );
    };

    const handelChange = (event, index) => {
        const { checked } = event.target;

        let data = [...browseItemData];
        data[index].checked = event.target.checked;
        setBrowseItemData(data);
    };

    const initBrowseItem = (index) => {
        setCurrentIndex(index);
        const subItems = formik?.values?.items[index].subItems;
        let data = browseItemData.map((item) => {
            return {
                ...item, checked: Boolean(subItems.filter(a => a.id === item.id)[0])
            };
        });
        setBrowseItemData(data);
    };

    const addBrowseItems = () => {
        const data = browseItemData.filter(item => item.checked);
        let allValues = [...formik.values.items];
        if (currentIndex !== null) {
            allValues[currentIndex].subItems = data;
            formik.setFieldValue('items', allValues);
        }
    };

    const deleteSubItem = (parentIndex, i) => {
        let allValues = [...formik.values.items];
        allValues[parentIndex].subItems.splice(i, 1);
        formik.setFieldValue('items', allValues);
    };
    const filteredBrowseItems = browseItemData.filter(item =>
        item.item_name.toLowerCase().includes(searchInput.toLowerCase())
    );
    const disabledItemIds = formik.values.items.flatMap(item =>
        item.subItems.map(subItem => subItem.id)
    );
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setPreviewImage(URL.createObjectURL(file));
        } else {
            // If no file is selected, keep the previous image
            setPreviewImage(defaultImage);
        }
    };

    const handleFileClick = () => {
        // Clear the file input value
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };
    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <h4>Meals/Combos</h4>
                    <Breadcrums data={breadcrumbs} />
                </div>
                <div class="page-header-right ms-auto">

                </div>
            </div>

            <form onSubmit={formik.handleSubmit}>
                <div className="card custom-card">
                    <div className="card-body">
                        <div className="row row-sm">
                            <div className='col-md-12'>
                                <div className='upload-logo d-flex align-items-center gap-3 mb-3'>
                                    <div class="avatar avatar-xl">

                                        {previewImage ?

                                            <img alt="avatar" src={previewImage} className={previewImage ? "" : "d-none"} />
                                            :
                                            <img alt="avatar" src={defaultImage} />
                                        }

                                    </div>
                                    <div className='btn btn-black btn-file-type'>Upload logo <input id="logo" onClick={handleFileClick} name="logo" accept="image/*" type="file" onChange={(event) => {
                                        formik.setFieldValue("logo", event.currentTarget.files[0] || "");
                                        { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                                    }}
                                    /></div>
                                </div>
                                <span className='text-danger d-flex text-left' id='errortext'>
                                    {formik.errors.logo && formik.touched.logo && formik.errors.logo}
                                </span>
                            </div>
                            <div className='col-lg-6 text-center form-group mb-3'>
                                <label htmlFor='name' className='text-left d-flex'>Name<span className="requirestar">*</span></label>
                                <input name='name' id="name" type='text' placeholder='Enter Name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} className='form-control' />
                                <span className='text-danger d-flex text-left'>{formik.errors.name && formik.touched.name && formik.errors.name}</span>
                            </div>
                            <div className='col-lg-6 text-center form-group mb-3'>
                                <label htmlFor='name_ar' className='text-left d-flex'>Name (arabic)<span className="requirestar">*</span></label>
                                <input name='name_ar' id="name_ar" type='text' placeholder='Enter Name (arabic)' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name_ar} className='form-control' />
                                <span className='text-danger d-flex text-left'>{formik.errors.name_ar && formik.touched.name_ar && formik.errors.name_ar}</span>
                            </div>
                            <div className='col-lg-6 text-center form-group mb-3'>
                                <label htmlFor='price' className='text-left d-flex'>Price<span className="requirestar">*</span></label>
                                <input name='price' id="price" type='number' placeholder='Enter Price' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.price} className='form-control' />
                                <span className='text-danger d-flex text-left'>{formik.errors.price && formik.touched.price && formik.errors.price}</span>
                            </div>
                            <div className='col-lg-6 text-center form-group mb-3'></div>
                            <div className='col-lg-6 text-center form-group required mb-3'>
                                <label htmlFor='description' className='text-left d-flex'>Description </label>
                                <textarea name='description' id="description" rows="2" type='text' placeholder='Enter Description' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.description} className='form-control' style={{ height: "100px" }} />
                                <span className='text-danger d-flex text-left'>{formik.errors.description && formik.touched.description && formik.errors.description}</span>
                            </div>
                            <div className='col-lg-6 text-center form-group required mb-3'>
                                <label htmlFor='description_ar' className='text-left d-flex'>Description (arabic)</label>
                                <textarea name='description_ar' id="description_ar" rows="2" type='text' placeholder='Enter Description (arabic)' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.description_ar} className='form-control' style={{ height: "100px" }} />
                                <span className='text-danger d-flex text-left'>{formik.errors.description_ar && formik.touched.description_ar && formik.errors.description_ar}</span>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                <h2>Items</h2>
                            </div>
                            {formik.values.items.map((item, index) => (
                                <div className="row mb-3" key={index}>
                                    <div className="text-end">
                                        {index === formik.values.items.length - 1 && (
                                            <Link
                                                onClick={handleAdd}
                                            >
                                                + Add more
                                            </Link>
                                        )}
                                    </div>
                                    <div className='col-lg-6 text-center form-group mb-3 mt-3'>
                                        <label htmlFor='name' className='text-left d-flex'>Item Type<span className="requirestar">*</span></label>
                                        <input name={`items[${index}].item_type`} type='text' placeholder='Enter Item Type' onChange={formik.handleChange} onBlur={formik.handleBlur} value={item.item_type} className='form-control' />
                                        {formik.touched.items && formik.touched.items[index] && formik.errors.items && formik.errors.items[index] && formik.errors.items[index].item_type ? (
                                            <div className="text-danger d-flex text-left">{formik.errors.items[index].item_type}</div>
                                        ) : null}
                                    </div>
                                    <div className='col-lg-6 text-center form-group mb-3 mt-3'>
                                        <label htmlFor='name' className='text-left d-flex'>Quantity<span className="requirestar">*</span></label>
                                        <input name={`items[${index}].quantity`} type='number' placeholder='Enter Quantity' onChange={formik.handleChange} onBlur={formik.handleBlur} value={item.quantity} className='form-control' />
                                        {formik.touched.items && formik.touched.items[index] && formik.errors.items && formik.errors.items[index] && formik.errors.items[index].quantity ? (
                                            <div className="text-danger d-flex text-left">{formik.errors.items[index].quantity}</div>
                                        ) : null}
                                    </div>
                                    <div className='text-end'>
                                        <a data-bs-toggle="modal" data-bs-target="#addCardModal" href='#addCardModal' onClick={() => initBrowseItem(index)}>+ Browse Items</a>
                                        {formik.touched.items && formik.touched.items[index] && formik.errors.items && formik.errors.items[index] && formik.errors.items[index].subItems ? (
                                            <div className="text-danger">{formik.errors.items[index].subItems}</div>
                                        ) : null}
                                    </div>
                                    {
                                        item?.subItems?.length ?
                                            <>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h2>Menu Items</h2>
                                                </div>
                                                <div className="tabler-wrap">
                                                    <div className="">
                                                        <table className="table table-custom">
                                                            <thead>
                                                                <tr>
                                                                    <th className="" width="50%">Item</th>
                                                                    <th className="" width="30%">Price</th>
                                                                    <th className='' width="200%">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    item?.subItems.map((subItem, i) => (
                                                                        <tr key={i}>
                                                                            <td>{capitalizeAllFirstLetters(subItem?.item_name)}</td>
                                                                            <td>SAR <input type="number" name={`items[${index}].subItems[${i}].price`}
                                                                                value={subItem?.default_price} onChange={formik.handleChange} class="form-control d-inline-block w-auto" /></td>
                                                                            <td><button class="btn btn-danger" onClick={() => deleteSubItem(index, i)}>✖</button></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                    }

                                    {(formik.values.items.length > 1) &&
                                        <div className="col-md-2 d-flex align-items-center mt-2">
                                            <button
                                                type="button"
                                                className="btn btn-danger me-2"
                                                onClick={() => handleRemove(index)}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    }
                                </div>
                            ))}
                            <div className="mt-5">
                                <button className="btn btn-primary mr-2" type="submit" disabled={submitted ? true : null}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            {/*Modal */}
            {/* <div className="modal fade" id="addCardModal" aria-labelledby="addCardModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="addCardModalLabel">Browse Items</h2>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                />
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        {filteredBrowseItems && filteredBrowseItems.length > 0 && filteredBrowseItems.map((item, i) => (
                                            <div key={i} class="col-12 d-flex justify-content-between align-items-center py-2">
                                                <span>{capitalizeAllFirstLetters(item.item_name)}</span>
                                                <input type="checkbox" checked={item?.checked} class="form-check-input" onChange={(event) => handelChange(event, i)} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-dark" style={{ width: '48%' }} data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                            <button type="submit" className="btn btn-primary" style={{ width: '48%' }} data-bs-dismiss="modal" aria-label="Close" onClick={addBrowseItems}>Add</button>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="modal fade" id="addCardModal" aria-labelledby="addCardModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="addCardModalLabel">Browse Items</h2>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {browseItemData && browseItemData.length > 0 ? (
                                <>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Search here" value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)} />
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                {filteredBrowseItems.map((item, i) => (
                                                    <div
                                                        key={i}
                                                        className="col-12 d-flex justify-content-between align-items-center py-2"
                                                        style={{
                                                            position: disabledItemIds.includes(item.id) ? 'absolute' : 'static',
                                                            visibility: disabledItemIds.includes(item.id) ? 'hidden' : 'visible',
                                                            width: '100%', // Ensures the hidden item takes up the full width but is not visible
                                                            height: 'auto' // Ensures proper height if needed
                                                        }}
                                                    >
                                                        <span>{capitalizeAllFirstLetters(item.item_name)}</span>
                                                        <input
                                                            type="checkbox"
                                                            checked={item?.checked}
                                                            className="form-check-input"
                                                            onChange={(event) => handelChange(event, i)}
                                                        />
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="alert alert-warning" data-bs-dismiss="modal">
                                    There is no menu item created yet. Please{' '}
                                    <Link to="/company/menu/menu/menu_items/add">
                                        create a menu item
                                    </Link>{' '}
                                    to browse items.
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-dark" style={{ width: '48%' }} data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                            {browseItemData && browseItemData.length > 0 && (
                                <button type="submit" className="btn btn-primary" style={{ width: '48%' }} data-bs-dismiss="modal" aria-label="Close" onClick={addBrowseItems}>Add</button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>
    );
}

export default CreateMealsCombos;