// export const API_BASE = "http://localhost:4000/webservice/admin/";   // lakshya sir
// export const API_BASE = "http://192.168.1.83:5000/webservice/admin/";   //naveen sir
// export const API_BASE = "http://samasport.devtechnosys.tech:17345/webservice/admin/"
export const EDITOR_TOOLBAR = ['sourceEditing'];

export const VIDEO_UPLOAD = {
    CHUNK_SIZE: 1048576, /** 1 MB */
}
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const MATCH_TYPES = ['Live', 'Recorded'];

export const MEDIA_TYPES = ['Audio', 'Video'];

export const COMPETITION_TYPES = ['Championship', 'Season'];

export const DEVMODE = { USERNAME: "DEV@ADMIN", PASSWORD: "Dev@1234", SESSION_TIME: 300000 /** 5 MINUTES */ };

export const ORDER_STATUS = {
    PENDING: 0,/**Product is ordered successfully but not delivered */
    CANCELED: 1,/**Order is cancel */
    DELIVERED: 2,/**Product is successfully delivered */
}

export const productcategorylist = [{ value: "Product" }, { value: "Coupon" }]
export const Transaction_Status = [{ name: "Status", status__id: "" }, { name: "Failed", status__id: 0 }, { name: "Success", status__id: 1 }]
export const showOrder_Status = [{ name: "Status", status__id: "" }, { name: "Pending", status__id: 0 }, { name: "Cancelled", status__id: 1 }, { name: "Delivered", status__id: 2 }];
// export const showFilterlist = [
//   { name: "list_heading_status", status__id: "" },
//   { name: "btn_active", status__id: "1" },
//   { name: "btn_inactive", status__id: "0" },
// ];
export const showFilterlist = [{ name: "Status", status__id: "" }, { name: "Active", status__id: "1" }, { name: "Inactive", status__id: "0" }]
export const showFilterlistBoolean = [{ name: "Status", status__id: "" }, { name: "Active", status__id: true }, { name: "Inactive", status__id: false }]
export const UserGraphOption = [{ name: "This Week", value: "this_week" }, { name: "Last Month", value: "last_month" }, { name: "Last Year", value: "last_year" }]
export const DURATION = [{ key: "1", label: "1 Month" }, { key: "2", label: "2 Month" }, { key: "3", label: "3 Month" }, { key: "4", label: "4 Month" }]
export const COUPON_TYPE = [{ key: "flat", label: "Flat" }, { key: "percentage", label: "Percentage" }]
export const CHOOSE_BUY_ITEMS = [{ key: "burger", label: "Burger" }, { key: "pizza", label: "Pizza" }, { key: "pasta", label: "Pasta" }, { key: "sandwich", label: "Sandwich" }, { key: "momos", label: "Momos" }]
export const CHOOSE_GET_ITEMS = [{ key: "burger", label: "Burger" }, { key: "pizza", label: "Pizza" }, { key: "pasta", label: "Pasta" }, { key: "sandwich", label: "Sandwich" }, { key: "momos", label: "Momos" }]
export const BUY = [
    { key: "1", label: "1" },
    { key: "2", label: "2" },
    { key: "3", label: "3" },
    { key: "4", label: "4" },
    { key: "5", label: "5" },
    { key: "6", label: "6" },
    { key: "7", label: "7" },
    { key: "8", label: "8" },
    { key: "9", label: "9" },
    { key: "10", label: "10" }
  ];
  
  export const GET = [
    { key: "1", label: "1" },
    { key: "2", label: "2" },
    { key: "3", label: "3" },
    { key: "4", label: "4" },
    { key: "5", label: "5" },
    { key: "6", label: "6" },
    { key: "7", label: "7" },
    { key: "8", label: "8" },
    { key: "9", label: "9" },
    { key: "10", label: "10" }
  ];
  
export const USER = [{ key: "user1", label: "User1" }, { key: "user2", label: "User2" }]


export const FONT_STYLE = ["Arial", "Verdana", "Helvetica", "Times New Roman", "Courier New", "Georgia", "Palatino", "Garamond", "Bookman", "Comic Sans MS", "Trebuchet MS", "Arial Black", "Impact", "Lucida Sans Unicode", "Tahoma", "Geneva", "Courier", "Lucida Console", "Monaco", "Bradley Hand", "Brush Script MT", "Century Gothic", "Franklin Gothic Medium", "Gill Sans", "Hobo Std", "Jazz LET", "Luminari", "Marker Felt", "Optima", "Papyrus", "Skia", "Zapfino"];

export const FONT_SIZE = ["8px", "10px", "12px", "14px", "16px", "18px", "20px", "24px", "28px", "32px", "36px", "40px", "48px", "56px", "64px", "72px"];

export const WEEK = [{ key: "sunday", label: "Sunday" }, { key: "monday", label: "Monday" }, { key: "tuesday", label: "Tuesday" }, { key: "wednesday", label: "Wednesday" }, { key: "thursday", label: "Thursday" }, { key: "friday", label: "Friday" }, { key: "saturday", label: "Saturday" }]
export const IMG_TYPE = ["jpg","jpeg","png","webp","avif","gif"];
export const MAX_FILE_SIZE = 5 * 1024 * 1024
export const FILE_TYPE_ERROR = "Invalid file type";
export const FILE_SIZE_ERROR = "File size exceeds the limit";
export const LOADER_TIME = 1000; /** Values should be in ms */

export const PASSWORD_MIN_LENGTH = 8
export const PASSWORD_MAX_LENGTH = 20
export const ATLEAST_ONE_SMALL_REGEX = /[a-z]/g
export const ATLEAST_ONE_CAPITAL_REGEX = /[A-Z]/g
export const ATLEAST_ONE_NUMBER_REGEX = /[0-9]/g;
export const ATLEAST_ONE_SPECIAL_CHARACTER_REGEX = /[^\w\s]/;

export const CUSTOMER_TYPE = {
  All: "all",
  New: "new",
  Existing:'existing'
};

export const IMG_MAX_SIZE = { value: 5 * 1024 * 1024, display_name: "5 MB"} ;

export const SUPPORTED_FORMATS = ['jpg', 'jpeg', 'png', 'webp', 'avif', 'gif'];

// 30000

export const WEEKDAYS = [
  { value: "sunday", label: "Sunday" }, 
  { value: "monday", label: "Monday" }, 
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" }, 
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" }, 
  { value: "saturday", label: "Saturday" }
];

export const SUBSCRIPTION_CONTENT_LENGTH = 50;