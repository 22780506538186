import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getCompanyToken } from './auth';
import { checkPermission} from '../helpers/commonFunctions';


const CompanyPrivateRoute = ({ component: Component,moduleName:moduleName,permissionName:permissionName }) => {

    const token = getCompanyToken();
    const permission = permissionName && moduleName ? checkPermission(moduleName,permissionName) : true ;

    return (
        token && permission  ? <Component /> : <Navigate to="/company/login" />
    )

}

export default CompanyPrivateRoute;