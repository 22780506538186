import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { getCompany } from './helpers/commonFunctions';
import { globalLoader } from './helpers/global';
import { GetPermission } from './services/company_panel/LoginCompanyServices';
import Header from './components/company_panel/common/header';
import SideBar from './components/company_panel/common/sidebar';
import Footer from './components/company_panel/common/footer'

const CompanyLayout = () => {
  useEffect(()=>{
    globalLoader(true);
    Promise.all([
      import("./assets/company/css/bootstrap.min.css"),
      import("./assets/company/css/style.css"),
    ]).then(()=>{
      globalLoader(false);
    }).catch((error)=>{
      console.log("Error in CSS",error);
    })

    
    GetPermission(getCompany().company_id,getCompany().id).then(data => {
      localStorage.setItem("permission", JSON.stringify(data.data.response.module_wise_permissions));
    }).catch(error => {
        console.log("error=====>", error)
    })

  },[]);


  return (
    <div className='page'>
      <Header />
      <SideBar />
      <main id="content">
        <div className="content-inner">
          <Outlet/>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default CompanyLayout;