import React, { useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { CreateSupplier } from '../../services/company_panel/supplierService';
import { CreateInventory, GetAllInventories } from '../../services/company_panel/inventoryService';
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { countries } from 'countries-list';
import { Dropdown } from 'semantic-ui-react';
import { blockInvalidChar, capitalizeAllFirstLetters, getBrandId, getCompany, getExtension } from '../../helpers/commonFunctions';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GetAllChildCategories, GetAllParentCategories } from '../../services/company_panel/categoryService';
import ReactCountryFlag from 'react-country-flag';
import { SelectPicker } from 'rsuite';
import { IMG_MAX_SIZE, IMG_TYPE } from '../../utils/Constants';
import defaultImage from '../../assets/company/img/default_img.png';
import { globalLoader } from '../../helpers/global';

const SupplierAdd = () => {
  const breadcrumbs = [
    {
      title: "Supplier",
      url: "/company/raw_materials/supplier_manage/list/1"
    },
    {
      title: "Create Supplier",
      url: ""
    },
  ];


  const unitOfMeasureOptions = [
    { value: 'kg', label: 'Kilogram' },
    { value: 'g', label: 'Gram' },
    { value: 'lb', label: 'Pound' },
    { value: 'oz', label: 'Ounce' },
  ];

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState({
    category_id: '',
    sub_category_id: '',
    item_type: '',
    production_item: '',
    item_name: '',
    sku: '',
    primary_unit_of_measure: '',
    calories_as_per_primary_unit: '',
    raw_material_price: '',
  });

  const [parentCategories, setParentCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isBrandChanged, setIsBrandChanged] = useState(false);

  useMemo(() => {
    document.addEventListener('brand-changed', function () {
      setIsBrandChanged(!isBrandChanged);
    });
  });

  useEffect(() => {
    // const options = Object.keys(countries).map(countryCode => ({
    //   key: countryCode,
    //   text: (
    //       <div style={{ display: 'flex', alignItems: 'center' }}>
    //           <ReactCountryFlag countryCode={countryCode} style={{ marginRight: 10 }} />
    //           {countries[countryCode].phone}
    //       </div>
    //   ),
    //   value: countryCode
    // }));
    //  setSelectedCountryCode(options);

    const options = Object.keys(countries).map(countryCode => ({
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ReactCountryFlag
            countryCode={countryCode}
            style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }}
            aria-label={countryCode}
          />
          {` + ${countries[countryCode].phone}`}
        </div>
      ),
      value: countryCode
    }));
    setSelectedCountryCode(options);
    console.log('-----------options', countries);

    GetAllInventories({ brand_id: getBrandId() }).then(data => {
      const tempInventories = data && data.data && data.data.response ? data.data.response.rows : [];
      const mappedInventories = tempInventories.map((res) => ({
        key: res.id,
        text: capitalizeAllFirstLetters(res.item_name),
        value: res.id,
      }));
      setInventories(mappedInventories);
      setLoader(false)
    }).catch(error => {
      console.log("error=====>", error);
    });
  }, [isBrandChanged]);


  const validationSchema = yup.object().shape({
    first_name: yup.string()
      .trim()
      .required('Name is required')
      .max(20, 'Name must be 20 characters or less'),
    // country_code: yup.string().trim().required('Country code is required'),
    // mobile: yup.string().trim().matches(/^[0-9]+$/, "Mobile number must contain only digits").required('Mobile is required'),
    email: yup.string()
      .email('Invalid email address')
      .required('Email is required')
      .matches(/^[^\s]+@[^\s]+\.[^\s]+$/, 'Invalid email address')
      .matches(/^[^\s#$%&*?]+$/, {
        excludeEmptyString: true,
        message: 'Email should not contain special characters like #$%&*?',
      }),

    mobile: yup.string().required('Phone number is required').matches(/^[0-9]{10}$/, 'Phone number is not valid'),
    country_code: yup.string().required('Country code is required'),
    // street_address: yup.string().trim().required('Street address is required'),
    // zip_code: yup.string().trim().required('Zip code is required'),
    // inventory: yup.array()
    //   .min(1, 'Select at least one inventory item')
    //   .required('Select at least one inventory item'),
    logo: yup.mixed().test("file_types", (value) => {
      if (value && value.name) {
        const fileType = getExtension(value?.name);
        return IMG_TYPE.includes(fileType);
      }
      return true;
    })
      .test("file_size", `Image size exceeds the maximum limit of ${IMG_MAX_SIZE.display_name}`, (value) => {
        if (value && value.size) {
          return value.size <= IMG_MAX_SIZE.value
        }
        return true;
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      logo: "",
      first_name: "",
      email: "",
      country_code: "",
      city: "",
      mobile: "",
      street_address: "",
      zip_code: "",
      inventory: [],
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      console.log("Form Values:", values);
      setSubmitted(true);
      let formData = new FormData();

      formData.append('verified_id', values.logo);
      formData.append('name', values.first_name);
      formData.append('email', values.email);
      formData.append('city', values.city);
      formData.append('country_code', values.country_code);
      formData.append('phone_number', values.mobile);
      formData.append('street_address', values.street_address);
      formData.append('zip_code', values.zip_code);
      formData.append('inventories', JSON.stringify(values.inventory));
      formData.append('company_id', getCompany().company_id);
      formData.append('brand_id', getBrandId());
      CreateSupplier(formData).then(response => {
        if (response.data.status === 1 || response.data.status === "1") {
          Swal.fire({
            customClass: 'swal-wide',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            position: 'top-right',
          });
          setTimeout(() => {
            navigate(`/company/raw_materials/supplier_manage/list/${1}`);
          }, 1000);
        } else {
          Swal.fire({
            customClass: 'swal-wide',
            icon: 'error',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000,
            toast: true,
            position: 'top-right',
          });
          setSubmitted(false);
        }
      }).catch(error => {
        console.log("error ====> ", error);
      });
    }
  });


  const getAllCategories = () => {
    const formData = new FormData()
    formData.append('brand_id', getBrandId());
    GetAllParentCategories(formData).then(data => {
      setParentCategories(data && data.data && data.data.response ? data.data.response.rows : [])
    }).catch(error => {
      console.log("error ====> ", error);
    })
  }
  const getSubCategories = (categoryId) => {
    if (!categoryId) {
      return;
    }
    const formData = new FormData()
    formData.append('category_id', categoryId);
    formData.append('brand_id', getBrandId());
    GetAllChildCategories(formData).then(data => {
      setSubCategories(data && data.data && data.data.response ? data.data.response.rows : [])
    }).catch(error => {
      console.log("error ====> ", error);
    })
  };
  const generateSKU = (length = 8) => {
    console.log('generating--------')
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let sku = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      sku += characters[randomIndex];
    }
    formik.setFieldValue('sku', sku);
    setFormData({ ...formData, sku: sku })
  }

  const handleModalSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSubmitted(true);
    // globalLoader(true);
    if (event.currentTarget.checkValidity() === false) {
      return;
    }
    // Handle form submission logic (e.g., submit to API, console.log for demonstration)
    console.log(formData); // Replace with actual submission logic

    let payload = new FormData();
    payload.append('category_id', formData.category_id);
    payload.append('sub_category_id', formData.sub_category_id);
    payload.append('item_type', 'item1');
    payload.append('production_item', 'item1');
    payload.append('item_name', formData.item_name);
    payload.append('sku', formData.sku);
    payload.append('primary_unit_of_measure', formData.primary_unit_of_measure);
    payload.append('calories_as_per_primary_unit', formData.calories_as_per_primary_unit);
    payload.append('raw_material_price', formData.raw_material_price);
    payload.append('can_change_later', true);
    payload.append('minimum_stock_limit', 5);
    payload.append('transferable', true);
    payload.append('company_id', getCompany().company_id);
    payload.append('brand_id', getBrandId());
    payload.append('conversion1', 10);
    payload.append('price1', 10);
    payload.append('calories1', 10);
    payload.append('uom2', 'kg');
    payload.append('conversion2', 10);
    payload.append('price2', 10);
    payload.append('calories2', 10);
    payload.append('uom3', 'kg');
    payload.append('conversion3', 10);
    payload.append('price3', 10);
    payload.append('calories3', 10);
    payload.append('uom4', 'kg');
    payload.append('conversion4', 10);
    payload.append('price4', 10);
    payload.append('calories4', 10);

    CreateInventory(payload).then(response => {
      // globalLoader(false);
      setSubmitted(false);
      if (response.data.status === 1 || response.data.status === "1") {
        Swal.fire({
          customClass: 'swal-wide',
          icon: 'success',
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          position: 'top-right',
        });
      } else {
        Swal.fire({
          customClass: 'swal-wide',
          icon: 'error',
          title: response.data.message,
          showConfirmButton: false,
          timer: 5000,
          toast: true,
          position: 'top-right',
        })
        setSubmitted(false)
      }
    }).catch(error => {
      globalLoader(false);
      console.log("error ====> ", error);
    })
    // Clear form fields and reset modal state
    setFormData({
      category_id: '',
      sub_category_id: '',
      item_type: '',
      production_item: '',
      item_name: '',
      sku: '',
      primary_unit_of_measure: '',
      calories_as_per_primary_unit: '',
      raw_material_price: '',
    });
    setShowModal(false);
    setSubmitted(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleCancel = () => {
    setFormData({
      category_id: '',
      sub_category_id: '',
      item_type: '',
      production_item: '',
      item_name: '',
      sku: '',
      primary_unit_of_measure: '',
      calories_as_per_primary_unit: '',
      raw_material_price: '',
    });
    setShowModal(false);
    setSubmitted(false);
  };
  return (
    <>
      <div className="page-header d-flex align-items-center">
        <div className="page-header-left">
          <Breadcrums data={breadcrumbs} />
          <h4>Supplier</h4>
        </div>
        <div className="page-header-right ms-auto">

        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row row-sm">
          <div className="card custom-card">
            <div className="card-body">
              <div className="row row-sm">
                {inventories.length === 0 && (loader == false || loader == 'false') ?
                  <div className="alert alert-warning text-center">
                    There is not inventory created yet, Please{' '}
                    <Link to="/company/raw_materials/inventory/add">
                      create a inventory
                    </Link>{' '}
                    to assign to supplier
                  </div>
                  : null}
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='first_name' className='text-left d-flex'>Name<span className="requirestar">*</span></label>
                  <input name='first_name' placeholder="Enter name" id="first_name" type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.first_name} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.first_name && formik.touched.first_name && formik.errors.first_name}</span>
                </div>

                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='email' className='text-left d-flex'>Email<span className="requirestar">*</span></label>
                  <input name='email' id="email" placeholder="Enter email" type='email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.email && formik.touched.email && formik.errors.email}</span>
                </div>

                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='name' className='text-left d-flex'>Phone number<span className="requirestar">*</span></label>
                  <div className="input-group">
                    <span>
                      {/* <Dropdown
                        className='form-control'
                        id="country_code"
                        placeholder='Code'
                        search
                        fluid
                        selection
                        options={selectedCountryCode}
                        value={formik.values.country_code}
                        onChange={(event, data) => { formik.setFieldValue("country_code", data.value) }}
                      /> */}
                      <SelectPicker
                        style={{ border: 'none', paddingLeft: '0', paddingRight: '0' }}
                        data={selectedCountryCode}
                        name='country_code'
                        className='form-control'
                        id='country_code'
                        onChange={(value) => formik.setFieldValue("country_code", value)}
                        onBlur={formik.handleBlur}
                        value={formik.values.country_code}
                        placeholder="Code"
                      />
                    </span>
                    <input
                      type="text"
                      name='mobile'
                      id="mobile"
                      className="form-control bordered"
                      style={{ border: '1px solid #e8e8e8' }}
                      placeholder="Enter phone number"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onKeyDown={blockInvalidChar}
                    />
                  </div>
                  <span className='text-danger d-flex  text-left'>
                    {formik.errors.country_code && formik.touched.country_code && formik.errors.country_code}
                  </span>
                  <span className='text-danger d-flex  text-left'>
                    {formik.errors.mobile && formik.touched.mobile && formik.errors.mobile}
                  </span>
                </div>

                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='street_address' className='text-left d-flex'>Street Address</label>
                  <input name='street_address' placeholder="Enter street address" id="street_address" type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.street_address} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.street_address && formik.touched.street_address && formik.errors.street_address}</span>
                </div>

                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='city' className='text-left d-flex'>City</label>
                  <input name='city' placeholder="Enter city" id="city" type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.city} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.city && formik.touched.city && formik.errors.city}</span>
                </div>

                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='zip_code' className='text-left d-flex'>Zip Code</label>
                  <input name='zip_code' id="zip_code" placeholder="Enter zip code" type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.zip_code} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.zip_code && formik.touched.zip_code && formik.errors.zip_code}</span>



                </div>
                {/* <div className='col-md-12'>
                  <div className='upload-logo d-flex align-items-center gap-3 mb-3'>
                    <div className="avatar avatar-xl">
                      {previewImage ?
                        <img alt="avatar" src={previewImage} className={previewImage ? "" : "d-none"} />
                        :
                        <img alt="avatar" src="https://marketplace.canva.com/EAFXH3NOgek/1/0/1600w/canva-red-minimalist-restaurant-logo-2ygr4Ex-o9s.jpg" />
                      }
                    </div>

                  </div>
                  <span className='text-danger d-flex text-left' id='errortext'>
                    {formik.errors.logo && formik.touched.logo && formik.errors.logo}
                  </span>
                </div> */}
                <div className="col-lg-6 text-center form-group mt-3">
                  <label htmlFor="logo" className="text-left d-flex align-items-center mb-2">
                    Upload File
                  </label>
                  <input
                    id="logo"
                    name="logo"
                    type="file"
                    accept="image/*"
                    className="form-control"
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      formik.setFieldValue("logo", file || "");
                      if (file) {
                        console.log('--------', file);
                        setPreviewImage(URL.createObjectURL(file));
                      } else {
                        setPreviewImage('');
                      }
                    }}
                  />
                  <span className="text-danger d-flex">
                    {formik.touched.logo && formik.errors.logo}
                  </span>

                  <div className=" text-start col-12 mt-3">
                    <div className="avatar-container d-inline-block">
                      {previewImage ? (
                        <img alt="Preview" src={previewImage} className="avatar-img" />
                      ) : (
                        <img alt="Default" src={defaultImage} className="avatar-img" />
                      )}
                    </div>
                  </div>

                  <style jsx>{`
                 .avatar-container {
                   width: 100px; /* Adjust size as needed */
                   height: 100px; /* Ensure it's square */
   
                  }

                 .avatar-img {
                   width: 100%;
                   height: 100%;
                   object-fit: cover;
                   }`
                  }
                  </style>
                </div>

                {/* 
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='inventory' className='text-left d-flex'>Inventory :<span className="requirestar">*</span></label>
                  <input name='inventory' id="inventory" type='number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.inventory} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.inventory && formik.touched.inventory && formik.errors.inventory}</span>
                </div> */}

                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor="role_id" className="d-flex justify-content-between align-items-center">
                    Inventory
                    <span
                      className="requirestar"
                      onClick={() => { setShowModal(true); getAllCategories(); }}
                    >
                    </span>
                  </label>

                  <Dropdown
                    multiple
                    className='form-control'
                    id="role_id"
                    placeholder='Select inventory'
                    search
                    fluid
                    selection
                    options={inventories}
                    value={formik.values.inventory}
                    onChange={(event, data) => { formik.setFieldValue("inventory", data.value) }}
                  />
                  {formik.errors.inventory && formik.touched.inventory ? (
                    <span className="text-danger d-flex text-left">
                      {formik.errors.inventory}</span>
                  ) : null}

                </div>
              </div>



              <div className="row row-sm">
                <div className="mt-3">
                  <button className="btn btn-primary mr-2" type="submit" >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <ToastContainer
          position="top-right"
          hideProgressBar={false}
          autoClose={true | 1000}
          newestOnTop={true}
          closeOnClick={false}
          draggable={false}
          rtl={false}
        />
      </form>
      <Modal show={showModal} onHide={() => handleCancel()}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={submitted} onSubmit={handleModalSubmit} centered>

            <Form.Group className="mb-3">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="category_id"
                required
                value={formData.category_id}
                onChange={(event) => { handleInputChange(event); getSubCategories(event.target.value) }}
              >
                <option value="">Select category</option>
                {parentCategories.length ? parentCategories.map((row) => (
                  <option key={row.id} value={row.id}>{row.name}</option>
                )) : ''}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please provide category
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Sub category</Form.Label>
              <Form.Control
                as="select"
                name="sub_category_id"
                required
                value={formData.sub_category_id}
                onChange={handleInputChange}
              >
                <option value="">Select sub category</option>
                {subCategories.length ? subCategories.map((row) => (
                  <option key={row.id} value={row.id}>{row.name}</option>
                )) : ''}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please provide sub category
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Item name</Form.Label>
              <Form.Control
                type="text"
                name="item_name"
                required
                value={formData.item_name}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please select item name.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>SKU</Form.Label>
              {/* <Form.Control
                type="text"
                name="sku"
                required
                value={formData.sku}
                onChange={handleInputChange}
              /> */}
              <div className="set-plus-icon-input">
                <Form.Control type="text" name="sku" className="form-control" id="sku" placeholder='Enter SKU' onChange={handleInputChange} value={formData.sku} autoComplete='off' />
                <div className="icon-box">
                  <a onClick={(e) => generateSKU(8)}><i className="fa fa-rotate-right"></i></a>
                </div>
              </div>
              <Form.Control.Feedback type="invalid">
                Please provide SKU
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Primary unit of measure</Form.Label>
              <Form.Control
                as="select"
                name="primary_unit_of_measure"
                required
                value={formData.primary_unit_of_measure}
                onChange={handleInputChange}
              >
                <option value="">Select primary unit</option>
                {unitOfMeasureOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please provide primary unit of measure
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Calories as per primary unit</Form.Label>
              <Form.Control
                type="number"
                name="calories_as_per_primary_unit"
                required
                value={formData.calories_as_per_primary_unit}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide calories as per primary unit
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Raw material price</Form.Label>
              <Form.Control
                type="number"
                name="raw_material_price"
                required
                value={formData.raw_material_price}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide raw material price
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="secondary" onClick={handleCancel} style={{ marginRight: '10px' }}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Add
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SupplierAdd
