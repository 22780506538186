import React, { useEffect, useState } from 'react'
import { DateRangePicker } from 'rsuite';

const CustomRangepicker = (props) => {
    const [value, setValue] = useState([]);


    useEffect(() => {
        setValue([]);
    }, [props.resetdate])

    function handleChange(daterange) {
        if (daterange && daterange.length == 2) {
            props.GetDateRange({ start: daterange[0], end: daterange[1] });
            setValue(daterange);
        } else {
            props.GetDateRange({});
            setValue([]);
        }
    }

    return (
        <>
            <div className="form-group mb-0 rangepicker_container">
                <i className="fa fa-calendar glyphicon glyphicon-calendar calender_icon"></i>
                <DateRangePicker
                    value={value}
                    placement='bottomEnd'
                    format="dd-MM-yyyy"
                    placeholder={"Date"}
                    cleanable={false}
                    onChange={handleChange}
                    showHeader={false}

                />
            </div>
        </>
    )
}

export default CustomRangepicker;