import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CouponValidator, GetMealCombosOrder, GetMenuItemsOrder } from '../../services/company_panel/orderService';
import { capitalizeFirstLetter, getBrandId, getOutletId, showPrice } from '../../helpers/commonFunctions';
import { GetActiveOrderModes } from '../../services/company_panel/setting/orderModeConfigurationService';
import { Modal, Button } from 'react-bootstrap';
import ItemDetailsModal from './model';
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import noRecordFoundImg from './../../assets/company/img/no-record-found.png'
import { LOADER_TIME } from '../../utils/Constants';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';

export const RefundOrderAdd = () => {
    const [menuItemsOrders, setMenuItemsOrders] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);
    const [categoryData, setCategoryData] = useState([]);
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [filteredMenuItems, setFilteredMenuItems] = useState([]);
    const [activeTab, setActiveTab] = useState(null);
    const [orderModeData, setOrderModesData] = useState([]);
    const [mealCombosData, setMealCombosData] = useState([]);
    const [viewCombos, setViewCombos] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedComboId, setSelectedComboId] = useState(null);
    const [selectedItemNames, setSelectedItemNames] = useState([]);
    const [selectedComboDetails, setSelectedComboDetails] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState(filteredMenuItems);
    const [filteredCombos, setFilteredCombos] = useState(mealCombosData);
    const [selectedItemDetails, setSelectedItemDetails] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [couponId, setCouponId] = useState(null)
    const [discount, setDiscount] = useState(0);/** This will be replaced by real value from API */
    const [surcharge, setSurcharge] = useState(10);/** This will be replaced by real value from API */
    const navigate = useNavigate();
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);
    const [currentOutlet, setCurrentOutlet] =useState( getOutletId());

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
        if (currentOutlet != getOutletId()) {
            navigate(`/company/transaction/order/list/${1}`);
        }
    });

    const handleCategoryClick = (categoryId) => {
        console.log("categoryId", categoryId)
        setActiveCategory(categoryId);
        // const filteredSubCategories = menuItemsOrders?.subCategories?.filter(
        //     (subCategory) => subCategory?.parent_id === categoryId
        // );
        const filteredSubCategories = menuItemsOrders?.subCategories?.filter((subCategory) => {
            const hasMatchingMenuItem = menuItemsOrders?.menuItems?.some(
                (menuItem) => menuItem?.sub_category_id == subCategory?.id
            );
            return subCategory?.parent_id == categoryId && hasMatchingMenuItem;
        });
        console.log("filteredSubCategories--->",filteredSubCategories);
        
        setSubCategoryData(filteredSubCategories);
        if (filteredSubCategories.length === 0) {
            setFilteredMenuItems([]);
        }
    };

    useEffect(() => {
        if (activeCategory) {
            const filteredSubCategories = menuItemsOrders?.subCategories?.filter((subCategory) => {
                const hasMatchingMenuItem = menuItemsOrders?.menuItems?.some(
                    (menuItem) => menuItem?.sub_category_id == subCategory?.id
                );
                return subCategory?.parent_id == activeCategory && hasMatchingMenuItem;
            });
            
            setSubCategoryData(filteredSubCategories);
            if (filteredSubCategories.length === 0) {
                setFilteredMenuItems([]);
            }
        }
    }, [activeCategory]);

    // useEffect(() => {
    //     const filteredCategories = menuItemsOrders?.categories?.filter((category) => {
    //         const hasMatchingSubcategory = menuItemsOrders?.subCategories?.some(
    //             (subCategory) => subCategory?.parent_id == category?.id
    //         );
    //         return category?.id == hasMatchingSubcategory;
    //     });
        
    //     setCategoryData(filteredCategories);
    // }, []);

    // useEffect(() => {
    //     const filteredCategories = menuItemsOrders?.categories?.filter((category) => {
    //         const hasMatchingSubcategory = menuItemsOrders?.subCategories?.some(
    //             (subCategory) => subCategory?.parent_id === category?.id
    //         );
    //         return hasMatchingSubcategory;
    //     });
    
    //     setCategoryData(filteredCategories);
    // }, []);

    // console.log("categoryData",categoryData);
    


    const filterMenuItemsByCategory = (categoryId) => {
        setActiveTab(categoryId);
        const filteredItems = menuItemsOrders?.menuItems?.filter(
            (item) => item?.sub_category_id === categoryId
        );
        setFilteredMenuItems(filteredItems);
    };

    useEffect(() => {
        if (subCategoryData?.length > 0) {
            setActiveTab(subCategoryData[0].id);
            filterMenuItemsByCategory(subCategoryData[0].id);
        }
    }, [subCategoryData]);

    useEffect(() => {
        // setTimeout(() => {
            if (viewCombos) {
                GetMealCombosOrder({ outlet_id: getOutletId() }).then(data => {
                    if (data && data.data && data.data.response) {
                        setMealCombosData(data.data.response);
                    }
                }).catch(error => {
                    console.log("error=====>", error);
                });
            } else {
                GetMenuItemsOrder({ outlet_id: getOutletId() }).then(data => {
                    if (data && data.data && data.data.response) {
                        setMenuItemsOrders(data.data.response);
                        setActiveCategory(data?.data?.response?.categories[0]?.id)
                    }
                }).catch(error => {
                    console.log("error=====>", error);
                });
            }
        // }, 500);
    }, [viewCombos, isOutletChanged, isBrandChanged]);

    useEffect(() => {
        GetActiveOrderModes(getBrandId()).then(data => {
            setOrderModesData(data && data.data && data.data.response ? data.data.response : []);
        }).catch(error => {
            console.log("error=====>", error);
        });
    }, [isBrandChanged]);

    const formik = useFormik({
        initialValues: {
            orderType: '',
            customerNote: '',
            couponCode: '',
            items: []
        },
        validationSchema: Yup.object({
            orderType: Yup.string().required('Order type is required'),
            customerNote: Yup.string().max(200, 'Note must be 200 characters or less'),
            couponCode: Yup.string(),
            items: Yup.array().of(
                Yup.object({
                    quantity: Yup.number().min(1, 'Minimum quantity is 1').required('Quantity is required'),
                    name: Yup.string().required('Item name is required'),
                    price: Yup.number().required('Price is required'),
                    image: Yup.string().url().required('Image URL is required')
                })
            )
        }),
        onSubmit: values => {
            console.log('Form Values:', values);
        }
    });

    const handleQuantityChange = (index, value) => {
        const updatedItems = [...formik.values.items];
        updatedItems[index].quantity = value;
        formik.setFieldValue('items', updatedItems);
    };

    const handleRemoveItem = (index) => {
        const updatedItems = formik.values.items.filter((_, i) => i !== index);
        const removedItemName = formik.values.items[index].name;
        formik.setFieldValue('items', updatedItems);
        setSelectedItemNames(selectedItemNames.filter(name => name !== removedItemName));
    };


    const handleItemSelection = (item, index) => {
        console.log("***+++-", item)
        let updatedItems = [...formik.values.items];
        const itemIndex = updatedItems.findIndex(existingItem => existingItem.id === item.id);
        let addons = [];
        if (itemIndex === -1) {
            item?.menu_item_add_ons?.forEach(category => {
                category?.menu_add_on_category?.menu_add_ons.forEach(addon => {
                    addons.push({
                        ...addon,
                        quantity: category?.free,
                        free: category?.
                            free, min: category?.min,
                        max: category?.max,
                        price: Number(addon.price),
                        total_price: 0,
                        // add_on_id: category?.id
                    });
                });
            });
            updatedItems.push({
                ...item,
                quantity: 1,
                menu_addons: addons,
                type: "menu_item"
            });
            formik.setFieldValue('items', updatedItems);
        } else {
            // updatedItems[itemIndex].menu_addons = addons;
            // console.log("addons", addons);
        }
        setSelectedItem(item);
        setShowModal(true);
    };

    const handleComboSelection = (combo) => {
        console.log()
        const updatedItems = [...formik.values.items];
        const comboItems = {
            id: combo.id,
            item_name: combo?.name,
            name_ar: combo.name_ar,
            description: combo.description,
            description_ar: combo.description_ar,
            quantity: 1,
            default_price: combo?.price,
            image: combo?.logo,
            combo_items: combo.combo_items || [],
            combo_menu_items: combo.combo_menu_items || [],
            logo: combo?.logo,
            type: "combos"
        };
        setSelectedComboDetails(comboItems);
        setIsModalOpen(true);
        const itemExists = updatedItems.findIndex(item => item.id === comboItems.id);
        if (itemExists === -1) {
            formik.setFieldValue('items', [...updatedItems, comboItems]);
        }
    };

    const validCoupons = {
        SAVE10: 10,
        SAVE20: 20,
        WELCOME5: 5,
    };


    const handleCouponValidation = () => {
        const enteredCode = formik.values.couponCode.trim();
        let formData = new FormData();
        formData.append('coupon_code', enteredCode);
        formData.append('order_amount', getSubTotal().subtotal);
        formData.append('order_mode', formik.values.orderType);
        formData.append('outlet_id', getOutletId());
        formData.append('customer_number', '8899098877');
        CouponValidator(formData).then(response => {
            if (response.data.status === 1 || response.data.status === "1") {
                setDiscount(response.data?.response?.discount_amount || validCoupons[enteredCode] || 0);
                setCouponId(response.data?.response?.coupon_id)
                Swal.fire({
                    customClass: 'swal-wide',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    position: 'top-right',
                });

                setTimeout(() => {
                    // navigate(`/company/transaction/order/list/${1}`);
                }, 1000);
            } else {
                formik.setFieldValue('discount', 0);

                Swal.fire({
                    customClass: 'swal-wide',
                    icon: 'error',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 5000,
                    toast: true,
                    position: 'top-right',
                });
            }
        }).catch(error => {
            console.log("error ====> ", error);

            Swal.fire({
                customClass: 'swal-wide',
                icon: 'error',
                title: 'Error validating coupon.',
                showConfirmButton: false,
                timer: 5000,
                toast: true,
                position: 'top-right',
            });
        });
    };




    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
    };
    useEffect(() => {
        if (viewCombos) {
            setFilteredCombos(
                mealCombosData.filter(combo => combo.name.toLowerCase().includes(searchQuery))
            );
        } else {
            setFilteredItems(
                filteredMenuItems.filter(item => item.item_name.toLowerCase().includes(searchQuery))
            );
        }
    }, [searchQuery, viewCombos, mealCombosData, filteredMenuItems]);

    const handleTabChange = (isCombo) => {
        setViewCombos(isCombo);
        setSearchQuery('');
        setFilteredItems(filteredMenuItems);
        setFilteredCombos(mealCombosData);
    };

    const isSelected = (item) => {
        return formik?.values?.items?.findIndex(record => record.id === item.id) != -1;
    };
    const handleMinAddOn = (index, type) => {
        let data = [...formik?.values?.items];
        let foundIndex = data.findIndex(record => record.id === selectedItem.id && record.category_id === selectedItem.category_id);
        let currentPrice = data[foundIndex]?.menu_addons[index].total_price;
        let quantity = data[foundIndex]?.menu_addons[index].quantity;

        if (type === 'increment') {
            quantity += 1;
        } else if (type === 'decrement') {
            quantity -= 1;
        }
        if (quantity > data[foundIndex]?.menu_addons[index].free) {
            currentPrice = (quantity - data[foundIndex]?.menu_addons[index].free) * data[foundIndex]?.menu_addons[index].price;
        } else {
            currentPrice = 0;
        }
        data[foundIndex].menu_addons[index]['total_price'] = currentPrice;
        data[foundIndex].menu_addons[index]['quantity'] = quantity;
        formik.setFieldValue('items', data);
    };
    const getAddonsFinalPrice = (data) => {
        if (data) {
            let foundIndex = formik?.values?.items.findIndex(record => record.id === data.id && record.category_id === data.category_id);
            let addons = [...formik?.values?.items[foundIndex]?.menu_addons?.length ? formik?.values?.items[foundIndex]?.menu_addons : []];
            let currentPrice = 0;
            addons.forEach((addon) => {
                currentPrice += addon.total_price;
            });
            return currentPrice;
        }
        return 0;
    };
    const getAddons = (item) => {
        if (item) {
            let items = [...formik?.values?.items];
            const activeItem = items.filter(record => record.id === item.id && item.category_id === record.category_id)[0];
            return activeItem.menu_addons;
        }
        return [];
    };
    const getSubTotal = () => {
        let result = {
            subtotal: 0,
            taxes: 0,
            surcharge: 0,
            total: 0,
            discount: 0
        };
        const taxRate = 5;/** In percentage */
        let items = [...formik?.values?.items];
        items.forEach(item => {
            let itemPrice = item.default_price * item.quantity;
            let addonPrices = getAddonsFinalPrice(item) * item.quantity;
            let subtotal = itemPrice + addonPrices;
            result.subtotal += subtotal;
        });
        if (items.length) {
            result.taxes = (result.subtotal * taxRate) / 100;
            result.discount = discount;
            result.surcharge = surcharge;
            result.total = (result.subtotal + result.taxes + result.surcharge) - result.discount;
        }
        return result
    };

    const handleContinue = () => {
        navigate(`/company/transaction/order/continueOrder`, { state: { items: formik?.values?.items, pricing: getSubTotal(), order_id: formik.values.orderType, customer_note: formik.values?.customerNote, coupon_id: couponId } });
    }

    const breadcrumbs = [
        {
          title: "Orders ",
          url: "/company/transaction/order/list/1"
        },
        {
          title: "Create Order",
          url: ""
        },
      ]

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                <Breadcrums data={breadcrumbs} />
                    <h4>Orders</h4>
                    {/* <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/company/transaction/order/list/1">Loyalty Points</a>
                            </li>
                            <li className="breadcrumb-item">
                                <a className="active disableclick" href="/company/transaction/order/add">Create Order</a>
                            </li>
                        </ol>
                    </nav> */}
                </div>
                <div className="page-header-right ms-auto"></div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-5'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h4>Order Summary</h4>
                                    <div className='order-item-wraper d-flex flex-column gap-3'>
                                        {formik?.values?.items?.map((item, index) => (
                                            <div className='order-item-list d-flex gap-3' key={index}>
                                                {console.log('***********', item)}
                                                <div className='order-item-info d-flex align-items-center gap-3 w-100'>
                                                    <figure className='avatar avatar-sm m-0'>
                                                        <img src={item?.logo} alt='Item' />
                                                    </figure>
                                                    <figcaption>
                                                        <h3 className='m-0'>{capitalizeFirstLetter(item?.item_name)}</h3>
                                                        <span>{item.default_price * item.quantity} SAR</span>
                                                    </figcaption>
                                                </div>
                                                <div className='order-item-list-control d-flex gap-2'>
                                                    <div className='plus-minus-input d-flex'>
                                                        <button
                                                            type='button'
                                                            className='btn btn-icon'
                                                            onClick={() => handleQuantityChange(index, Math.max(1, item.quantity - 1))}
                                                        >
                                                            <i className='ti ti-minus'></i>
                                                        </button>
                                                        <input
                                                            type='number'
                                                            className='form-control border-0'
                                                            value={item.quantity}
                                                            onChange={(e) => handleQuantityChange(index, Math.max(1, Number(e.target.value)))}
                                                        />
                                                        <button
                                                            type='button'
                                                            className='btn btn-icon'
                                                            onClick={() => handleQuantityChange(index, item.quantity + 1)}
                                                        >
                                                            <i className='ti ti-plus'></i>
                                                        </button>
                                                    </div>
                                                    <button
                                                        type='button'
                                                        className='btn btn-icon bg-danger text-white'
                                                        onClick={() => handleRemoveItem(index)}
                                                    >
                                                        <i className='ti ti-x'></i>
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label>Order type</label><span className="requirestar">*</span>
                                        <select
                                            className='form-control'
                                            name='orderType'
                                            value={formik.values.orderType}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            <option value=''>Select Order type</option>
                                            {orderModeData.map((mode, index) => (
                                                <option key={index} value={mode.id}>
                                                    {mode.name}
                                                </option>
                                            ))}
                                        </select>
                                        {formik.touched.orderType && formik.errors.orderType ? (
                                            <div className="text-danger">{formik.errors.orderType}</div>
                                        ) : null}
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label>Customer note</label>
                                        <textarea
                                            className='form-control'
                                            name='customerNote'
                                            placeholder='Enter customer note'
                                            value={formik.values.customerNote}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        ></textarea>
                                        {formik.touched.customerNote && formik.errors.customerNote ? (
                                            <div className="text-danger">{formik.errors.customerNote}</div>
                                        ) : null}
                                    </div>
                                    <div className='coupon-code-box'>
                                        <div className='form-group'>
                                            <label className="me-2">Coupon Code</label>
                                            <input
                                                type="text"
                                                name="couponCode"
                                                placeholder="Enter Coupon Code"
                                                className="form-control me-2"
                                                value={formik.values.couponCode}
                                                onChange={formik.handleChange}
                                                disabled={!formik.values.orderType || getSubTotal().subtotal === 0}
                                            />
                                            <button
                                                type="button"
                                                className='btn'
                                                onClick={handleCouponValidation}
                                                disabled={!formik.values.orderType || getSubTotal().subtotal === 0 || !formik.values.couponCode}
                                            >
                                                <i className='ti ti-circle-check'></i>
                                            </button>
                                        </div>

                                    </div>
                                    <div className='subtotal-list mt-3 d-flex flex-column gap-2'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h4>Sub-Total</h4>
                                            <h4>{showPrice(getSubTotal()?.subtotal)} SAR</h4>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h4>Taxes</h4>
                                            <h4>{showPrice(getSubTotal()?.taxes)} SAR</h4>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h4>Discount</h4>
                                            <h4>{showPrice(getSubTotal()?.discount)} SAR</h4>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h4>Surcharge</h4>
                                            <h4>{showPrice(getSubTotal()?.surcharge)} SAR</h4>
                                        </div>
                                        <hr className='my-2'></hr>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h3>Total</h3>
                                            <h3>{showPrice(getSubTotal()?.total)} SAR</h3>
                                        </div>
                                    </div>
                                    {console.log('***********', formik.values.items)}
                                    <div className='form-group mt-4'>
                                        <button type='button' className='btn btn-primary' disabled={!formik.values.orderType || formik?.values?.items.length === 0} onClick={handleContinue}>Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className={`nav-link ${!viewCombos ? 'active' : ''}`} id="pills-Daily-tab" data-bs-toggle="pill" data-bs-target="#pills-Daily" type="button" role="tab" aria-controls="pills-Daily" aria-selected={!viewCombos} onClick={() => handleTabChange(false)} >
                                                    Food Items
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className={`nav-link ${viewCombos ? 'active' : ''}`} id="pills-Monthly-tab" data-bs-toggle="pill" data-bs-target="#pills-Monthly" type="button" role="tab" aria-controls="pills-Monthly" aria-selected={viewCombos} tabindex="-1" onClick={() => handleTabChange(true)}>
                                                    Combo
                                                </button>
                                            </li>
                                        </ul>
                                        <div className=''>
                                            <input type='text' placeholder='Search' className='form-control' value={searchQuery}
                                                onChange={handleSearch} />
                                        </div>
                                    </div>
                                    {/* {!viewCombos && menuItemsOrders?.categories?.length <= 0 &&
                                        <div className="alert alert-warning mt-2">
                                            There is no Primary Category created yet. Please{' '}
                                            <Link to="/company/menu/menu/categories/list/1">
                                                create a Primary Category
                                            </Link>{' '}
                                            first
                                        </div>
                                    } */}
                                    {!viewCombos && menuItemsOrders?.categories?.length > 0 && !activeCategory &&
                                        <div className="alert alert-warning mt-2">
                                            Please Select Category
                                        </div>
                                    }
                                    <div className='tags-wrap'>
                                        {console.log("subCategoryData------------->", subCategoryData, !viewCombos)}

                                        {/* {(subCategoryData?.length > 0 || !viewCombos) && menuItemsOrders?.categories?.map((category, index) => ( */}
                                        {!viewCombos && menuItemsOrders?.categories && menuItemsOrders?.categories?.map((category, index) => (
                                            <button type='button' className={activeCategory === category.id ? 'active' : ''} key={index} onClick={() => handleCategoryClick(category.id)}
                                            >
                                                {capitalizeFirstLetter(category?.name)}
                                            </button>
                                        ))}
                                    </div>
                                    {!viewCombos ? (
                                        <>
                                            {/* {activeCategory && subCategoryData?.length <= 0 &&
                                                <div className="alert alert-warning">
                                                    There is no Secondary Category of Selected Category. Please{' '}
                                                    <Link to="/company/menu/menu/categories/list/1">
                                                        create a Secondary Category
                                                    </Link>{' '}
                                                    first
                                                </div>
                                            } */}
                                            <ul className="d-flex setting submenu-list border-bottom mb-0 p-0">
                                                {subCategoryData?.map((subCategory, index) => (
                                                    <li
                                                        className={activeTab === subCategory.id ? 'active' : ''}
                                                        key={index}
                                                        onClick={() => filterMenuItemsByCategory(subCategory.id)}
                                                    >
                                                        {capitalizeFirstLetter(subCategory?.name)}
                                                    </li>
                                                ))}
                                            </ul>
                                            {console.log(activeCategory, subCategoryData,filteredMenuItems)}
                                            {/* {activeCategory && subCategoryData?.length > 0 && filteredItems?.length <= 0 && */}
                                            {filteredItems?.length <= 0 &&
                                                <div className="alert alert-warning mt-2">
                                                    There is no menu item created yet. Please{' '}
                                                    <Link to="/company/menu/menu/menu_items/add">
                                                        create a Menu Item
                                                    </Link>{' '}
                                                    first
                                                </div>
                                            }
                                            {filteredItems.length > 0 ? (
                                                <div className='menu-item-list mt-3'>
                                                    {filteredItems.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div
                                                                    className={`menu-item-box${isSelected(item) ? ' item-selected' : ''}`}
                                                                    onClick={() => handleItemSelection(item, index)}
                                                                >
                                                                    <figure>
                                                                        <img src={item.logo} alt={item.item_name} />
                                                                    </figure>
                                                                    <figcaption className="text-center">
                                                                        <h4 className="m-0">{capitalizeFirstLetter(item.item_name)}</h4>
                                                                        <span>{item.default_price} SAR</span>
                                                                    </figcaption>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <div className='text-center mt-3'>
                                                    <img src={noRecordFoundImg} alt='No record found' />
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {console.log("filteredCombos--->", filteredCombos)
                                            }
                                            {filteredCombos.length > 0 ? (
                                                <div className='menu-item-list mt-3'>
                                                    {filteredCombos.map((combo, index) => {
                                                        // const isSelected = formik.values.items.map(item => item.name).includes(combo.name);
                                                        return (
                                                            <div
                                                                key={index}

                                                                className={`menu-item-box ${isSelected(combo) ? ' item-selected' : ''}`}
                                                                // className={`menu-item-box ${isSelected ? 'item-selected' : ''}`}
                                                                onClick={() => handleComboSelection(combo)}
                                                            >
                                                                <figure>
                                                                    <img src={combo?.logo} alt={combo?.name} />
                                                                </figure>
                                                                <figcaption className="text-center">
                                                                    <h4 className='m-0'>{capitalizeFirstLetter(combo?.name)}</h4>
                                                                    <span>{combo?.price} SAR</span>
                                                                </figcaption>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <div className='text-center mt-3'>
                                                    <img src={noRecordFoundImg} alt='No record found' />
                                                </div>
                                            )}

                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* {showModal && (
                <ItemDetailsModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    item={selectedItem}
                    onNext={handleNext}
                />
            )} */}

            <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '22px' }}>Meal Combo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedComboDetails ? (
                        <div className="container mt-4">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <h4 className="card-title"><strong>Name:</strong> {capitalizeFirstLetter(selectedComboDetails?.item_name)}</h4>
                                        </div>
                                        <div className="col-md-6">
                                            {/* <h4 className="card-title"><strong>Name (AR):</strong> {selectedComboDetails?.name_ar}</h4> */}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <p><strong>Description:</strong> {capitalizeFirstLetter(selectedComboDetails?.description)}</p>
                                        </div>
                                        <div className="col-md-6">
                                            {/* <p><strong>Description (AR):</strong> {selectedComboDetails?.description_ar}</p> */}
                                        </div>
                                    </div>
                                    <p className="font-weight-bold"><strong>Price:</strong> {selectedComboDetails?.default_price} SAR</p>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <h5 className="mt-0 mb-4 font-weight-bold" style={{ fontSize: '20px' }}><i className="bi bi-box"></i> Combo Items </h5>
                                    <ul className="list-unstyled">
                                        {selectedComboDetails?.combo_items?.map((item, idx) => (
                                            <li key={idx} className="mb-3 p-3 border rounded">

                                                <div className="row mb-2">
                                                    <div className="col-md-6">
                                                        <p className="font-weight-bold mb-1 fs-4 " ><strong>Item Type:</strong> {capitalizeFirstLetter(item?.item_type)}</p>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <p className="font-weight-bold mb-1"><strong>Quantity:</strong> {item?.quantity}</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                    </div>
                                                </div>
                                                <ul className="list-unstyled mt-2">
                                                    {item?.combo_menu_items?.map((menuItem, idy) => (
                                                        <li key={idy} className="mb-2">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <p className="mb-1"><strong>Item Name:</strong> {capitalizeFirstLetter(menuItem?.menu_item?.item_name)}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p className="mb-1"><strong>Price:</strong> {menuItem?.price} SAR</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container mt-4">
                            <p className="text-center text-muted">No details available.</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-primary" onClick={() => setIsModalOpen(false)}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={selectedItem !== null} onHide={() => { setSelectedItem(null) }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '22px' }}>Select Modifier</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container p-0">
                        <table className="table table-custom p-0 m-0">
                            <thead className="thead-light">
                                <tr className="text-center">
                                    <th>Item</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {console.log("formik?.values?.items[]?.menu_addons", formik?.values?.items)}
                                {getAddons(selectedItem)?.map((addOn, index) => (
                                    <tr key={index} className="text-center align-middle">
                                        <td>{capitalizeFirstLetter(addOn?.name)}</td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button
                                                    className="btn btn-outline-secondary mx-1"
                                                    style={{ width: '30px', height: '30px', padding: '0' }}
                                                    onClick={() => handleMinAddOn(index, 'decrement')}
                                                    disabled={addOn.quantity === addOn.min}
                                                >
                                                    <i className='ti ti-minus' style={{ margin: 0, padding: 0 }}></i>
                                                </button>
                                                <span className="mx-2">{addOn.quantity}</span>
                                                <button
                                                    className="btn btn-outline-secondary mx-1"
                                                    style={{ width: '30px', height: '30px', padding: '0' }}
                                                    onClick={() => handleMinAddOn(index, 'increment')}
                                                    disabled={addOn.quantity === addOn.max}
                                                >
                                                    <i className='ti ti-plus'></i>
                                                </button>
                                            </div>
                                        </td>
                                        <td><span className="mx-2">{showPrice(addOn.price)}</span> SAR</td>
                                        <td>{showPrice(addOn.total_price)} SAR</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Display final price below the table */}
                        <div className="text-end mt-3">
                            <h5 style={{ fontSize: '15px' }}>Final Price: <span className="font-weight-bold">{showPrice(getAddonsFinalPrice(selectedItem))} SAR</span></h5>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => { setSelectedItem(null) }}>Cancel</Button>
                    <Button variant="primary" onClick={() => {
                        setSelectedItem(null)
                    }}>Next</Button>
                </Modal.Footer>
            </Modal>
        </form>
    );
};
