
import React, { useState, useEffect,useRef} from 'react'
import { Field, Formik } from 'formik';
import { CreateCompany } from '../../services/companyPanelService';
import { useNavigate} from 'react-router-dom';
import Swal from "sweetalert2"
import $ from 'jquery';
import { countries } from 'countries-list';
import LoginLayout from '../../companyloginlayout';
import { Dropdown } from 'semantic-ui-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CompanyAdd = () => {

  const fileInputRef = useRef(null);

  const handleReset = () => {
    // Reset the value of the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };



  const navigate = useNavigate()
  const [submitted, setSubmitted] = useState(false)
  const [year, setYear] = useState([])
  const [selectedCountryCode, setSelectedCountryCode] = useState([]);

  let options = Object.keys(countries).map((countryCode) =>{
    return {
      key: `${countries[countryCode].phone}`,
      text: `${countries[countryCode].phone}`,
      value: `${countries[countryCode].phone}`,
    }  
  });

  const seen = new Set();
  const filterOptions = options.filter(obj => {
    const duplicate = seen.has(obj.key);
    seen.add(obj.key);
    return !duplicate;
  });

  // Using filter and a Set to track unique ids
  
  

  useEffect(() => {
    
    var currentYear = new Date().getFullYear();
    let yearArray = [];
    for (var i = 1800; i <= currentYear; i++) {
      const data = {
      key: i,
      text: i,
      value: i,
    }
      yearArray.push(data);
    }

    setYear(yearArray)
    setSelectedCountryCode(filterOptions);
  }, [])

  return (
    <LoginLayout>
    <section>
        <Formik
        initialValues={{
          name : '',
          established_year: '' ,
          official_address:'',
          official_email:'',
          official_phone_number:'',
          country_code:'',
        }}
        validate={values => {
          const error = {};
          
          if (!values.name.trim()) {
            error.name = "Name is required";
          } else if (values.name.length < 3) {
            error.name = "Name must be at least 3 characters";
          } else if (values.name.length > 100) {
            error.name = "Name must be less than 100 characters";
          }
          if (!values.established_year) error.established_year = "Established year is required";
          if (!values.official_address.trim()) {
            error.official_address = "Official address is required";
          } else if (values.official_address.length < 5) {
            error.official_address = "Official address must be at least 5 characters";
          } else if (values.official_address.length > 100) {
            error.official_address = "Official address must be less than 100 characters";
          }          if (!values.country_code.trim()) error.country_code = "Country code is required";
          if (!values.official_email.trim()) {
            error.official_email = "Email is required";
          } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values.official_email)) {
            error.official_email = "Invalid email address";
          }
         

          if (!values.official_phone_number.trim()) {
            error.official_phone_number = "Phone number is required";
          } else if (!/^\d{6,15}$/.test(values.official_phone_number)) {
            error.official_phone_number = 'Please enter a valid phone number with 6 to 15 digits';
          }
          return error;
        }}

        onSubmit={(values, { setSubmitting,resetForm}) => {
          setSubmitted(true)
          let formData = new FormData();
         
          formData.append('name', values.name);
          formData.append('established_year', values.established_year);
          formData.append('official_address', values.official_address);
          formData.append('official_email', values.official_email);
          formData.append('official_phone_number', values.official_phone_number);
          formData.append('country_code', values.country_code);  

          CreateCompany(formData).then(response => {
            setSubmitting(false);
            if (response.data.status === 1 || response.data.status === "1") {
              Swal.fire({
                  customClass: 'swal-wide',
                  icon: 'success',
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                  toast: true,
                  position: 'top-right',
              });
              resetForm();
              setSubmitted('');
              handleReset();

            } else {
              Swal.fire({
                  customClass: 'swal-wide',
                  icon: 'error',
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 5000,
                  toast: true,
                  position: 'top-right',
              })
              setSubmitted(false)
            }
          }).catch(error => {
              console.log("error ====> ", error);
          })
        }}
        
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
      }) => (
          <form onSubmit={handleSubmit}>
            <div class="contact-form-home">
              <div class="about-text about-text-dark text-center mb-4">
                <h4>Want to join ? </h4>
              </div>
              <div class="contact-form-body">
                <div class="form-group mb-3">
                  <label class="col-form-label">Company Name <span className="requirestar">*</span></label>
                  <input name='name' id="name" type='text' onChange={handleChange} onBlur={handleBlur} value={values.name}  className='form-control' />
                  <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                </div>
                <div class="form-group mb-3">
                  <label class="col-form-label">Established year <span className="requirestar">*</span></label>
                  <Dropdown
                        className='form-control'
                        id="established_year"
                        placeholder='Select Year'
                        search
                        fluid
                        selection
                        options={year}
                        value={values.established_year}
                        onChange={(event, data) => { setFieldValue("established_year", data.value) }}
                    />
                  <span className='text-danger d-flex text-left'>{errors.established_year && touched.established_year && errors.established_year}</span>
                </div>
                <div class="form-group mb-3">
                  <label class="col-form-label">Official address <span className="requirestar">*</span></label>
                  <input name='official_address' id="official_address" type='text' onChange={handleChange}  onBlur={handleBlur} value={values.official_address}   className='form-control' />
                  <span className='text-danger d-flex text-left'>{errors.official_address && touched.official_address && errors.official_address}</span>
                </div>
                <div class="form-group mb-3">
                  <label class="col-form-label">Official email <span className="requirestar">*</span></label>
                  <input name='official_email' id="official_email" type='email' onChange={handleChange}  onBlur={handleBlur} value={values.official_email}  className='form-control' />
                  <span className='text-danger d-flex text-left'>{errors.official_email && touched.official_email && errors.official_email}</span>
                </div>
                <div class="form-group mb-3">
                  <label class="col-form-label">Official phone number <span className="requirestar">*</span></label>
                  <div className="input-group">
                    <span className=''>
                      <Dropdown
                          className='form-control'
                          id="country_code"
                          placeholder='Code'
                          search
                          fluid
                          selection
                          options={selectedCountryCode}
                          value={values.country_code}
                          onChange={(event, data) => { setFieldValue("country_code", data.value) }}
                      />
                    </span>
                    <input name='official_phone_number' id="official_phone_number" type='text' onChange={handleChange}  onBlur={handleBlur} value={values.official_phone_number}  className='form-control' />
                    </div>
                    <span className='text-danger d-flex text-left'>{errors.official_phone_number && touched.official_phone_number && errors.official_phone_number}</span>
                    <span className='text-danger d-flex text-left'>{errors.country_code && touched.country_code && errors.country_code}</span>
                </div>
                <button class="btn btn-primary w-100" type="submit">Submit</button>
              </div>
            </div>
          </form>
        )}
        </Formik>
        <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={true | 1000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
    </section>
    </LoginLayout>
  );
}

export default CompanyAdd