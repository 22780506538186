const { MONTHS } = require("../utils/Constants");
// import Swal from 'sweetalert2';
module.exports = {
    formateDate: (dateString, showTime = true) => {
        let newStrDate = "";
        const date = new Date(dateString);
        newStrDate = MONTHS[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
        if (showTime) {
            const hour = date.getHours() == 0 ? 12 : (date.getHours() > 12 ? date.getHours() - 12 : date.getHours());
            const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            const secs = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            const ampm = date.getHours() < 12 ? 'AM' : 'PM';
            // newStrDate += ` ${hour}:${min}:${secs} ${ampm}`;
        }
        return newStrDate;
    },

    formateDateWithTime: (dateString, showTime = true) => {
        let newStrDate = "";
        const date = new Date(dateString);
        newStrDate = MONTHS[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();

        if (showTime) {
            const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
            newStrDate += `  ${time}`;
          }

        return newStrDate;
    },

    formatePickerDate: (dateString) => {
        const date = new Date(dateString);
        return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
    },

    Editorconvertercsv: (paragraph) => {
        let text = ""
        return text = paragraph ? paragraph.toString().replace(/(<([^>]+)>)/gi, "").replace(/(?:\\[rn]|[\r\n]+)+/g, "") : "-";
    },

    formateDataMultiSelect: (data, labelField, valueField) => {
        let newData = [];
        if (Array.isArray(data)) {
            data.forEach((item) => {
                let newItem = {
                    label: item[labelField],
                    value: item[valueField]
                };
                newData.push(newItem);
            });
        }
        return newData;
    },

    formateIdArray: (data, fromField) => {
        let newData = [];
        if (Array.isArray(data)) {
            data.forEach((item) => {
                newData.push(item[fromField]);
            });
        }
        return newData;
    },

    TrimText: (value, size = 40) => {
        return value != undefined && value.length > size ? value.slice(0, size) + "..." : value;
    },

    // logOutUser: () => {
    //     localStorage.clear();
    //     window.location.href = "/login";
    // },

    logOutUser: () => {

        if (localStorage.getItem('user')) {

            localStorage.removeItem('user');
            localStorage.removeItem('token');
            window.location.href = "/admin/login";

        }

    },

    logOutCompany: () => {

        if (localStorage.getItem('company_user')) {

            localStorage.removeItem('company_user');
            localStorage.removeItem('company_token');
            localStorage.removeItem('permission');
            localStorage.removeItem('outlet_id');
            localStorage.removeItem('company_detail');
            window.location.href = "/company/login";

        }

    },

    setSessionKey: (key, value) => {
        sessionStorage.setItem(btoa(key), btoa(value));
    },

    getSessionKey: (key) => {
        if (sessionStorage.getItem(btoa(key))) {
            return atob(sessionStorage.getItem(btoa(key)));
        }
    },

    removeSessionKey: (key) => {
        return sessionStorage.removeItem(btoa(key));
    },

    getUser: function () {
        if (localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user'));
            return user;
        } else {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            window.location.href = "/admin/login";
        }
    },

    getCompany: function () {
        if (localStorage.getItem('company_user')) {
            const user = JSON.parse(localStorage.getItem('company_user'));
            return user;
        } else {
            localStorage.removeItem('company_user');
            localStorage.removeItem('company_token');
            window.location.href = "/company/login";
        }
    },

    getBrandId: function () {
        if (localStorage.getItem('brand_id')) {
            return localStorage.getItem('brand_id');
        } else {
            return null;
        }
    },

    getOutletId: function () {
        if (localStorage.getItem('outlet_id')) {
            return localStorage.getItem('outlet_id');
        } else {
            return null;
        }
    },

    blockInvalidChar: (e) => {
        const str = 'eE+-.,;:/|\\!@#$%^&*()_=~`abcdefghijklmnopqrstuvwxyzABCEFGHIJKLMNOPQRSTUVWXYZ';
        return str.split('').includes(e.key) && e.preventDefault();
    },

    companyDetail: function () {
        if (localStorage.getItem('company_detail')) {
            return JSON.parse(localStorage.getItem('company_detail'));
        }
    },


    checkPermission: function (moduleName, permissionName) {
        const permissions = JSON.parse(localStorage.getItem('permission'));

        var permissionsCheck = false;
        if (permissions) {

            permissions.map(value => {

                if (value.module_name == moduleName && value.permission_name == permissionName) {
                    permissionsCheck = true;
                    return;
                }
            });


        }
        return permissionsCheck;
    },

    capitalizeFirstLetter: (str) => {
        if (str && str.length) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        } else {
            return "";
        }
    },

    capitalizeAllFirstLetters: (str) => {
        if (!str) return "";

        return str?.split(" ")?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(" ");
    },

    removeSpecialCharacter: (value) => {
        let text = "";
        try {
            text = String(value);
        } catch (err) {
            console.log(err);
        }
        if (text) {
            return text.replace("-", " ").replace("_", " ");
        }
        return "";
    },

    SpecialCharacter: (text) => {
        if (text) {
            return text.replace(/_/g, " ").replace("-", " ");
        } else {
            return "";
        }
    },

    removeHyphens: (text) => {
        if (text) {
            return text.replace(/-/g, " ");
        } else {
            return "";
        }
    },


    diffrenceBetweenTwoDates: (startDate, endDate) => {
        const date1 = new Date(startDate);
        const date2 = new Date(endDate);
        console.log("date2 - date1", date2 - date1);
        const diffTime = date2 - date1;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays;
    },

    getDiffrenceInDays: (startDate, endDate) => {
        if (typeof startDate === "object") {
            startDate = startDate.toISOString().split("T")[0];
        }
        if (typeof endDate === "object") {
            endDate = endDate.toISOString().split("T")[0];
        }
        const date1 = new Date(startDate);
        const date2 = new Date(endDate);
        const diffTime = date2 - date1;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    },
    convertTo12Hour: (timeStr) => {
        let [hours, minutes, seconds] = timeStr.split(':').map(Number);
        let period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${period}`;
    },

    handleServerValidations: (response) => {
        let message = response.message;
        if (Array.isArray(response.error)) {
            if (response.error[0] && response.error[0].msg) {
                message = response.error[0].msg;
            } else if (response.error[0] && response.error[0].message) {
                message = response.error[0].message;
            }
        }
        return message;
    },
    formatTime: (time) => {
        return time ? time.split(':').slice(0, 2).join(':') : "N/A";
    },
    getExtension: (fileName) => {
        const fileNameArr = fileName.split(".");
        return fileNameArr[fileNameArr.length - 1];
    },

    showLimitedElements: (arr, numToShow) => {
        if (!Array.isArray(arr) || typeof numToShow !== 'number' || numToShow < 0) {
            return 'Invalid input';
        }

        const elementsToShow = arr.slice(0, numToShow);
        const remainingElementsCount = arr.length - numToShow;

        let result = elementsToShow.join(', ');

        if (remainingElementsCount > 0) {
            result += `, and ${remainingElementsCount} more...`;
        }

        return result;
    },

    removeModalBackdrops: () => {
        const modals = document.querySelectorAll('.modal-backdrop');
        modals.forEach((item) => {
            item.remove();
        });
    },

    slugToReadable: (slug) => {
        if (!slug) return '';

        // Replace hyphens with spaces
        let readable = slug.replace(/-/g, ' ');

        // Capitalize the first letter of each word
        // readable = readable
        //     .toLowerCase()
        //     .replace(/\b\w/g, char => char.toUpperCase());

        return readable;
    },

    showPrice: (amount) => {
        if (Number(amount)) {
            return Number(Number(amount).toFixed(2));
        }
        return 0.00;
    },


};

