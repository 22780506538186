import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../components/company_panel/common/loader';
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import { CouponDetails } from '../../services/company_panel/coupons.service';
import { SpecialCharacter, capitalizeAllFirstLetters, capitalizeFirstLetter, convertTo12Hour, formateDate, formateDateWithTime, getBrandId, getCompany } from '../../helpers/commonFunctions';
import { GetActiveOrderModes } from '../../services/company_panel/setting/orderModeConfigurationService';
import { GetBrandOutletsList } from '../../services/company_panel/outletService';
import { getAllMenuItems } from '../../services/company_panel/mealService';

const CouponsView = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [showDefault, setShowDefault] = useState({})
  const [orderModeData, setOrderModesData] = useState([]);
  const [outletList, setOutletList] = useState([]);
  const [menuItemData, setMenuItemsData] = useState([]);
  const breadcrumbs = [{ title: "Coupons", url: "/company/crm/coupons/coupon/list/1" }, {
    title: "Coupon Detail",
    url: ""
  },]

  useEffect(() => {
    CouponDetails({ id: params.id }).then(data => {
      setShowDefault(data && data.data && data.data.response ? data.data.response : [])
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [])

  useEffect(() => {
    GetActiveOrderModes(getBrandId()).then(data => {
      setOrderModesData(data && data.data && data.data.response ? data.data.response : []);
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [])

  useEffect(() => {
    GetBrandOutletsList({ brand_id: getBrandId() }).then(data => {
      setOutletList(data && data.data && data.data.response ? data.data.response : []);
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [])

  useEffect(() => {
    getAllMenuItems({ brand_id: getBrandId() }).then(data => {
      if (data && data.data && data.data.response) {
        setMenuItemsData(data.data.response.rows);
      }
    }).catch(error => {
      console.log("error=====>", error);
    });
  }, []);

  const showWeek = (data) => {
    return (
      <div className='d-flex flex-wrap '>
        {data?.map((option, i) => {
          return (
            <span key={i} className="me-1 badge btn-primary d-flex  justify-content-end text-capitalize">{option}</span>
          )
        })}
      </div>
    )
  }

  const getOutletNames = (outletIds) => {
    return outletIds.map(id => {
      const outlet = outletList.find(outlet => outlet.id === id);
      return (
        <span key={id} className="badge btn-primary d-flex justify-content-end text-capitalize">
          {outlet?.outlet_name}
        </span>
      );
    });
  };

  const getApplicableNames = () => {
    if (!showDefault.applicable_on || !orderModeData.length) return "N/A";
    const applicableIds = new Set(showDefault.applicable_on.map(id => Number(id)));
    const applicableNames = orderModeData
      .filter(mode => applicableIds.has(Number(mode.id)))
      .map(mode => mode.name);
    return (
      <div className="w-50 justify-content-end d-flex flex-wrap gap-2">
        {applicableNames.length > 0 ? (
          applicableNames.map((name, index) => (
            <span
              key={index}
              className="me-1 badge btn-primary d-flex justify-content-end text-capitalize"
            >
              {name}
            </span>
          ))
        ) : (
          <span className="badge bg-secondary">N/A</span>
        )}
      </div>
    );
  };

  const getMenuItemsNames = (menuItemsIds) => {
    return menuItemsIds.map(id => {
      const menuItems = menuItemData.find(outlet => outlet.id === id);
      return (
        <span key={id} className="badge btn-primary d-flex justify-content-end text-capitalize">
          {menuItems?.item_name}
        </span>
      );
    });
  };




  return (
    <>
      <div class="page-header d-flex align-items-center">
        <div class="page-header-left">
          <Breadcrums data={breadcrumbs} />
          <h4>Coupons</h4>
        </div>
      </div>
      <div className="card custom-card">
        {showDefault && Object.keys(showDefault).length > 0 ?
          <div className="card-body p-0">
            <div className="col-lg-12 form-group">
              <table id="simple-table" className="table table-custom  ">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td className='text-end'>{showDefault && showDefault.name ? capitalizeAllFirstLetters(showDefault.name) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td >Coupon Code</td>
                    <td className='text-end'>{showDefault && showDefault.coupon_code ? capitalizeAllFirstLetters(showDefault.coupon_code) : "N/A"}</td>
                  </tr>
                  {/* <tr>
                    <td>Type</td>
                    <td className='text-end'>{showDefault && showDefault.type ? SpecialCharacter(capitalizeAllFirstLetters(showDefault.type)) : "N/A"}</td>
                  </tr> */}
                  <tr>
                    <td>Type</td>
                    <td className='text-end'>
                      {showDefault && showDefault.type ? (
                        showDefault.type === 'item_free' ? (
                         'Free Item'
                        ) : (
                          SpecialCharacter(capitalizeAllFirstLetters(showDefault.type))
                        )
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                  {showDefault?.type === "flat" ? (
                    <tr>
                      <td>Flat Discount</td>
                      <td className='text-end'>
                        {showDefault && showDefault.percentage ? (showDefault.percentage + " SAR") : "N/A"}
                      </td>
                    </tr>
                  ) : showDefault?.type === "percentage" ? (
                    <tr>
                      <td>Discount (Percentage)</td>
                      <td className='text-end'>
                        {showDefault && showDefault.percentage ? (showDefault.percentage + " %") : "N/A"}
                      </td>
                    </tr>
                  ) : (
                    null
                  )}
                  {showDefault?.type == "percentage" || showDefault?.type == "ask_for_amount" || showDefault?.type == "ask_for_percentage" ?
                    <tr>
                      <td>Maximum Discount Amount</td>
                      <td className='text-end'>{showDefault && showDefault.maximum_discount_amount ? showDefault.maximum_discount_amount + " " + "SAR" : 0 + " " + "SAR"}</td>
                    </tr>
                    : null}
                  {showDefault?.type == "percentage" || showDefault?.type == "flat" || showDefault?.type == "ask_for_amount" || showDefault?.type == "ask_for_percentage" ?
                    <tr>
                      <td>Minimum Applicable Order Amount</td>
                      <td className='text-end'>{showDefault && showDefault.minimum_applicable_order_amount ? showDefault.minimum_applicable_order_amount + " " + "SAR" : 0 + " " + "SAR"}</td>
                    </tr>
                    : null}
                  {showDefault?.type === "item_free" ?
                    <tr>
                      <td>Menu Items</td>
                      <td className='d-flex justify-content-end'>
                        <div className='w-50 d-flex justify-content-end flex-wrap gap-1'>
                          {showDefault && showDefault.menu_items ? (
                            <>
                              {getMenuItemsNames(showDefault.menu_items).map((data, index) => (
                                <>
                                  <span key={index} className='outlet-name'>
                                    {data}
                                  </span>
                                  {(index + 1) % 3 === 0 && index !== getMenuItemsNames(showDefault.menu_items).length - 1 && (
                                    <div key={`break-${index}`} ></div>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </td>

                    </tr>
                    : null}
                  <tr>
                    <td>Applicable On</td>
                    <td className='text-end d-flex justify-content-end'>
                      {getApplicableNames()}
                    </td>
                  </tr>
                  <tr>
                    <td>Usage Condition</td>
                    <td className='text-end'>{showDefault && showDefault.usage_condition ? SpecialCharacter(capitalizeAllFirstLetters(showDefault.usage_condition)) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Usage Per Customer</td>
                    <td className='text-end'>{showDefault && showDefault.usage_per_customer ? SpecialCharacter(capitalizeAllFirstLetters(showDefault.usage_per_customer)) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Usage Condition Number</td>
                    <td className='text-end'>{showDefault && showDefault.usage_condition_number ? (showDefault.usage_condition_number) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Usage Per Customer Number</td>
                    <td className='text-end'>{showDefault && showDefault.usage_per_customer_number ? (showDefault.usage_per_customer_number) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Customer Type</td>
                    <td className='text-end'>{showDefault && showDefault.customer_type ? SpecialCharacter(capitalizeAllFirstLetters(showDefault.customer_type)) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Authentication Type</td>
                    <td className='text-end'>{showDefault && showDefault.authentication_type ? SpecialCharacter(capitalizeAllFirstLetters(showDefault.authentication_type)) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Outlet</td>
                    <td className='d-flex justify-content-end'>
                      <div className='w-50 d-flex justify-content-end flex-wrap gap-1'>
                        {showDefault && showDefault.outlet_id ? (
                          <>
                            {getOutletNames(showDefault.outlet_id).map((outlet, index) => (
                              <>
                                <span key={index} className='outlet-name'>
                                  {outlet}
                                </span>
                                {(index + 1) % 3 === 0 && index !== getOutletNames(showDefault.outlet_id).length - 1 && (
                                  <div key={`break-${index}`} ></div>
                                )}
                              </>
                            ))}
                          </>
                        ) : (
                          "N/A"
                        )}
                      </div>
                    </td>

                  </tr>



                  <tr>
                    <td>Start Date </td>
                    <td className='text-end'>{showDefault && showDefault.start_date ? (formateDate(showDefault.start_date)) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>End Date </td>
                    <td className='text-end'>{showDefault && showDefault.end_date ? (formateDate(showDefault.end_date)) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Start Time </td>
                    <td className='text-end'>{showDefault && showDefault.start_time ? (convertTo12Hour(showDefault.start_time)) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>End Time </td>
                    <td className='text-end'>{showDefault && showDefault.end_time ? (convertTo12Hour(showDefault.end_time)) : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Week </td>
                    <td className='text-end d-flex justify-content-end'>{showDefault && showDefault.week ? showWeek(showDefault.week) : "N/A"}</td>
                  </tr>
                </tbody>
                <button className="btn btn-primary m-4" onClick={() => navigate(`/company/crm/coupons/${params.id}/edit/${params.id}`)}>Update Coupon</button>
              </table>
            </div>
          </div> : <Loader />}
      </div>
    </>
  )
}

export default CouponsView