import React, { useState } from 'react'
import LoginLayout from '../../companyloginlayout'
import { Formik } from 'formik'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetResetpassword } from '../../services/company_panel/LoginCompanyServices';
import Logo from '../../assets/company/img/logo.png';
import loginBg from '../../assets/company/img/login4.jpeg';
import { getSessionKey, removeSessionKey } from '../../helpers/commonFunctions';


const CompanyResetPwdPage = () => {
	const navigate = useNavigate();
	const [newshow, setNewShow] = useState('false');
	const [conformshow, setConformShow] = useState('false');
	const [searchParams] = useSearchParams();
	const handleshow = (event) => {
		if (event === "newpwd") {
			setNewShow(!newshow)
		}
		if (event === "confirepwd") {
			setConformShow(!conformshow)
		}
	}

	return (
		<LoginLayout>
			<Formik
				initialValues={{
					newpassword: '',
					confirmpassword: ''
				}}
				validate={values => {
					const errors = {};
					if (!values.newpassword) {
						errors.newpassword = 'Password is required';
					} else if (values.newpassword.length < 6) {
						errors.newpassword = 'Password must be at least 6 characters long';
					}

					if (!values.confirmpassword) {
						errors.confirmpassword = 'Confirm password is required';
					} else if (values.confirmpassword !== values.newpassword) {
						errors.confirmpassword = 'Password does not match';
					}
					return errors;
				}}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					let formData = new FormData();
					formData.append("password", values.newpassword);
					formData.append("confirm_password", values.confirmpassword);
					formData.append("validate_string", getSessionKey('reset_password_token'));
					formData.append("company_id", searchParams.get('company_id'));

					GetResetpassword(formData).then(data => {
						if (data && data.data && data.data.statuscode === 200) {
							removeSessionKey('reset_password_token');
							toast(data.data.message);
							setTimeout(() => {
								resetForm()
								navigate("/company/login");
							}, 2000);
						} else if (data && data.data && data.data.statuscode !== 200) {
							toast(data.data.message);
						}
					}).catch(error => {
						console.log("error: ", error);
					})
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					// and other goodies
				}) => (
					<div className="page">
						<div className="login-wraper">
							<div className='login-wraper-in'>
								<div className='login-left'>
									<figure>
										<img src={loginBg} />
									</figure>
									<div className='login-text'>
										<h4>Efficiency at your fingertips! </h4>
										<p>With POS Restaurant Admin, managing your  restaurant has never been easier. From orders to inventory, we've got  you covered.</p>
									</div>
								</div>
								<div className='login-right'>
									<div className='login-right-in'>
										<div class="login-head">
											<a href="javascript:;">
												<img src={Logo} />
											</a>
											<div class="login-head-text">
												<h2>Reset Password</h2>
												<p>Create a new password</p>
											</div>
										</div>
										<div className='login-body'>
											<div className='form-box'>
												<form onSubmit={handleSubmit}>
													<div className="form-group text-left">
														<label>New Password</label>
														<div className="input-group">
															<input className={"form-control"}
																placeholder="Enter password"
																type={!newshow === true ? "text" : "password"}
																name="newpassword"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.newpassword}
																autoComplete="off" />
															<span className='input-group-text' onClick={() => { handleshow("newpwd") }}>{(!newshow === true ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>)}</span>
														</div>
														<span className='text-danger text-left d-blockerr-spn'>{errors.newpassword && touched.newpassword && errors.newpassword}</span>
													</div>
													<div className="form-group text-left">
														<label>Confirm Password</label>
														<div className="input-group">
															<input className={"form-control"}
																placeholder="Enter confirm password"
																type={!conformshow === true ? "text" : "password"}
																name="confirmpassword"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.confirmpassword}
																autoComplete="off" />
															<span className='input-group-text' onClick={() => { handleshow("confirepwd") }}>{(!conformshow === true ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>)}</span>
														</div>
														<span className='text-danger text-left d-blockerr-spn'>{errors.confirmpassword && touched.confirmpassword && errors.confirmpassword}</span>
													</div>
													<button className="btn ripple btn-primary signbtn w-100 position-relative mt-4" type='submit'>Reset</button>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</Formik>
			{/* Error message show */}
			<ToastContainer
				position="top-right"
				hideProgressBar={false}
				autoClose={true | 1500}
				newestOnTop={true}
				closeOnClick={false}
				draggable={false}
				rtl={false}
			/>
			{/* error msg end */}
		</LoginLayout>
	)
}

export default CompanyResetPwdPage