import React, { useState, useEffect, useMemo } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import { AssignGroup, DetailsStation, GetAssignedGroups, GetAvailableGroups } from '../../../services/company_panel/routingStationService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { capitalizeFirstLetter, getBrandId } from '../../../helpers/commonFunctions';

const AssignGroups = () => {
    const params = useParams();
    const navigate = useNavigate();
    const breadcrumbs = [
        {
            title: "Routing Station",
            url: "/company/menu/routing-station/groups/list/1"
        },
        {
            title: "Stations",
            url: "/company/menu/routing-station/stations/list/1"
        },
        {
            title: "Assign Groups",
            url: ""
        },
    ]

    const [showDefault, setShowDefault] = useState({})
    const [availableGroups, setAvailableGroups] = useState([]);
    const [assignedGroups, setAssignedGroups] = useState([]);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentBrand] = useState(getBrandId());

    useMemo(()=>{
		document.addEventListener('brand-changed', function(){
			setIsBrandChanged(!isBrandChanged);
		});
        setTimeout(() => {
            if (currentBrand != getBrandId()) {
                navigate(`/company/menu/routing-station/stations/list/${1}`);
            }
        }, 100);
	});

    useEffect(() => {
        GetAvailableGroups(params.id, getBrandId()).then((response) => {
            setAvailableGroups(response?.data?.response);
        })
            .catch((error) => {
                console.log("error===>", error)
            })
    }, [isBrandChanged])

    useEffect(() => {
        GetAssignedGroups(params.id).then((response) => {
            setAssignedGroups(response?.data?.response);
        })
            .catch((error) => {
                console.log("error===>", error)
            })
    }, [])

    useEffect(() => {
        DetailsStation(params.id).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    const handleOnDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) return;

        let newAvailableGroups = [...availableGroups];
        let newAssignedGroups = [...assignedGroups];

        if (source.droppableId === 'availableGroups' && destination.droppableId === 'assignedGroups') {
            const [movedGroup] = newAvailableGroups.splice(source.index, 1);
            newAssignedGroups.splice(destination.index, 0, movedGroup);
        } else if (source.droppableId === 'assignedGroups' && destination.droppableId === 'availableGroups') {
            const [movedGroup] = newAssignedGroups.splice(source.index, 1);
            newAvailableGroups.splice(destination.index, 0, movedGroup);
        } else if (source.droppableId === destination.droppableId) {
            const groups = source.droppableId === 'availableGroups' ? newAvailableGroups : newAssignedGroups;
            const [movedGroup] = groups.splice(source.index, 1);
            groups.splice(destination.index, 0, movedGroup);
        }

        setAvailableGroups(newAvailableGroups);
        setAssignedGroups(newAssignedGroups);
    };

    function handleSave() {
        const groupIds = assignedGroups.map((group) => group.id);
        AssignGroup({ id: params.id, brand_id: getBrandId(), assigned_items: JSON.stringify(groupIds) })
            .then((response) => {
                if (response.data.status === 1 || response.data.status === "1") {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true,
                        position: 'top-right',
                    });
                    setTimeout(() => {
                        navigate(`/company/menu/routing-station/stations/list/${1}`)
                    }, 1000);
                } else {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'error',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 5000,
                        toast: true,
                        position: 'top-right',
                    })
                }

            })
            .catch((error) => {
                console.log("error===>", error)
            })
    }



    return (
        <>
            <div class="page-header d-flex align-items-center">
                <div class="page-header-left">
                    <h4>Routing Station</h4>
                    <Breadcrums data={breadcrumbs} />
                </div>
                <div class="page-header-right ms-auto">
                </div>
            </div>
            <div className="card custom-card">
                <div className="card-body">
                    <div className="card">
                        <div className="card-body">
                         
                            <div className="d-flex justify-content-between p-0">
                                <p className='m-0 fw-bold'>Station Name</p>
                                {/* <input type="text" className="form-control" id="groupName" defaultValue="Vegetables Group" /> */}
                                <div>{showDefault?.name}</div>
                            </div>
                        </div>
                    </div>
                    <p className='mt-4 fw-bold'>Drag and drop group onto the right-hand side<span className='requirestar'>*</span></p>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <div className="row">
                            <Droppable droppableId="availableGroups">
                                {(provided) => (
                                    <div className="col-md-6" {...provided.droppableProps} ref={provided.innerRef} >
                                        <div className='card'>
                                            <div className="card-body">
                                                <h5>Available Groups</h5>
                                                {availableGroups.map((group, index) => (
                                                    <Draggable key={index} draggableId={"group_" + group.id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="draggable-item"
                                                            >
                                                                {capitalizeFirstLetter(group?.name)}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="assignedGroups">
                                {(provided) => (
                                    <div className="col-md-6" {...provided.droppableProps} ref={provided.innerRef} >
                                        <div className='card'>
                                            <div className="card-body">
                                                <h5>Assigned Groups</h5>
                                                {assignedGroups.map((group, index) => (
                                                    <Draggable key={index} draggableId={"group_" + group.id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="draggable-item"
                                                            >
                                                                {capitalizeFirstLetter(group?.name)}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </div>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </DragDropContext>
                    <div className="mt-5">
                        <button className="btn btn-primary mr-2" type="button" onClick={handleSave}>
                            Save
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AssignGroups;
